import React, { useRef, MutableRefObject } from 'react';
import { v4 } from 'uuid';

import Input from '../../../../components/Input';

import { dateBrToDefaultString } from '../../../../../../utils/converts';
import { dateMaskByElementKeyDown, currencyMaskByElement } from '../../../../../../utils/masks';

import { ModalFormProps } from './interface';

import { SelectContainer, ButtonCreateRelease } from './styles';

const ModalForm: React.FC<ModalFormProps> = ({ onSubmit, releaseOptions }) => {
  const displayRef = useRef() as MutableRefObject<HTMLSelectElement>;
  const descriptionRef = useRef() as MutableRefObject<HTMLInputElement>;
  const dateRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleOnSubmit = (e: any): void => {
    e.preventDefault();

    const inputDate = dateRef.current.value;
    const dateString = dateBrToDefaultString(inputDate);
    const date = new Date(dateString);
    const isValidDate = date.getTime();

    if (!isValidDate) {
      dateRef.current.value = '';
      throw alert('Data inválida.');
    }

    const obj = {
      display: +displayRef.current.value as any,
      description: descriptionRef.current.value,
      tms: date.getTime(),
    };

    onSubmit(obj);
  };

  return (
    <form className="1s" onSubmit={handleOnSubmit}>
      <div className="10p">
        <label htmlFor="">
          <div className="blanc">Lançamentos:</div>
        </label>
        <div className="col center">
          <select className="blanc 10p 10r 100w" style={SelectContainer} ref={displayRef}>
            {
              releaseOptions.map((option) => (
                <option key={v4()} value={option.id}>{option.display}</option>
              ))
            }
          </select>
        </div>
      </div>
      <Input labelName="Data" onKeyDown={dateMaskByElementKeyDown} reference={dateRef} minLength={10} maxLength={10} required />
      <Input labelName="Valor" reference={descriptionRef} onChange={currencyMaskByElement} required />
      <div className="10p">
        <button className="100w 10r blanc 1p" type="submit" style={ButtonCreateRelease}>Cadastrar</button>
      </div>
    </form>
  );
};

export default ModalForm;
