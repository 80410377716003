import React, { useRef, useState } from 'react';

interface FilterLeadsAttributes {
  onFilter: (filter: any) => void;
  initialLeads: any;
}

const FilterLeads:React.FC<FilterLeadsAttributes> = ({ initialLeads, onFilter }) => {
  const [filters, setFilters] = useState(() => {
    const currentFilters = localStorage.getItem('@Contemplato/filters-crm');

    if (currentFilters) {
      const storagedFilters = JSON.parse(currentFilters);

      return storagedFilters;
    }

    return {};
  });

  function filterLeads(filter: any): any {
    if (filter) {
      const hasAnyFilter = !!Object.values(filter).length;

      if (hasAnyFilter) {
        const filteredLeads = initialLeads.filter((lead: any) => {
          if (
            filter?.display && !lead.display.toLowerCase().includes(filter?.display.toLowerCase())
          ) {
            return false;
          }

          if (
            filter?.email && !lead.email.toLowerCase().includes(filter?.email.toLowerCase())
          ) {
            return false;
          }

          if (
            filter?.phone && !lead.phone.replaceAll(/\D/g, '').toLowerCase().includes(filter?.phone.replaceAll(/\D/g, '').toLowerCase())
          ) {
            return false;
          }

          if (
            filter?.status && lead.status !== +filter?.status
          ) {
            return false;
          }

          if (
            filter?.extract && lead.extract !== +filter?.extract
          ) {
            return false;
          }

          if (
            filter?.id && filter?.id !== lead.id
          ) {
            return false;
          }

          return true;
        });

        onFilter(filteredLeads);
      } else {
        onFilter(initialLeads);
      }
    }

    setFilters(filter);
  }

  const idRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const displayRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLSelectElement>(null);
  const extractRef = useRef<HTMLSelectElement>(null);

  const handleOnFilter = (e: any): void => {
    e.preventDefault();

    const id = idRef.current?.value && +idRef.current?.value;
    const phone = phoneRef.current?.value && phoneRef.current?.value;
    const email = emailRef.current?.value && emailRef.current?.value;
    const status = statusRef.current?.value && statusRef.current?.value;
    const extract = extractRef.current?.value && extractRef.current?.value;
    const display = displayRef.current?.value && displayRef.current?.value;

    const filterObj = {
      id,
      phone,
      email,
      status,
      extract,
      display,
    };

    localStorage.setItem('@Contemplato/filters-crm', JSON.stringify(filterObj));

    filterLeads(filterObj);
  };

  return (
    <form
      onSubmit={handleOnFilter}
      className="100w"
      style={{
        display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '0 16px', justifyContent: 'space-between',
      }}
    >
      <label style={{ display: 'flex', flexDirection: 'column' }} htmlFor="">
        <b style={{ fontSize: '14px' }}>ID:</b>
        <input
          ref={idRef}
          defaultValue={filters?.id}
          className="10r 100w"
          style={{
            maxWidth: '120px', marginTop: '4px', padding: '10px', border: '1px solid #eaeef5',
          }}
          type="text"
        />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b style={{ fontSize: '14px' }}>Nome:</b>
        <input
          ref={displayRef}
          defaultValue={filters?.display}
          className="10r 100w"
          style={{
            maxWidth: '260px', marginTop: '4px', padding: '10px', border: '1px solid #eaeef5',
          }}
          type="text"
        />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b style={{ fontSize: '14px' }}>Telefone:</b>
        <input
          ref={phoneRef}
          className="10r 100w"
          defaultValue={filters?.phone}
          style={{
            maxWidth: '200px', marginTop: '4px', padding: '10px', border: '1px solid #eaeef5',
          }}
          type="text"
        />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b style={{ fontSize: '14px' }}>E-mail:</b>
        <input
          ref={emailRef}
          className="10r 100w"
          defaultValue={filters?.email}
          style={{
            maxWidth: '200px', marginTop: '4px', padding: '10px', border: '1px solid #eaeef5',
          }}
          type="text"
        />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b style={{ fontSize: '14px' }}>Extrato:</b>
        <select
          ref={extractRef}
          className="10r"
          defaultValue={filters?.extract || 1}
          onChange={handleOnFilter}
          style={{
            marginTop: '4px', padding: '10px', border: '1px solid #eaeef5',
          }}
        >
          <option value="">Todos</option>
          <option value="1">Sim</option>
          <option value="0">Não</option>
        </select>
      </label>
      <button
        type="submit"
        className="blanc bold"
        style={{
          borderRadius: '4px', padding: '12px', marginTop: '20px', border: 0, backgroundColor: '#0A65FE', marginLeft: '8px',
        }}
      >
        Filtrar
      </button>
    </form>
  );
};

export default FilterLeads;
