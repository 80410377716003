import React from 'react';
import { HiOfficeBuilding } from 'react-icons/hi';
import { BiTransfer } from 'react-icons/bi';
import { FaWallet, FaCompressAlt } from 'react-icons/fa';
import { SiGoogleanalytics } from 'react-icons/si';
import { IoMdSettings } from 'react-icons/io';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../../hooks/auth';

import { MenuAttributes } from './interface';

import Logo from '../../../../assets/logo_contemplato_azul.png';

const Menu: React.FC<MenuAttributes> = ({ setIsOpen, isOpen }) => {
  const { handleLogout } = useAuth();

  return (
    <>
      <div
        style={{
          position: 'fixed' as const,
          top: 0,
          left: 0,
          transition: '0.4s',
          width: isOpen ? '100%' : '0px',
          height: '100%',
          backgroundColor: '#00000080',
          zIndex: 999,
        }}
        onClick={() => setIsOpen(!isOpen)}
      />

      <div
        className="Blanc 100w"
        style={{
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          transition: '0.3s',
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: isOpen ? '280px' : '0px',
          padding: '24px 0',
          overflow: 'hidden',
        }}
      >
        <img src={Logo} alt="logo_contemplato" style={{ width: 'auto', height: 'auto', maxHeight: '40px' }} />
        <hr className="100w" style={{ margin: '20px 0', opacity: '20%' }} />
        <div
          className="100w"
          style={{
            display: 'flex', flexDirection: 'column', padding: '0 8px',
          }}
        >
          <Link to="/crm">
            <div
              className="100w"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '44px',
                borderRadius: '4px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
              }}
            >
              <HiOfficeBuilding className="black" size={20} style={{ marginLeft: '16px', minWidth: '20px', minHeight: '20px' }} />
              <span
                className="black"
                style={{
                  fontSize: '14px', marginLeft: '12px',
                }}
              >Originação passiva
              </span>
            </div>
          </Link>
          <Link to="/presession">
            <div
              className="100w"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                height: '44px',
                background: 'linear-gradient(45deg, #14489c, #012d75)',
                borderRadius: '4px',
                cursor: 'pointer',
                marginTop: '8px',
                whiteSpace: 'nowrap',
              }}
            >
              <FaCompressAlt className="blanc" size={20} style={{ marginLeft: '16px', minWidth: '20px', minHeight: '20px' }} />
              <strong className="blanc" style={{ fontSize: '14px', marginLeft: '8px' }}>Pré-sessão</strong>
            </div>
          </Link>
          <Link to="/dossie">
            <div
              className="100w"
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '44px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                marginTop: '8px',
              }}
            >
              <BiTransfer className="black" size={20} style={{ marginLeft: '16px', minWidth: '20px', minHeight: '20px' }} />
              <span
                className="black"
                style={{
                  fontSize: '14px', marginLeft: '8px',
                }}
              >Transferência
              </span>
            </div>
          </Link>
          <Link to="/wallet">
            <div
              className="100w"
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '44px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                marginTop: '8px',
              }}
            >
              <FaWallet className="black" size={16} style={{ marginLeft: '16px', minWidth: '16px', minHeight: '16px' }} />
              <span
                className="black"
                style={{
                  fontSize: '14px', marginLeft: '12px',
                }}
              >Gestão da carteira
              </span>
            </div>
          </Link>
          <Link to="/analytics/data-studio">
            <div
              className="100w"
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '44px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                marginTop: '8px',
              }}
            >
              <SiGoogleanalytics className="black" size={18} style={{ marginLeft: '16px', minWidth: '18px', minHeight: '18px' }} />
              <span
                className="black"
                style={{
                  fontSize: '14px', marginLeft: '8px',
                }}
              >Dados análiticos
              </span>
            </div>
          </Link>
          <Link to="/managment">
            <div
              className="100w"
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '44px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                marginTop: '8px',
              }}
            >
              <IoMdSettings className="black" size={20} style={{ marginLeft: '16px', minWidth: '20px', minHeight: '20px' }} />
              <span
                className="black"
                style={{
                  fontSize: '14px', marginLeft: '8px',
                }}
              >Configurações
              </span>
            </div>
          </Link>
        </div>

        <button
          className="100w 1p"
          style={{
            height: '44px', borderTop: '1px solid red', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', bottom: 0,
          }}
          type="button"
          onClick={handleLogout}
        >
          <span className="tomato">
            Sair
          </span>
        </button>
      </div>
    </>

  );
};

export default Menu;
