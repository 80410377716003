const Container = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px dashed',
  cursor: 'pointer',
  borderRadius: '12px',
  transition: '0.2s ease',
  height: '100%',
  padding: '24px 12px',
};

export {
  Container,
};
