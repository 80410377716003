import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx';
import CircularProgress from '@mui/material/CircularProgress';

import api from '../../../../services/api';
import { storage } from '../../../../services/firebase';

import { tmsToDateMask } from '../../../../utils/masks';

import { useCache } from '../../../../hooks/cacheServices';
import { useAuth } from '../../../../hooks/auth';

import ModalOptions from '../../components/ModalOptions';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import ListUsers from './components/ListUsers';
import FormModal from './components/FormModal';
import Filter from './components/Filter';

import { UserLeadsAll } from '../../../_interfaces';
import { ModalFormData, SubmitDataDTO } from './interface';

import {
  Container,
} from './styles';

let requestedLeads: any[] = [];

const ListTransferences: React.FC = () => {
  const { token, handleLogout } = useAuth();

  const history = useHistory();

  const {
    administrators, types, items, requestAdministrators, requestItems, requestTypes,
  } = useCache();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReleases, setIsOpenReleases] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [assignees, setAssignees] = useState([] as any[]);
  const [listUsers, setListUsers] = useState([] as UserLeadsAll[]);
  const [releaseOptions, setReleaseOptions] = useState([] as UserLeadsAll[]);

  useEffect(() => {
    const requestAssigneeList = async (): Promise<void> => {
      try {
        const { data: assigneeData } = await api.post('/output/cessionario', {
          agent: token,
        });

        setAssignees(assigneeData.data);
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    const requestUsersList = async (): Promise<void> => {
      try {
        const storageRef = storage.ref(process.env.REACT_APP_FIREBASE_STORAGE_PREFIX);
        const storageList = await storageRef.listAll();
        const userIds = storageList.prefixes.map((userId) => +userId.name);

        const { data } = await api.post('/output/dossie', {
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          const usersResponse = Object.values(data.data) as UserLeadsAll[];

          usersResponse.forEach((user) => {
            const hasExtract = userIds.find((userId) => userId === user.id);

            if (hasExtract) {
              user.extract = 1;
            } else {
              user.extract = 0;
            }
          });

          requestedLeads = usersResponse;
          setListUsers(usersResponse);
          setIsLoading(false);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    const requestReleasesData = async (): Promise<void> => {
      try {
        const { data } = await api.post('/output/lista_lancamentos', {
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          setReleaseOptions(data.data);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    if (token) {
      requestAssigneeList();
      requestReleasesData();
      requestUsersList();
      requestAdministrators();
      requestItems();
      requestTypes();
    }
  }, [token]);

  const handleMoveSelect = useCallback(async (userLead: UserLeadsAll): Promise<void> => {
    try {
      if (userLead.status >= 300) {
        const newLeads = listUsers.filter((lead) => lead.id !== userLead.id);

        setListUsers([...newLeads]);

        const { data } = await api.put('/input/dossie', {
          id: userLead.id,
          status: userLead.status,
          agent: token,
          sendNotification: true,
          isSaasV2: userLead.isSaasV2,
          tmsFinished: new Date().getTime(),
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        }
      } else {
        const index = listUsers.indexOf(userLead);

        const todayTms = new Date().getTime();

        const timestampStatus = {
          210: { tmsContract: todayTms },
          220: { tmsTransfer: todayTms },
          230: { tmsAdmin: todayTms },
          240: { tmsSendDoc: todayTms },
          250: { tmsTerm: todayTms },
          260: { tmsWaitTransfer: todayTms },
          270: { tmsDieTransfer: todayTms },
        } as any;

        listUsers[index] = {
          ...userLead,
          ...timestampStatus[userLead.status],
        };

        setListUsers([...listUsers]);

        const { data } = await api.put('/input/dossie', {
          id: userLead.id,
          status: userLead.status,
          agent: token,
          sendNotification: true,
          isSaasV2: userLead.isSaasV2,
          ...timestampStatus[userLead.status],
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        }
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  }, [listUsers, token, handleLogout]);

  const handleUpdateField = useCallback(async (userLead: any): Promise<void> => {
    try {
      const { data } = await api.put('/input/dossie', {
        agent: token,
        status: userLead.status,
        ...userLead,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  }, [token, handleLogout]);

  const handleOnSubmit = useCallback(
    async (formData: ModalFormData, userLead: UserLeadsAll): Promise<void> => {
      setListUsers(listUsers.filter((user) => user.id !== userLead.id));

      const todayTms = new Date().getTime();

      await api.put('/input/dossie', {
        id: userLead.id,
        tmsAssembleia: formData.assembly,
        tmsParcela: formData.maturity_date,
        status: 300,
        tmsFinished: todayTms,
        agent: token,
        isSaasV2: userLead.isSaasV2,
      });
    }, [token, listUsers],
  );

  const handleOnInsertModalRelease = useCallback(async (modalData: any): Promise<void> => {
    await api.post('/input/lancamentos', {
      idRelational: +modalData.id,
      agent: token,
      description: modalData.description,
      display: +`${modalData.display}`,
      visible: 1,
      tms: modalData.tms,
      into: 'dossie',
    });
  }, [token]);

  const handleOnDelete = useCallback(async (id: number): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        const usersFiltered = listUsers.filter((user) => user.id !== id);
        const lead = listUsers.find((user) => user.id === id);
        setListUsers(usersFiltered);

        const { data: updateData } = await api.put('/input/dossie', {
          id,
          status: 2,
          agent: token,
          isSaasV2: lead.isSaasV2,
        });

        if (!updateData.status) {
          alert(`Erro: ${updateData.message}`);
          handleLogout();
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  }, [token, listUsers]);

  const handleOnSubmitModal = async (submitData: SubmitDataDTO): Promise<void> => {
    const { data } = await api.post<any>('/input/atendimento', {
      ...submitData,
      extract: 0,
    });

    if (!data?.data?.insertId) {
      alert(`Erro: ${data.error}`);
    }

    await api.put('/input/atendimento', {
      id: data?.data?.insertId,
      status: 200,
      administrator: 1,
      type: 1,
      item: 1,
      agent: token,
      cessionario: 1,
    });

    setIsOpen(false);

    history.push(`/dossie/extract/${data?.data?.insertId}`);
  };

  const handleOnUpdateStrategy = async (lead: any): Promise<void> => {
    const { data } = await api.put<any>('/input/dossie', {
      ...lead,
      agent: token,
    });

    if (!data?.status) {
      alert(`Erro: ${data.error}`);
    }
  };

  const handleConvertDataToCSV = async (): Promise<void> => {
    const storageRef = storage.ref(process.env.REACT_APP_FIREBASE_STORAGE_PREFIX);
    const storageList = await storageRef.listAll();
    const userIds = storageList.prefixes.map((userId) => +userId.name);

    const { data: releasesOptions } = await api.post('/output/lancamentos', {
      agent: token,
    });

    const leadsUser = await Promise.all(listUsers.map(async (user: any) => {
      const hasExtract = userIds.find((userId) => userId === user.id);

      const { data: userReleases } = await api.post('/output/lancamentos', {
        agent: token,
        relational: user.id,
      });

      user.launches = userReleases.data;

      if (hasExtract) {
        user.extract = 1;
      } else {
        user.extract = 0;
      }

      return user;
    }));

    const displayOptions = {} as any;

    releasesOptions.data.forEach((option: any) => {
      displayOptions[option.id] = option.display;
    });

    const newUsers = [] as any;
    const newLaunches = [] as any;
    leadsUser.forEach((user: any) => {
      const userObj = {
        id: user.id || '#######',
        Data: tmsToDateMask(user.tmsIn) || '#######',
        'Data proposta': tmsToDateMask(user.tmsPricing) || '#######',
        'Data finalizado': tmsToDateMask(user.tmsFinished) || '#######',
        'Data assembleia': tmsToDateMask(user.tmsAssembleia) || '#######',
        'Data de vencimento da parcela': tmsToDateMask(user.tmsParcela) || '#######',
        'id Extrato': user.idExtract || '#######',
        'id Data': user.idData || '#######',
        'id Tms': user.idTms || '#######',
        extract: user.extract || '#######',
        status: user.status || '#######',
        Nome: user.display || '#######',
        'E-mail': user.email || '#######',
        Telefone: user.phone || '#######',
        Administrador: administrators.find(
          (administrator: any) => administrator.id === user.administrator,
        )?.display || '#######',
        Item: items?.find(
          (item: any) => item.id === user.item,
        )?.display || '#######',
        Tipo: types?.find(
          (type: any) => type.id === user.type,
        )?.display || '#######',
        Crédito: user.credit || '#######',
        'Total pago': user.paid || '#######',
        'Divida restante': user.debit || '#######',
        'Fundo comum': user.unitrust || '#######',
        'Débito total': user.totalDebit || '#######',
        'Valor da parcela': user.portion || '#######',
        'Total de parcelas': user.totalPortion || '#######',
        'Parcelas restantes': user.totalPortionToPay || '#######',
        'Meses em aberto': user.totalPortionToDie || '#######',
        'Preço da proposta': user.pricing || '#######',
      };

      if (user.launches.length) {
        user?.launches?.forEach((launch: any) => {
          const launchObj = {
            'id Lancamento': launch.id || '#######',
            'Status Lancamento': launch.status || '#######',
            'Nome Lancamento': displayOptions[launch.display] || '#######',
            'Data Lancamento': tmsToDateMask(launch.tms) || '#######',
            'Descrição Lancamento': launch.description || '#######',
          };

          newLaunches.push(launchObj);
        });
      }

      newUsers.push(userObj);
    });

    const ws = XLSX.utils.json_to_sheet(newUsers);
    const wsLaunches = XLSX.utils.json_to_sheet(newLaunches);
    const wb = XLSX.utils.book_new();
    const wbLaunches = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ws_data');
    XLSX.utils.book_append_sheet(wbLaunches, wsLaunches, 'ws_data');
    XLSX.writeFile(wb, 'contemplato_dossie_leads.xlsb');
    XLSX.writeFile(wbLaunches, 'contemplato_dossie_launches.xlsb');
  };

  const handleOnFilter = async (filter: any): Promise<void> => {
    const hasAnyFilter = !!Object.values(filter).length;

    if (hasAnyFilter) {
      const filteredLeads = requestedLeads.filter((lead) => {
        if (
          filter?.display && !lead.display.toLowerCase().includes(filter?.display.toLowerCase())
        ) {
          return false;
        }

        if (
          filter?.email && !lead.email.toLowerCase().includes(filter?.email.toLowerCase())
        ) {
          return false;
        }

        if (
          filter?.phone && !lead.phone.replaceAll(/\D/g, '').toLowerCase().includes(filter?.phone.replaceAll(/\D/g, '').toLowerCase())
        ) {
          return false;
        }

        if (
          filter?.status && lead.status !== +filter?.status
        ) {
          return false;
        }

        if (
          filter?.cessionario && lead.cessionario !== +filter?.cessionario
        ) {
          return false;
        }

        if (
          filter?.id && filter?.id !== lead.id
        ) {
          return false;
        }

        return true;
      });

      setListUsers(filteredLeads);
    } else {
      setListUsers(requestedLeads);
    }
  };

  return (
    <div className="col center" style={Container}>
      <Header />
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <h3 className="blanc">Cadastrar novo cliente</h3>
        <FormModal
          fnOnSubmit={handleOnSubmitModal}
        />
      </Modal>
      <ModalOptions
        handleOnClick={handleConvertDataToCSV}
      />
      {
        isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Filter assignees={assignees} isLoading={isLoading} onFilter={handleOnFilter} />
            <ListUsers
              items={items}
              types={types}
              administrators={administrators}
              listUsers={listUsers}
              onSubmit={handleOnSubmit}
              handleOnDelete={handleOnDelete}
              onUpdateField={handleUpdateField}
              onMoveSelect={handleMoveSelect}
              setListUsers={setListUsers}
              setIsOpen={setIsOpen}
              setIsOpenReleases={setIsOpenReleases}
              releaseOptions={releaseOptions}
              isOpenReleases={isOpenReleases}
              onUpdateStrategy={handleOnUpdateStrategy}
              onInsertModalRelease={handleOnInsertModalRelease}
            />
          </>
        )
      }
    </div>
  );
};

export default ListTransferences;
