import React, { useEffect, useState, useRef } from 'react';
import { IoIosEye } from 'react-icons/io';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import moment from 'moment';

import apiSaas from '../../../../services/apiSaas';

import Modal from '../../components/Modal';
import Header from '../../components/Header';

interface FollowUp {
  id: number;
  interact: string;
  details: string;
  createdAt: string;
}

interface Notes {
  id: number;
  message: string;
  createdAt: string;
}

interface Proposal {
  id: number;
  finalValue: number;
  crmId: number;
  lastNote: string;
  lastDateNote: Date;
  followUp: FollowUp[];
  notes: Notes[];
}

const Pending: React.FC = () => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState<FollowUp[]>([]);
  const [modalContentNotes, setModalContentNotes] = useState<Notes[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalProposals, setTotalProposals] = useState(0);
  const [filters, setFilters] = useState(() => {
    const currentFilters = localStorage.getItem('@Contemplato/filters-dossie');

    if (currentFilters) {
      const storagedFilters = JSON.parse(currentFilters);

      return storagedFilters;
    }

    return {};
  });

  const idRef = useRef<HTMLInputElement>(null);
  const idProposalRef = useRef<HTMLInputElement>(null);

  const handleOnFilter = (e: any): void => {
    e.preventDefault();

    const id = idRef.current?.value && +idRef.current?.value;
    const idProposal = idProposalRef.current?.value && +idProposalRef.current?.value;

    const filterObj = {
      ...filters,
      idProposal,
      id,
    };

    localStorage.setItem('@Contemplato/filters-dossie', JSON.stringify(filterObj));

    setFilters(filterObj);
  };

  async function handleOnCheckPendencies(id: number): Promise<void> {
    const confirmPendencies = confirm('Você tem certeza que deseja confirmar que o documento foi checkado?');

    if (confirmPendencies) {
      await apiSaas.patch(`/v1/proposals-crm/${id}/documents-resolved`);

      setProposals((currProposals) => currProposals.filter((proposal) => proposal.id !== id));
    }
  }

  async function handleCheckDetails(id: number): Promise<void> {
    const proposal = proposals.find((itemProposal) => itemProposal.id === id);

    setModalIsOpen(true);

    if (proposal) {
      setModalContent(proposal.followUp);
      setModalContentNotes(proposal.notes);
    }
  }

  async function handleAddAnotation(id: number): Promise<void> {
    const message = prompt('Digite sua anotação aqui');

    const proposal = proposals.find((itemProposal) => itemProposal.crmId === id);

    if (message) {
      const { data } = await apiSaas.post('/v1/notes', {
        id,
        message,
      });

      const updatedProposals = proposals.map((itemProposal) => {
        if (proposal?.crmId === itemProposal.crmId) {
          itemProposal.lastNote = message;
          itemProposal.lastDateNote = data?.createdAt;
          itemProposal.notes.push({
            id: data.id,
            message,
            createdAt: data?.createdAt,
          });
        }

        return itemProposal;
      });

      setProposals(updatedProposals);
    }
  }

  useEffect(() => {
    async function requestProposalsByPendingDocuments(): Promise<any> {
      const { data } = await apiSaas.get('/v1/proposals-crm/pending-documents', {
        params: {
          id: filters.idProposal,
          crmId: filters.id,
          limit: pageSize,
          page,
        },
      });

      setTotalProposals(data.totalItems);
      setProposals(data.paginatedPendingProposals);
      setIsLoading(false);

      return data.paginatedPendingProposals;
    }

    setIsLoading(true);
    requestProposalsByPendingDocuments();
  }, [page, pageSize, filters]);

  const tableColumns: GridColumns = [
    {
      field: 'crmId',
      headerName: 'Id',
      width: 200,
    },
    {
      field: 'name',
      headerName: 'Nome',
      width: 200,
    },
    {
      field: 'lastNote',
      headerName: 'Última anotação',
      width: 200,
    },
    {
      field: 'lastDateNote',
      headerName: 'Última data da anotação',
      width: 200,
      valueFormatter: ({ value }) => (typeof value === 'string' ? moment(value).format('DD/MM/YYYY') : ''),
    },
    {
      field: 'finalValue',
      headerName: 'Valor Final',
      width: 260,
      valueFormatter: ({ value }) => value?.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    },
    {
      field: 'action',
      headerName: 'Ações',
      width: 460,
      renderCell: ({ row }) => (
        <>
          <a href={`/dossie/extract/${row.crmId}`} target="_blank" rel="noreferrer">
            <button
              className="1p 10r blanc"
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '6px 12px', backgroundColor: '#345d9d',
              }}
              type="button"
            >
              <IoIosEye size={20} color="#fff" />
            </button>
          </a>
          <button className="1p 10r blanc" onClick={() => handleCheckDetails(row.id)} style={{ padding: '6px 12px', backgroundColor: '#0A65FE', marginLeft: '8px' }} type="button">
            Ver detalhes
          </button>
          <button className="1p 10r blanc" onClick={() => handleAddAnotation(row.crmId)} style={{ padding: '6px 12px', backgroundColor: 'rgb(24, 138, 120)', marginLeft: '8px' }} type="button">
            Adicionar anotação
          </button>
          <button className="1p 10r blanc" onClick={() => handleOnCheckPendencies(row.id)} style={{ padding: '6px 12px', backgroundColor: '#4cd930', marginLeft: '8px' }} type="button">
            Marcar resolvido
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="dossie-table" style={{ backgroundColor: '#2F3B53', minHeight: '100vh' }}>
      <Header />
      <form
        onSubmit={handleOnFilter}
        className="100w"
        style={{
          display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '0 16px',
        }}
      >
        <label style={{ display: 'flex', flexDirection: 'column' }} htmlFor="">
          <b style={{ fontSize: '14px', color: '#fff' }}>ID:</b>
          <input
            ref={idRef}
            defaultValue={filters?.id}
            className="10r 100w"
            style={{
              maxWidth: '120px', marginTop: '4px', padding: '10px', border: '1px solid #eaeef5',
            }}
            type="text"
          />
        </label>
        <button
          type="submit"
          className="blanc bold"
          style={{
            borderRadius: '4px', padding: '12px', marginTop: '20px', border: 0, backgroundColor: '#0A65FE', marginLeft: '8px',
          }}
        >
          Filtrar
        </button>
      </form>
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%',
      }}
      >
        <Modal
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
          maxWidth={800}
        >
          {
            modalIsOpen && (
            <table style={{ padding: '16px', width: '100%' }}>
              <thead style={{ color: '#fff' }}>
                <tr>
                  <th style={{ textAlign: 'left' }}>Detalhes</th>
                  <th style={{ textAlign: 'left' }}>Data</th>
                </tr>
              </thead>
              <tbody>
                {
                  modalContent.map((content) => (
                    <tr
                      key={content.id}
                      style={{ color: '#fff' }}
                    >
                      <td>{`${content.interact?.replace('transfer note - ', '')}: ${content.details}`}</td>
                      <td style={{ marginLeft: '8px' }}>{moment(content.createdAt).format('DD/MM/YYYY')}</td>
                    </tr>
                  ))
                }
                {
                  modalContentNotes.map((note) => (
                    <tr
                      key={note.id}
                      style={{ color: '#fff' }}
                    >
                      <td>{`anotação: ${note.message}`}</td>
                      <td style={{ marginLeft: '8px' }}>{moment(note.createdAt).format('DD/MM/YYYY')}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            )
          }
        </Modal>
        <div className="100w">
          <DataGrid
            rows={proposals}
            rowCount={totalProposals}
            loading={isLoading}
            autoHeight
            rowsPerPageOptions={[50]}
            pagination
            page={page}
            disableSelectionOnClick
            pageSize={pageSize}
            classes={{
              columnHeader: 'datagrid-dossie-cell',
              root: 'datagrid-dossie-cell',
              row: 'datagrid-dossie-row',
            }}
            paginationMode="server"
            onPageChange={(newPage: number) => setPage(newPage)}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            columns={tableColumns}
          />
        </div>
      </div>
    </div>
  );
};

export default Pending;
