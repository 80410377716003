import React, {
  useRef, MutableRefObject, useCallback,
} from 'react';

import { validateDateByString } from '../../../../../../utils/validations';
import { dateBrToDefaultString, convertBRLToNumbers } from '../../../../../../utils/converts';
import {
  currencyMaskByElement, currencyMaskByValue, dateMaskByElementKeyDown, tmsToDateMask,
} from '../../../../../../utils/masks';

import Input from '../../../../components/Input';

import { ExtractFormProps } from './interface';

import {
  ButtonContainer,
  FieldSetContainer,
} from './styles';

const Form: React.FC<ExtractFormProps> = ({
  administrators, userLead, fnOnSubmit, items, types,
}) => {
  const paidRef = useRef() as MutableRefObject<HTMLInputElement>;
  const itemRef = useRef() as MutableRefObject<HTMLInputElement>;
  const typeRef = useRef() as MutableRefObject<HTMLInputElement>;
  const debitRef = useRef() as MutableRefObject<HTMLInputElement>;
  const groupRef = useRef() as MutableRefObject<HTMLInputElement>;
  const quotaRef = useRef() as MutableRefObject<HTMLInputElement>;
  const creditRef = useRef() as MutableRefObject<HTMLInputElement>;
  const portionRef = useRef() as MutableRefObject<HTMLInputElement>;
  const pricingRef = useRef() as MutableRefObject<HTMLInputElement>;
  const unitrustRef = useRef() as MutableRefObject<HTMLInputElement>;
  const totalDebitRef = useRef() as MutableRefObject<HTMLInputElement>;
  const netPaymentRef = useRef() as MutableRefObject<HTMLInputElement>;
  const totalPortionRef = useRef() as MutableRefObject<HTMLInputElement>;
  const administratorRef = useRef() as MutableRefObject<HTMLInputElement>;
  const tmsContemplatedRef = useRef() as MutableRefObject<HTMLInputElement>;
  const totalPortionToPayRef = useRef() as MutableRefObject<HTMLInputElement>;

  const tmsIssueQuotaRef = useRef() as MutableRefObject<HTMLInputElement>;
  const contractNumberRef = useRef() as MutableRefObject<HTMLInputElement>;
  const bidPlacedRef = useRef() as MutableRefObject<HTMLInputElement>;
  const indexReadjustmentRef = useRef() as MutableRefObject<HTMLInputElement>;

  const tmsExtractRef = useRef() as MutableRefObject<HTMLInputElement>;
  const tmsDieGroupRef = useRef() as MutableRefObject<HTMLInputElement>;

  const subtractTotalDebit = useCallback((e: any) => {
    currencyMaskByElement(e);

    if (e.currentTarget.name === 'totalDebit') {
      return;
    }

    const paid = paidRef.current.value;
    const debit = debitRef.current.value;

    const paidParsed = Number(paid.replace(/\./g, '').replace(',', '.'));
    const debitParsed = Number(debit.replace(/\./g, '').replace(',', '.'));

    const total = debitParsed + paidParsed;

    if (total > 0) {
      totalDebitRef.current.value = currencyMaskByValue(total.toFixed(2).toString());
    } else {
      totalDebitRef.current.value = '0,00';
    }
  }, []);

  const handleOnSubmit = useCallback((e) => {
    e.preventDefault();

    const administratorValue = administrators.find(
      (administrator) => administrator.display.trim() === administratorRef.current.value.trim(),
    );

    const typeValue = types.find(
      (type) => type.display === typeRef.current.value,
    );

    const itemValue = items.find(
      (item) => item.display === itemRef.current.value,
    );

    if (!administratorValue) {
      throw alert('Administrador(a) não bate, preencha o campo da Administrador(a) corretamente');
    }

    if (!typeValue) {
      throw alert('Tipo não bate, preencha o campo do Tipo corretamente');
    }

    if (!itemValue) {
      throw alert('Item não bate, preencha o campo do Item corretamente');
    }

    const isValidDateExtract = validateDateByString(
      tmsExtractRef.current.value,
    );

    const isValidDateGroup = validateDateByString(
      tmsDieGroupRef.current.value,
    );

    const isValidIssueQuota = validateDateByString(
      tmsIssueQuotaRef.current.value,
    );

    const tmsExtract = isValidDateExtract ? new Date(
      dateBrToDefaultString(tmsExtractRef.current.value),
    ).getTime() : undefined;

    const tmsDieGroup = isValidDateGroup ? new Date(
      dateBrToDefaultString(tmsDieGroupRef.current.value),
    ).getTime() : undefined;

    const tmsIssueQuota = isValidIssueQuota ? new Date(
      dateBrToDefaultString(tmsIssueQuotaRef.current.value),
    ).getTime() : undefined;

    const tmsContemplated = new Date(
      dateBrToDefaultString(tmsContemplatedRef.current.value),
    ).getTime() || undefined;

    if (
      (tmsExtractRef.current.value && !tmsExtract)
      || (tmsDieGroupRef.current.value && !tmsDieGroup)
      || (tmsContemplatedRef.current.value && !tmsContemplated)
      || (tmsIssueQuotaRef.current.value && !tmsIssueQuota)) {
      throw alert('Data invalida');
    }

    const formData = {
      paid: paidRef.current.value.length ? paidRef.current.value : null,
      item: itemValue.id,
      type: typeValue.id,
      debit: debitRef.current.value.length ? debitRef.current.value : null,
      group: groupRef.current.value.length ? groupRef.current.value : null,
      quota: quotaRef.current.value.length ? quotaRef.current.value : null,
      credit: creditRef.current.value.length ? creditRef.current.value : null,
      portion: portionRef.current.value.length ? portionRef.current.value : null,
      pricing: pricingRef.current.value.length ? pricingRef.current.value : null,
      unitrust: unitrustRef.current.value.length ? unitrustRef.current.value : null,
      totalDebit: totalDebitRef.current.value.length ? totalDebitRef.current.value : null,
      totalPortion: totalPortionRef.current.value.length ? +totalPortionRef.current.value : null,
      administrator: administratorValue.id,
      totalPortionToPay: totalPortionToPayRef.current.value.length
        ? +totalPortionToPayRef.current.value : null,
      tmsIssueQuota,
      contractNumber: contractNumberRef.current.value.length
        ? contractNumberRef.current.value : null,
      bidPlaced: bidPlacedRef.current.value.length ? bidPlacedRef.current.value : null,
      indexReadjustment: indexReadjustmentRef.current.value.length
        ? indexReadjustmentRef.current.value : null,
      tmsContemplated: tmsContemplated || null,
      tmsExtract,
      tmsDieGroup,
    } as any;

    fnOnSubmit(formData);
  }, [fnOnSubmit, administrators, items, types]);

  function calculateNetPayable(brlCreditValue: string, brlBidPlacedValue: string): string {
    const credit = convertBRLToNumbers(brlCreditValue);
    const bidPlaced = convertBRLToNumbers(brlBidPlacedValue);

    const total = credit - bidPlaced;
    const netPayment = total.toFixed(2);

    if (total < 0) {
      return `-${currencyMaskByValue(netPayment)}`;
    }

    return currencyMaskByValue(netPayment);
  }

  function updateNetPayableValue(e: any): void {
    currencyMaskByElement(e);

    const netPayment = calculateNetPayable(creditRef.current.value, bidPlacedRef.current.value);

    netPaymentRef.current.value = netPayment;
  }

  return (
    <form name="extract-form" method="post" onSubmit={(e) => handleOnSubmit(e)} className="100w col center">
      <div className="100w row wrap">
        <div className="col">
          <fieldset className="10p" style={FieldSetContainer}>
            <legend>Administrador(a):</legend>
            <input
              type="text"
              list="admins"
              defaultValue={administrators.find((administrator) => administrator.id === +userLead.administrator)?.display || ''}
              ref={administratorRef}
              className="Blanc 100w 10p 10r"
              required
            />
            <datalist id="admins">
              {administrators.length > 1 && administrators.map((administrator) => (
                <option key={administrator.id}>
                  {administrator.display}
                </option>
              ))}
            </datalist>
          </fieldset>
          <div className="row wrap">
            <Input reference={paidRef} labelName="Total Pago" name="paid" defaultValue={userLead.paid} onChange={subtractTotalDebit} maxLength={14} type="text" required />
            <Input reference={debitRef} labelName="Divida restante" name="debit" defaultValue={userLead.debit} onChange={subtractTotalDebit} type="text" maxLength={14} required />
          </div>
          <div className="row 100w wrap">
            <Input reference={totalDebitRef} labelName="Débito total" onChange={subtractTotalDebit} defaultValue={userLead.totalDebit} name="totalDebit" type="text" maxLength={14} required />
            <Input reference={totalPortionRef} labelName="Total de parcelas" name="totalPortion" type="number" max="999" defaultValue={userLead.totalPortion} required />
          </div>
          <div className="row 100w wrap">
            <Input reference={unitrustRef} labelName="Fundo comum" onChange={subtractTotalDebit} defaultValue={userLead.unitrust} name="unitrust" type="text" maxLength={14} required />
            <Input reference={groupRef} labelName="Grupo" defaultValue={userLead.group} name="group" type="text" />
          </div>
          <div className="row 100w wrap">
            <Input reference={tmsContemplatedRef} labelName="Data Contemplação" name="tmsContemplated" defaultValue={userLead.tmsContemplated && tmsToDateMask(userLead.tmsContemplated)} onKeyDown={dateMaskByElementKeyDown} type="text" maxLength={10} />
            <Input reference={quotaRef} labelName="Cota" name="quota" defaultValue={userLead.quota} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={bidPlacedRef} labelName="Lance embútido" name="bidPlaced" onChange={updateNetPayableValue} defaultValue={userLead.bidPlaced} type="text" />
            <Input reference={indexReadjustmentRef} labelName="Índice reajuste" name="indexReadjustment" defaultValue={userLead.indexReadjustment} type="text" />
          </div>
        </div>
        <div className="col">
          <div className="row wrap">
            <fieldset className="10p" style={FieldSetContainer}>
              <legend>Item:</legend>
              <input
                type="text"
                list="items"
                defaultValue={items.find(
                  (item) => item.id === +userLead.item,
                )?.display}
                ref={itemRef}
                className="Blanc 100w 10p 10r"
                required
              />
              <datalist id="items">
                {items.length > 1 && items.map((item) => (
                  <option key={item.id}>
                    {item.display}
                  </option>
                ))}
              </datalist>
            </fieldset>
            <fieldset className="10p" style={FieldSetContainer}>
              <legend>Tipo:</legend>
              <input
                type="text"
                list="types"
                defaultValue={types.find(
                  (type) => type.id === +userLead.type,
                )?.display}
                ref={typeRef}
                className="Blanc 100w 10p 10r"
                required

              />
              <datalist id="types">
                {types.length > 1 && types.map((type) => (
                  <option key={type.id}>
                    {type.display}
                  </option>
                ))}
              </datalist>
            </fieldset>
          </div>
          <div className="row wrap">
            <Input reference={creditRef} labelName="Crédito" name="credit" maxLength={14} defaultValue={userLead.credit} type="text" onChange={updateNetPayableValue} required />
            <Input reference={portionRef} labelName="Valor da parcela" name="portion" maxLength={14} defaultValue={userLead.portion} type="text" onChange={currencyMaskByElement} required />
          </div>
          <div className="row wrap">
            <Input reference={tmsExtractRef} labelName="Data do extrato" name="tmsExtract" defaultValue={userLead.tmsExtract && tmsToDateMask(userLead.tmsExtract)} onKeyDown={dateMaskByElementKeyDown} type="text" maxLength={10} required />
            <Input reference={tmsDieGroupRef} labelName="Encerramento do grupo" name="tmsDieGroup" defaultValue={userLead.tmsDieGroup && tmsToDateMask(userLead.tmsDieGroup)} onKeyDown={dateMaskByElementKeyDown} type="text" maxLength={10} />
          </div>
          <div className="row wrap">
            <Input reference={totalPortionToPayRef} labelName="Parcelas restantes" name="totalPortionToPay" defaultValue={userLead.totalPortionToPay} type="number" max="999" required />
            <Input reference={pricingRef} labelName="Preço da proposta" value={userLead.pricing} defaultValue={userLead.pricing} disabled />
          </div>
          <div className="row wrap">
            <Input reference={tmsIssueQuotaRef} labelName="Data de emissão da cota" name="tmsIssueQuota" defaultValue={userLead.tmsIssueQuota && tmsToDateMask(userLead.tmsIssueQuota)} onKeyDown={dateMaskByElementKeyDown} type="text" maxLength={10} />
            <Input reference={contractNumberRef} labelName="Número do contrato" name="contractNumber" defaultValue={userLead.contractNumber} type="text" />
          </div>
          <Input reference={netPaymentRef} labelName="Líquido a pagar" disabled value={calculateNetPayable(userLead.credit, userLead.bidPlaced) || '0'} type="text" required />
        </div>
      </div>
      <button type="submit" className="blanc 10r 1p" style={{ ...ButtonContainer, backgroundColor: userLead.item ? '#4AEE78' : '#1e8cf9' }}>{userLead.item ? 'Atualizar' : 'Cadastrar'}</button>
    </form>
  );
};

export default Form;
