import React from 'react';

import { Switch } from 'react-router-dom';

import SignIn from '../modules/Others/pages/SignIn';
import Firebase from '../modules/Others/pages/Firebase';
import Selection from '../modules/Others/pages/Selection';

import Extract from '../modules/Crm/pages/Extract';
import Pricing from '../modules/Crm/pages/Pricing';
import Analyze from '../modules/Crm/pages/Analyze';
import ListLeads from '../modules/Crm/pages/ListLeads';
import Reanalyze from '../modules/Crm/pages/Reanalyze';
import Finalized from '../modules/Crm/pages/Finalized';

import DataStudio from '../modules/Analytics/pages/DataStudio';

import PresessionExtract from '../modules/Presession/pages/Extract';
import ListPresessionLeads from '../modules/Presession/pages/ListPresessionLeads';

import Transference from '../modules/Dossie/pages/Transference';
import ListTransferences from '../modules/Dossie/pages/ListTransferences';
import Pending from '../modules/Dossie/pages/Pending';

import Quota from '../modules/Wallet/pages/Quota';
import ListQuotas from '../modules/Wallet/pages/ListQuotas';

import Rate from '../modules/Management/pages/Rate';
import Assignee from '../modules/Management/pages/Assignee';
import Commission from '../modules/Management/pages/Commission';
import Administrator from '../modules/Management/pages/Administrator';

import Route from './route';

import AppProvider from '../hooks';

const Routes: React.FC = () => (
  <AppProvider>
    <Switch>
      <Route path="/signin" component={SignIn} />
      <Route path="/firebase/:id" isPrivate component={Firebase} />
      <Route path="/" exact isPrivate component={Selection} />

      <Route path="/crm" exact isPrivate component={ListLeads} />
      <Route path="/crm/reanalyze" isPrivate component={Reanalyze} />
      <Route path="/crm/extract/:id" isPrivate component={Extract} />
      <Route path="/crm/pricing" isPrivate component={Pricing} />
      <Route path="/crm/analyze" isPrivate component={Analyze} />
      <Route path="/crm/finalized" isPrivate component={Finalized} />

      <Route path="/analytics/data-studio" isPrivate component={DataStudio} />

      <Route path="/presession" exact isPrivate component={ListPresessionLeads} />
      <Route path="/presession/extract/:id" exact isPrivate component={PresessionExtract} />

      <Route path="/dossie" exact isPrivate component={ListTransferences} />
      <Route path="/dossie/extract/:id" isPrivate component={Transference} />
      <Route path="/dossie/pending" exact isPrivate component={Pending} />

      <Route path="/wallet" exact isPrivate component={ListQuotas} />
      <Route path="/wallet/extract/:id" isPrivate component={Quota} />

      <Route path="/managment" exact isPrivate component={Administrator} />
      <Route path="/managment/assignee" exact isPrivate component={Assignee} />
      <Route path="/managment/commission" exact isPrivate component={Commission} />
      <Route path="/managment/administrator/:id" exact isPrivate component={Rate} />

      {/* <Route path="/analytics" isPrivate component={AllDatas} /> */}
    </Switch>
  </AppProvider>
);

export default Routes;
