export function datesAreOnSameDay(first: Date, second: Date): boolean {
  return first.getFullYear() === second.getFullYear()
  && first.getMonth() === second.getMonth()
  && first.getDate() === second.getDate();
}

export function removeEmptyFields(obj: any): any {
  const newObj = {} as any;
  Object.entries(obj).forEach(([k, v]) => {
    if (v === Object(v)) {
      newObj[k] = removeEmptyFields(v);
    } else if (v != null && v !== '') {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export function removeNullFields(obj: any): any {
  const newObj = {} as any;
  Object.entries(obj).forEach(([k, v]) => {
    if (v === Object(v)) {
      newObj[k] = removeNullFields(v);
    } else if (v != null) {
      newObj[k] = obj[k];
    }
  });
  return newObj;
}

export function isPropValuesEqual(subject: any, target: any, propNames: any): any {
  return propNames.every((propName: any) => subject[propName] === target[propName]);
}
export function getUniqueItemsByProperties(items: any, propNames: any): any {
  return items.filter(
    (item: any, index: any, array: any) => index === array.findIndex(
      (foundItem: any) => isPropValuesEqual(foundItem, item, propNames),
    ),
  );
}
