import React from 'react';

import MultiNotification from './components/MultiNotification';
import Filter from './components/Filter';

import { ModalOptionsComponent } from './interface';

import {
  Container,
} from './styles';

const ModalOptions: React.FC<ModalOptionsComponent> = ({
  status, listUsers, setFilteredUsers,
}) => (
  <div style={Container}>
    <Filter
      status={status}
      listUsers={listUsers}
      setFilteredUsers={setFilteredUsers}
    />
    <MultiNotification />
  </div>
);

export default ModalOptions;
