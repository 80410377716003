import React, {
  useState, useCallback, useEffect,
} from 'react';
import { mutate as mutateGlobal } from 'swr';
import { CircularProgress } from '@mui/material';

import ModalOptions from '../../components/ModalOptions';
import Header from '../../components/Header';
import ListUsers from './components/ListUsers';
import Filter from '../../components/Filter';

import api from '../../../../services/api';

import { useAuth } from '../../../../hooks/auth';
import { useFetch } from '../../../../hooks/fetch';
import { useInfiniteScroll } from '../../../../hooks/infiniteScroll';

import { UserLeadsAll } from '../../../_interfaces';

import { SelectContainer } from './styles';

let initialLeads: any[] = [];

const Finalized: React.FC = () => {
  const { token, handleLogout } = useAuth();
  const { pagination, infiniteScrollPagination } = useInfiniteScroll();

  const [listUsers, setListUsers] = useState([] as UserLeadsAll[]);
  const [filteredUsers, setFilteredUsers] = useState([] as UserLeadsAll[]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(134);

  const { apiSwr } = useFetch();

  const { dataFetch, mutate } = apiSwr(`list-${filter}`, '/output/atendimento', token, {
    status: filter,
  });

  useEffect(() => {
    function fetchRequests(): void {
      if (filteredUsers.length) {
        setListUsers(filteredUsers.slice(0, pagination * 30));
      } else {
        setListUsers(dataFetch.data.slice(0, pagination * 30));
        setIsLoading(false);
        initialLeads = dataFetch?.data;
      }
    }

    if (dataFetch?.data) {
      fetchRequests();

      if (!listUsers.length) {
        infiniteScrollPagination();
      }
    }
  }, [dataFetch, pagination, filteredUsers]);

  const handleOnDelete = useCallback(async (userLead: UserLeadsAll): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        if (filteredUsers.length) {
          const usersFiltered = filteredUsers.filter((user) => user.id !== userLead.id);
          setFilteredUsers(usersFiltered);
        }

        const usersFiltered = listUsers.filter((user) => user.id !== userLead.id);
        setListUsers(usersFiltered);

        const { data: updateData } = await api.put('/input/atendimento', {
          id: userLead.id,
          status: 1,
          agent: token,
        });

        if (!updateData.status) {
          alert(`Erro: ${updateData.message}`);
          handleLogout();
        } else {
          mutate(usersFiltered, false);
          mutateGlobal(`list-${userLead.status}`, {}, true);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  }, [token, listUsers]);

  const handleOnFilter = (e: any): void => {
    const { value } = e.currentTarget;

    setFilteredUsers([]);
    setFilter(+value);
  };

  const handleMoveSelect = async (e: any, userLead: UserLeadsAll): Promise<void> => {
    try {
      const { value } = e.currentTarget;

      const usersFiltered = listUsers.filter((user) => user.id !== userLead.id);
      setListUsers(usersFiltered);

      const { data: updateData } = await api.put('/input/atendimento', {
        id: userLead.id,
        status: +value,
        agent: token,
      });

      if (!updateData.status) {
        alert(`Erro: ${updateData.error}`);
        console.error(userLead, updateData);
      } else {
        mutate(usersFiltered, false);
        mutateGlobal(`list-${value}`, {}, true);
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleOnFilterLeads = async (filteredLeads: any): Promise<void> => {
    setListUsers(filteredLeads);
  };

  return (
    <div className="col center">
      <Header />
      <ModalOptions
        status={filter}
        listUsers={listUsers}
        setFilteredUsers={setFilteredUsers}
      />
      <select style={SelectContainer} onChange={handleOnFilter} className="Blanc 960y 10p 10r">
        <option value={134}>Ofertas Recusadas</option>
        <option value={131}>Negados</option>
        <option value={101}>Expirados</option>
      </select>
      {
        isLoading ? (
          <div style={{ marginTop: '16px' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="100w" style={{ maxWidth: '960px', marginTop: '16px' }}>
              <Filter initialLeads={initialLeads} onFilter={handleOnFilterLeads} />
            </div>
            <ListUsers
              listUsers={listUsers}
              filter={filter}
              handleMoveSelect={handleMoveSelect}
              handleOnDelete={handleOnDelete}
            />
          </>
        )
      }
    </div>
  );
};

export default Finalized;
