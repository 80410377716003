const TableContent = {
  borderCollapse: 'collapse' as const,
  overflow: 'hidden',
};

const TableHeadContainer = {
  backgroundColor: '#CBCBCB',
  textAlign: 'left' as const,
};

const TableHeadContent = {
  padding: '16px 24px',
};

const TableBodyContainer = {
  textAlign: 'left' as const,
};

const TableBodyContent = {
  backgroundColor: '#E8E8E8',
  borderTop: '1px solid #CFCFCF',
  textAlign: 'left' as const,
};

export {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
  TableBodyContent,
};
