import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../hooks/auth';
import { removeEmptyFields } from '../../../../utils/functionalities';

import api from '../../../../services/api';

import Header from '../../components/Header';
import TableAssignee from './components/TableAssignee';
import ModalAssignee from './components/ModalAssignee';

import { Assignee } from './interface';

import {
  ButtonContainer,
} from './styles';

const AssigneeComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [assignees, setAssignees] = useState([] as Assignee[]);

  const { token } = useAuth();

  useEffect(() => {
    async function requestAssigneeData(): Promise<void> {
      const { data } = await api.post('/output/cessionario', {
        agent: token,
      });

      setAssignees(data.data?.map((assignee: any) => {
        delete assignee.password;

        return assignee;
      }));
    }

    requestAssigneeData();
  }, [token]);

  const handleOnUpdateField = async (assignee: Assignee): Promise<void> => {
    const submitObj = {
      agent: token,
      ...assignee,
      enable: 1,
    };

    await api.put('/input/cessionario', submitObj);
  };

  const handleOnSubmit = async (assignee: Assignee): Promise<void> => {
    const submitObj = {
      agent: token,
      ...assignee,
    };

    const { data } = await api.post('/input/cessionario', submitObj);

    if (data.status) {
      alert('Cessionário criado com sucesso!');

      setAssignees([...assignees, {
        id: assignees.length + 2,
        ...submitObj,
      }]);
    } else {
      alert(`Erro ao criar o cessionário: ${data.error}`);
    }
  };

  const handleOnDelete = async (assigneeToDelete: any): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        const { data: updateData } = await api.put('/input/cessionario', removeEmptyFields({
          ...assigneeToDelete,
          enable: 0,
          agent: token,
        }));

        if (updateData.status) {
          const filteredAssignees = assignees.filter(
            (assignee) => assignee.id !== assigneeToDelete.id,
          );

          setAssignees(filteredAssignees);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  };

  return (
    <div className="col center">
      <Header />
      <div className="30p 100w">
        <ModalAssignee isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={handleOnSubmit} />
        <button type="button" className="10r 100w 10p Green blanc" style={ButtonContainer} onClick={() => setIsOpen(!isOpen)}>Cadastrar novo cessionário</button>
        <TableAssignee
          onUpdateField={handleOnUpdateField}
          assignees={assignees}
          onDelete={handleOnDelete}
        />
      </div>
    </div>
  );
};

export default AssigneeComponent;
