const ContainerButtons = {
  fontWeight: 'bold' as const,
  marginTop: '8px',
};

const SubmitButton = {
  fontWeight: 'bold' as const,
  color: '#fff',
  margin: '4px',
  backgroundColor: '#4AEE78',
};

const DenyButton = {
  fontWeight: 'bold' as const,
  color: '#fff',
  margin: '4px',
};

export {
  SubmitButton,
  DenyButton,
  ContainerButtons,
};
