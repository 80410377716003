const Content = {
  marginTop: '16px',
};

const ContainerLead = {
  padding: '18px 36px 18px 36px',
  borderRadius: '16px 0 0 16px',
  justifyContent: 'space-between',
};

const SpanStyle = {
  margin: '8px 0',
};

const ExtractStatusContainer = {
  position: 'relative' as const,
  width: '60px',
  flex: '0, 1',
  borderRadius: '0 16px 16px 0',
};

const SelectStyle = {
  marginBottom: '8px',
};

const SpanHeader = {
  paddingRight: '32px',
  justifyContent: 'space-between' as const,
};

const ArrowDownStyle = {
  cursor: 'pointer',
};

const XCross = {
  position: 'absolute' as const,
  top: -10,
  right: -10,
  color: '#fff',
  backgroundColor: '#630c0a',
  padding: '6px',
};

export {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
  SelectStyle,
  SpanHeader,
  ArrowDownStyle,
  XCross,
};
