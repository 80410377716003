/* eslint-disable no-await-in-loop */
import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { mutate as mutateGlobal } from 'swr';
import axios from 'axios';

import moment from 'moment';
import { useCache } from '../../../../hooks/cacheServices';
import { useAuth } from '../../../../hooks/auth';

import { tmsToDateMask } from '../../../../utils/masks';

import { storage } from '../../../../services/firebase';
import api from '../../../../services/api';
import apiSaas from '../../../../services/apiSaas';

import Form from './components/Form';
import Lead from './components/Lead';
import TableStatus from './components/TableStatus';
import Header from '../../components/Header';

import { RouteParams, UserLeadsAll } from '../../../_interfaces';
import {
  SubmitExtractData,
} from './interface';

import {
  Container,
  Content,
  Title,
  Heading,
  OcrButton,
} from './styles';

const Extract: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();

  const { token, operator, handleLogout } = useAuth();
  const {
    administrators,
    items,
    types,
    requestAdministrators,
    requestItems,
    requestTypes,
  } = useCache();

  const [userLead, setUserLead] = useState({} as UserLeadsAll);
  const [anotations, setAnotations] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);

  const storageRef = storage.ref(`${process.env.REACT_APP_FIREBASE_STORAGE_PREFIX}/${id}`);

  useEffect(() => {
    const requestAnotations = async (): Promise<void> => {
      try {
        const { data: notes } = await apiSaas.get(`/v1/notes/${id}`);

        setAnotations(notes);
      } catch (err) {
        console.error(err);
      }
    };

    requestAnotations();
  }, [id]);

  const requestUserLeadData = useCallback(async (): Promise<void> => {
    try {
      await requestAdministrators();
      await requestItems();
      await requestTypes();

      const { data } = await api.post('/output/atendimento', {
        agent: token,
        id: +id,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      } else {
        const usersList = Object.values(data.data) as UserLeadsAll[];

        const hasUser = usersList.find((user) => user.id === +id);

        if (hasUser) {
          const storageItemsResponse = await storageRef.list();
          const hasExtract = !!storageItemsResponse.items.length;

          if (hasExtract) {
            hasUser.extract = 1;
          } else {
            hasUser.extract = 0;
          }

          setUserLead(hasUser);
        } else {
          history.push('/');
        }
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  }, [id, history, token]);

  const handleStorageFile = async (
    files: FileList | null,
  ): Promise<void> => {
    if (!files) {
      throw alert('Arquivos reconhecidos como nulos, tente novamente.');
    }

    const arrayFiles = Object.values(files);

    await Promise.all(
      arrayFiles.map((file: File): any => {
        const refChild = storageRef.child(`${file.name}`);
        return refChild.put(file);
      }),
    );

    const objSubmit = {
      ...userLead,
      extract: 1,
      agent: token,
    };

    await api.put('/input/atendimento', objSubmit);

    setUserLead((lead) => ({
      ...lead,
      extract: 1,
    }));
  };

  useEffect(() => {
    requestUserLeadData();
  }, [requestUserLeadData]);

  const handleOnSubmit = async (formData: SubmitExtractData): Promise<void> => {
    try {
      const objSubmit = {
        id: +id,
        ...formData,
        status: (userLead.status === 100 || +`${userLead.status}`.split('')[1] === 5) ? 110 : userLead.status,
        sendNotification: !!(userLead.status === 100),
        agent: token,
        isSaasV2: userLead.isSaasV2,
        phone: userLead.phone,
        idData: userLead.idData,
        display: userLead.display,
      };

      const { data } = await api.put('/input/atendimento', objSubmit);

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      } else {
        mutateGlobal('list-110', {}, true);
        mutateGlobal(`list-${userLead.status}`, {}, true);
        alert('Atualizado com sucesso!');
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleOnUpdateUser = async (params: any): Promise<void> => {
    await api.put('/input/atendimento', {
      ...params,
      agent: token,
      status: userLead.status,
      id: +id,
    });
  };

  const handleOnUserStatus = async (status: number): Promise<void> => {
    await api.put('/input/atendimento', {
      agent: token,
      status,
      id: +id,
    });
  };

  const handleOCR = async (): Promise<void> => {
    setIsLoading(true);

    const storageItemsResponse = await storageRef.list();
    const storage_items = storageItemsResponse.items;
    if (storage_items.length > 0) {
      // dont have file name in database to use, so uses the first file found in foilder
      const downloadUrl = await storage_items[0].getDownloadURL();
      const extratoResponse = await axios.get(downloadUrl, { responseType: 'blob' });

      const formData = new FormData();
      formData.append('file', extratoResponse.data, 'extrato.pdf');
      const responseInput = await api.post<any>('/ocr/input', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      });

      if (responseInput?.data?.status === true) {
        const payload = {
          jobId: responseInput.data.result,
        };

        // wait before OCR takes time to process
        await new Promise((resolve) => setTimeout(resolve, 10000));

        let responseOutput = await api.post<any>('/ocr/output', payload);
        const maxRetryAttempts = 3;
        let retryAttempt = 0;
        while (retryAttempt < maxRetryAttempts && responseOutput.data.result.Status === 'IN_PROGRESS') {
          retryAttempt++;
          await new Promise((resolve) => setTimeout(resolve, 5000));

          responseOutput = await api.post<any>('/ocr/output', payload);
        }

        if (responseOutput.data.result.Status === 'DONE') {
          const dataOutput = responseOutput.data.result.Output[0];
          const paidOutput = Number(dataOutput.valoresPagosTotal.replaceAll('.', '').replaceAll(',', '.'));
          const debtOutput = Number(dataOutput.valoresAPagarTotal.replaceAll('.', '').replaceAll(',', '.'));
          const totalDebtOutput = paidOutput + debtOutput;
          const totalPortionOutput = Number(dataOutput.planoBasico.substring(0, 3));
          const portionOutput = totalDebtOutput / totalPortionOutput;
          const newExtract:any = {
            paid: dataOutput.valoresPagosTotal,
            debit: dataOutput.valoresAPagarTotal,
            totalDebit: totalDebtOutput.toString().replaceAll('.', ','),
            totalPortion: totalPortionOutput.toString(),
            credit: dataOutput.valorCredito,
            portion: portionOutput.toFixed(2).replaceAll('.', ',').toString(),
            totalPortionToPay: (debtOutput / portionOutput).toFixed(0).toString(),
            percentAdmin: dataOutput.taxaAdm,
            unitrust: dataOutput.valoresPagosComum,
            item: dataOutput.bemBasico.includes('IMOVEL') ? 1 : 2,
            percentReserveFund: dataOutput.fdoReserva,
          };

          const objSubmit = {
            id: +id,
            status: userLead.status,
            ...newExtract,
            agent: token,
          };
          await api.put('/input/atendimento', objSubmit);
          await requestUserLeadData();
        } else {
          alert('Error processing OCR, maximum number of attempts reached.');
        }
        setIsLoading(false);
      }
    }
  };

  const userLeadComponent = useMemo(() => {
    if (!userLead.tmsIn) {
      return <div />;
    }

    const lead = {
      ...userLead,
      dateIn: tmsToDateMask(userLead.tmsIn),
    };

    return (
      <Lead
        lead={lead}
        operatorName={operator.name}
        onUpdateUser={handleOnUpdateUser}
        onStorageFile={handleStorageFile}
        onUpdateUserStatus={handleOnUserStatus}
      />
    );
  }, [operator, userLead]);

  const handleOnCreateAnnotation = async (): Promise<void> => {
    const message = prompt('Digite sua anotação aqui');

    if (message) {
      const { data } = await apiSaas.post('/v1/notes', {
        id,
        message,
      });

      setAnotations([...anotations, {
        id: data.id,
        message,
        createdAt: data?.createdAt,
      }]);
    }
  };

  return (
    <div>
      <Header />
      <div className="960y col" style={Container}>
        <div style={Content} className="col center Gray2 20r 20p">
          <h3 style={Title} className="fo20">Usuário</h3>
          {userLeadComponent}
        </div>
        <div style={Content} className="col center Gray2 20r 20p">
          <h3 style={Title} className="fo20">Anotações</h3>
          {/* <div className="100w 10r" style={TableContainer}> */}
          <div className="100w 10r">
            {
            anotations.map((anotation: any, index: any) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div key={anotation.id} style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                  <b>{index + 1}</b>
                  <span style={{ marginLeft: '16px' }}>{anotation.message}</span>
                </div>
                <span style={{ marginLeft: '16px' }}>{anotation?.createdAt ? moment(anotation?.createdAt).format('DD/MM/YYYY') : ''}</span>
              </div>
            ))
          }
          </div>
          <button onClick={handleOnCreateAnnotation} type="button" className="blanc col center 10p 10r 100w Blue" style={{ marginTop: '16px' }}>Cadastrar anotação</button>
        </div>
        <div style={Content} className="col center Gray2 20r 20p">
          <h3 style={Title} className="fo20">Tabela Status</h3>
          <TableStatus userLead={userLead} />
        </div>
        <div style={Content} className="col center Gray2 20r 20p">
          <div style={Heading}>
            <h1 style={Title} className="fo20 10p">Extrato</h1>
            <div>
              <span style={{ visibility: isLoading ? 'visible' : 'hidden' }}>Loading OCR...</span>
              <button style={OcrButton} type="button" onClick={handleOCR}>OCR para extrato</button>
            </div>
          </div>
          <Form
            userLead={userLead}
            fnOnSubmit={handleOnSubmit}
            items={items}
            types={types}
            administrators={administrators}
          />
        </div>
      </div>
    </div>
  );
};

export default Extract;
