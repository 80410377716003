const TableContent = {
  borderCollapse: 'collapse' as const,
  overflow: 'hidden',
};

const TableHeadContainer = {
  backgroundColor: '#46474B',
  textAlign: 'left' as const,
};

const TableHeadContent = {
  padding: '16px 24px',
};

const TableBodyContainer = {
  textAlign: 'left' as const,
};

const TableBodyContent = {
  backgroundColor: '#2F3034',
  borderTop: '1px solid #3E4045',
  textAlign: 'left' as const,
};

export {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
  TableBodyContent,
};
