import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { FaCheck, FaTimes } from 'react-icons/fa';

import Modal from '../../../../../../components/Modal';
import ModalForm from '../../../ModalForm';

import { tmsToDateMask } from '../../../../../../../../utils/masks';

import { LeadAttributes } from './interface';

import {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
  SubmitButton,
  DenyButton,
  ContainerButtons,
  SpanHeader,
  XCross,
} from './styles';

const Lead: React.FC<LeadAttributes> = ({
  userLead,
  handleOnAccept,
  handleOnRefused,
  handleMoveSelect,
  handleOnDelete,
  assignees,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOnAcceptModal = (userLeadAccepted: any): void => {
    setModalIsOpen(false);

    handleOnAccept(userLeadAccepted);
  };

  return (
    <div className="960y row 20r" style={Content}>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={() => setModalIsOpen(false)}
      >
        <ModalForm
          userLead={userLead}
          onSubmit={handleOnAcceptModal}
          assignees={assignees}
        />
      </Modal>
      <div className="100w Gray2" style={ContainerLead}>
        <div style={SpanHeader} className="row center wrap">
          <span style={SpanStyle} className="fo16"><b>Código: {userLead.id}</b></span>
          <select className="Blanc 10p 10r" defaultValue="" onChange={(e) => handleMoveSelect(e, userLead)}>
            <option value="" disabled>Mover para</option>
            <option value="110">Preços</option>
          </select>
        </div>
        <Link className="row center black 1s" to={`/crm/extract/${userLead.id}`}>
          <div className="col">
            <div className="row center wrap">
              <div className="col 1s">
                <span style={SpanStyle}>Nome: {userLead.display}</span>
                <span style={SpanStyle}>E-mail: {userLead.email}</span>
                {userLead.tmsPricing && (
                <span style={SpanStyle}>
                  Data da proposta: {tmsToDateMask(userLead.tmsPricing)}
                </span>
                )}
                {userLead.tmsDeny && (
                <span style={SpanStyle}>
                  Data da proposta <b>NEGADA</b>: {tmsToDateMask(userLead.tmsDeny)}
                </span>
                )}
              </div>
              <div className="col 1s">
                <span style={SpanStyle}>Data: {tmsToDateMask(userLead.tmsIn)}</span>
                <span style={SpanStyle}>Telefone: {userLead.phone}</span>
              </div>
            </div>
          </div>
        </Link>
        <div className="col 1s 100w">
          <div style={ContainerButtons} className="row wrap">
            <button className="100w 10r 10p Blue2 col center" style={SubmitButton} type="button" onClick={() => setModalIsOpen(true)}>
              <FaCheck size={16} />
            </button>
            <button className="100w 10r 10p Tomato col center" style={DenyButton} type="button" onClick={() => handleOnRefused(userLead)}>
              <FaTimes size={17} />
            </button>
          </div>
        </div>
      </div>
      <div style={{ ...ExtractStatusContainer, backgroundColor: userLead.extract ? '#1e8cf9' : '#ef5350' }}>
        <button className="1p 5r" style={XCross} onClick={() => handleOnDelete(userLead)} type="button">
          <FaTimes className="col center" size={16} />
        </button>
      </div>
    </div>
  );
};

export default Lead;
