import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { getUniqueItemsByProperties } from 'utils/functionalities';

// import apiSaas from '../../../../../../services/apiSaas';

import FirebaseModal from '../../../../../components/FirebaseModal';

// import { Container } from './styles';

interface Signatory {
  email: string;
  contract: number;
  sign_as: string;
}

interface ModalRequestSignAttributes {
  id: number;
  assignee: any;
  userLead: any;
  selectedFiles: any[];
  handleOnCheck: (file: any, allSelectedFiles: any[]) => void;
  handleRequestSign: (signatories: Signatory[], allSelectedFiles: any[]) => void;
}

const ContractStatus = {
  1: 'Termo de transferência',
  2: 'Termo de cessão',
  3: 'Termo de transferência e cessão',
} as any;

const ContractAsSign = {
  transferee: 'Cessionário',
  transferor: 'Cedente',
  witness: 'Testemunhas',
  intervening: 'Interveniente',
  party: 'parte',
} as any;

const ModalRequestSign: React.FC<ModalRequestSignAttributes> = ({
  id, selectedFiles, handleRequestSign, handleOnCheck, assignee, userLead,
}) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const signAsRef = useRef<HTMLSelectElement>(null);
  const contractRef = useRef<HTMLSelectElement>(null);

  const [signatories, setSignatories] = useState<Signatory[]>([]);

  const selectedFirebaseFiles = useMemo(() => selectedFiles.map((firebaseFile) => {
    if (firebaseFile.contract) {
      return firebaseFile.contract;
    }

    return firebaseFile;
  }), [selectedFiles]);

  useEffect(() => {
    const signatoriesToSend: any[] = [];

    if (userLead?.third_party_data?.otherOwnershipAccount) {
      const thirdPartyAssignor = {
        email: userLead?.third_party_data?.email,
        contract: 3,
        sign_as: 'party',
      };

      signatoriesToSend.push(thirdPartyAssignor);
    }

    if (userLead.email_contract || userLead.email) {
      const currentAssignorSignatory = {
        email: userLead.email_contract || userLead.email,
        contract: 3,
        sign_as: 'transferor',
      };

      if (assignee?.signatures) {
        const signatoriesParsed = JSON.parse(assignee.signatures) || [];

        setSignatories((currSignatories) => {
          const remainingSignatories = [
            ...currSignatories,
            ...signatoriesParsed,
            currentAssignorSignatory,
          ];

          signatoriesToSend.push(...remainingSignatories);

          const uniqueSignatories = getUniqueItemsByProperties(signatoriesToSend, ['email']);

          return uniqueSignatories;
        });
      } else {
        setSignatories((currSignatories) => {
          const remainingSignatories = [
            ...currSignatories,
            currentAssignorSignatory,
          ];

          signatoriesToSend.push(...remainingSignatories);

          const uniqueSignatories = getUniqueItemsByProperties(signatoriesToSend, ['email']);

          return uniqueSignatories;
        });
      }
    }
  }, [assignee, userLead.email, userLead.email_contract, userLead?.third_party_data]);

  const handleOnAddSignatory = (): any => {
    const emailValue = emailRef.current?.value;
    const signAsValue = signAsRef.current?.value;
    const contractValue = contractRef.current?.value;

    if (emailValue && contractValue && signAsValue) {
      setSignatories((currSignatories) => {
        const hasEmail = currSignatories.find(
          (itemSignatory) => itemSignatory.email === emailValue,
        );

        if (hasEmail) {
          alert('Email já adicionado.');
          return currSignatories;
        }

        return [...currSignatories, {
          email: emailValue,
          sign_as: signAsValue,
          contract: +contractValue,
        }];
      });

      emailRef.current.value = '';
    }
  };

  const handleOnRemoveSignatory = (email: string): any => {
    setSignatories(
      (currSignatories) => currSignatories.filter((itemSignatory) => itemSignatory.email !== email),
    );
  };

  const handleOnSubmitSignatories = async (): Promise<void> => {
    if (!signatories.length) {
      alert('Por favor, adicione pelo menos um email antes de enviar.');
    } else {
      // const assigneeCampaigns: any = {
      //   2: 'inbound-consort',
      //   6: 'inbound-rodobens',
      // };

      // const campaignName = assigneeCampaigns[userLead.cessionario] || 'inbound-contemplato';
      // if (!userLead.isSaasV2) {
      //   await apiSaas.post('/v1/messaging-crm/send-custom-message', {
      //     campaignName,
      //     templateName: 'inbound-contemplato-contract',
      //     contacts: [
      //     { phone_number: `55${userLead.phone.replace(/\D/g, '')}`, field_1: userLead.display },
      //     ],
      //   });
      // }

      handleRequestSign(signatories, selectedFiles);
    }
  };

  return (
    <div className="col" style={{ width: '100%', padding: '32px', overflow: 'auto' }}>
      <div className="100w col center">
        <h1 className="blanc" style={{ fontSize: '20px' }}>Solicitação de assinaturas</h1>
      </div>
      <div>
        <FirebaseModal
          id={id}
          selectable
          onCheckFile={handleOnCheck}
          defaultSelected={selectedFirebaseFiles}
        />
      </div>
      <div className="col" style={{ marginTop: '16px' }}>
        <table>
          <thead>
            <tr className="blanc" style={{ textAlign: 'left' }}>
              <th>
                Email
              </th>
              <th>
                Assinado como
              </th>
              <th>
                Tipo de contrato
              </th>
            </tr>
          </thead>
          <tbody>
            {signatories.map((itemSignatory) => (
              <tr key={itemSignatory.email} className="blanc">
                <td>
                  {itemSignatory.email}
                </td>
                <td>
                  {ContractAsSign[itemSignatory.sign_as]}
                </td>
                <td>
                  {ContractStatus[itemSignatory.contract]}
                </td>
                <td style={{ textAlign: 'right' }}>
                  <button onClick={() => handleOnRemoveSignatory(itemSignatory.email)} type="button" className="1p" style={{ color: 'red', backgroundColor: 'transparent' }}>X</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="col" style={{ marginTop: '16px' }}>
        <div className="row">
          <input ref={emailRef} className="100w 10p 10r" type="text" />
          <select ref={signAsRef} className="100w 10r 10p" style={{ width: '140px', margin: '0 8px' }}>
            <option value="transferee">Cessionário</option>
            <option value="transferor">Cedente</option>
            <option value="witness">Testemunhas</option>
            <option value="intervening">Interveniente</option>
            <option value="party">Parte</option>
          </select>
          <select ref={contractRef} className="100w 10r 10p" style={{ width: '200px' }} defaultValue={1}>
            <option disabled value={0}>Selecione o tipo</option>
            <option value={1}>Termo de transferência</option>
            <option value={2}>Termo de cessão</option>
            <option value={3}>Termo de transferência e cessão</option>
          </select>
        </div>
        <div className="row" style={{ marginTop: '8px' }}>
          <button className="10p 10r blanc 100w" type="button" style={{ backgroundColor: '#4AEE78' }} onClick={handleOnAddSignatory}>Adicionar email</button>
          <button className="10p 10r blanc 100w Blue" type="button" style={{ marginLeft: '8px' }} onClick={handleOnSubmitSignatories}>Solicitar assinatura</button>
        </div>
      </div>
    </div>
  );
};

export default ModalRequestSign;
