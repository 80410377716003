import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

import { tmsToDateMask } from '../../../../../../utils/masks';

import { UserLeadsAll } from '../../../../../_interfaces';
import { ListUsersAttributes } from './interface';

import {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
  SpanHeader,
  SpanHeaderStatus,
  XCross,
  ContainerSelectStatus,
} from './styles';

const ListUsers: React.FC<ListUsersAttributes> = ({
  listUsers, filter, handleMoveSelect, handleOnDelete,
}) => {
  const HeaderSpanContentStatus = (status: number): string => {
    let text = '';

    switch (true) {
      case status === 101:
        text = 'Expirados';
        break;
      case status === 131:
        text = 'Negado';
        break;
      case status >= 134:
        text = 'Oferta Recusada';
        break;
      default:
        console.log(status);
    }

    return text;
  };

  const listUsersComponent = useMemo(() => {
    if (listUsers.length) {
      let list = [] as UserLeadsAll[];

      if (filter !== 130) {
        list = listUsers.filter((user) => +user.status === filter);
      } else {
        list = listUsers.filter((user) => +user.status !== 150);
      }

      if (!list.length) {
        return <div className="20p">Carregando...</div>;
      }

      return list.map((userLead) => (
        <div className="960y 20r row" style={Content} key={userLead.id}>
          <div style={ContainerLead} className="row center Gray2 1s">
            <div className="col">
              <div style={SpanHeader} className="row center wrap">
                <span style={SpanStyle} className="fo16"><b>Código: {userLead.id}</b></span>
                <div style={ContainerSelectStatus}>
                  <select className="Blanc 10p 10r" defaultValue="" onChange={(e) => handleMoveSelect(e, userLead)}>
                    <option value="" disabled>Mover para</option>
                    <option value="110">Preços</option>
                  </select>
                  <div style={SpanHeaderStatus(userLead.status)} className="10r 100w Blue">
                    <span>
                      {HeaderSpanContentStatus(userLead.status)}
                    </span>
                  </div>
                </div>
              </div>
              <Link className="row center black wrap" to={`/crm/extract/${userLead.id}`}>
                <div className="col 1s">
                  <span style={SpanStyle}>Nome: {userLead.display}</span>
                  <span style={SpanStyle}>E-mail: {userLead.email}</span>
                  {userLead.tmsPricing && (
                    <span style={SpanStyle}>
                      Data da proposta: {tmsToDateMask(userLead.tmsPricing)}
                    </span>
                  )}
                  {userLead.tmsDeny && (
                    <span style={SpanStyle}>
                      Data da proposta <b>NEGADA</b>: {tmsToDateMask(userLead.tmsDeny)}
                    </span>
                  )}
                </div>
                <div className="col 1s">
                  <span style={SpanStyle}>Data: {tmsToDateMask(userLead.tmsIn)}</span>
                  <span style={SpanStyle}>Telefone: {userLead.phone}</span>
                </div>
              </Link>
            </div>
          </div>
          <div style={{ ...ExtractStatusContainer, backgroundColor: userLead.extract ? '#1e8cf9' : '#ef5350' }}>
            <button className="1p 5r" style={XCross} onClick={() => handleOnDelete(userLead)} type="button">
              <FaTimes className="col center" size={16} />
            </button>
          </div>
        </div>
      ));
    }
    return <div className="20p">Tudo em ordem!</div>;
  }, [listUsers, handleOnDelete, filter]);

  return <div className="100w col center">{listUsersComponent}</div>;
};

export default ListUsers;
