import React from 'react';
import { RiFileExcelLine } from 'react-icons/ri';

import { ModalOptionsComponent } from './interface';

import {
  Container,
} from './styles';

const ModalOptions: React.FC<ModalOptionsComponent> = ({
  handleOnClick,
}) => (
  <div style={Container}>
    <button
      className="blanc darken Blue"
      type="button"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '54px',
        width: '54px',
        borderRadius: '100%',
        border: 0,
      }}
      onClick={handleOnClick}
    >
      <RiFileExcelLine size={24} />
    </button>
  </div>
);

export default ModalOptions;
