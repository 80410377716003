import React, {
  createContext, useContext, useState, useCallback,
} from 'react';

import { useAuth } from '../auth';

import api from '../../services/api';

import { CacheContextData } from './interface';

import {
  ExtractAdministrator,
  ExtractType,
  ExtractItem,
} from '../../modules/_interfaces';

const CacheContext = createContext({} as CacheContextData);

const CacheProvider: React.FC = ({ children }) => {
  const [administrators, setAdministrators] = useState<ExtractAdministrator[]>([]);
  const [releases, setReleases] = useState([]);
  const [items, setItems] = useState<ExtractItem[]>([]);
  const [types, setTypes] = useState<ExtractType[]>([]);
  const [bacen, setBacen] = useState([]);
  const [rates, setRates] = useState([]);

  const { token } = useAuth();

  const requestAdministrators = useCallback(async (): Promise<ExtractAdministrator[]> => {
    if (administrators.length) {
      return administrators;
    }

    const { data } = await api.post('/output/administradoras', {
      agent: token,
    });

    const administratorsData = data?.data;

    setAdministrators(administratorsData);

    return administratorsData;
  }, [administrators, token]);

  const requestItems = useCallback(async (): Promise<ExtractItem[]> => {
    if (items.length) {
      return items;
    }

    const { data } = await api.post('/output/itens', {
      agent: token,
    });

    const itemsData = data?.data;

    setItems(itemsData);

    return itemsData;
  }, [items, token]);

  const requestTypes = useCallback(async (): Promise<ExtractType[]> => {
    if (types.length) {
      return types;
    }

    const { data } = await api.post('/output/tipos', {
      agent: token,
    });

    const typesData = data?.data;

    setTypes(typesData);

    return typesData;
  }, [types, token]);

  const requestBacen = useCallback(async (): Promise<any> => {
    if (bacen.length) {
      return bacen;
    }

    const { data } = await api.post('/output/apiBacen', {
      agent: token,
    });

    const bacenData = data?.data;

    setBacen(bacenData);

    return bacenData;
  }, [bacen, token]);

  const requestReleases = useCallback(async (): Promise<any> => {
    if (releases.length) {
      return releases;
    }

    const { data } = await api.post('/output/lancamentos', {
      agent: token,
    });

    const releasesData = data?.data;

    setReleases(releasesData);

    return releasesData;
  }, [releases, token]);

  const requestRates = useCallback(async (): Promise<any> => {
    if (rates.length) {
      return rates;
    }

    const { data } = await api.post('/output/rate', {
      agent: token,
    });

    const ratesData = data?.data;

    setRates(ratesData);

    return ratesData;
  }, [rates, token]);

  return (
    <CacheContext.Provider value={{
      requestAdministrators,
      requestReleases,
      requestRates,
      requestItems,
      requestBacen,
      requestTypes,
      administrators,
      releases,
      rates,
      items,
      bacen,
      types,
    }}
    >
      {children}
    </CacheContext.Provider>
  );
};

function useCache(): CacheContextData {
  const context = useContext(CacheContext);

  if (!context) {
    throw new Error('useCache must be used within an CacheProvider');
  }

  return context;
}

export { CacheContext, CacheProvider, useCache };
