import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import Menu from '../Menu';

import {
  Container,
  ContainerLink,
  Content,
  ContentLink,
} from './styles';

const Header: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const url = window.location.pathname;

  return (
    <header className="100w center row" style={Container}>
      <Menu isOpen={isOpenMenu} setIsOpen={setIsOpenMenu} />
      <FiMenu size={24} color="#fff" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => setIsOpenMenu(true)} />
      <div className="100w center row" style={Content}>
        <Link className="fo16" to="/dossie" style={ContainerLink}>
          <div className="blanc" style={ContentLink(url, '/dossie')}>
            Início
          </div>
        </Link>
        <Link className="blanc fo16" style={ContainerLink} to="/dossie/pending">
          <div className="blanc" style={ContentLink(url, '/dossie/pending')}>
            Pendências
          </div>
        </Link>
      </div>
    </header>
  );
};

export default Header;
