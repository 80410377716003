import React, { useCallback, useState } from 'react';
import {
  IoIosEye, IoIosRocket, IoMdTrash, IoMdCheckmark,
} from 'react-icons/io';
import { ImNotification } from 'react-icons/im';
import {
  GridColDef, GridRowData, GridRowsProp,
} from '@mui/x-data-grid-pro';

import TableDataGrid from './components/TableDataGrid';

// import Lead from './components/Lead';

import ModalForm from '../ModalForm';
import Modal from '../../../../components/Modal';

import ModalFormReleases from '../../../Transference/components/ModalForm';

import { validateDateByString } from '../../../../../../utils/validations';
import { removeEmptyFields } from '../../../../../../utils/functionalities';
import {
  tmsToDateMask, currencyMaskByValue, dateMaskByValue, phoneMaskByValue,
} from '../../../../../../utils/masks';
import { dateBrToDefaultString } from '../../../../../../utils/converts';

import { ListUserAttributes } from './interface';
import { ModalFormData } from '../../interface';

const allColumns = {
  id: 'id',
  Nome: 'display',
  Email: 'email',
  Telefone: 'phone',
  Data: 'tmsIn',
  Administradora: 'administrator',
  'Total pago': 'paid',
  'Divida restante': 'debit',
  'Débito total': 'totalDebit',
  'Total de parcelas': 'totalPortion',
  'Fundo comum': 'unitrust',
  Grupo: 'group',
  'Data contemplação': 'tmsContemplated',
  Cota: 'quota',
  Item: 'item',
  Tipo: 'type',
  Crédito: 'credit',
  'Valor da parcela': 'portion',
  'Parcelas restantes': 'totalPortionToPay',
  'Data do extrato': 'tmsExtract',
  'Encerramento do grupo': 'tmsDieGroup',
  'Preço da proposta': 'pricing',
  Estratégia: 'strategy',
  Status: 'status',
} as any;

// eslint-disable-next-line
export enum StatusDossie {
  'Inicio' = 200,
  'Contrato a ser enviado' = 210,
  'Contrato' = 215,
  'Solicitação de transferência' = 220,
  'Reativação de Cota' = 221,
  'Solicitar Kit Transferência' = 222,
  'Aguardo Documento Interno' = 223,
  'Aguardo Documento Cliente' = 224,
  'Cartório' = 225,
  'Motoboy' = 226,
  'Distrato' = 227,
  'Cadastro na administradora' = 230,
  'Envio de documentos' = 240,
  'Termo de cessão' = 250,
  'Aguardando transferência' = 260,
  'Desistente' = 270,
  'Descartado' = 271,
  'Finalizado' = 280,
}

let currentLead: any = {};

const ListLeads: React.FC<ListUserAttributes> = ({
  listUsers,
  onUpdateField,
  items,
  types,
  onMoveSelect,
  administrators,
  onSubmit,
  handleOnDelete,
  onInsertModalRelease,
  setListUsers,
  setIsOpen,
  releaseOptions,
  onUpdateStrategy,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOpenReleases, setIsOpenReleases] = useState(false);
  const [isTableEditable, setIsTableEditable] = useState(false);

  const handleModalFormOnSubmit = useCallback(async (modalData: any, id: number): Promise<void> => {
    setIsOpenReleases(false);

    onInsertModalRelease({
      ...modalData,
      id,
    });
  }, [onInsertModalRelease, setIsOpenReleases]);

  const handleOnSubmit = useCallback((modalFormData: ModalFormData): void => {
    onSubmit(modalFormData, currentLead);

    setModalIsOpen(false);
  }, [onSubmit]);

  const handleOnOpenModalCreateRelease = useCallback(async (id: number): Promise<void> => {
    setIsOpenReleases(true);

    currentLead = {
      id,
    };
  }, [setIsOpenReleases]);

  const handleOnInputChange = useCallback((rowLead: any): void => {
    const { field } = rowLead;

    switch (field) {
      case ('Total pago'):
      case ('Divida restante'):
      case ('Débito total'):
      case ('Fundo comum'):
      case ('Crédito'):
      case ('Valor da parcela'):
      case ('Preço da proposta'):
        rowLead.props.value = currencyMaskByValue(rowLead.props.value);
        break;
      case ('Data'):
      case ('Data contemplação'):
      case ('Data do extrato'):
      case ('Encerramento do grupo'):
        rowLead.props.value = dateMaskByValue(rowLead.props.value);
        break;
      case ('Telefone'):
        rowLead.props.value = phoneMaskByValue(rowLead.props.value);
        break;
      default:
        break;
    }
  }, []);

  const keys = Object.keys(allColumns);

  const columnsButEmpty = keys.map((key, index) => {
    if (!allColumns[key]) {
      return null;
    }

    if (index) {
      if (key === 'Administradora' || key === 'Item' || key === 'Tipo' || key === 'Status' || key === 'Estratégia') {
        return {
          field: key,
          headerName: key,
          width: 150,
          editable: false,
        };
      }

      return {
        field: key, headerName: key, width: 150, editable: true,
      } as GridColDef;
    }
    return {
      field: key, headerName: key, width: 100, editable: false,
    };
  });

  const columns: GridColDef[] = Object.values(removeEmptyFields(columnsButEmpty));

  columns.push({
    field: 'Cessionário',
    headerName: 'Cessionário',
    width: 150,
    editable: false,
  });

  columns.push({
    field: 'Transferido',
    headerName: 'Transferido',
    width: 150,
    editable: false,
  });

  columns.push({
    field: 'Ações',
    headerName: 'Ações',
    width: 150,
    editable: false,
  });

  const handleMoveSelect = useCallback((e: any, userLead: any): void => {
    const value = +e.currentTarget.value;

    if (value === 280) {
      currentLead = userLead;

      onMoveSelect({
        ...userLead,
        status: 300,
        tmsFinished: new Date().getTime(),
      });
    } else {
      Object.assign(userLead, {
        status: value,
      });

      onMoveSelect(userLead);
    }
  }, [onMoveSelect]);

  const handleOnUpdateStrategy = useCallback((e: any, userLead: any): void => {
    onUpdateStrategy({
      id: userLead.id,
      status: userLead.status,
      strategy: +e.currentTarget.value,
    });
  }, [onUpdateStrategy]);

  const rows: GridRowsProp = [...listUsers].map((lead: any) => {
    const gridRow: GridRowData = {
      editable: true,
    };

    columns.forEach((column) => {
      const actualColumn = column?.field;
      const leadRow = lead[allColumns[actualColumn]];

      if (lead && actualColumn) {
        if (typeof leadRow === 'number' && `${leadRow}`.length === 13) {
          gridRow[actualColumn] = tmsToDateMask(leadRow);
        } else if (actualColumn === 'Administradora') {
          gridRow[actualColumn] = administrators.find(
            (administrator) => administrator?.id === leadRow,
          )?.display;
        } else if (actualColumn === 'Item') {
          gridRow[actualColumn] = items.find(
            (item) => item?.id === leadRow,
          )?.display;
        } else if (actualColumn === 'Cessionário') {
          gridRow[actualColumn] = lead.displayCessionario;
        } else if (actualColumn === 'Tipo') {
          gridRow[actualColumn] = types.find(
            (type) => type?.id === leadRow,
          )?.display;
        } else if (actualColumn === 'Transferido') {
          const fidcLog = JSON.parse(lead?.fidclog || '{}');

          if (fidcLog.status === 'Aguardando Elegibilidade') {
            gridRow[actualColumn] = fidcLog;
          }
        } else if (actualColumn === 'Status') {
          gridRow[actualColumn] = StatusDossie[leadRow];
        } else {
          gridRow[actualColumn] = leadRow;
        }
      }
    });

    return gridRow;
  });

  const handleOnUpdate = useCallback((item: any): void => {
    let fieldValue = +item?.value;

    if (!item?.value || typeof fieldValue !== 'number' || isNaN(fieldValue)) {
      fieldValue = item?.value;
    }

    const submitObj = removeEmptyFields({
      ...item.row,
      [item.field]: fieldValue,
    });

    const submitObjKeys = Object.keys(submitObj);

    const updateObj = submitObjKeys.reduce((curr, prev) => {
      if (!allColumns[prev]) {
        return curr;
      }

      if (typeof submitObj[prev] === 'string' && submitObj[prev].length === 10) {
        const isValid = validateDateByString(submitObj[prev]);

        if (isValid) {
          const defaultDate = dateBrToDefaultString(submitObj[prev]);
          const tmsDate = new Date(defaultDate).getTime();

          submitObj[prev] = tmsDate;
        }
      }

      if (currentLead.status) {
        return {
          ...curr,
          [allColumns[prev]]: submitObj[prev],
          status: currentLead.status,
          id: item?.id,
        };
      }

      const actualLead = listUsers.find((lead) => lead.id === item?.id);

      if (actualLead?.status) {
        return {
          ...curr,
          [allColumns[prev]]: submitObj[prev],
          status: actualLead.status,
          id: item?.id,
        };
      }

      return {
        ...curr,
        [allColumns[prev]]: submitObj[prev],
        id: item?.id,
      };
    }, {} as any);

    delete updateObj?.administrator;
    delete updateObj?.item;
    delete updateObj?.type;

    setListUsers((allLeads: any) => allLeads.map((lead: any) => {
      if (lead.id === updateObj.id) {
        return {
          ...lead,
          ...updateObj,
        };
      }

      return lead;
    }));

    onUpdateField(updateObj);
  }, [onUpdateField, setListUsers, listUsers]);

  const administratorsOptions = administrators.map((administrator) => (
    <option key={administrator.id} value={administrator.id}>{administrator.display}</option>
  ));

  const itemsOptions = items.map((item) => (
    <option key={item.id} value={item.id}>{item.display}</option>
  ));

  const typesOptions = types.map((type) => (
    <option key={type.id} value={type.id}>{type.display}</option>
  ));

  const administratorColumnIndex = columns.findIndex((column) => column.field === 'Administradora');
  const itemColumnIndex = columns.findIndex((column) => column.field === 'Item');
  const typeColumnIndex = columns.findIndex((column) => column.field === 'Tipo');
  const statusColumnIndex = columns.findIndex((column) => column.field === 'Status');
  const actionsColumnIndex = columns.findIndex((column) => column.field === 'Ações');
  const assigneeColumnIndex = columns.findIndex((column) => column.field === 'Cessionário');
  const strategyColumnIndex = columns.findIndex((column) => column.field === 'Estratégia');
  const transferedColumnIndex = columns.findIndex((column) => column.field === 'Transferido');

  if (typeof administratorColumnIndex === 'number') {
    columns[administratorColumnIndex].renderCell = (rowLead: any) => {
      const leadAdministratorName = rowLead?.row?.Administradora;
      const defaultAdministratorId = administrators.find(
        (administrator) => administrator?.display === leadAdministratorName,
      )?.id;

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultAdministratorId}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                return {
                  ...lead,
                  administrator: +e.currentTarget.value,
                };
              }

              return lead;
            }));
            onUpdateField(
              {
                id,
                administrator: +e.currentTarget.value,
                status: StatusDossie[rowLead?.row?.Status],
              },
            );
          }}
        >
          {administratorsOptions}
        </select>
      );
    };
  }

  if (typeof transferedColumnIndex === 'number') {
    columns[transferedColumnIndex].renderCell = (rowLead: any) => {
      if (rowLead?.row?.Transferido) {
        return <IoMdCheckmark />;
      }

      return <ImNotification />;
    };
  }

  if (typeof itemColumnIndex === 'number') {
    columns[itemColumnIndex].renderCell = (rowLead: any) => {
      const leadItemName = rowLead?.row?.Item;
      const defaultItemId = items.find(
        (item) => item?.display === leadItemName,
      )?.id;

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultItemId}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                return {
                  ...lead,
                  item: +e.currentTarget.value,
                };
              }

              return lead;
            }));

            onUpdateField(
              {
                id: rowLead?.row?.id,
                item: +e.currentTarget.value,
                status: StatusDossie[rowLead?.row?.Status],
              },
            );
          }}
        >
          {itemsOptions}
        </select>
      );
    };
  }

  if (typeof typeColumnIndex === 'number') {
    columns[typeColumnIndex].renderCell = (rowLead: any) => {
      const leadTypeName = rowLead?.row?.Tipo;
      const defaultTypeId = types.find(
        (type) => type?.display === leadTypeName,
      )?.id;

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultTypeId}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                return {
                  ...lead,
                  type: +e.currentTarget.value,
                };
              }

              return lead;
            }));
            onUpdateField(
              {
                id: rowLead?.row?.id,
                type: +e.currentTarget.value,
                status: StatusDossie[rowLead?.row?.Status],
              },
            );
          }}
        >
          {typesOptions}
        </select>
      );
    };
  }

  if (typeof statusColumnIndex === 'number') {
    columns[statusColumnIndex].renderCell = (rowLead: any) => (
      <select
        disabled={!isTableEditable}
        defaultValue={StatusDossie[rowLead.row.Status]}
        onChange={(e) => {
          const id = rowLead?.row?.id;

          currentLead = {
            id,
            status: +e.currentTarget.value,
          };

          setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
            if (lead.id === id) {
              handleMoveSelect(e, lead);
              return {
                ...lead,
                ...currentLead,
              };
            }

            return lead;
          }));
        }}
      >
        <option value="200">Inicio</option>
        <option value="210">Contrato</option>
        <option value="215">Contrato enviado</option>
        <option value="220">Solicitação de transferência</option>
        <option value="221">Reativação de Cota</option>
        <option value="222">Solicitar Kit Transferência</option>
        <option value="223">Aguardo Documento Interno</option>
        <option value="224">Aguardo Documento Cliente</option>
        <option value="225">Cartório</option>
        <option value="226">Motoboy</option>
        <option value="227">Distrato</option>
        <option value="230">Cadastro na administradora</option>
        <option value="240">Envio de documentos</option>
        <option value="250">Termo de cessão</option>
        <option value="260">Aguardando transferência</option>
        <option value="270">Desistente</option>
        <option value="271">Descartado</option>
        <option value="280">Finalizado</option>
      </select>
    );
  }

  if (typeof strategyColumnIndex === 'number') {
    columns[strategyColumnIndex].renderCell = (rowLead: any) => {
      const defaultValueStrategy = rowLead.row.Estratégia || 0;

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultValueStrategy}
          style={{ marginLeft: '16px' }}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            currentLead = {
              id,
              strategy: +e.currentTarget.value,
            };

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                handleOnUpdateStrategy(e, lead);
                return {
                  ...lead,
                  ...currentLead,
                };
              }

              return lead;
            }));
          }}
        >
          <option disabled value={0}>Estratégia</option>
          <option value={1}>Quitação</option>
          <option value={2}>Cancelada</option>
          <option value={3}>Intermediação</option>
        </select>
      );
    };
  }

  if (typeof actionsColumnIndex === 'number') {
    columns[actionsColumnIndex].renderCell = (rowLead: any) => {
      const { id } = rowLead?.row;

      return (
        <div className="row">
          <div>
            <a href={`/dossie/extract/${id}`} target="_blank" rel="noreferrer">
              <button className="col center" type="button">
                <IoIosEye size={20} />
              </button>
            </a>
          </div>
          <div>
            <button
              className="col center"
              type="button"
              onClick={() => handleOnOpenModalCreateRelease(id)}
            >
              <IoIosRocket size={20} />
            </button>
          </div>
          <div>
            <button
              className="col center"
              type="button"
              onClick={() => handleOnDelete(id)}
            >
              <IoMdTrash size={20} />
            </button>
          </div>
        </div>
      );
    };
  }

  if (typeof assigneeColumnIndex === 'number') {
    columns[assigneeColumnIndex].renderCell = (rowLead: any) => {
      const { id } = rowLead?.row;

      const lead = listUsers.find((itemLead: any) => itemLead.id === id);

      if (!lead) {
        return <div>N/A</div>;
      }

      return (
        <div className="row">
          {lead.displayCessionario}
        </div>
      );
    };
  }

  return (
    <div className="100w col center dossie-table">
      <div
        className="blanc"
        style={{
          backgroundColor: '#2F3B53', width: '100%', height: '600px',
        }}
      >
        <Modal
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
        >
          <ModalForm
            lead={currentLead}
            onSubmit={handleOnSubmit}
          />
        </Modal>
        <Modal setIsOpen={setIsOpenReleases} isOpen={isOpenReleases}>
          <ModalFormReleases
            releaseOptions={releaseOptions}
            onSubmit={(modalData) => handleModalFormOnSubmit(modalData, currentLead.id)}
          />
        </Modal>
        <TableDataGrid
          setIsTableEditable={setIsTableEditable}
          isTableEditable={isTableEditable}
          columns={columns}
          rows={rows}
          setIsOpen={setIsOpen}
          handleOnInputChange={handleOnInputChange}
          handleOnUpdate={handleOnUpdate}
        />
      </div>
    </div>
  );
};

export default ListLeads;
