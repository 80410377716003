import React from 'react';

import { InputAttributes } from './interface';

const Input: React.FC<InputAttributes> = ({ reference, labelName, ...rest }) => (
  <div className="10p 100w">
    {labelName && <label htmlFor={rest.name}>{labelName}:</label>}
    <input className="Blanc 100w 10p 10r" ref={reference} {...rest} />
  </div>
);

export default Input;
