import React, {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';

import { removeEmptyFields } from '../../../../../../utils/functionalities';

import Input from '../../../../components/Input';

import { OnboardingFormAttributes } from './interface';

import { ButtonContainer } from './styles';

const OnboardingForm: React.FC<OnboardingFormAttributes> = ({ onSubmit, userLead }) => {
  const [accountType, setAccountType] = useState('');

  useEffect(() => {
    setAccountType(userLead.bank_account_type);
  }, [userLead]);

  const bankNumberAccountRef = useRef() as MutableRefObject<HTMLInputElement>;
  const bankAccountTypeRef = useRef() as MutableRefObject<HTMLSelectElement>;
  const nationalityRef = useRef() as MutableRefObject<HTMLInputElement>;
  const civilStatusRef = useRef() as MutableRefObject<HTMLInputElement>;
  const complementRef = useRef() as MutableRefObject<HTMLInputElement>;
  const codePostalRef = useRef() as MutableRefObject<HTMLInputElement>;
  const document2Ref = useRef() as MutableRefObject<HTMLInputElement>;
  const documentRef = useRef() as MutableRefObject<HTMLInputElement>;
  const districtRef = useRef() as MutableRefObject<HTMLInputElement>;
  const addressRef = useRef() as MutableRefObject<HTMLInputElement>;
  const agencyRef = useRef() as MutableRefObject<HTMLInputElement>;
  const genderRef = useRef() as MutableRefObject<HTMLInputElement>;
  const careerRef = useRef() as MutableRefObject<HTMLInputElement>;
  const bankRef = useRef() as MutableRefObject<HTMLInputElement>;
  function handleOnSubmit(e: any): void {
    e.preventDefault();

    const submitObj = removeEmptyFields({
      bank_account_type: bankAccountTypeRef.current?.value,
      bank_number_account: bankNumberAccountRef.current?.value,
      nationality: nationalityRef.current?.value,
      civil_status: civilStatusRef.current?.value,
      complement: complementRef.current?.value,
      codePostal: codePostalRef.current?.value,
      document2: document2Ref.current?.value,
      document: documentRef.current?.value,
      district: districtRef.current?.value,
      address: addressRef.current?.value,
      bank_ag: agencyRef.current?.value,
      gender: genderRef.current?.value,
      career: careerRef.current?.value,
      bank: bankRef.current?.value,
    });

    onSubmit(submitObj);
  }

  return (
    <form name="extract-form" method="post" onSubmit={(e) => handleOnSubmit(e)} className="100w col center">
      <div className="100w row wrap">
        <div className="col">
          <div className="row wrap">
            <Input reference={addressRef} labelName="Endereço" name="address" defaultValue={userLead.address} type="text" />
            <Input reference={bankRef} labelName="Banco" name="bank" defaultValue={userLead.bank} type="text" maxLength={14} />
          </div>
          <div className="row 100w wrap">
            <div className="10p 10r 100w">
              <label htmlFor="">Tipo do banco:</label>
              <select className=" 10p 10r 100w Blanc" ref={bankAccountTypeRef} value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                <option disabled>Tipo do banco</option>
                <option value="Corrente">Conta corrente</option>
                <option value="Poupanca">Conta poupança</option>
                <option value="Salario">Conta salário</option>
              </select>
            </div>
            <Input reference={agencyRef} labelName="Agência" defaultValue={userLead.bank_ag} name="agency" type="text" />
          </div>
          <div className="row wrap">
            <Input reference={documentRef} labelName="CPF/CNPJ" name="document" defaultValue={userLead.document} type="text" />
            <Input reference={document2Ref} labelName="RG" name="document2" defaultValue={userLead.document2} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={civilStatusRef} labelName="Estado cívil" name="civil_status" defaultValue={userLead.civil_status} type="text" />
            <Input reference={careerRef} labelName="Carreira" name="career" defaultValue={userLead.career} type="text" />
          </div>
        </div>
        <div className="col">
          <div className="row wrap">
            <Input reference={bankNumberAccountRef} labelName="Numero da conta" defaultValue={userLead.bank_number_account} name="bank_number_account" type="text" />
            <Input reference={codePostalRef} labelName="Código postal" name="codePostal" defaultValue={userLead.codePostal} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={complementRef} labelName="Complemento" name="complement" defaultValue={userLead.complement} type="text" />
            <Input reference={districtRef} labelName="Bairro" name="district" defaultValue={userLead.district} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={nationalityRef} labelName="Nacionalidade" name="nationality" defaultValue={userLead.nationality} type="text" />
            <Input reference={genderRef} labelName="Gênero" name="gender" defaultValue={userLead.gender} type="text" />
          </div>
        </div>
      </div>
      <button type="submit" className="blanc 10r 1p" style={{ ...ButtonContainer, backgroundColor: '#36CF61' }}>Salvar dados</button>
    </form>
  );
};

export default OnboardingForm;
