import { useState } from 'react';

interface InfiniteScrollAttributes {
  pagination: number;
  infiniteScrollPagination(): void;
}

export function useInfiniteScroll(defaultPage = 1): InfiniteScrollAttributes {
  const [pagination, setPagination] = useState(defaultPage);
  let pages = defaultPage;

  function handleOnScroll(): void {
    if (window.scrollY > pages * 4000) {
      pages++;
      setPagination(pages);
    }
  }

  function infiniteScrollPagination(): void {
    document.addEventListener('scroll', handleOnScroll);
  }

  return {
    pagination,
    infiniteScrollPagination,
  };
}
