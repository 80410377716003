import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { TiDelete } from 'react-icons/ti';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';

import { useCnabModal } from '../../../../hooks/cnabModal';
import { useAuth } from '../../../../hooks/auth';
import { useCache } from '../../../../hooks/cacheServices';

import { removeEmptyFields } from '../../../../utils/functionalities';
import { tmsToDateMask } from '../../../../utils/masks';
import { removeAllCommas } from '../../../../utils/converts';

import api from '../../../../services/api';
import apiSaas from '../../../../services/apiSaas';
import apiFunctions from '../../../../services/apiFunctions';
import apiMogno from '../../../../services/apiMogno';
import { storage } from '../../../../services/firebase';

import Lead from './components/Lead';
import Form from './components/Form';
import Table from './components/Table';
import ModalForm from './components/ModalForm';
import Documents from './components/Documents';
import OnboardingForm from './components/OnboardingForm';
import TableReleases from './components/TableReleases';
import ModalRequestSign from './components/ModalRequestSign';
import ModalUploadDocuments from './components/ModalUploadDocuments';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import DropZone from '../../../components/DropZone';

import { RouteParams, UserLeadsAll } from '../../../_interfaces';
import {
  SubmitExtractData,
  DocumentStoraged,
  QuotaRelease,
  UpdateReleaseParams,
  ModalSubmitParams,
  UserLeadQuota,
  ReleaseOptions,
} from './interface';

import {
  Container,
  Content,
  Title,
  Wrapper,
  ContainerDocuments,
  TableContainer,
  ButtonRequestSign,
  ButtonCreateRelease,
  ButtonTransferFidcDocument,
} from './styles';

interface Signatory {
  email: string;
  contract: number;
  sign_as: string;
  sign_key?: string;
  group?: number;
}

const Transference: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const { handleAddCnabData } = useCnabModal();

  const history = useHistory();

  const { token, operator, handleLogout } = useAuth();
  const {
    administrators, types, items, requestAdministrators, requestItems, requestTypes,
  } = useCache();

  const [isOpen, setIsOpen] = useState(false);
  const [fidcFiles, setFidcFiles] = useState([] as any);
  const [isModalSignOpen, setIsModalSignOpen] = useState(false);
  const [isModalTransferDocuments, setIsModalTransferDocuments] = useState(false);
  const [isOpenSignedDocuments, setIsOpenSignedDocuments] = useState(false);
  const [assignee, setAssignee] = useState<any>({});
  const [userLead, setUserLead] = useState({} as UserLeadsAll);
  const [releases, setReleases] = useState([{} as QuotaRelease]);
  const [fidcErros, setFidcErrors] = useState([] as string[]);
  const [releaseOptions, setReleaseOptions] = useState([{} as ReleaseOptions]);
  const [documents, setDocuments] = useState([] as DocumentStoraged[]);
  const [anotations, setAnotations] = useState([] as any[]);
  const [signedSignatories, setSignedSignatories] = useState([] as any);
  const [selectedFirebaseFiles, setSelectedFirebaseFiles] = useState<any[]>([]);

  // const [documents, setDocuments] = useState([] as string[]);
  const storageRef = storage.ref(`${process.env.REACT_APP_FIREBASE_STORAGE_PREFIX}/${id}`);

  useEffect(() => {
    const requestAnotations = async (): Promise<void> => {
      try {
        const { data: notes } = await apiSaas.get(`/v1/notes/${id}`);

        setAnotations(notes);
      } catch (err) {
        console.error(err);
      }
    };

    requestAnotations();
  }, [id]);

  useEffect(() => {
    const requestUserLeadData = async (): Promise<void> => {
      try {
        const { data } = await api.post('/output/dossie', {
          agent: token,
          id: +id,
        });

        const { data: dataLancamentos } = await api.post('/output/lancamentos', {
          agent: token,
          relational: +id,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          const usersList = Object.values(data.data) as UserLeadQuota[];

          const hasUser = usersList.find((user) => user.id === +id);

          if (hasUser) {
            const storageItemsResponse = await storageRef.list();
            const hasExtract = !!storageItemsResponse.items.length;

            if (hasExtract) {
              hasUser.extract = 1;
            } else {
              hasUser.extract = 0;
            }

            setUserLead(hasUser);
            setReleases(dataLancamentos?.data);
          } else {
            history.push('/');
          }
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    const requestReleasesData = async (): Promise<void> => {
      try {
        const { data } = await api.post('/output/lista_lancamentos', {
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          setReleaseOptions(data.data);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    const requestDocumentsData = async (): Promise<void> => {
      try {
        const storageContratoList = await storageRef.child('Contrato').listAll();
        const storageTermosList = await storageRef.child('Termo de cessao').listAll();
        const storageFichaList = await storageRef.child('Ficha cadastral').listAll();
        const storageBoletosList = await storageRef.child('Boletos').listAll();

        setDocuments([
          {
            name: 'Contrato',
            hasDocument: !!storageContratoList.items.length,
          },
          {
            name: 'Termo de cessão',
            hasDocument: !!storageTermosList.items.length,
          },
          {
            name: 'Ficha cadastral',
            hasDocument: !!storageFichaList.items.length,
          },
          {
            name: 'Boletos',
            hasDocument: !!storageBoletosList.items.length,
          },
        ]);
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    const requestSelectedFirebaseFiles = async (): Promise<void> => {
      const contracts = await storageRef.child('Contrato').listAll();

      const { data: assigneeData } = await api.post('/output/cessionario', {
        agent: token,
      });

      const requestedAssignees = assigneeData.data;

      const currentAssignee = requestedAssignees.find(
        (itemAssignee: any) => itemAssignee.id === userLead.cessionario,
      );

      setAssignee(currentAssignee);

      const fullPaths: any[] = [];

      if (currentAssignee?.signatures) {
        const contractSearch = {
          1: () => {
            const transferenceContract = contracts.items.find(
              (contract) => contract.fullPath.match('Transferencia'),
            );

            if (!fullPaths.includes(transferenceContract)) {
              const hasContract = fullPaths.find(
                (fullPath) => fullPath.type === 1,
              );

              if (!hasContract) {
                fullPaths.push({
                  contract: transferenceContract,
                  type: 1,
                });
              }
            }
          },
          2: () => {
            const cessionContract = contracts.items.find(
              (contract) => contract.fullPath.match('Cessao'),
            );

            if (!fullPaths.includes(cessionContract)) {
              const hasContract = fullPaths.find(
                (fullPath) => fullPath.type === 2,
              );

              if (!hasContract) {
                fullPaths.push({
                  contract: cessionContract,
                  type: 2,
                });
              }
            }
          },
          3: () => {
            const cessionContract = contracts.items.find(
              (contract) => contract.fullPath.match('Cessao'),
            );

            if (!fullPaths.includes(cessionContract)) {
              const hasContract = fullPaths.find(
                (fullPath) => fullPath.type === 2,
              );

              if (!hasContract) {
                fullPaths.push({
                  contract: cessionContract,
                  type: 2,
                });
              }
            }

            const transferenceContract = contracts.items.find(
              (contract) => contract.fullPath.match('Transferencia'),
            );

            if (!fullPaths.includes(transferenceContract)) {
              const hasContract = fullPaths.find(
                (fullPath) => fullPath.type === 1,
              );

              if (!hasContract) {
                fullPaths.push({
                  contract: transferenceContract,
                  type: 1,
                });
              }
            }
          },
        } as any;

        const signatories = currentAssignee?.signatures
          ? JSON.parse(currentAssignee.signatures)
          : [];

        const contractIds = [1, 2, 3];

        contractIds.forEach((contractId: number) => {
          const signatoryContract = signatories.find(
            (signatory: any) => signatory.contract === contractId,
          );

          if (signatoryContract) {
            const contractSearchFunction = contractSearch[signatoryContract.contract];

            if (contractSearchFunction) {
              contractSearchFunction();
            }
          }
        });

        setSelectedFirebaseFiles(fullPaths);
      }
    };

    if (token) {
      requestUserLeadData();
      requestDocumentsData();
      requestReleasesData();
      requestAdministrators();
      requestItems();
      requestTypes();
      requestSelectedFirebaseFiles();
    }
  }, [id, token, history, userLead.cessionario]);

  const handleStorageExtract = async (
    files: FileList | null,
  ): Promise<void> => {
    if (!files) {
      throw alert('Arquivos reconhecidos como nulos, tente novamente.');
    }

    const arrayFiles = Object.values(files);

    await Promise.all(
      arrayFiles.map((file: File): any => {
        const refChild = storageRef.child(`${file.name}`);
        return refChild.put(file);
      }),
    );

    await api.put('/input/dossie', {
      agent: token,
      extract: 1,
      status: userLead.status,
      id: +id,
    });

    setUserLead((lead) => ({
      ...lead,
      extract: 1,
    }));
  };

  const handleStorageFile = async (
    files: FileList | null, documentName: string,
  ): Promise<void> => {
    if (!files) {
      throw alert('Arquivos reconhecidos como nulos, tente novamente.');
    }

    const arrayFiles = Object.values(files);

    await Promise.all(
      arrayFiles.map((file: File): any => {
        const refChild = storageRef.child(`${file.name}`);

        return refChild.put(file);
      }),
    );

    const documentIndex = documents.findIndex(
      (arrayDocument) => arrayDocument.name === documentName,
    );

    documents[documentIndex].hasDocument = true;

    setDocuments([...documents]);
  };

  const handleAddModalReleases = (release: any): void => {
    handleAddCnabData({
      ...userLead,
      launches: releases as any[],
      release,
    });
  };

  const handleGenerateContract = async (contract: any): Promise<void> => {
    const administrator = administrators.find(
      (item) => item.id === userLead.administrator,
    );

    const { data } = await api.put('/input/contract', {
      agent: token,
      ...userLead,
      ...contract,
      id: userLead.id,
      administratorDisplay: administrator?.display,
      administratorDocument: administrator?.cnpj,
      name: userLead.display_contract,
      birth: userLead.birth,
      number: userLead.number,
      city: userLead.city,
      assetName: userLead.assetName,
      state: userLead.state,
      cep: userLead.codePostal,
      phone: userLead.phone,
      group: userLead.group,
      quota: userLead.quota,
      tmsSentContract: new Date().getTime(),
      contractNumber: userLead.contractNumber,
      tmsContemplated: userLead.tmsContemplated,
      tmsDieGroup: userLead.tmsDieGroup,
      tmsParcela: userLead.tmsParcela,
      totalPortion: userLead.totalPortion,
      paid: userLead.paid,
      bidPlaced: userLead.bidPlaced,
      totalDebit: userLead.totalDebit,
      totalPortionToPay: userLead.totalPortionToPay,
      bank: userLead.bank,
      email: userLead.email,
      type: userLead.type,
      item: userLead.item,
      bank_name: userLead.bank_name,
      bank_ag: userLead.bank_ag,
      bank_number_account: userLead.bank_number_account,
      bank_account_type: userLead.bank_account_type,
    });

    if (data.status) {
      alert('Contrato gerado com sucesso!');
    } else {
      alert(`Erro: ${data.message}`);
    }
  };

  const handleOnSubmit = async (formData: SubmitExtractData): Promise<void> => {
    try {
      const objSubmit = removeEmptyFields({
        id: +id,
        ...formData,
        status: (userLead.status === 100 || userLead.status === 150) ? 110 : userLead.status,
        agent: token,
        isSaasV2: userLead.isSaasV2,
      });

      const { data } = await api.put('/input/dossie', objSubmit);

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      } else {
        alert('Atualizado com sucesso!');
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
      handleLogout();
    }
  };

  const handleOnUpdateUser = async (params: any): Promise<void> => {
    setUserLead({
      ...userLead,
      ...params,
      agent: token,
      status: userLead.status,
      id: +id,
    });

    await api.put('/input/dossie', {
      ...params,
      agent: token,
      status: userLead.status,
      id: +id,
    });
  };

  const handleOnUpdateStatus = async (status: number): Promise<void> => {
    setUserLead({
      ...userLead,
      status,
      id: +id,
    });

    const payload: any = {
      agent: token,
      status,
      id: +id,
      isSaasV2: userLead.isSaasV2,
    };

    if (status === 300) {
      payload.tmsFinished = new Date().getTime();
    }

    await api.put('/input/dossie', payload);
  };

  async function handleConsultDocuments(): Promise<void> {
    const { data } = await apiFunctions.post('/onboarding/tokenValidate', {
      token: userLead.bgChecked,
    });

    return data;
  }

  const handleModalFormOnSubmit = async (modalData: ModalSubmitParams): Promise<void> => {
    setIsOpen(false);

    try {
      const { data } = await api.post('/input/lancamentos', {
        idRelational: +id,
        agent: token,
        description: modalData.description,
        display: +`${modalData.display}`,
        visible: 1,
        tms: modalData.tms,
        into: 'dossie',
      });

      if (data.status) {
        setReleases([...releases, {
          id: data.data.insertId,
          description: modalData.description,
          display: modalData.display,
          tms: modalData.tms,
          visible: 1,
          idLancamento: +id,
          status: userLead.status,
        }]);
      } else {
        alert(`Falha ao cadastrar o lançamento: ${data.message}`);
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
      handleLogout();
    }
  };

  const onUpdateField = useCallback(async (data: UpdateReleaseParams): Promise<void> => {
    await api.put('/input/lancamentos', {
      ...data,
      agent: token,
      visible: 1,
    });

    const index = releases.findIndex((release) => data.id === release.id);

    releases[index] = {
      ...data,
      status: userLead.status,
      idLancamento: +id,
      visible: 1,
    };

    setReleases([...releases]);
  }, [token, userLead, releases, id]);

  const handleOnDeleteRelease = async (params: any): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        const { data } = await api.put('/input/lancamentos', {
          ...params,
          agent: token,
          visible: 0,
        });

        if (data.status) {
          const filteredReleases = releases.filter((release) => release.id !== params.id);
          setReleases(filteredReleases);
        } else {
          alert(`Falha na requisição ${data.message}`);
          console.error(data);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  };

  const userLeadComponent = useMemo(() => {
    if (!userLead.tmsIn) {
      return <div />;
    }

    const lead = {
      ...userLead,
      dateIn: tmsToDateMask(userLead.tmsIn),
    };

    return (
      <Lead
        lead={lead}
        operatorName={operator.name}
        assignee={assignee}
        onUpdateStatus={handleOnUpdateStatus}
        onUpdateUser={handleOnUpdateUser}
        fnConsultDocuments={handleConsultDocuments}
        onStorageExtract={handleStorageExtract}
      />
    );
  }, [userLead, operator, assignee]);

  const onDropAccepted = useCallback((acceptedFiles: any) => {
    // Do something with the files
    setFidcFiles([...acceptedFiles]);
  }, []);

  const handleOnConsultSignedDocuments = useCallback(async () => {
    const { data } = await api.post('/output/webhook-clicksign', {
      agent: token,
      cessionario: assignee?.display,
      id: +id,
    });

    if (data.status) {
      setSignedSignatories(data.data);
    } else {
      alert('Erro ao realizar a requisição, verifique a aba rede');
    }
  }, [id, assignee, token]);

  const handleOnTransferFidcDocuments = useCallback(async () => {
    setFidcErrors([]);
    if (fidcFiles.length) {
      const payload = {
        agent: token,
        email: assignee.email,
        password: assignee.password,
        fundo: '43721713000157',
        codigo: 'DC00',
        documento: {
          numero: '12345',
          descricao: 'Teste de api',
          tipo: 'term_cession',
          indexador: '1234567890',
          data: '10/10/2021',
          categoria: 'dawikodjkaw',
        },
        arquivo: {
          nome: fidcFiles[0].path,
          conteudo: fidcFiles[0].base64,
        },
      };

      const { data } = await api.post('/input/fsengine-documentos', payload);
      await apiMogno.post('/log', payload);

      if (!data?.status) {
        if (data.data.global) {
          setFidcErrors(typeof data.data.global === 'string' ? data.data.global : [data.message]);
        } else {
          setFidcErrors([data.message]);
        }
      } else {
        alert('Sucesso ao transferir os documentos!');
      }
    } else {
      alert('Nenhum arquivo encontrado.');
    }
  }, [fidcFiles, userLead, token, assignee]);

  const handleOnUpdateOnboardingExtract = useCallback(async (onboardingData: any) => {
    try {
      setUserLead({
        ...userLead,
        ...onboardingData,
        status: userLead.status,
      });

      const { data } = await api.put('/input/dossie', {
        id: +id,
        agent: token,
        status: userLead.status,
        ...onboardingData,
        third_party_data: onboardingData.third_party_data,
      });

      if (!data.status) {
        alert('Falha na requisição, tente novamente.');
      } else {
        alert('Atualizado com sucesso!');
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
    }
  }, [token, id, userLead]);

  const getBase64FromUrl = async (url: string): Promise<string | ArrayBuffer | null> => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const requestSignatoriesKeys = async (
    selectedFiles: any[], signatories: Signatory[],
  ): Promise<any> => {
    let success = true;

    await Promise.all(selectedFiles.map(async (itemContrato) => {
      const base64File = await storageRef.root.child(
        itemContrato.contract.fullPath,
      ).getDownloadURL();
      const base64result = await getBase64FromUrl(base64File) as string;

      const signatoriesOrganized = signatories.map((signatory) => {
        if (signatory.contract === 3) {
          return [{ ...signatory, contract: 1 }, { ...signatory, contract: 2 }];
        }

        return signatory;
      }).flat(10);

      const signatoriesByContract = signatoriesOrganized.filter(
        (signatory) => signatory.contract === itemContrato.type,
      );

      const signObj = {
        id,
        email: signatoriesByContract,
        contract: itemContrato.contract.name,
        file: base64result.split(',')[1],
        agent: token,
        cessionario: assignee?.display,
      };

      const { data } = await api.post('/input/clickSign', signObj);

      if (!data.status) {
        success = false;
        throw alert(`Erro ao solicitar a assinatura usando o arquivo: ${itemContrato.contract.name}`);
      }

      return signObj;
    }));

    return {
      success,
    };
  };

  const handleRequestSign = async (
    signatories: Signatory[], selectedFiles: any[],
  ): Promise<void> => {
    if (!selectedFiles.length) {
      alert('Nenhum contrato selecionado.');
    }

    alert('Carregando...');

    const assigneeSignatories = assignee?.signatures ? JSON.parse(assignee.signatures) : [];

    const emailsWithoutSignatoriesSignKey = assigneeSignatories.map(
      (itemAssigneeSignatory: any) => {
        const signatory = signatories.find(
          (itemSignatory: any) => (
            (itemSignatory.email === itemAssigneeSignatory.email)
          ),
        );

        if (signatory?.sign_key || itemAssigneeSignatory?.sign_key) {
          return undefined;
        }

        return itemAssigneeSignatory.email;
      },
    );

    const emailsWithoutSignKeys = emailsWithoutSignatoriesSignKey?.filter(
      (itemSignatory: any) => itemSignatory !== undefined,
    );

    if (emailsWithoutSignKeys.length) {
      const { data } = await api.post('/input/webhook-create-signatures-clicksign', {
        agent: token,
        cessionario: assignee.display,
        sign: emailsWithoutSignKeys,
      });

      if (data.status) {
        const signatoriesKeys = data.data;

        const signatoriesToSave: any = [];

        const signatoriesWithKeys = signatories?.map((itemSignatory) => {
          const signatoryWithKey = signatoriesKeys.find(
            (signatoryKey: any) => signatoryKey.email === itemSignatory.email,
          );

          if (signatoryWithKey) {
            const newSignatorie = {
              ...itemSignatory,
              sign_key: signatoryWithKey.sign_key,
            };

            signatoriesToSave.push(newSignatorie);

            return newSignatorie;
          }

          return itemSignatory;
        });

        const newAssignee = {
          ...assignee,
          agent: token,
          signatures: JSON.stringify(signatoriesToSave),
        };

        await api.put('/input/cessionario', newAssignee);

        setAssignee(newAssignee);

        const { success } = await requestSignatoriesKeys(selectedFiles, signatoriesWithKeys);

        if (success) {
          await api.put('/input/dossie', {
            id: +id,
            agent: token,
            tmsSentContract: new Date().getTime(),
            status: 215,
            isSaasV2: userLead.isSaasV2,
          });
          await api.put('/input/userlead', newAssignee);
        }
      } else {
        alert('Falha na requisição, verifique o erro na aba redes.');
      }
    } else {
      const { success } = await requestSignatoriesKeys(selectedFiles, signatories);

      if (success) {
        await api.put('/input/dossie', {
          id: +id,
          agent: token,
          tmsSentContract: new Date().getTime(),
          status: 215,
          isSaasV2: userLead.isSaasV2,
        });
      }
    }

    alert('Enviado com sucesso!');

    setIsModalSignOpen(false);
  };

  const UploadDocumentSubmit = async ({
    file, base64, type, code,
  }: any): Promise<any> => {
    const payload = {
      id: +id,
      email: assignee?.email,
      password: assignee?.password,
      fundo: removeAllCommas(assignee.doc_fundo),
      agent: token,
      codigo: code,
      documento: {
        numero: userLead.contractNumber,
        descricao: `${userLead.group}_${userLead.quota}`,
        tipo: type,
        indexador: `${userLead.id}`,
      },
      arquivo: {
        nome: file.name,
        conteudo: base64.split(',')[1],
      },
    };

    const { data } = await api.post('/input/fsengine-documentos', payload);
    await apiMogno.post('/log', payload);

    if (!data.status) {
      alert(`Falha ao enviar o arquivo: ${file.name}`);
    }
  };

  const handleOnUploadDocumentSubmit = async (file: any): Promise<any> => {
    const { name, url } = file;

    const base64 = await getBase64FromUrl(url) as string;

    if (name.match('Cessao')) {
      UploadDocumentSubmit({
        file,
        base64,
        code: 'DC01',
        type: 'Contcessao',
      });
    } else if (name.match('Transferencia')) {
      UploadDocumentSubmit({
        file,
        base64,
        code: 'DC00',
        type: 'term_cession',
      });
    } else {
      alert("Arquivo não foi reconhecido como 'Cessao ou Transferencia'");
    }
  };

  const handleOnCreateAnnotation = async (): Promise<any> => {
    const message = prompt('Digite sua anotação aqui');

    if (message) {
      const { data } = await apiSaas.post('/v1/notes', {
        id,
        message,
      });

      setAnotations([...anotations, {
        id: data.id,
        message,
        createdAt: data?.createdAt,
      }]);
    }
  };

  const handleOnCheck = (file: any, allSelectedFiles: any[]): any => {
    const checkedFiles = allSelectedFiles.map((firebaseContract: any) => {
      const transferenceContract = firebaseContract.name.match('Transferencia');
      const cessionContract = firebaseContract.name.match('Cessao');

      if (transferenceContract) {
        return {
          contract: firebaseContract,
          type: 1,
        };
      }

      if (cessionContract) {
        return {
          contract: firebaseContract,
          type: 2,
        };
      }

      return {
        contract: firebaseContract,
        type: 999,
      };
    });

    setSelectedFirebaseFiles(checkedFiles);
  };

  const handleSendFiles = async ():Promise<any> => {
    const response = await api.put('/input/transfer', {
      id: +id,
      agent: token,
    });
    if (response.data.status) {
      alert('email com documentos enviado com sucesso, lead enviado para status: aguardando transferência.');
    } else {
      alert('erro ao enviar email de transferência, contate suporte');
    }
  };

  console.log(anotations);

  return (
    <div style={Container}>
      <Header />
      <Modal setIsOpen={setIsOpen} isOpen={isOpen}>
        <ModalForm
          releaseOptions={releaseOptions}
          onSubmit={handleModalFormOnSubmit}
        />
      </Modal>
      <Modal maxWidth={800} height="100%" setIsOpen={setIsModalSignOpen} isOpen={isModalSignOpen}>
        <ModalRequestSign
          id={+id}
          selectedFiles={selectedFirebaseFiles}
          handleRequestSign={handleRequestSign}
          handleOnCheck={handleOnCheck}
          assignee={assignee}
          userLead={userLead}
        />
      </Modal>
      <Modal maxWidth={800} height="100%" setIsOpen={setIsModalTransferDocuments} isOpen={isModalTransferDocuments}>
        <ModalUploadDocuments
          id={+id}
          handleOnUploadDocument={handleOnUploadDocumentSubmit}
        />
      </Modal>
      <Modal setIsOpen={setIsOpenSignedDocuments} isOpen={isOpenSignedDocuments}>
        <div className="100w col center 10p" style={{ display: 'flex', height: '100%', alignItems: 'space-between' }}>
          <h2 className="blanc">Leads assinados</h2>
          {signedSignatories.map((signed: any) => (
            <div key={signed?.email} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span style={{ color: '#fff' }}>{signed?.email}</span>
              <div style={{ marginLeft: '8px' }}>
                {
                  signed?.status ? <BsCheckCircleFill color="#fff" size={15} /> : <BsXCircleFill color="#fff" size={16} />
                }
              </div>
            </div>
          ))}
          <p className="blanc" style={{ fontSize: '12px' }}>quando todos assinarem, o documento assinado será incluído no Firebase</p>
          <button className="10p 10r Blue 100w blanc" onClick={handleOnConsultSignedDocuments} type="button">Consultar</button>
        </div>
      </Modal>
      <div className="960y col" style={Content}>
        <div style={Wrapper} className="col center blanc 20r 20p">
          <h3 style={Title} className="fo20">Usuário</h3>
          {userLeadComponent}
        </div>
        <div
          style={
            Wrapper
          }
          className="Gray2 col center blanc wrap 20r 20p 960y"
        >
          <h3 style={Title} className="fo20">Anotações</h3>
          <div className="100w 10r" style={TableContainer}>
            {
            anotations.map((anotation, index) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div key={anotation.id} style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                  <b>{index + 1}</b>
                  <span style={{ marginLeft: '16px' }}>{anotation.message}</span>
                </div>
                <span style={{ marginLeft: '16px' }}>{anotation?.createdAt ? moment(anotation?.createdAt).format('DD/MM/YYYY') : ''}</span>
              </div>
            ))
          }
          </div>
          <button onClick={handleOnCreateAnnotation} type="button" className="blanc col center 10p 10r 100w Blue" style={{ marginTop: '16px' }}>Cadastrar anotação</button>
        </div>
        <div style={ContainerDocuments} className="col center blanc wrap 20r 20p">
          <Documents
            id={id}
            documents={documents}
            onStorageFile={handleStorageFile}
          />
          <div className="row 100w">
            <button onClick={() => setIsModalSignOpen(true)} type="button" className="blanc col center 1p 10r 100w Blue" style={ButtonRequestSign}>Solicitar assinaturas</button>
            <button onClick={() => setIsOpenSignedDocuments(true)} type="button" className="blanc col center 1p 10r 100w" style={{ ...ButtonTransferFidcDocument, backgroundColor: 'rgb(24, 138, 120)', marginLeft: '8px' }}>Consulta assinaturas</button>
            <button onClick={() => handleSendFiles()} type="button" className="blanc col center 1p 10r 100w" style={{ ...ButtonRequestSign, marginLeft: '8px', backgroundColor: '#4AEE78' }}>Transmitir arquivos</button>
          </div>
        </div>
        <div
          style={
            Wrapper
          }
          className="Gray2 col center blanc wrap 20r 20p"
        >
          <h3 style={Title} className="fo20">Tabela Lançamentos</h3>
          <div className="100w 10r" style={TableContainer}>
            <TableReleases
              onUpdateField={onUpdateField}
              onDeleteRelease={handleOnDeleteRelease}
              onAddModalReleases={handleAddModalReleases}
              releaseOptions={releaseOptions}
              releases={releases}
            />
          </div>
          <button onClick={() => setIsOpen(true)} type="button" className="blanc col center 1p 10r 100w" style={ButtonCreateRelease}>Cadastrar lançamentos</button>
        </div>

        { false && (
          <div
            style={Wrapper}
            className="Gray2 col center blanc wrap 20r 20p"
          >
            <h3 style={Title} className="fo20">Transferir Contratos</h3>
            <DropZone
              isMultiple={false}
              onDropAccepted={onDropAccepted}
            />
              {
                fidcFiles.length ? (
                  <div className="10p flex col">
                    {
                      fidcFiles.map((file: any) => (
                        <div key={file.id}>
                          <span style={{ marginTop: '5px' }}>{file.path}</span>
                          <TiDelete
                            onClick={() => setFidcFiles(
                              fidcFiles.filter((fidcFile: any) => file.id !== fidcFile.id),
                            )}
                            className="tomato"
                            size={24}
                            style={{
                              position: 'relative', marginLeft: '4px', top: '6px', cursor: 'pointer',
                            }}
                          />
                        </div>
                      ))
                    }
                  </div>
                ) : <div />
              }
            <div className="row 100w">
              <button onClick={() => setIsOpenSignedDocuments(true)} type="button" className="blanc col center 1p 10r 100w" style={{ ...ButtonTransferFidcDocument, backgroundColor: 'rgb(74, 238, 120)' }}>Realizar consulta dos signatários</button>
              <button onClick={handleOnTransferFidcDocuments} type="button" className="blanc col center 1p 10r 100w Blue" style={{ ...ButtonTransferFidcDocument, marginLeft: '8px' }}>Transmitir contrato cessão</button>
            </div>
              {
                fidcErros.map((itemFidc) => (
                  <div key={itemFidc} style={{ marginTop: '8px' }}>
                    <span className="tomato">{itemFidc}</span>
                    <br />
                  </div>
                ))
              }
          </div>
        )}

        <div
          style={
            Wrapper
          }
          className="col center blanc wrap 20r 20p"
        >
          <h3 style={Title} className="fo20">Tabela Status</h3>
          <div className="100w 10r" style={TableContainer}>
            <Table userLead={userLead} />
          </div>
        </div>
        <div style={Wrapper} className="col center blanc 20r 20p">
          <h1 style={Title} className="fo20 10p">Dados Onboarding</h1>
          <OnboardingForm
            userLead={userLead}
            onSubmit={handleOnUpdateOnboardingExtract}
          />
        </div>
        <div style={Wrapper} className="col center blanc 20r 20p">
          <h1 style={Title} className="fo20 10p">Extrato</h1>
          <Form
            userLead={userLead}
            fnOnSubmit={handleOnSubmit}
            fnGenerateContract={handleGenerateContract}
            items={items}
            types={types}
            administrators={administrators}
          />
        </div>
      </div>
    </div>
  );
};

export default Transference;
