interface GoogleApiInt {
  authenticate(): Promise<void>
  createContact(name: string, phone: string): Promise<void>
}

export function useGoogleApi(): GoogleApiInt {
  async function authenticate(): Promise<void> {
    await gapi.auth2.getAuthInstance().signIn({ scope: 'https://www.googleapis.com/auth/contacts' });
    console.log('Sign-in successful');
    await loadClient();
  }
  async function loadClient(): Promise<void> {
    gapi.client.setApiKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`);
    await gapi.client.load('https://people.googleapis.com/$discovery/rest?version=v1', 'v1');
    console.log('GAPI client loaded for API');
  }

  async function createContact(name: string, phone: string): Promise<void> {
    if (!gapi?.client?.people?.people) {
      alert('Usuário do google não autenticado, por favor, logue com a sua conta do google antes de aprovar/recusar as análises.');
    } else {
      try {
        await gapi.client.people.people.createContact({
          resource: {
            phoneNumbers: [
              {
                value: phone,
                type: 'mobile',
              },
            ],
            names: [
              {
                givenName: name,
              },
            ],
          },
        });
      } catch (err) {
        alert('Erro ao salvar o contato, por favor, veja os logs do erro no console.');
        console.error('Execute error', err);
      }
    }
  }

  gapi.load('client:auth2', () => {
    gapi.auth2.init({ client_id: process.env.REACT_APP_GOOGLE_USER_ID });
  });

  return {
    authenticate,
    createContact,
  };
}
