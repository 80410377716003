import BackgroundImage from '../../../../assets/bg_contemplato_login.png';

const Container = {
  height: '100vh',
  backgroundColor: '#FAFAFA',
  // eslint-disable-next-line
  backgroundImage: `url(${BackgroundImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto',
  backgroundPosition: 'top right',
};

export {
  Container,
};
