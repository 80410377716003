import React, { useCallback, useState } from 'react';
import {
  IoIosEye, IoIosRocket, IoMdTrash, IoMdCheckmark,
} from 'react-icons/io';
import { ImNotification } from 'react-icons/im';
import {
  GridColDef, GridRowData, GridRowsProp,
} from '@mui/x-data-grid-pro';

import {
  tmsToDateMask, currencyMaskByValue, dateMaskByValue, phoneMaskByValue,
} from '../../../../../../utils/masks';
import { convertBRLToNumbers, dateBrToDefaultString, numberOfDaysBetweenDates } from '../../../../../../utils/converts';
import { validateDateByString } from '../../../../../../utils/validations';
import { removeEmptyFields } from '../../../../../../utils/functionalities';

import Modal from '../../../../components/Modal';
import ModalFormReleases from '../../../Quota/components/ModalForm';
import TableDataGrid from './components/TableDataGrid';
import ModalForm from '../ModalForm';

import { ListUserAttributes } from './interface';

// eslint-disable-next-line
enum StatusWallet {
  'Inicio' = 300,
  'Excluido' = 301,
  'Cadastro' = 310,
  'Não Contemplada' = 320,
  'Contemplada' = 330,
  'Finalizada' = 340,
  'Cancelada' = 350,
}

// eslint-disable-next-line
enum Strategies {
  'Quitação' = 1,
  'Cancelada' = 2,
  'Intermediação' = 3,
}

const allColumns = {
  id: 'id',
  Nome: 'display',
  Email: 'email',
  Telefone: 'phone',
  Data: 'tmsIn',
  Administradora: 'administrator',
  'Total pago': 'paid',
  'Divida restante': 'debit',
  'Débito total': 'totalDebit',
  'Total de parcelas': 'totalPortion',
  'Fundo comum': 'unitrust',
  Grupo: 'group',
  'Data contemplação': 'tmsContemplated',
  Cota: 'quota',
  Item: 'item',
  Tipo: 'type',
  Crédito: 'credit',
  'Valor da parcela': 'portion',
  'Parcelas restantes': 'totalPortionToPay',
  'Número do contrato': 'contractNumber',
  'Data do extrato': 'tmsExtract',
  'Data origem': 'tmsIn',
  'Encerramento do grupo': 'tmsDieGroup',
  'Preço da proposta': 'pricing',
  Estratégia: 'strategy',
  'Tir efetiva': 'tirEfetiva',
  Status: 'status',
} as any;

let currentLead: any = {};

function calculateUpdatedTotalDebit(leadId: number, releases: any, extractDate: any): any {
  const filteredReleases = releases.filter((release: any) => release.relational === leadId);

  const updatedDebitTotal = filteredReleases?.reduce((prev: any, curr: any) => {
    if (curr.tms && (curr.display === 3 || curr.display === 4 || curr.display === 5)) {
      const numberPrevBetweenDays = numberOfDaysBetweenDates(
        extractDate,
        new Date(curr.tms),
      );

      if (numberPrevBetweenDays > 0) {
        prev.total += convertBRLToNumbers(curr.description);
      }
    }

    return prev;
  }, {
    total: 0,
  });

  return updatedDebitTotal;
}

const components: React.FC<ListUserAttributes> = ({
  listUsers,
  items,
  types,
  administrators,
  onSubmit,
  handleOnDelete,
  setListUsers,
  onUpdateField,
  onMoveSelect,
  setIsOpen,
  releaseOptions,
  onInsertModalRelease,
  calculateAllTirs,
  onUpdateStrategy,
  releases,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOpenReleases, setIsOpenReleases] = useState(false);
  const [isTableEditable, setIsTableEditable] = useState(false);

  const handleModalFormOnSubmit = useCallback(async (modalData: any, id: number): Promise<void> => {
    setIsOpenReleases(false);

    onInsertModalRelease({
      ...modalData,
      id,
    });
  }, [onInsertModalRelease, setIsOpenReleases]);

  const handleOnSubmit = useCallback((modalFormData: any): void => {
    onSubmit(modalFormData, currentLead);

    setModalIsOpen(false);
  }, [onSubmit]);

  const handleOnOpenModalCreateRelease = useCallback(async (id: number): Promise<void> => {
    setIsOpenReleases(true);

    currentLead = {
      id,
    };
  }, [setIsOpenReleases]);

  const handleOnInputChange = useCallback((rowLead: any): void => {
    const { field } = rowLead;

    switch (field) {
      case ('Total pago'):
      case ('Divida restante'):
      case ('Débito total'):
      case ('Fundo comum'):
      case ('Crédito'):
      case ('Valor da parcela'):
      case ('Preço da proposta'):
        rowLead.props.value = currencyMaskByValue(rowLead.props.value);
        break;
      case ('Data'):
      case ('Data contemplação'):
      case ('Data do extrato'):
      case ('Encerramento do grupo'):
        rowLead.props.value = dateMaskByValue(rowLead.props.value);
        break;
      case ('Telefone'):
        rowLead.props.value = phoneMaskByValue(rowLead.props.value);
        break;
      default:
        break;
    }
  }, []);

  const keys = Object.keys(allColumns);

  const columnsButEmpty = keys.map((key, index) => {
    if (!allColumns[key]) {
      return null;
    }

    if (index) {
      if (key === 'Administradora' || key === 'Item' || key === 'Tipo' || key === 'Status' || key === 'Estratégia') {
        return {
          field: key,
          headerName: key,
          width: 150,
          editable: false,
        };
      }

      return {
        field: key, headerName: key, width: 150, editable: true,
      } as GridColDef;
    }
    return {
      field: key, headerName: key, width: 100, editable: false,
    };
  });

  const columns: GridColDef[] = Object.values(removeEmptyFields(columnsButEmpty));

  columns.push({
    field: 'Divida restante atualizado',
    headerName: 'Divida restante atualizado',
    width: 150,
    editable: false,
  });

  columns.push({
    field: 'Data origem',
    headerName: 'Data origem',
    width: 150,
    editable: false,
  });

  columns.push({
    field: 'Cessionário',
    headerName: 'Cessionário',
    width: 150,
    editable: false,
  });

  columns.push({
    field: 'Pagamento realizado?',
    headerName: 'Pagamento realizado?',
    width: 150,
    editable: false,
  });

  columns.push({
    field: 'Ações',
    headerName: 'Ações',
    width: 150,
    editable: false,
  });

  const rows: GridRowsProp = [...listUsers].map((lead: any) => {
    const gridRow: GridRowData = {
      editable: true,
    };

    columns.forEach((column) => {
      const actualColumn = column?.field;
      const leadRow = lead[allColumns[actualColumn]];

      if (lead && actualColumn) {
        if (typeof leadRow === 'number' && `${leadRow}`.length === 13) {
          gridRow[actualColumn] = tmsToDateMask(leadRow);
        } else if (actualColumn === 'Administradora') {
          gridRow[actualColumn] = administrators?.find(
            (administrator) => administrator?.id === leadRow,
          )?.display;
        } else if (actualColumn === 'Item') {
          gridRow[actualColumn] = items?.find(
            (item) => item?.id === leadRow,
          )?.display;
        } else if (actualColumn === 'Tipo') {
          gridRow[actualColumn] = types?.find(
            (type) => type?.id === leadRow,
          )?.display;
        } else if (actualColumn === 'Estratégia') {
          gridRow[actualColumn] = Strategies[leadRow];
        } else if (actualColumn === 'Status') {
          gridRow[actualColumn] = StatusWallet[leadRow];
        } else if (actualColumn === 'Pagamento realizado?') {
          const today = new Date();

          const releaseOptionPricingId = releaseOptions.find((release) => release.display === 'Parcela')?.id;
          // verificar se a parcela foi paga esse mês
          const releasesFiltered = releases.filter(
            (release) => release.relational === lead.id
            && release.display === releaseOptionPricingId
            && (today.getMonth() === new Date(release.tms).getMonth()
            && today.getFullYear() === new Date(release.tms).getFullYear()),
          );

          const updatedTotalDebit = calculateUpdatedTotalDebit(
            lead.id, releases, new Date(lead.tmsExtract),
          );
          const total = +convertBRLToNumbers(lead.debit) - updatedTotalDebit.total;

          if (releasesFiltered.length) {
            gridRow[actualColumn] = 2;
          } else if (
            total > 0
            && lead?.strategy === Strategies.Quitação
            && !releasesFiltered.length
            && lead.tmsExtract
            && lead.status !== StatusWallet.Finalizada
          ) {
            gridRow[actualColumn] = 1;
          } else {
            gridRow[actualColumn] = 0;
          }
        } else {
          gridRow[actualColumn] = leadRow;
        }
      }
    });

    return gridRow;
  });

  const handleOnUpdateStrategy = useCallback((e: any, userLead: any): void => {
    onUpdateStrategy({
      id: userLead.id,
      status: userLead.status,
      strategy: +e.currentTarget.value,
    });
  }, [onUpdateStrategy]);

  const handleOnUpdate = useCallback((item: any): void => {
    let fieldValue = +item?.value;

    if (!item?.value || typeof fieldValue !== 'number' || isNaN(fieldValue)) {
      fieldValue = item?.value;
    }

    const submitObj = removeEmptyFields({
      ...item.row,
      [item.field]: fieldValue,
    });

    const submitObjKeys = Object.keys(submitObj);

    const updateObj = submitObjKeys.reduce((curr, prev) => {
      if (!allColumns[prev]) {
        return curr;
      }

      if (typeof submitObj[prev] === 'string' && submitObj[prev].length === 10) {
        const isValid = validateDateByString(submitObj[prev]);

        if (isValid) {
          const defaultDate = dateBrToDefaultString(submitObj[prev]);
          const tmsDate = new Date(defaultDate).getTime();

          submitObj[prev] = tmsDate;
        }
      }

      if (currentLead.status) {
        return {
          ...curr,
          [allColumns[prev]]: submitObj[prev],
          status: currentLead.status,
          id: item?.id,
        };
      }

      const actualLead = listUsers.find((lead) => lead.id === item?.id);

      if (actualLead?.status) {
        return {
          ...curr,
          [allColumns[prev]]: submitObj[prev],
          status: actualLead.status,
          id: item?.id,
        };
      }

      return {
        ...curr,
        [allColumns[prev]]: submitObj[prev],
        id: item?.id,
      };
    }, {} as any);

    delete updateObj?.administrator;
    delete updateObj?.item;
    delete updateObj?.type;

    setListUsers((allLeads: any) => allLeads.map((lead: any) => {
      if (lead.id === updateObj.id) {
        return {
          ...lead,
          ...updateObj,
        };
      }

      return lead;
    }));

    onUpdateField(updateObj);
  }, [onUpdateField, setListUsers, listUsers]);

  const administratorsOptions = administrators?.map((administrator) => (
    <option key={administrator.id} value={administrator.id}>{administrator.display}</option>
  ));

  const itemsOptions = items?.map((item) => (
    <option key={item.id} value={item.id}>{item.display}</option>
  ));

  const typesOptions = types?.map((type) => (
    <option key={type.id} value={type.id}>{type.display}</option>
  ));

  const administratorColumnIndex = columns.findIndex((column) => column.field === 'Administradora');
  const itemColumnIndex = columns.findIndex((column) => column.field === 'Item');
  const typeColumnIndex = columns.findIndex((column) => column.field === 'Tipo');
  const statusColumnIndex = columns.findIndex((column) => column.field === 'Status');
  const actionsColumnIndex = columns.findIndex((column) => column.field === 'Ações');
  const updatedTotalDebitColumnIndex = columns.findIndex((column) => column.field === 'Divida restante atualizado');
  const isPayedColumnIndex = columns.findIndex((column) => column.field === 'Pagamento realizado?');
  const assigneeColumnIndex = columns.findIndex((column) => column.field === 'Cessionário');
  const strategyColumnIndex = columns.findIndex((column) => column.field === 'Estratégia');
  const tirEfetivaIndex = columns.findIndex((column) => column.field === 'Tir efetiva');

  if (typeof isPayedColumnIndex === 'number') {
    columns[isPayedColumnIndex].renderCell = (rowLead: any) => {
      const leadId = rowLead?.row?.id;

      const today = new Date();

      const releaseOptionPricingId = releaseOptions.find((release) => release.display === 'Parcela')?.id;

      // verificar se a parcela foi paga esse mês
      const releasesFiltered = releases.filter(
        (release) => release.relational === leadId
        && release.display === releaseOptionPricingId
        && (today.getMonth() === new Date(release.tms).getMonth()
        && today.getFullYear() === new Date(release.tms).getFullYear()),
      );

      if (releasesFiltered.length) {
        return (
          <div>
            <IoMdCheckmark />
          </div>
        );
      }

      if (rowLead?.row['Data do extrato'] && rowLead?.row['Estratégia'] && +StatusWallet[rowLead?.row.Status] !== StatusWallet.Finalizada && typeof rowLead?.row['Data do extrato'] === 'string') {
        const updatedTotalDebit = calculateUpdatedTotalDebit(leadId, releases, new Date(dateBrToDefaultString(rowLead?.row['Data do extrato'])));

        const total = convertBRLToNumbers(rowLead?.row['Divida restante']) - updatedTotalDebit.total;

        if (total > 0 && +Strategies[rowLead?.row['Estratégia']] === Strategies.Quitação && !releasesFiltered.length) {
          return (
            <div>
              <ImNotification />
            </div>
          );
        }
      }

      return <div />;
    };
  }

  if (typeof administratorColumnIndex === 'number') {
    columns[administratorColumnIndex].renderCell = (rowLead: any) => {
      const leadAdministratorName = rowLead?.row?.Administradora;
      const defaultAdministratorId = administrators.find(
        (administrator) => administrator?.display === leadAdministratorName,
      )?.id;

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultAdministratorId}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                return {
                  ...lead,
                  administrator: +e.currentTarget.value,
                };
              }

              return lead;
            }));
            onUpdateField(
              {
                id,
                administrator: +e.currentTarget.value,
                status: StatusWallet[rowLead?.row?.Status],
              },
            );
          }}
        >
          {administratorsOptions}
        </select>
      );
    };
  }

  if (typeof tirEfetivaIndex === 'number') {
    columns[tirEfetivaIndex].renderCell = (rowLead: any) => {
      const leadTirEfetiva = rowLead?.row['Tir efetiva'];

      if (leadTirEfetiva) {
        return (
          <span>{leadTirEfetiva * 100}%</span>
        );
      }

      return (
        <span>N/A</span>
      );
    };
  }

  if (typeof strategyColumnIndex === 'number') {
    columns[strategyColumnIndex].renderCell = (rowLead: any) => {
      const defaultValueStrategy = Strategies[rowLead.row.Estratégia];

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultValueStrategy}
          style={{ marginLeft: '16px' }}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            currentLead = {
              id,
              strategy: +e.currentTarget.value,
            };

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                handleOnUpdateStrategy(e, lead);
                return {
                  ...lead,
                  ...currentLead,
                };
              }

              return lead;
            }));
          }}
        >
          <option disabled value={0}>Estratégia</option>
          <option value={1}>Quitação</option>
          <option value={2}>Cancelada</option>
          <option value={3}>Intermediação</option>
        </select>
      );
    };
  }

  if (typeof itemColumnIndex === 'number') {
    columns[itemColumnIndex].renderCell = (rowLead: any) => {
      const leadItemName = rowLead?.row?.Item;
      const defaultItemId = items.find(
        (item) => item?.display === leadItemName,
      )?.id;

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultItemId}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                return {
                  ...lead,
                  item: +e.currentTarget.value,
                };
              }

              return lead;
            }));

            onUpdateField(
              {
                id: rowLead?.row?.id,
                item: +e.currentTarget.value,
                status: StatusWallet[rowLead?.row?.Status],
              },
            );
          }}
        >
          {itemsOptions}
        </select>
      );
    };
  }

  if (typeof typeColumnIndex === 'number') {
    columns[typeColumnIndex].renderCell = (rowLead: any) => {
      const leadTypeName = rowLead?.row?.Tipo;
      const defaultTypeId = types.find(
        (type) => type?.display === leadTypeName,
      )?.id;

      return (
        <select
          disabled={!isTableEditable}
          defaultValue={defaultTypeId}
          onChange={(e) => {
            const id = rowLead?.row?.id;

            setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
              if (lead.id === id) {
                return {
                  ...lead,
                  type: +e.currentTarget.value,
                };
              }

              return lead;
            }));
            onUpdateField(
              {
                id: rowLead?.row?.id,
                type: +e.currentTarget.value,
                status: StatusWallet[rowLead?.row?.Status],
              },
            );
          }}
        >
          {typesOptions}
        </select>
      );
    };
  }

  if (typeof statusColumnIndex === 'number') {
    columns[statusColumnIndex].renderCell = (rowLead: any) => (
      <select
        disabled={!isTableEditable}
        defaultValue={StatusWallet[rowLead.row.Status]}
        onChange={(e) => {
          const id = rowLead?.row?.id;

          currentLead = {
            id,
            status: +e.currentTarget.value,
          };

          setListUsers((allLeads: any) => [...allLeads].map((lead: any) => {
            if (lead.id === id) {
              onMoveSelect({
                ...lead,
                ...currentLead,
              });
              return {
                ...lead,
                ...currentLead,
              };
            }

            return lead;
          }));
        }}
      >
        <option value="300">Inicio</option>
        <option value="301">Excluido</option>
        <option value="310">Cadastro</option>
        <option value="320">Não contemplada</option>
        <option value="330">Contemplada</option>
        <option value="340">Finalizada</option>
        <option value="350">Cancelada</option>
      </select>
    );
  }

  if (typeof actionsColumnIndex === 'number') {
    columns[actionsColumnIndex].renderCell = (rowLead: any) => {
      const { id } = rowLead?.row;

      return (
        <div className="row">
          <div>
            <a href={`/wallet/extract/${id}`} target="_blank" rel="noreferrer">
              <button className="col center" type="button">
                <IoIosEye size={20} />
              </button>
            </a>
          </div>
          <div>
            <button
              className="col center"
              type="button"
              onClick={() => handleOnOpenModalCreateRelease(id)}
            >
              <IoIosRocket size={20} />
            </button>
          </div>
          <div>
            {false && (
            <button
              className="col center"
              type="button"
              onClick={() => handleOnDelete(id)}
            >
              <IoMdTrash size={20} />
            </button>
            )}
          </div>
        </div>
      );
    };
  }

  if (typeof updatedTotalDebitColumnIndex === 'number') {
    columns[updatedTotalDebitColumnIndex].renderCell = (rowLead: any) => {
      const { id } = rowLead?.row;

      if (rowLead?.row['Data do extrato']) {
        if (typeof rowLead?.row['Data do extrato'] !== 'string') {
          return (
            <div className="row">
              N/A
            </div>
          );
        }

        const updatedTotalDebit = calculateUpdatedTotalDebit(id, releases, new Date(dateBrToDefaultString(rowLead?.row['Data do extrato'])));

        const total = convertBRLToNumbers(rowLead?.row['Divida restante']) - updatedTotalDebit.total;

        return (
          <div className="row">
            {currencyMaskByValue(
              `${total.toFixed(2)}`,
            )}
          </div>
        );
      }

      return (
        <div className="row">
          N/A
        </div>
      );
    };
  }

  if (typeof assigneeColumnIndex === 'number') {
    columns[assigneeColumnIndex].renderCell = (rowLead: any) => {
      const { id } = rowLead?.row;

      const lead = listUsers.find((itemLead: any) => itemLead.id === id);

      if (!lead) {
        return <div>N/A</div>;
      }

      return (
        <div className="row">
          {lead.displayCessionario}
        </div>
      );
    };
  }

  return (
    <div className="100w col center" style={{ marginTop: '16px' }}>
      <Modal
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      >
        <ModalForm
          onSubmit={handleOnSubmit}
        />
      </Modal>
      <Modal setIsOpen={setIsOpenReleases} isOpen={isOpenReleases}>
        <ModalFormReleases
          releaseOptions={releaseOptions}
          onSubmit={(modalData) => handleModalFormOnSubmit(modalData, currentLead.id)}
        />
      </Modal>
      <TableDataGrid
        columns={columns}
        rows={rows}
        setIsOpen={setIsOpen}
        handleOnInputChange={handleOnInputChange}
        handleCalculateAllTirs={calculateAllTirs}
        handleOnUpdate={handleOnUpdate}
        isTableEditable={isTableEditable}
        setIsTableEditable={setIsTableEditable}
      />
    </div>
  );
};

export default components;
