import React, { useRef } from 'react';

import { TableFilteredLeadsAttributes } from './interface';

import Lead from './components/Lead';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
} from './styles';

const TableFilteredLeads: React.FC<TableFilteredLeadsAttributes> = ({
  userLeads,
  handleOnCheck,
  checkedLeads,
}) => {
  const checkboxRef = useRef<HTMLInputElement>([] as any) as any;

  function toggleCheckbox(source: any): void {
    const checkboxes = checkboxRef.current;
    for (let i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i]) {
        checkboxes[i].checked = source.checked;
        handleOnCheck(userLeads[i], checkboxes[i]);
      }
    }
  }

  return (
    <table
      style={TableContent}
      className="10p 20r 100w"
    >
      <thead className="100w" style={TableHeadContainer}>
        <tr className="100w">
          <th style={TableHeadContent}>
            <input type="checkbox" onClick={(e) => toggleCheckbox(e.currentTarget)} />
          </th>
          <th style={TableHeadContent}>
            Código
          </th>
          <th style={TableHeadContent}>
            Nome
          </th>
          <th style={TableHeadContent}>
            Email
          </th>
          <th style={TableHeadContent}>
            Telefone
          </th>
          <th style={TableHeadContent}>
            Aceita apenas
          </th>
        </tr>
      </thead>
      <tbody style={TableBodyContainer}>
        {
        userLeads?.map((lead, i) => (
          <Lead
            key={lead.id}
            lead={lead}
            index={i}
            handleOnCheck={handleOnCheck}
            checkedLeads={checkedLeads}
            checkboxRef={checkboxRef}
          />
        ))
      }
      </tbody>
    </table>
  );
};

export default TableFilteredLeads;
