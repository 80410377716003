import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { TiDelete } from 'react-icons/ti';
import moment from 'moment';

import { useAuth } from '../../../../hooks/auth';
import { useCache } from '../../../../hooks/cacheServices';
import { useCnabModal } from '../../../../hooks/cnabModal';

import apiFunctions from '../../../../services/apiFunctions';
import apiMogno from '../../../../services/apiMogno';
import api from '../../../../services/api';
import { storage } from '../../../../services/firebase';

import Form from './components/Form';
import ModalForm from './components/ModalForm';
import TableStatus from './components/TableStatus';
import TableReleases from './components/TableReleases';
import Lead from './components/Lead';
import OnboardingForm from './components/OnboardingForm';
import Documents from './components/Documents';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import apiSaas from '../../../../services/apiSaas';
import DropZone from '../../../components/DropZone';

import { tmsToDateMask } from '../../../../utils/masks';

import { RouteParams } from '../../../_interfaces';
import {
  UserLeadQuota,
  SubmitExtractData,
  DocumentStoraged,
  QuotaRelease,
  ModalSubmitParams,
  UpdateReleaseParams,
  ReleaseOptions,
} from './interface';

import {
  Container,
  Content,
  Title,
  Wrapper,
  ContainerDocuments,
  TableContainer,
  ButtonCreateRelease,
  ButtonTransferFidcDocument,
} from './styles';

const Quota: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const { handleAddCnabData } = useCnabModal();

  const history = useHistory();

  const { token, operator, handleLogout } = useAuth();
  const {
    administrators,
    types,
    items,
    requestAdministrators,
    requestItems,
    requestTypes,
  } = useCache();

  const [isOpen, setIsOpen] = useState(false);
  const [fidcFiles, setFidcFiles] = useState([] as any);
  const [releases, setReleases] = useState([{} as QuotaRelease]);
  const [releaseOptions, setReleaseOptions] = useState([{} as ReleaseOptions]);
  const [userLead, setUserLead] = useState({} as UserLeadQuota);
  const [fidcErros, setFidcErrors] = useState([] as string[]);
  const [anotations, setAnotations] = useState([] as any[]);
  const [documents, setDocuments] = useState([] as DocumentStoraged[]);

  const storageRef = storage.ref(`${process.env.REACT_APP_FIREBASE_STORAGE_PREFIX}/${id}`);

  useEffect(() => {
    const requestAnotations = async (): Promise<void> => {
      try {
        const { data: notes } = await apiSaas.get(`/v1/notes/${id}`);

        setAnotations(notes);
      } catch (err) {
        console.error(err);
      }
    };

    requestAnotations();
  }, [id]);

  useEffect(() => {
    const requestUserLeadData = async (): Promise<void> => {
      try {
        const { data } = await api.post('/output/gestao', {
          agent: token,
          id: +id,
        });

        const { data: dataReleases } = await api.post('/output/lancamentos', {
          agent: token,
          relational: +id,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          const usersList = Object.values(data.data) as UserLeadQuota[];

          const hasUser = usersList.find((user) => user.id === +id);

          if (hasUser) {
            const storageItemsResponse = await storageRef.list();
            const hasExtract = !!storageItemsResponse.items.length;

            if (hasExtract) {
              hasUser.extract = 1;
            } else {
              hasUser.extract = 0;
            }

            setUserLead(hasUser);
            setReleases(dataReleases?.data);
          } else {
            history.push('/');
          }
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
        handleLogout();
      }
    };

    const requestReleasesData = async (): Promise<void> => {
      try {
        const { data } = await api.post('/output/lista_lancamentos', {
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          setReleaseOptions(data.data);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
        handleLogout();
      }
    };

    const requestDocumentsData = async (): Promise<void> => {
      try {
        const storageContratoList = await storageRef.child('Contrato').listAll();
        const storageTermosList = await storageRef.child('Termo de cessao').listAll();
        const storageFichaList = await storageRef.child('Ficha cadastral').listAll();
        const storageBoletosList = await storageRef.child('Boletos').listAll();

        setDocuments([
          {
            name: 'Contrato',
            hasDocument: !!storageContratoList.items.length,
          },
          {
            name: 'Termo de cessão',
            hasDocument: !!storageTermosList.items.length,
          },
          {
            name: 'Ficha cadastral',
            hasDocument: !!storageFichaList.items.length,
          },
          {
            name: 'Boletos',
            hasDocument: !!storageBoletosList.items.length,
          },
          {
            name: 'Documentos públicos',
            hasDocument: !!storageBoletosList.items.length,
          },
        ]);
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    requestUserLeadData();
    requestDocumentsData();
    requestReleasesData();
    requestAdministrators();
    requestItems();
    requestTypes();
  }, [id, token]);

  const handleOnCreateAnnotation = async (): Promise<any> => {
    const message = prompt('Digite sua anotação aqui');

    if (message) {
      const { data } = await apiSaas.post('/v1/notes', {
        id,
        message,
      });

      setAnotations([...anotations, {
        id: data.id,
        message,
        createdAt: data?.createdAt,
      }]);
    }
  };

  const handleStorageFile = async (
    files: FileList | null, documentName: string,
  ): Promise<void> => {
    if (!files) {
      throw alert('Arquivos reconhecidos como nulos, tente novamente.');
    }

    const arrayFiles = Object.values(files);

    await Promise.all(
      arrayFiles.map((file: File): any => {
        const refChild = storageRef.child(`${file.name}`);

        return refChild.put(file);
      }),
    );

    const documentIndex = documents.findIndex(
      (arrayDocument) => arrayDocument.name === documentName,
    );

    documents[documentIndex].hasDocument = true;

    setDocuments([...documents]);
  };

  const handleGenerateContract = async (contract: any): Promise<void> => {
    const { data } = await api.put('/input/contract', {
      agent: token,
      ...contract,
    });

    if (data.status) {
      alert('Contrato gerado com sucesso!');
    } else {
      alert(`Erro: ${data.message}`);
    }
  };

  const onUpdateField = useCallback(async (data: UpdateReleaseParams): Promise<void> => {
    await api.put('/input/lancamentos', {
      ...data,
      status: userLead.status,
      agent: token,
    });

    const index = releases.findIndex((release) => data.id === release.id);

    releases[index] = {
      ...data,
      status: userLead.status,
      release: +id,
    };

    setReleases([...releases]);
  }, [token, userLead, releases, id]);

  async function handleConsultDocuments(): Promise<void> {
    const { data } = await apiFunctions.post('/onboarding/tokenValidate', {
      token: userLead.bgChecked,
    });

    return data;
  }

  const handleModalFormOnSubmit = async (modalData: ModalSubmitParams): Promise<void> => {
    setIsOpen(false);

    try {
      const newRelease = {
        idRelational: +id,
        agent: token,
        description: modalData.description,
        display: +`${modalData.display}`,
        tms: modalData.tms,
        origin: +modalData.origin,
        visible: 1,
        into: 'gestao',
      };

      const { data } = await api.post('/input/lancamentos', newRelease);

      if (data.status) {
        window.location.reload();
      } else {
        alert(`Falha ao cadastrar o lançamento: ${data.message}`);
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleOnSubmit = async (formData: SubmitExtractData): Promise<void> => {
    try {
      const { data } = await api.put('/input/gestao', {
        id: +id,
        ...formData,
        status: userLead.status,
        agent: token,
        visible: 1,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
      } else {
        alert('Atualizado com sucesso!');
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
      handleLogout();
    }
  };

  const handleOnUpdateUser = async (params: any): Promise<void> => {
    setUserLead({
      ...userLead,
      ...params,
      agent: token,
      status: userLead.status,
      id: +id,
    });

    await api.put('/input/gestao', {
      ...params,
      agent: token,
      status: userLead.status,
      id: +id,
    });
  };

  const handleOnUpdateStatus = async (status: number): Promise<void> => {
    setUserLead({
      ...userLead,
      status,
      id: +id,
    });

    await api.put('/input/gestao', {
      agent: token,
      status,
      id: +id,
    });
  };

  const handleOnDeleteRelease = async (params: any): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        const { data } = await api.put('/input/lancamentos', {
          ...params,
          agent: token,
          visible: 0,
          status: userLead.status,
        });

        if (data.status) {
          const filteredReleases = releases.filter((release) => release.id !== params.id);
          setReleases(filteredReleases);
        } else {
          alert(`Falha na requisição ${data.message}`);
          console.error(data);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  };

  const handleAddModalReleases = (release: QuotaRelease): void => {
    handleAddCnabData({
      ...userLead,
      launches: releases,
      release,
    });
  };

  const handleStorageExtract = async (
    files: FileList | null,
  ): Promise<void> => {
    if (!files) {
      throw alert('Arquivos reconhecidos como nulos, tente novamente.');
    }

    const arrayFiles = Object.values(files);

    await Promise.all(
      arrayFiles.map((file: File): any => {
        const refChild = storageRef.child(`${file.name}`);
        return refChild.put(file);
      }),
    );

    await api.put('/input/gestao', {
      agent: token,
      extract: 1,
      status: userLead.status,
      id: +id,
    });

    setUserLead((lead) => ({
      ...lead,
      extract: 1,
    }));
  };

  const onDropAccepted = useCallback((acceptedFiles: any) => {
    // Do something with the files
    setFidcFiles([...acceptedFiles]);
  }, []);

  const handleOnTransferFidcDocuments = useCallback(async () => {
    setFidcErrors([]);
    const { data: assigneeData } = await api.post('/output/cessionario', {
      agent: token,
    });

    const assignees = assigneeData.data;

    const assignee = assignees.find(
      (itemAssignee: any) => itemAssignee.id === userLead.cessionario,
    );

    if (fidcFiles.length) {
      const payload = {
        agent: token,
        email: assignee.email,
        password: assignee.password,
        fundo: '43721713000157',
        codigo: 'DC00',
        documento: {
          numero: '12345',
          descricao: 'Teste de api',
          tipo: 'term_cession',
          indexador: '1234567890',
          data: '10/10/2021',
          categoria: 'dawikodjkaw',
        },
        arquivo: {
          nome: fidcFiles[0].path,
          conteudo: fidcFiles[0].base64,
        },
      };

      const { data } = await api.post('/input/fsengine-documentos', payload);
      await apiMogno.post('/log', payload);

      if (!data?.status) {
        if (data.data.global) {
          setFidcErrors(typeof data.data.global === 'string' ? data.data.global : [data.message]);
        } else {
          setFidcErrors([data.message]);
        }
      } else {
        alert('Sucesso ao transferir os documentos!');
      }
    } else {
      alert('Nenhum arquivo encontrado.');
    }
  }, [fidcFiles, userLead, token]);

  const userLeadComponent = useMemo(() => {
    if (!userLead.tmsIn) {
      return <div />;
    }

    const lead = {
      ...userLead,
      dateAssembly: tmsToDateMask(userLead.tmsAssembleia),
      dateParcel: tmsToDateMask(userLead.tmsParcela),
      dateIn: tmsToDateMask(userLead.tmsIn),
    };

    return (
      <Lead
        lead={lead}
        operatorName={operator.name}
        onUpdateUser={handleOnUpdateUser}
        onUpdateStatus={handleOnUpdateStatus}
        fnConsultDocuments={handleConsultDocuments}
        onStorageExtract={handleStorageExtract}
      />
    );
  }, [operator, userLead]);

  const handleCalculateTir = useCallback(async () => {
    const { data } = await api.put('/input/calculate-tir', {
      id: +id,
      agent: token,
      status: userLead.status,
    });

    alert('Tir calculada com sucesso!');
    console.log(data.data, id);
  }, [token, userLead, id]);

  const handleOnUpdateOnboardingExtract = useCallback(async (onboardingData: any) => {
    try {
      setUserLead({
        ...userLead,
        ...onboardingData,
        status: userLead.status,
      });

      const { data } = await api.put('/input/gestao', {
        id: +id,
        agent: token,
        status: userLead.status,
        ...onboardingData,
      });

      if (!data.status) {
        alert('Falha na requisição, tente novamente.');
      } else {
        alert('Atualizado com sucesso!');
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
    }
  }, [token, id, userLead]);

  return (
    <div style={Container}>
      <Header />
      <Modal setIsOpen={setIsOpen} isOpen={isOpen}>
        <ModalForm
          releaseOptions={releaseOptions}
          onSubmit={handleModalFormOnSubmit}
        />
      </Modal>
      <div className="960y col" style={Content}>
        <div style={Wrapper} className="Gray2 col center blanc 20r 20p">
          <h3 style={Title} className="fo20">Usuário</h3>
          {userLeadComponent}
        </div>
        <div
          style={
            Wrapper
          }
          className="Gray2 col center blanc wrap 20r 20p 960y"
        >
          <h3 style={Title} className="fo20">Anotações</h3>
          <div className="100w 10r" style={TableContainer}>
            {
            anotations.map((anotation, index) => (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div key={anotation.id} style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                  <b>{index + 1}</b>
                  <span style={{ marginLeft: '16px' }}>{anotation.message}</span>
                </div>
                <span style={{ marginLeft: '16px' }}>{anotation?.createdAt ? moment(anotation?.createdAt).format('DD/MM/YYYY') : ''}</span>
              </div>
            ))
          }
          </div>
          <button onClick={handleOnCreateAnnotation} type="button" className="blanc col center 10p 10r 100w Blue" style={{ marginTop: '16px' }}>Cadastrar anotação</button>
        </div>
        <div style={ContainerDocuments} className="Gray2 row center blanc wrap 20r 20p">
          <Documents
            id={id}
            documents={documents}
            onStorageFile={handleStorageFile}
          />
        </div>
        <div
          style={
            Wrapper
          }
          className="Gray2 col center blanc wrap 20r 20p"
        >
          <h3 style={Title} className="fo20">Tabela Lançamentos</h3>
          <div className="100w 10r" style={TableContainer}>
            <TableReleases
              onUpdateField={onUpdateField}
              onDeleteRelease={handleOnDeleteRelease}
              releases={releases}
              onAddModalReleases={handleAddModalReleases}
              releaseOptions={releaseOptions}
            />
          </div>
          <div className="row 100w">
            <button onClick={() => setIsOpen(true)} type="button" className="blanc col center 1p 10r 100w Green" style={ButtonCreateRelease}>Cadastrar lançamentos</button>
            <button onClick={handleCalculateTir} type="button" className="blanc col center 1p 10r 100w Blue" style={{ ...ButtonCreateRelease, marginLeft: '8px' }}>Calcular TIR efetiva</button>
          </div>
        </div>
        <div
          style={
            Wrapper
          }
          className="Gray2 col center blanc wrap 20r 20p"
        >
          <h3 style={Title} className="fo20">Transferir Contratos</h3>
          <DropZone
            isMultiple={false}
            onDropAccepted={onDropAccepted}
          />
          {
            fidcFiles.length ? (
              <div className="10p flex col">
                {
                  fidcFiles.map((file: any) => (
                    <div key={file.id}>
                      <span style={{ marginTop: '5px' }}>{file.path}</span>
                      <TiDelete
                        onClick={() => setFidcFiles(
                          fidcFiles.filter((fidcFile: any) => file.id !== fidcFile.id),
                        )}
                        className="tomato"
                        size={24}
                        style={{
                          position: 'relative', marginLeft: '4px', top: '6px', cursor: 'pointer',
                        }}
                      />
                    </div>
                  ))
                }
              </div>
            ) : <div />
          }
          <button onClick={handleOnTransferFidcDocuments} type="button" className="blanc col center 1p 10r 100w Blue" style={ButtonTransferFidcDocument}>Transmitir contrato cessão</button>
          {
            fidcErros?.map((itemFidc) => (
              <div key={itemFidc} style={{ marginTop: '8px' }}>
                <span className="tomato">{itemFidc}</span>
                <br />
              </div>
            ))
          }
        </div>
        <div
          style={
            Wrapper
          }
          className="Gray2 col center blanc wrap 20r 20p"
        >
          <h3 style={Title} className="fo20">Tabela Status</h3>
          <div className="100w 10r" style={TableContainer}>
            <TableStatus userLead={userLead} />
          </div>
        </div>
        <div style={Wrapper} className="Gray2 col center blanc 20r 20p">
          <h1 style={Title} className="fo20 10p">Dados Onboarding</h1>
          <OnboardingForm
            userLead={userLead}
            onSubmit={handleOnUpdateOnboardingExtract}
          />
        </div>
        <div style={Wrapper} className="Gray2 col center blanc 20r 20p">
          <h1 style={Title} className="fo20 10p">Extrato</h1>
          <Form
            userLead={userLead}
            fnOnSubmit={handleOnSubmit}
            fnGenerateContract={handleGenerateContract}
            items={items}
            types={types}
            releases={releases}
            administrators={administrators}
          />
        </div>
      </div>
    </div>
  );
};

export default Quota;
