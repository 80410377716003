import React, { useRef } from 'react';

import { CNPJMaskByElement } from '../../../../../../utils/masks';

import Input from '../../../../components/Input';

import { ModalRateAttributes } from './interface';

import {
  BackgroundContainer,
  Container,
  Content,
  ButtonModalContainer,
  FieldSetContainer,
} from './styles';

const ModalRate: React.FC<ModalRateAttributes> = ({
  isOpen, setIsOpen, onSubmit, items, types,
}) => {
  const itemRef = useRef<HTMLInputElement>(null);
  const typeRef = useRef<HTMLInputElement>(null);
  const intervalo1Ref = useRef<HTMLInputElement>(null);
  const intervalo2Ref = useRef<HTMLInputElement>(null);
  const intervalo3Ref = useRef<HTMLInputElement>(null);
  const multa1Ref = useRef<HTMLInputElement>(null);
  const multa2Ref = useRef<HTMLInputElement>(null);
  const multa3Ref = useRef<HTMLInputElement>(null);
  const originRef = useRef<HTMLInputElement>(null);
  const taxMaxRef = useRef<HTMLInputElement>(null);
  const taxMinRef = useRef<HTMLInputElement>(null);

  const handleOnSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    const itemElement = itemRef.current;
    const typeElement = typeRef.current;
    const intervalo1Element = intervalo1Ref.current;
    const intervalo2Element = intervalo2Ref.current;
    const intervalo3Element = intervalo3Ref.current;
    const multa1Element = multa1Ref.current;
    const multa2Element = multa2Ref.current;
    const multa3Element = multa3Ref.current;
    const originElement = originRef.current;
    const taxMaxElement = taxMaxRef.current;
    const taxMinElement = taxMinRef.current;

    if (!typeElement?.value
      || !itemElement?.value
      || !intervalo1Element?.value
      || !intervalo2Element?.value
      || !intervalo3Element?.value
      || !multa1Element?.value
      || !multa2Element?.value
      || !multa3Element?.value
      || !originElement?.value
      || !taxMaxElement?.value
      || !taxMinElement?.value) {
      throw alert('Campos vazios, por favor, preencha todos os campos corretamente');
    }

    const typeValue = types.find(
      (type) => type.display === typeElement.value,
    );

    const itemValue = items.find(
      (item) => item.display === itemElement.value,
    );

    if (!typeValue || !itemValue) {
      alert('Item ou tipo inválido, preencha corretamente o(s) campo(s) e tente novamente.');
      return;
    }

    await onSubmit({
      item: +itemValue.id,
      type: +typeValue.id,
      intervalo1: +intervalo1Element.value,
      intervalo2: +intervalo2Element.value,
      intervalo3: +intervalo3Element.value,
      multa1: +multa1Element.value,
      multa2: +multa2Element.value,
      multa3: +multa3Element.value,
      origin: originElement.value,
      taxMax: +taxMaxElement.value,
      taxMin: +taxMinElement.value,
    });

    setIsOpen(false);
  };

  return (
    isOpen ? (
      <div style={Container}>
        <div
          style={BackgroundContainer}
          onClick={() => setIsOpen(!isOpen)}
        />
        <div style={Content}>
          <h4>Cadastro da multa</h4>
          <form onSubmit={handleOnSubmit} className="100w col">
            <div className="row">
              <div>
                <fieldset className="10p" style={FieldSetContainer}>
                  <legend>Item:</legend>
                  <input
                    type="text"
                    list="items"
                    ref={itemRef}
                    defaultValue={items[0].display}
                    className="Blanc 100w 10p 10r"
                    required
                  />
                  <datalist id="items">
                    {items.length > 1 && items.map((item) => (
                      <option key={item.id}>
                        {item.display}
                      </option>
                    ))}
                  </datalist>
                </fieldset>
                <Input reference={intervalo1Ref} type="number" name="intervalo1" labelName="Intervalo 1" />
                <Input reference={intervalo3Ref} type="number" name="intervalo3" labelName="Intervalo 3" />
                <Input reference={multa2Ref} type="number" name="multa2" labelName="Multa 2" />
                <Input reference={taxMaxRef} type="number" name="taxMax" labelName="Taxa máxima" />
                <Input reference={originRef} name="origin" labelName="Origem" maxLength={2} />
              </div>
              <div>
                <fieldset className="10p" style={FieldSetContainer}>
                  <legend>Tipo:</legend>
                  <input
                    type="text"
                    list="types"
                    ref={typeRef}
                    defaultValue={types[0].display}
                    className="Blanc 100w 10p 10r"
                    required
                  />
                  <datalist id="types">
                    {types.length > 1 && types.map((type) => (
                      <option key={type.id}>
                        {type.display}
                      </option>
                    ))}
                  </datalist>
                </fieldset>
                <Input reference={intervalo2Ref} type="number" name="intervalo2" labelName="Intervalo 2" onKeyDown={CNPJMaskByElement} maxLength={18} />
                <Input reference={multa1Ref} type="number" name="multa1" labelName="Multa 1" maxLength={2} />
                <Input reference={multa3Ref} type="number" name="multa3" labelName="Multa 3" />
                <Input reference={taxMinRef} type="number" name="taxMin" labelName="Taxa mínima" />
              </div>
            </div>
            <div className="10p">
              <button className="1p 10r 100w Green blanc" type="submit" style={ButtonModalContainer}>Cadastrar</button>
            </div>
          </form>
        </div>
      </div>
    ) : <div />
  );
};
export default ModalRate;
