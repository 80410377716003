import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { mutate as mutateGlobal } from 'swr';
import { CircularProgress } from '@mui/material';

import ModalOptions from '../../components/ModalOptions';
import Header from '../../components/Header';
import Modal from '../../components/Modal';
import ListUsers from './components/ListUsers';
import FormModal from './components/FormModal';

import api from '../../../../services/api';

import { useAuth } from '../../../../hooks/auth';
import { useFetch } from '../../../../hooks/fetch';

import { UserLeadsAll } from '../../../_interfaces';
import { SubmitDataDTO, SubmitRequestDataDTO } from './interface';

import {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
} from './styles';

let initialLeads: any = [];

const ListLeads: React.FC = () => {
  const { token, handleLogout } = useAuth();

  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [listUsers, setListUsers] = useState([] as UserLeadsAll[]);
  const [filteredUsers, setFilteredUsers] = useState([] as UserLeadsAll[]);

  const { apiSwr } = useFetch();

  const { dataFetch, mutate } = apiSwr('list-100', '/output/atendimento', token, {
    status: 100,
  });

  useEffect(() => {
    if (dataFetch?.data) {
      setIsLoading(false);

      if (filteredUsers.length) {
        setListUsers(filteredUsers);
      } else {
        initialLeads = dataFetch.data;
        setListUsers(dataFetch.data);
      }
    }
  }, [dataFetch, filteredUsers]);

  const handleOnSubmitModal = async (submitData: SubmitDataDTO): Promise<void> => {
    const { data } = await api.post<SubmitRequestDataDTO>('/input/atendimento', {
      ...submitData,
      extract: 0,
    });

    setIsOpen(false);
    mutate([...listUsers, data?.data], false);

    history.push(`/crm/extract/${data?.data?.insertId}`);
  };

  const handleOnDelete = useCallback(async (userLead: UserLeadsAll): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        if (filteredUsers.length) {
          const usersFiltered = filteredUsers.filter((user) => user.id !== userLead.id);
          setFilteredUsers(usersFiltered);
        }

        const usersFiltered = listUsers.filter((user) => user.id !== userLead.id);
        setListUsers(usersFiltered);

        const { data: updateData } = await api.put('/input/atendimento', {
          id: userLead?.id,
          status: 1,
          agent: token,
        });

        if (!updateData.status) {
          alert(`Erro: ${updateData.message}`);
          handleLogout();
        } else {
          mutate(usersFiltered, false);
          mutateGlobal('list-100', {}, true);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  }, [token, listUsers]);

  const handleOnFilterLeads = async (filteredLeads: any): Promise<void> => {
    setListUsers(filteredLeads);
  };

  async function handleOnUpdate({ field, value, id }: any): Promise<void> {
    const { data } = await api.put('/input/atendimento', {
      id,
      agent: token,
      status: 100,
      [field]: value,
    });

    if (data?.status === true) {
      mutateGlobal('list-100', {}, true);
    } else {
      console.log('Erro: ', data);
      alert('Erro ao salvar os dados do lead.');
    }
  }

  return (
    <div className="col center">
      <Header />
      <ModalOptions
        status={100}
        listUsers={listUsers}
        setFilteredUsers={setFilteredUsers}
      />
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <h3>Cadastrar novo cliente</h3>
        <FormModal
          fnOnSubmit={handleOnSubmitModal}
        />
      </Modal>
      {
        isLoading ? (
          <div style={{ marginTop: '16px' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="960y row black 20r" style={Content} onClick={() => setIsOpen(true)}>
              <div style={ContainerLead} className="row center Gray2 1s">
                <div className="col">
                  <div className="row center wrap">
                    <div className="col center 1s">
                      <span style={SpanStyle}>Cadastrar cliente</span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={ExtractStatusContainer} />
            </div>
            <ListUsers
              listUsers={listUsers}
              initialLeads={initialLeads}
              handleOnDelete={handleOnDelete}
              handleOnFilter={handleOnFilterLeads}
              handleOnUpdate={handleOnUpdate}
            />
          </>
        )
      }
    </div>
  );
};

export default ListLeads;
