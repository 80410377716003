import React from 'react';

import { ModalAttributes } from './interface';

import { BackgroundContainer, Container, Content } from './styles';

const Modal: React.FC<ModalAttributes> = ({ isOpen, setIsOpen, children }) => (
  <div style={{ ...Container, display: isOpen ? 'flex' : 'none' }}>
    <div
      style={BackgroundContainer}
      onClick={() => setIsOpen(!isOpen)}
    />
    <div style={Content}>{children}</div>
  </div>
);

export default Modal;
