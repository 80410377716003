const ButtonContainer = {
  width: '92%',
  height: '36px',
  backgroundColor: '#1e8cf9',
};

const FormContainer = {
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
};

export {
  ButtonContainer,
  FormContainer,
};
