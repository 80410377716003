import React, { useEffect, useRef, useState } from 'react';

import { Rate } from '../../../../interface';
import { RateProps } from './interface';

import {
  TableBodyContent,
  // TableInput,
  TableHeadContent,
} from '../../styles';

const AdministratorComponent: React.FC<RateProps> = (
  {
    rate,
    onUpdateField,
  },
) => {
  const [toggleTarget, setToggleTarget] = useState('');
  const [rateData, setRateData] = useState(rate as Rate);

  const originRef = useRef<HTMLInputElement>(null);
  const taxMaxRef = useRef<HTMLInputElement>(null);
  const taxMinRef = useRef<HTMLInputElement>(null);
  const multa1Ref = useRef<HTMLInputElement>(null);
  const multa2Ref = useRef<HTMLInputElement>(null);
  const multa3Ref = useRef<HTMLInputElement>(null);
  const intervalo1Ref = useRef<HTMLInputElement>(null);
  const intervalo2Ref = useRef<HTMLInputElement>(null);
  const intervalo3Ref = useRef<HTMLInputElement>(null);

  const handleOnFocus = (target: 'origin' | 'taxMax' | 'taxMin' | 'multa1' | 'multa2' | 'multa3' | 'intervalo1' | 'intervalo2' | 'intervalo3'): void => {
    setToggleTarget(target);
  };

  const handleOnBlur = async (value: any, target: 'origin' | 'taxMax' | 'taxMin' | 'multa1' | 'multa2' | 'multa3' | 'intervalo1' | 'intervalo2' | 'intervalo3'): Promise<void> => {
    setToggleTarget('');

    const newRate = {
      ...rate,
      id: rateData.id,
      origin: rateData.origin,
      taxMax: rateData.taxMax,
      taxMin: rateData.taxMin,
      multa1: rateData.multa1,
      multa2: rateData.multa2,
      multa3: rateData.multa3,
      intervalo1: rateData.intervalo1,
      intervalo2: rateData.intervalo2,
      intervalo3: rateData.intervalo3,
      [target]: value,
    };

    setRateData(newRate);
    await onUpdateField(newRate);
  };

  useEffect(() => {
    if (toggleTarget) {
      originRef.current?.focus();
      taxMaxRef.current?.focus();
      taxMinRef.current?.focus();
      multa1Ref.current?.focus();
      multa2Ref.current?.focus();
      multa3Ref.current?.focus();
      intervalo1Ref.current?.focus();
      intervalo2Ref.current?.focus();
      intervalo3Ref.current?.focus();
    }
  }, [toggleTarget]);

  return (
    <tr
      className="darken 100w"
      style={TableBodyContent}
      key={rateData.id}
    >
      <td
        style={TableHeadContent}
      >
        <span>{rateData.displayType}</span>
      </td>
      <td
        style={TableHeadContent}
      >
        <span>{rateData.displayItem}</span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('origin')}
      >
        <input
          ref={originRef}
          hidden={!(toggleTarget === 'origin')}
          type="text"
          defaultValue={rateData.origin}
          onBlur={(e) => handleOnBlur(e.currentTarget.value, 'origin')}
        />
        <span hidden={toggleTarget === 'origin'}>{rateData.origin}</span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('taxMax')}
      >
        <input
          ref={taxMaxRef}
          hidden={!(toggleTarget === 'taxMax')}
          type="text"
          defaultValue={rateData.taxMax}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'taxMax')}
        />
        <span hidden={toggleTarget === 'taxMax'}>
          {rateData.taxMax}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('taxMin')}
      >
        <input
          ref={taxMinRef}
          hidden={!(toggleTarget === 'taxMin')}
          type="text"
          defaultValue={rateData.taxMin}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'taxMin')}
        />
        <span hidden={toggleTarget === 'taxMin'}>
          {rateData.taxMin}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('multa1')}
      >
        <input
          ref={multa1Ref}
          hidden={!(toggleTarget === 'multa1')}
          type="text"
          defaultValue={rateData.multa1}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'multa1')}
        />
        <span hidden={toggleTarget === 'multa1'}>
          {rateData.multa1}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('intervalo1')}
      >
        <input
          ref={intervalo1Ref}
          hidden={!(toggleTarget === 'intervalo1')}
          type="text"
          defaultValue={rateData.intervalo1}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'intervalo1')}
        />
        <span hidden={toggleTarget === 'intervalo1'}>
          {rateData.intervalo1}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('multa2')}
      >
        <input
          ref={multa2Ref}
          hidden={!(toggleTarget === 'multa2')}
          type="text"
          defaultValue={rateData.multa2}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'multa2')}
        />
        <span hidden={toggleTarget === 'multa2'}>
          {rateData.multa2}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('intervalo2')}
      >
        <input
          ref={intervalo2Ref}
          hidden={!(toggleTarget === 'intervalo2')}
          type="text"
          defaultValue={rateData.intervalo2}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'intervalo2')}
        />
        <span hidden={toggleTarget === 'intervalo2'}>
          {rateData.intervalo2}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('multa3')}
      >
        <input
          ref={multa3Ref}
          hidden={!(toggleTarget === 'multa3')}
          type="text"
          defaultValue={rateData.multa3}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'multa3')}
        />
        <span hidden={toggleTarget === 'multa3'}>
          {rateData.multa3}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('intervalo3')}
      >
        <input
          ref={intervalo3Ref}
          hidden={!(toggleTarget === 'intervalo3')}
          type="text"
          defaultValue={rateData.intervalo3}
          onBlur={(e) => handleOnBlur(+e.currentTarget.value, 'intervalo3')}
        />
        <span hidden={toggleTarget === 'intervalo3'}>
          {rateData.intervalo3}
        </span>
      </td>
    </tr>
  );
};

export default AdministratorComponent;
