import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../hooks/auth';

import api from '../../../../services/api';

import Header from '../../components/Header';
import TableAdministrator from './components/TableAdministrator';
import ModalAdministrator from './components/ModalAdministrator';

import { Administrator } from './interface';

import {
  ButtonContainer,
} from './styles';

const AdministratorComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [administrators, setAdministrators] = useState([] as Administrator[]);

  const { token } = useAuth();

  useEffect(() => {
    async function requestAdminData(): Promise<void> {
      const { data } = await api.post('/output/administradoras');

      setAdministrators(data.data);
    }

    requestAdminData();
  }, []);

  const handleOnUpdateField = async (administrator: Administrator): Promise<void> => {
    const submitObj = {
      agent: token,
      ...administrator,
    };

    await api.put('/input/administradoras', submitObj);
  };

  const handleOnSubmit = async (administrator: Administrator): Promise<void> => {
    const submitObj = {
      agent: token,
      ...administrator,
    };

    await api.post('/input/administradoras', submitObj);

    alert('Administradora criada com sucesso!');

    setAdministrators([...administrators, {
      id: administrators.length + 2,
      ...submitObj,
    }]);
  };

  return (
    <div className="col center">
      <Header />
      <div className="30p 100w">
        <ModalAdministrator isOpen={isOpen} setIsOpen={setIsOpen} onSubmit={handleOnSubmit} />
        <button type="button" className="10r 100w 10p Green blanc" style={ButtonContainer} onClick={() => setIsOpen(!isOpen)}>Cadastrar nova administradora</button>
        <TableAdministrator
          onUpdateField={handleOnUpdateField}
          administrators={administrators}
        />
      </div>
    </div>
  );
};

export default AdministratorComponent;
