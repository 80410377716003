import React from 'react';

import { TextAreaAttributes } from './interface';

const TextArea: React.FC<TextAreaAttributes> = ({ reference, labelName, ...rest }) => (
  <div className="10p 100w">
    {labelName && <label htmlFor={rest.name}>{labelName}:</label>}
    <textarea className="Blanc 100w 10p 10r" ref={reference} {...rest} />
  </div>
);

export default TextArea;
