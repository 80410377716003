const Container = {
  minHeight: '100vh',
  backgroundColor: '#2F3B53',
};

const Content = {
  marginTop: '16px',
  overflow: 'hidden',
};

const ContainerTransference = {
  padding: '18px 36px',
  justifyContent: 'space-between',
  backgroundColor: '#2A292E',
};

const SpanStyle = {
  margin: '8px 0',
};

const ExtractStatusContainer = {
  width: '60px',
  flex: '0, 1',
};

const SelectContainer = {
  marginTop: '16px',
  backgroundColor: '#2A292E',
  color: '#fff',
};

const SelectMoveToContainer = {
  backgroundColor: '#19181E',
  color: '#fff',
};

const SpanHeader = {
  justifyContent: 'space-between' as const,
};

const ContentModal = {
  marginTop: '16px',
  overflow: 'hidden',
  border: '1px dashed #19181E',
  cursor: 'pointer',
  backgroundColor: '#2A292E',
};

const ContainerLeadModal = {
  padding: '18px 0 18px 36px',
  justifyContent: 'space-between',
};

const SpanStyleModal = {
  margin: '16px 0',
};

const ExtractStatusContainerModal = {
  width: '56px',
  flex: '0, 1',
  backgroundColor: '#00000020',
};

export {
  Container,
  Content,
  ContainerTransference,
  SpanStyle,
  ExtractStatusContainer,
  SelectContainer,
  SpanHeader,
  SelectMoveToContainer,
  ContentModal,
  ContainerLeadModal,
  SpanStyleModal,
  ExtractStatusContainerModal,
};
