const Content = {
  marginTop: '16px',
  overflow: 'hidden',
  border: '1px dashed #00000060',
  cursor: 'pointer',
};

const ContainerLead = {
  padding: '18px 0 18px 36px',
  justifyContent: 'space-between',
};

const SpanStyle = {
  margin: '16px 0',
};

const ExtractStatusContainer = {
  width: '56px',
  flex: '0, 1',
  backgroundColor: '#00000020',
};

const SpanHeader = {
  paddingRight: '32px',
  justifyContent: 'space-between' as const,
};

export {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
  SpanHeader,
};
