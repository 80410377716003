const SpanStyle = {
  margin: '8px 0',
};

const Container = {
  justifyContent: 'center',
  height: '100%',
};

const Content = {
  marginTop: '16px',
};

const Title = {
  margin: '10',
};

const Wrapper = {
  padding: '0 16px',
};

const Heading = {
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
};

const OcrButton = {
  border: 'none',
  borderRadius: '8px',
  padding: '0.5rem 1rem',
  backgroundColor: 'cadetblue',
};

const Loading = {
  backgroundColor: 'grey',
};

export {
  Container,
  SpanStyle,
  Content,
  Title,
  Wrapper,
  Heading,
  OcrButton,
  Loading,
};
