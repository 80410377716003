import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

import Menu from '../Menu';

import {
  Container,
  Content,
  ContainerLink,
  ContentLink,
} from './styles';

const Header: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const url = window.location.pathname;

  return (
    <header className="100w center row" style={Container}>
      <Menu isOpen={isOpenMenu} setIsOpen={setIsOpenMenu} />
      <FiMenu size={24} style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => setIsOpenMenu(true)} />
      <div className="100w center row" style={Content}>
        <Link className="fo16" to="/crm" style={ContainerLink}>
          <div className="black" style={ContentLink(url, '/crm')}>
            Início
          </div>
        </Link>
        <Link className="black fo16" style={ContainerLink} to="/crm/pricing">
          <div className="black" style={ContentLink(url, '/crm/pricing')}>
            Preços
          </div>
        </Link>
        <Link className="black fo16" style={ContainerLink} to="/crm/analyze">
          <div className="black" style={ContentLink(url, '/crm/analyze')}>
            Análise
          </div>
        </Link>
        <Link className="black fo16" style={ContainerLink} to="/crm/finalized">
          <div className="black" style={ContentLink(url, '/crm/finalized')}>
            Finalizados
          </div>
        </Link>
        <Link className="black fo16" style={ContainerLink} to="/crm/reanalyze">
          <div className="black" style={ContentLink(url, '/crm/reanalyze')}>
            Reanálise
          </div>
        </Link>
      </div>
    </header>
  );
};

export default Header;
