import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';

import {
  Container,
  Content,
  ContainerLink,
  ContentLink,
} from './styles';
import Menu from '../Menu';

const Header: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const url = window.location.pathname;

  return (
    <header className="100w center row" style={Container}>
      <Menu isOpen={isOpenMenu} setIsOpen={setIsOpenMenu} />
      <FiMenu size={24} color="black" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => setIsOpenMenu(true)} />
      <div className="100w center row" style={Content}>
        <Link className="fo16" to="/managment" style={ContainerLink}>
          <div className="black" style={ContentLink(url, '/managment')}>
            Administradoras
          </div>
        </Link>
        <Link className="fo16" to="/managment/assignee" style={ContainerLink}>
          <div className="black" style={ContentLink(url, '/managment/assignee')}>
            Cessionário
          </div>
        </Link>
        <Link className="fo16" to="/managment/commission" style={ContainerLink}>
          <div className="black" style={ContentLink(url, '/managment/commission')}>
            Comissões
          </div>
        </Link>
      </div>
    </header>
  );
};

export default Header;
