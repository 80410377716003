const BackgroundContainer = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#2A292E90',
  filter: 'brightness(60%)',
  zIndex: 999,
};

const Container = {
  display: 'flex',
  alignItems: 'center' as const,
  justifyContent: 'center' as const,
  flexDirection: 'column' as const,
  position: 'fixed' as const,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '400px',
  maxHeight: '400px',
  width: '100%',
  minHeight: '200px',
  backgroundColor: '#282829',
  borderRadius: '16px',
  overflow: 'auto',
  zIndex: 1000,
};

export {
  BackgroundContainer,
  Container,
};
