import { dateBrToDefaultString } from './converts';

export function validateDateByString(dateString: string): boolean {
  if (typeof dateString !== 'string' || dateString.length < 10) return false;

  const date = new Date(dateBrToDefaultString(dateString));

  const isValidDate = !!date.getTime();

  return isValidDate;
}
