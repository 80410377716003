const SpanStyle = {
  margin: '8px 0',
};

const Container = {
  justifyContent: 'center',
  height: '100%',
};

const Content = {
  marginTop: '16px',
};

const Title = {
  margin: '10',
};

const Wrapper = {
  padding: '0 16px',
};

const ButtonContainer = {
  marginBottom: '12px',
  marginTop: '8px',
};

export {
  Container,
  SpanStyle,
  Content,
  Title,
  Wrapper,
  ButtonContainer,
};
