const BackgroundContainer = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#ffffff90',
  filter: 'brightness(60%)',
  zIndex: 50,
};

const Container = {
  display: 'flex',
  alignItems: 'center' as const,
  justifyContent: 'center' as const,
  flexDirection: 'column' as const,
  position: 'fixed' as const,
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '400px',
  width: '100%',
  minHeight: '200px',
  backgroundColor: '#fff',
  borderRadius: '16px',
  padding: '32px',
  zIndex: 100,
};

export {
  BackgroundContainer,
  Container,
};
