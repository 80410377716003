import React, {
  MutableRefObject, useEffect, useRef, useState,
} from 'react';

import { removeEmptyFields } from '../../../../../../utils/functionalities';

import Input from '../../../../components/Input';

import { OnboardingFormAttributes } from './interface';

import { ButtonContainer } from './styles';

const OnboardingForm: React.FC<OnboardingFormAttributes> = ({ onSubmit, userLead }) => {
  const [accountType, setAccountType] = useState('');
  const [isThirdParty, setIsThirdParty] = useState(false);

  useEffect(() => {
    setAccountType(userLead.bank_account_type);
    setIsThirdParty(!!userLead.third_party_data?.otherOwnershipAccount);
  }, [userLead]);

  const thirdPartyCpfCnpjRef = useRef() as MutableRefObject<HTMLInputElement>;
  const bankNumberAccountRef = useRef() as MutableRefObject<HTMLInputElement>;
  const bankAccountTypeRef = useRef() as MutableRefObject<HTMLSelectElement>;
  const thirdPartyEmailRef = useRef() as MutableRefObject<HTMLInputElement>;
  const displayContractRef = useRef() as MutableRefObject<HTMLInputElement>;
  const thirdPartyNameRef = useRef() as MutableRefObject<HTMLInputElement>;
  const emailContractRef = useRef() as MutableRefObject<HTMLInputElement>;
  const nationalityRef = useRef() as MutableRefObject<HTMLInputElement>;
  const civilStatusRef = useRef() as MutableRefObject<HTMLInputElement>;
  const complementRef = useRef() as MutableRefObject<HTMLInputElement>;
  const codePostalRef = useRef() as MutableRefObject<HTMLInputElement>;
  const document2Ref = useRef() as MutableRefObject<HTMLInputElement>;
  const assetNameRef = useRef() as MutableRefObject<HTMLInputElement>;
  const bankNameRef = useRef() as MutableRefObject<HTMLInputElement>;
  const documentRef = useRef() as MutableRefObject<HTMLInputElement>;
  const districtRef = useRef() as MutableRefObject<HTMLInputElement>;
  const addressRef = useRef() as MutableRefObject<HTMLInputElement>;
  const agencyRef = useRef() as MutableRefObject<HTMLInputElement>;
  const genderRef = useRef() as MutableRefObject<HTMLInputElement>;
  const careerRef = useRef() as MutableRefObject<HTMLInputElement>;
  const bankRef = useRef() as MutableRefObject<HTMLInputElement>;
  const birthRef = useRef() as MutableRefObject<HTMLInputElement>;
  const cityRef = useRef() as MutableRefObject<HTMLInputElement>;
  const numberRef = useRef() as MutableRefObject<HTMLInputElement>;
  const stateRef = useRef() as MutableRefObject<HTMLInputElement>;

  function handleOnSubmit(e: any): void {
    e.preventDefault();

    const submitObj = removeEmptyFields({
      bank_account_type: bankAccountTypeRef.current?.value,
      display_contract: displayContractRef.current?.value,
      bank_number_account: bankNumberAccountRef.current?.value,
      email_contract: emailContractRef.current?.value,
      nationality: nationalityRef.current?.value,
      civil_status: civilStatusRef.current?.value,
      complement: complementRef.current?.value,
      codePostal: codePostalRef.current?.value,
      document2: document2Ref.current?.value,
      bank_name: bankNameRef.current?.value,
      document: documentRef.current?.value,
      district: districtRef.current?.value,
      address: addressRef.current?.value,
      bank_ag: agencyRef.current?.value,
      gender: genderRef.current?.value,
      career: careerRef.current?.value,
      bank: bankRef.current?.value,
      assetName: assetNameRef.current?.value,
      birth: birthRef.current?.value,
      city: cityRef.current?.value,
      number: numberRef.current?.value,
      state: stateRef.current?.value,
      third_party_data: {
        cpf_cnpj: thirdPartyCpfCnpjRef.current?.value,
        email: thirdPartyEmailRef.current?.value,
        name: thirdPartyNameRef.current?.value,
        otherOwnershipAccount: isThirdParty,
      },
    });

    onSubmit(submitObj);
  }

  return (
    <form name="extract-form" method="post" onSubmit={(e) => handleOnSubmit(e)} className="100w col center">
      <div className="100w row wrap">
        <div className="col">
          <Input reference={displayContractRef} labelName="Nome do titular" name="display_contract" defaultValue={userLead.display_contract || userLead.display} type="text" />
          <div className="row wrap">
            <Input reference={addressRef} labelName="Endereço" name="address" defaultValue={userLead.address} type="text" />
            <Input reference={bankRef} labelName="Banco" name="bank" defaultValue={userLead.bank} type="text" maxLength={14} />
          </div>
          <div className="row 100w wrap">
            <div className="10p 10r 100w">
              <label htmlFor="">Tipo do banco:</label>
              <select className=" 10p 10r 100w blanc" ref={bankAccountTypeRef} style={{ backgroundColor: '#19181e' }} value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                <option disabled>Tipo do banco</option>
                <option value="Corrente">Conta corrente</option>
                <option value="Poupança">Conta poupança</option>
                <option value="Salario">Conta salário</option>
              </select>
            </div>
            <Input reference={agencyRef} labelName="Agência" defaultValue={userLead.bank_ag} name="agency" type="text" />
          </div>
          <div className="row wrap">
            <Input reference={documentRef} labelName="CPF/CNPJ" name="document" defaultValue={userLead.document} type="text" />
            <Input reference={document2Ref} labelName="RG" name="document2" defaultValue={userLead.document2} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={civilStatusRef} labelName="Estado cívil" name="civil_status" defaultValue={userLead.civil_status} type="text" />
            <Input reference={careerRef} labelName="Carreira" name="career" defaultValue={userLead.career} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={cityRef} labelName="Cidade" name="city" defaultValue={userLead.city} type="text" />
            <Input reference={numberRef} labelName="Número" name="number" defaultValue={userLead.number} type="text" />
          </div>

          <div className="row wrap">
            <div className="10p 10r 100w">
              <label htmlFor="">Conta de terceiro:</label>
              <select className=" 10p 10r 100w blanc" style={{ backgroundColor: '#19181e' }} value={`${isThirdParty}`} onChange={(e) => setIsThirdParty(e.target.value === 'true')}>
                <option disabled>Conta de terceiro</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </div>
            {
            isThirdParty && (
              <Input reference={thirdPartyEmailRef} labelName="Email do terceiro" name="third_party_email" defaultValue={userLead?.third_party_data?.email} type="text" />
            )
            }
          </div>
        </div>
        <div className="col">
          <Input reference={emailContractRef} labelName="Email do titular" name="email_contract" defaultValue={userLead.email_contract} type="text" />
          <div className="row wrap">
            <Input reference={bankNumberAccountRef} labelName="Numero da conta" defaultValue={userLead.bank_number_account} name="bank_number_account" type="text" />
            <Input reference={codePostalRef} labelName="Código postal" name="codePostal" defaultValue={userLead.codePostal} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={complementRef} labelName="Complemento" name="complement" defaultValue={userLead.complement} type="text" />
            <Input reference={districtRef} labelName="Bairro" name="district" defaultValue={userLead.district} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={nationalityRef} labelName="Nacionalidade" name="nationality" defaultValue={userLead.nationality} type="text" />
            <Input reference={genderRef} labelName="Gênero" name="gender" defaultValue={userLead.gender} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={assetNameRef} labelName="Descrição do bem" name="assetName" defaultValue={userLead.assetName} type="text" />
            <Input reference={birthRef} labelName="Data de nascimento" name="birth" defaultValue={userLead.birth} type="text" />
          </div>
          <div className="row wrap">
            <Input reference={stateRef} labelName="Estado" name="state" defaultValue={userLead.state} type="text" />
            <Input reference={bankNameRef} labelName="Nome do banco" name="bank_name" defaultValue={userLead.bank_name} type="text" />
          </div>
          {
              isThirdParty && (
              <div className="row wrap">
                <Input reference={thirdPartyNameRef} labelName="Nome do terceiro" name="third_party_name" defaultValue={userLead?.third_party_data?.name} type="text" />
                <Input reference={thirdPartyCpfCnpjRef} labelName="Documento do terceiro" name="third_party_document" defaultValue={userLead?.third_party_data?.cpf_cnpj} type="text" />
              </div>
              )
            }
        </div>
      </div>
      <button type="submit" className="blanc 10r 1p" style={{ ...ButtonContainer, backgroundColor: '#36CF61' }}>Salvar dados</button>
    </form>
  );
};

export default OnboardingForm;
