const InputStyle = {
  backgroundColor: '#fff',
  color: '#000',
};

const FieldSetContainer = {
  width: '100%',
  padding: 0,
};

const ButtonContainer = {
  width: '160px',
  height: '36px',
  margin: '24px 0',
};

export {
  ButtonContainer,
  FieldSetContainer,
  InputStyle,
};
