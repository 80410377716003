import React from 'react';
import { useParams } from 'react-router-dom';

import FirebaseModal from '../../../components/FirebaseModal';

import { RouteParams } from './interface';

const Firebase: React.FC = () => {
  const { id } = useParams<RouteParams>();

  return (
    <div style={{ backgroundColor: '#051E34', width: '100vw', height: '100vh' }}>
      <FirebaseModal id={+id} />
    </div>
  );
};

export default Firebase;
