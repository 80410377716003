import React, { useRef, MutableRefObject } from 'react';

import { phoneMaskByElement } from '../../../../../../utils/masks';

import Input from '../../../../components/Input';

import { FormAttributes } from './interface';

import {
  ButtonContainer,
  FormContainer,
} from './styles';

const FormModal: React.FC<FormAttributes> = ({ fnOnSubmit }) => {
  const displayRef = useRef() as MutableRefObject<HTMLInputElement>;
  const emailRef = useRef() as MutableRefObject<HTMLInputElement>;
  const phoneRef = useRef() as MutableRefObject<HTMLInputElement>;
  const targetRef = useRef() as MutableRefObject<HTMLSelectElement>;

  const handleOnSubmit = (e: any): void => {
    e.preventDefault();

    const data = {
      display: displayRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      target: +targetRef.current.value,
      crm_manual: true,
    };

    fnOnSubmit(data);
  };

  const targetOptions = {
    0: 'Formulário',
    1: 'Whatsapp',
    2: 'Facebook',
    3: 'Instagram',
    4: 'Outros',
    5: 'Saas',
  } as any;

  return (
    <form onSubmit={handleOnSubmit} style={FormContainer}>
      <Input type="text" labelName="Nome" required reference={displayRef} />
      <Input type="email" labelName="E-mail" required reference={emailRef} />
      <Input type="text" labelName="Telefone" reference={phoneRef} onChange={phoneMaskByElement} required minLength={14} maxLength={15} />
      <div className="10p 100w">
        Vindo do:
        <select ref={targetRef} className="Blanc 100w 10p 10r">
          {
          Object.values(targetOptions).map((itemTarget: any, index: number) => (
            <option key={itemTarget} value={index}>{itemTarget}</option>
          ))
        }
        </select>
      </div>
      <button type="submit" className="blanc 10r 1p" style={ButtonContainer}>Cadastrar</button>
    </form>
  );
};

export default FormModal;
