import React, { useRef, useState } from 'react';

import Input from '../../../../components/Input';
import { removeEmptyFields } from '../../../../../../utils/functionalities';

import { ModalAssigneeAttributes } from './interface';

import {
  BackgroundContainer,
  Container,
  Content,
  ButtonModalContainer,
} from './styles';

const Modal: React.FC<ModalAssigneeAttributes> = ({ isOpen, setIsOpen, onSubmit }) => {
  const [preSession, setPreSession] = useState(0);

  const displayRef = useRef<HTMLInputElement>(null);
  const doc_fundoRef = useRef<HTMLInputElement>(null);
  const nome_fundoRef = useRef<HTMLInputElement>(null);
  const codigo_registradoraRef = useRef<HTMLInputElement>(null);
  const doc_certificadoraRef = useRef<HTMLInputElement>(null);
  const nome_certificadoraRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const handleOnSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    const displayElement = displayRef.current;
    const doc_fundoElement = doc_fundoRef.current;
    const nome_fundoElement = nome_fundoRef.current;
    const codigo_registradoraElement = codigo_registradoraRef.current;
    const doc_certificadoraElement = doc_certificadoraRef.current;
    const nome_certificadoraElement = nome_certificadoraRef.current;
    const emailElement = emailRef.current;
    const passwordElement = passwordRef.current;

    if (
      !displayElement?.value
      || !doc_fundoElement?.value
      || !nome_fundoElement?.value
      || !emailElement?.value
      || !passwordElement?.value
    ) {
      throw alert('Campos vazios, por favor, preencha os campos corretamente');
    }

    const dataAssigneeWithoutEmptyFields = removeEmptyFields({
      display: displayElement?.value,
      doc_fundo: doc_fundoElement?.value,
      nome_fundo: nome_fundoElement?.value,
      codigo_registradora: codigo_registradoraElement?.value,
      doc_certificadora: doc_certificadoraElement?.value,
      nome_certificadora: nome_certificadoraElement?.value,
      pre_cessao: +preSession,
      email: emailElement?.value,
      password: passwordElement?.value,
    });

    await onSubmit(dataAssigneeWithoutEmptyFields);

    setIsOpen(false);
  };

  return (
    isOpen ? (
      <div style={Container}>
        <div
          style={BackgroundContainer}
          onClick={() => setIsOpen(!isOpen)}
        />
        <div style={Content}>
          <h4>Cadastro do cessionário</h4>
          <form onSubmit={handleOnSubmit} className="100w col">
            <div className="row">
              <Input reference={displayRef} name="display" labelName="Nome" required />
              <Input reference={doc_fundoRef} name="doc_fundo" labelName="Documento do cessionário" required />
            </div>
            <div className="row">
              <Input reference={nome_fundoRef} name="nome_fundo" labelName="Nome do cessionário" required />
              <Input reference={codigo_registradoraRef} name="codigo_registradora" labelName="Código da registradora" />
            </div>
            <div className="row">
              <Input reference={doc_certificadoraRef} name="doc_certificadora" labelName="Documento da certificadora" />
              <Input reference={nome_certificadoraRef} name="nome_certificadora" labelName="Nome da certificadora" />
            </div>
            <div className="row">
              <Input reference={emailRef} name="email" labelName="Email" type="email" required />
              <Input reference={passwordRef} name="password" labelName="Senha" type="password" required />
            </div>
            <div className="row">
              <label className="100w 10p">
                <span>Pré-sessão:</span>
                <select className="100w 10p 10r" style={{ height: '40px' }} defaultValue={preSession} onChange={(e) => setPreSession(+e.currentTarget.value)} name="pre_session" id="pre_session">
                  <option value={0}>
                    Não
                  </option>
                  <option value={1}>
                    Sim
                  </option>
                </select>
              </label>
            </div>
            <div className="10p">
              <button className="1p 10r 100w Green blanc" type="submit" style={ButtonModalContainer}>Cadastrar</button>
            </div>
          </form>
        </div>
      </div>
    ) : <div />
  );
};
export default Modal;
