import React, { useEffect, useState } from 'react';

import { useAuth } from '../../../../hooks/auth';

import api from '../../../../services/api';

import Header from '../../components/Header';
import TableCommission from './components/TableCommission';
import ModalCommission from './components/ModalCommission';

import { Commission } from './interface';
import { Assignee } from '../Assignee/interface';

import {
  ButtonContainer,
} from './styles';

const CommissionComponent: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [commissions, setCommissions] = useState([] as Commission[]);
  const [assignees, setAssignees] = useState([] as Assignee[]);

  const { token } = useAuth();

  useEffect(() => {
    async function requestCommissionsData(): Promise<void> {
      const { data } = await api.post('/output/commission', {
        agent: token,
      });

      setCommissions(data.data);
    }

    async function requestAssigneesData(): Promise<void> {
      const { data } = await api.post('/output/cessionario', {
        agent: token,
      });

      setAssignees(data.data);
    }

    requestAssigneesData();
    requestCommissionsData();
  }, [token]);

  const handleOnUpdateField = async (commission: Commission): Promise<void> => {
    const submitObj = {
      agent: token,
      ...commission,
    };

    await api.put('/input/commission', submitObj);
  };

  const handleOnSubmit = async (commission: Commission): Promise<void> => {
    const submitObj = {
      agent: token,
      ...commission,
    };

    const { data } = await api.post('/input/commission', submitObj);

    if (data.status) {
      alert('Comissão criada com sucesso!');

      setCommissions([...commissions, {
        id: commissions.length + 2,
        ...submitObj,
      }]);
    } else {
      alert(`Erro ao criar a comissão: ${data.error}`);
    }
  };

  const handleOnDelete = async (commissionId: number): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        const { data: updateData } = await api.put('/input/commission', {
          id: commissionId,
          status: 0,
          agent: token,
        });

        if (updateData.status) {
          const filteredCommissions = commissions.filter(
            (commission) => commission.id !== commissionId,
          );

          setCommissions(filteredCommissions);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  };

  return (
    <div className="col center">
      <Header />
      <div className="30p 100w">
        <ModalCommission
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onSubmit={handleOnSubmit}
          assignees={assignees}
        />
        <button type="button" className="10r 100w 10p Green blanc" style={ButtonContainer} onClick={() => setIsOpen(!isOpen)}>Cadastrar nova comissão</button>
        <TableCommission
          onUpdateField={handleOnUpdateField}
          commissions={commissions}
          assignees={assignees}
          onDelete={handleOnDelete}
        />
      </div>
    </div>
  );
};

export default CommissionComponent;
