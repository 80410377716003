import axios from 'axios';

const apiFunctions = axios.create({
  baseURL: String(process.env.REACT_APP_API_FUNCTIONS_URL),
  headers: {
    // Authorization: 'APP',
    Authorization: 'NOAUTH',
  },
});

export default apiFunctions;
