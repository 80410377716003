import React, { useEffect, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

import { Commission } from '../../../../interface';
import { CommissionProps } from './interface';

import {
  TableBodyContent,
  // TableInput,
  TableHeadContent,
} from '../../styles';

const CommissionComponent: React.FC<CommissionProps> = (
  {
    commission,
    assignees,
    onUpdateField,
    onDelete,
  },
) => {
  const [toggleTarget, setToggleTarget] = useState('');
  const [commissionData, setCommissionData] = useState(commission as Commission);

  const assignee = assignees.find(
    (itemAssignee: any) => itemAssignee.id === commissionData.cessionario,
  );
  const displayRef = useRef<HTMLInputElement>(null);
  const strategyRef = useRef<HTMLSelectElement>(null);
  const commissionRef = useRef<HTMLInputElement>(null);
  const cessionarioRef = useRef<HTMLSelectElement>(null);

  const strategies = {
    1: 'Quitação',
    2: 'Cancelada',
    3: 'Intermediação',
  } as any;

  const handleOnFocus = (target: any): void => {
    setToggleTarget(target);
  };

  const handleOnBlur = async (e: any, target: 'id' | 'commission' | 'cessionario' | 'display' | 'strategy' | 'status'): Promise<void> => {
    setToggleTarget('');

    if (target === 'commission' && isNaN(+e.currentTarget.value)) {
      throw alert('O campo comissão deve ser um número');
    }

    const newCommission = {
      display: commissionData.display,
      cessionario: commissionData?.cessionario,
      commission: commissionData.commission,
      strategy: commissionData.strategy,
      status: +commissionData.status,
      id: commissionData?.id,
      [target]: isNaN(+e.currentTarget.value) ? e.currentTarget.value : +e.currentTarget.value,
    };

    setCommissionData(newCommission);
    await onUpdateField(newCommission);
  };

  useEffect(() => {
    if (toggleTarget) {
      displayRef.current?.focus();
      cessionarioRef.current?.focus();
      commissionRef.current?.focus();
    }
  }, [toggleTarget]);

  const handleOnDelete = (): void => {
    if (commissionData?.id) {
      onDelete(commissionData.id);
    } else {
      alert('Erro: id não encontrado');
    }
  };

  return (
    <tr
      className="darken 100w"
      style={TableBodyContent}
      key={commissionData.display}
    >
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('display')}
      >
        <input
          ref={displayRef}
          hidden={!(toggleTarget === 'display')}
          type="text"
          defaultValue={commissionData.display}
          onBlur={(e) => handleOnBlur(e, 'display')}
        />
        <span hidden={toggleTarget === 'display'}>{commissionData.display}</span>
      </td>

      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('commission')}
      >
        <input
          ref={commissionRef}
          hidden={!(toggleTarget === 'commission')}
          type="text"
          defaultValue={commissionData.commission}
          onBlur={(e) => handleOnBlur(e, 'commission')}
        />
        <span hidden={toggleTarget === 'commission'}>{commissionData.commission}</span>
        &nbsp;%
      </td>

      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('cessionario')}
      >
        <select
          ref={cessionarioRef}
          hidden={!(toggleTarget === 'cessionario')}
          onBlur={(e) => handleOnBlur(e, 'cessionario')}
          defaultValue={commissionData.cessionario}
        >
          {
            assignees.map((itemAssignee) => (
              <option value={itemAssignee.id}>{itemAssignee.display}</option>
            ))
          }
        </select>
        <span hidden={toggleTarget === 'cessionario'}>{assignee?.display}</span>
      </td>

      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('strategy')}
      >
        <select
          ref={strategyRef}
          hidden={!(toggleTarget === 'strategy')}
          onBlur={(e) => handleOnBlur(e, 'strategy')}
          defaultValue={commissionData.strategy}
        >
          <option disabled value={0}>Estratégia</option>
          <option value={1}>Quitação</option>
          <option value={2}>Cancelada</option>
          <option value={3}>Intermediação</option>
        </select>
        <span hidden={toggleTarget === 'strategy'}>{strategies[commissionData.strategy]}</span>
      </td>

      <td
        style={TableHeadContent}
      >
        <button className="5p Tomato 10r center col" type="button" onClick={handleOnDelete}>
          <AiFillDelete size={20} color="white" />
        </button>
      </td>
    </tr>
  );
};

export default CommissionComponent;
