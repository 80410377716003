import React, {
  useState, useRef, MutableRefObject, useEffect, useCallback,
} from 'react';
import { IoIosAdd, IoMdTrash } from 'react-icons/io';

import { dateBrToDefaultString } from '../../../../../../../../utils/converts';
import { dateMaskByElementKeyDown, currencyMaskByElement } from '../../../../../../../../utils/masks';

import { ReleaseProps } from './interface';

import { ButtonContainer } from './styles';

import {
  TableBodyContent,
  TableInput,
  TableHeadContent,
} from '../../styles';

const Releases: React.FC<ReleaseProps> = ({
  release, releaseOptions, onUpdateField, onDeleteRelease, onAddModalReleases,
}) => {
  const selectDisplayRef = useRef() as MutableRefObject<HTMLSelectElement>;
  const inputDateRef = useRef() as MutableRefObject<HTMLInputElement>;
  const inputDescriptionRef = useRef() as MutableRefObject<HTMLInputElement>;

  const [inputLength, setInputLength] = useState(0);
  const [toggleTarget, setToggleTarget] = useState('');
  const [selectValue, setSelectValue] = useState(release.display);
  const [updateInput, setUpdateInput] = useState({ ...release });

  const displayOptions = {} as any;

  releaseOptions.forEach((option) => {
    displayOptions[option.id] = option.display;
  });

  const handleOnFocus = (target: 'display' | 'description' | 'dateLancamento' | 'origin'): void => {
    setToggleTarget(target);
  };

  const handleOnBlur = (e: any, target: 'display' | 'description' | 'dateLancamento' | 'origin'): void => {
    setToggleTarget('');

    if (target === 'dateLancamento') {
      const inputValue = e.currentTarget.value;
      const dateString = dateBrToDefaultString(inputValue);
      const date = new Date(dateString);
      const releaseDate = date.toLocaleDateString();
      const isValidDate = !!date.getTime();

      e.currentTarget.value = '';

      if (!isValidDate) {
        throw alert('Data inválida.');
      }

      e.currentTarget.value = releaseDate;
    }

    setUpdateInput({
      ...updateInput,
      [target]: e.currentTarget.value,
    });

    const defaultDateString = dateBrToDefaultString(inputDateRef.current.value);
    const tmsLancamento = new Date(defaultDateString).getTime();

    const obj = {
      id: release.id,
      display: +selectDisplayRef.current.value as any,
      origin: +release.origin as any,
      tms: tmsLancamento,
      visible: 1,
      description: inputDescriptionRef.current.value,
      specie: release.specie,
      occurrence: release.occurrence,
    };

    onUpdateField(obj);
  };

  const inputsOnChange = (e: any): void => {
    setInputLength(e.currentTarget.value.length);
    currencyMaskByElement(e);
  };

  const handleOnSetModalReleases = useCallback((): void => {
    onAddModalReleases({
      id: release.id,
      display: displayOptions[release.display],
      tms: release.tms,
      description: release.description,
      release: release.release,
      origin: release.origin,
      status: release.status,
      specie: release.specie,
      occurrence: release.occurrence,
    });
  }, [displayOptions, release, onAddModalReleases]);

  const handleOnDeleteRelease = useCallback((): void => {
    onDeleteRelease({
      id: release.id,
      visible: 0,
      status: release.status,
    });
  }, [onDeleteRelease, release]);

  useEffect(() => {
    if (toggleTarget) {
      selectDisplayRef.current.focus();
      inputDateRef.current.focus();
      inputDescriptionRef.current.focus();
    }
  }, [toggleTarget]);

  return (
    <tr
      className="darken 100w"
      style={TableBodyContent}
    >
      <td
        onDoubleClick={() => handleOnFocus('display')}
        style={TableHeadContent}
      >
        <select
          hidden={toggleTarget !== 'display' && true}
          ref={selectDisplayRef}
          onChange={(e) => setSelectValue(+e.target.value)}
          onBlur={(e) => handleOnBlur(e, 'display')}
          value={selectValue}
        >
          {
            releaseOptions.map((option) => (
              <option key={option.id} value={`${option.id}`}>{option.display}</option>
            ))
          }
        </select>
        <span hidden={toggleTarget === 'display' && true}>{displayOptions[updateInput.display]}</span>
      </td>
      <td
        onDoubleClick={() => handleOnFocus('dateLancamento')}
        style={TableHeadContent}
      >
        <input
          ref={inputDateRef}
          hidden={toggleTarget !== 'dateLancamento' && true}
          onBlur={(e) => handleOnBlur(e, 'dateLancamento')}
          defaultValue={updateInput.dateLancamento}
          style={TableInput(inputLength)}
          maxLength={10}
          onKeyDown={dateMaskByElementKeyDown}
          onFocus={(e) => setInputLength(e.currentTarget.value.length)}
          onChange={(e) => setInputLength(e.currentTarget.value.length)}
        />
        <span hidden={toggleTarget === 'dateLancamento' && true}>{updateInput.dateLancamento ? <span>{updateInput.dateLancamento}</span> : '-'}</span>
      </td>
      <td
        onDoubleClick={() => handleOnFocus('description')}
        style={TableHeadContent}
      >
        <span>R$ </span>
        <input
          ref={inputDescriptionRef}
          hidden={toggleTarget !== 'description' && true}
          onBlur={(e) => handleOnBlur(e, 'description')}
          defaultValue={updateInput.description}
          style={TableInput(inputLength)}
          onFocus={(e) => setInputLength(e.currentTarget.value.length)}
          onChange={inputsOnChange}
        />
        <span hidden={toggleTarget === 'description' && true}>
          {updateInput.description}
        </span>
      </td>
      <td style={TableHeadContent}>
        <div className="row center">
          <button style={ButtonContainer} className="10r 1p Green" type="button" onClick={handleOnSetModalReleases}>
            <IoIosAdd className="blanc" size={24} />
          </button>
          <button style={ButtonContainer} className="10r 5ml 1p Tomato" type="button" onClick={handleOnDeleteRelease}>
            <IoMdTrash className="blanc" size={20} />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default Releases;
