import React from 'react';

import { ModalAttributes } from './interface';

import { BackgroundContainer, Container } from './styles';

const Modal: React.FC<ModalAttributes> = ({
  isOpen, setIsOpen, onClose, children,
}) => {
  const handleToggleModal = (): void => {
    if (isOpen && onClose) {
      onClose();
    }

    setIsOpen(!isOpen);
  };

  return (
    isOpen ? (
      <div>
        <div
          style={BackgroundContainer}
          onClick={handleToggleModal}
        />
        <div className="Gray2" style={Container}>{children}</div>
      </div>
    ) : <div />
  );
};

export default Modal;
