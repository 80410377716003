import React, {
  FormEvent, useRef, MutableRefObject, useCallback,
} from 'react';

import { dateMaskByElementKeyDown } from '../../../../../../utils/masks';
import { dateBrToDefaultString } from '../../../../../../utils/converts';

import Input from '../../../../components/Input';

import { ModalFormProps } from './interface';

const ModalForm: React.FC<ModalFormProps> = ({ onSubmit }) => {
  const assemblyRef = useRef() as MutableRefObject<HTMLInputElement>;
  const maturityDateRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleOnSubmit = useCallback((e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const inputAssemblyDate = assemblyRef.current.value;
    const inputMaturityDate = maturityDateRef.current.value;

    const assemblyDateString = dateBrToDefaultString(inputAssemblyDate);
    const maturityDateString = dateBrToDefaultString(inputMaturityDate);
    const assemblyDate = new Date(assemblyDateString);
    const maturityDate = new Date(maturityDateString);
    const isValidAssemblyDate = !!assemblyDate.getTime();
    const isValidMaturityDate = !!maturityDate.getTime();

    if (!isValidAssemblyDate || !isValidMaturityDate) {
      throw alert('Data invalida.');
    }

    const obj = {
      assembly: assemblyDate.getTime(),
      maturity_date: maturityDate.getTime(),
    };

    onSubmit(obj);
  }, [onSubmit]);

  return (
    <div>
      <form onSubmit={handleOnSubmit}>
        <h3 className="blanc col center 10p">Cadastro</h3>
        <Input onKeyDown={dateMaskByElementKeyDown} labelName="Data da assembleia" reference={assemblyRef} required minLength={10} maxLength={10} />
        <Input onKeyDown={dateMaskByElementKeyDown} labelName="Vencimento da parcela" reference={maturityDateRef} required minLength={10} maxLength={10} />
        <div className="10p 100w">
          <button className="col 10r 10p center 100w" type="submit">Finalizar</button>
        </div>
      </form>
    </div>
  );
};

export default ModalForm;
