import React, { useEffect, useRef, useState } from 'react';
import { StatusDossie } from '../ListUsers';

interface FilterLeadsAttributes {
  onFilter: (filter: any) => void;
  isLoading: boolean;
  assignees: any[];
}

const FilterLeads:React.FC<FilterLeadsAttributes> = ({ assignees, isLoading, onFilter }) => {
  const [filters, setFilters] = useState(() => {
    const currentFilters = localStorage.getItem('@Contemplato/filters-dossie');

    if (currentFilters) {
      const storagedFilters = JSON.parse(currentFilters);

      return storagedFilters;
    }

    return {};
  });

  useEffect(() => {
    const obj:any = {
      id: filters?.id,
      phone: filters?.phone,
      status: filters?.status,
      display: filters?.display,
      cessionario: filters?.cessionario,
    };

    // eslint-disable-next-line
    for (const propName in obj) {
      if (!obj[propName]) {
        delete obj[propName];
      }
    }

    if (filters) {
      onFilter(filters);
    }
  }, [filters, isLoading]);

  const idRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const displayRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLSelectElement>(null);
  const assigneeRef = useRef<HTMLSelectElement>(null);

  const handleOnFilter = (e: any): void => {
    e.preventDefault();

    const id = idRef.current?.value && +idRef.current?.value;
    const phone = phoneRef.current?.value && phoneRef.current?.value;
    const status = statusRef.current?.value && statusRef.current?.value;
    const display = displayRef.current?.value && displayRef.current?.value;
    const cessionario = assigneeRef.current?.value && assigneeRef.current?.value;

    const filterObj = {
      id,
      phone,
      status,
      display,
      cessionario,
    };

    localStorage.setItem('@Contemplato/filters-dossie', JSON.stringify(filterObj));

    setFilters(filterObj);
  };

  const optionsSelect = [];

  // eslint-disable-next-line
  for (const enumMember in StatusDossie) {
    const isValueProperty = parseInt(enumMember, 10) >= 0;
    if (isValueProperty) {
      optionsSelect.push(
        <option key={enumMember} value={enumMember}>{StatusDossie[enumMember]}</option>,
      );
    }
  }

  return (
    <form
      onSubmit={handleOnFilter}
      className="100w"
      style={{
        display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '0 16px',
      }}
    >
      <label style={{ display: 'flex', flexDirection: 'column' }} htmlFor="">
        <b className="blanc" style={{ fontSize: '14px' }}>ID:</b>
        <input ref={idRef} defaultValue={filters?.id} className="10p 10r" style={{ width: '120px', marginTop: '4px' }} type="text" />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b className="blanc" style={{ fontSize: '14px' }}>Nome:</b>
        <input ref={displayRef} defaultValue={filters?.display} className="10p 10r" style={{ width: '260px', marginTop: '4px' }} type="text" />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b className="blanc" style={{ fontSize: '14px' }}>Telefone:</b>
        <input ref={phoneRef} className="10p 10r" defaultValue={filters?.phone} style={{ width: '200px', marginTop: '4px' }} type="text" />
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b className="blanc" style={{ fontSize: '14px' }}>Status:</b>
        <select ref={statusRef} className="10p 10r" defaultValue={filters?.status} onChange={handleOnFilter} style={{ width: '200px', marginTop: '4px' }}>
          <option selected value="">Nenhum</option>
          {
            optionsSelect.map((option) => option)
          }
        </select>
      </label>
      <label style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }} htmlFor="">
        <b className="blanc" style={{ fontSize: '14px' }}>Cessionário:</b>
        <select ref={assigneeRef} className="10p 10r" defaultValue={filters?.cessionario} onChange={handleOnFilter} style={{ width: '200px', marginTop: '4px' }}>
          <option selected value="">Nenhum</option>
          {
            assignees?.map((assignee) => (
              <option value={assignee.id}>{assignee?.display}</option>
            ))
          }
        </select>
      </label>
      <button
        type="submit"
        className="blanc bold"
        style={{
          borderRadius: '4px', padding: '12px', marginTop: '20px', border: 0, backgroundColor: '#0A65FE', marginLeft: '8px',
        }}
      >
        Filtrar
      </button>
    </form>
  );
};

export default FilterLeads;
