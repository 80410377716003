import React, {
  createContext, useContext, useCallback,
} from 'react';
import useSWR from 'swr';

import api from '../../services/api';
import { storage } from '../../services/firebase';

import { FetchContextData, Options } from './interface';

const FetchContext = createContext<FetchContextData>({} as FetchContextData);

const FetchProvider: React.FC = ({ children }) => {
  const apiSwr = useCallback((key: string, url: string, agent?: string, options?: Options) => {
    const { data: dataFetch, mutate } = useSWR(key, async () => {
      const { data: requestApiData } = await api.post(`${url}`, {
        agent,
        ...options,
      });

      const storageRef = storage.ref(process.env.REACT_APP_FIREBASE_STORAGE_PREFIX);
      const storageList = await storageRef.listAll();
      const userIds = storageList.prefixes.map((userId) => +userId.name);

      requestApiData.data.forEach((user: any) => {
        const hasExtract = userIds.find((userId) => userId === user.id);

        if (hasExtract) {
          user.extract = 1;
        }
      });

      const importantUsers = requestApiData.data.filter((user: any) => user.extract === 1);
      const restUsers = requestApiData.data.filter((user: any) => user.extract === 0);

      requestApiData.data = [...importantUsers, ...restUsers];

      return requestApiData;
    });

    return {
      dataFetch,
      mutate,
    };
  }, []);

  return (
    <FetchContext.Provider
      value={{
        apiSwr,
      }}
    >
      {children}
    </FetchContext.Provider>
  );
};

function useFetch(): FetchContextData {
  const context = useContext(FetchContext);

  if (!context) {
    throw new Error('useFetch must be used within an FetchProvider');
  }

  return context;
}

export { FetchContext, FetchProvider, useFetch };
