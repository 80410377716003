import React, { useMemo } from 'react';

import AdministratorComponent from './components/AdministratorComponent';

import { TableAdministratorAttributes } from './interface';
import { Administrator } from '../../interface';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
} from './styles';

const TableAdministrators: React.FC<TableAdministratorAttributes> = ({
  onUpdateField,
  administrators,
}) => {
  const administratorComponent = useMemo(() => administrators.map(
    (administrator: Administrator) => (
      <AdministratorComponent
        onUpdateField={onUpdateField}
        key={administrator.id}
        administrator={administrator}
      />
    ),
  ), [administrators, onUpdateField]);

  return (
    <div className="100w 10r" style={{ overflow: 'auto', height: '580px' }}>
      <table
        style={TableContent}
        className="10r 100w"
      >
        <thead className="100w" style={TableHeadContainer}>
          <tr className="100w">
            <th style={TableHeadContent}>
              Nome
            </th>
            <th style={TableHeadContent}>
              CNPJ
            </th>
            <th style={TableHeadContent}>
              Endereço
            </th>
            <th style={TableHeadContent}>
              Classe
            </th>
            <th style={TableHeadContent}>
              URL
            </th>
            <th style={TableHeadContent}>
              Social
            </th>
            <th style={TableHeadContent}>
              Lance
            </th>
            <th style={TableHeadContent}>
              Meses para contemplação
            </th>
            <th style={TableHeadContent}>
              R.E Minimo
            </th>
            <th style={TableHeadContent}>
              R.E Máximo
            </th>
            <th style={TableHeadContent}>
              Conveniado
            </th>
            <th style={TableHeadContent}>
              Multa
            </th>
          </tr>
        </thead>
        <tbody style={TableBodyContainer}>
          {administratorComponent}
        </tbody>
      </table>
    </div>
  );
};

export default TableAdministrators;
