import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import { ImCross } from 'react-icons/im';
import XLSX from 'xlsx';

import api from '../../services/api';
import apiMogno from '../../services/apiMogno';

import { useAuth } from '../auth';
import { useCache } from '../cacheServices';

import {
  convertBRLToNumbers, numberDiffOfMonth, removeAllCommas, tmsToCnabDate,
} from '../../utils/converts';
import { tmsToDateMask } from '../../utils/masks';

import {
  Container,
  ModalTable,
  ModalTableTd,
  ModalTableTh,
} from './styles';

import { CnabModalContextData, CnabModal, Administrator } from './interface';
import { ReleaseOptions } from '../../modules/Wallet/pages/Quota/interface';

// eslint-disable-next-line
enum TypesCRM {
  'Contemplado(a)' = 1,
  'Não Contemplado(a)' = 2,
  'Cancelado' = 3,
  'Novo' = 4,
}
// eslint-disable-next-line
enum StrategiesCRM {
  'Quitação' = 1,
  'Cancelada' = 2,
  'Intermediação' = 3,
}

const CnabModalContext = createContext<CnabModalContextData>(
  {} as CnabModalContextData,
);

function convertTmsToAmericanCnabDate(tms: number): string {
  const curr = new Date(tms);
  return `${curr.getFullYear()}-${(curr.getMonth() + 1).toString().padStart(2, '0')}-${curr.getDate().toString().padStart(2, '0')}`;
}

const CnabModalProvider: React.FC = ({ children }) => {
  const [releaseOptions, setReleaseOptions] = useState([] as ReleaseOptions[]);
  const [cnabData, setCnabData] = useState(() => {
    const cnabStorage = localStorage.getItem('@Contemplato/cnab');

    if (cnabStorage) {
      return JSON.parse(cnabStorage) as CnabModal[];
    }

    return [] as CnabModal[];
  });

  const { token, handleLogout } = useAuth();
  const { requestAdministrators, requestItems, requestRates } = useCache();

  const handleAddCnabData = useCallback(async (cnab: CnabModal) => {
    const hasData = cnabData.find((item) => cnab.release.id === item.release.id);

    if (hasData) {
      alert('Lançamento já adicionado!');
    } else {
      setCnabData([
        ...cnabData,
        cnab,
      ]);
    }
  }, [cnabData]);

  const handleRemoveCnabData = useCallback((id: number) => {
    const newCnab = cnabData.filter((cnab) => cnab.release.id !== id);

    setCnabData(newCnab);
  }, [cnabData]);

  const handleCreateFidcJSON = useCallback(async () => {
    const administrators = await requestAdministrators();
    const items = await requestItems();
    const rates = await requestRates();

    const { data: assigneeData } = await api.post('/output/cessionario', {
      agent: token,
    });

    const { data: commissionData } = await api.post('/output/commission', {
      agent: token,
    });

    const assignees = assigneeData.data;
    const commissions = commissionData.data;

    const FidcJSONs = cnabData.map((itemCnabData) => {
      let typePricing = itemCnabData.type;
      let itemPricing = itemCnabData.item;

      if (typePricing > 2) {
        typePricing = 2;
      }

      if (itemPricing > 2) {
        itemPricing = 2;
      }

      const administrator = administrators?.find(
        (itemAdministrator: any) => itemCnabData.administrator === itemAdministrator.id,
      );
      const assignee = assignees.find(
        (itemAssignee: any) => itemAssignee.id === itemCnabData.cessionario,
      );
      const item = items.find((itemItem: any) => itemItem.id === itemCnabData.item);

      const rate = rates.find(
        (itemRate: any) => itemRate.administrator === itemCnabData.administrator
          && itemRate.type === typePricing
          && itemRate.item === itemPricing,
      );

      if (!itemCnabData?.tmsDieGroup) {
        console.log('itemCnabData?.tmsDieGroup is null');
        throw new Error('Data de encerramento do grupo não está preenchida (nula).');
      }

      if (!administrator) {
        console.log('Administrator is null/not found');
        throw new Error('Administradora não foi encontrada no array ou se encontra nula.');
      }

      if (!item) {
        console.log('Item is null/not found');
        throw new Error('Item não foi encontrada no array ou se encontra nula.');
      }

      if (!rate) {
        console.log('rate is null');
        throw new Error('Multa não está preenchida/não encontrada (nula).');
      }

      if (!itemCnabData?.credit) {
        console.log('itemCnabData?.credit is null');
        throw new Error('Crédito não está preenchido (nula).');
      }

      if (!itemCnabData?.unitrust) {
        console.log('itemCnabData?.unitrust is null');
        throw new Error('Fundo comum não está preenchido (nula).');
      }

      if (!itemCnabData?.debit) {
        console.log('itemCnabData?.debit is null');
        throw new Error('Divida restante não está preenchido (nula).');
      }

      if (!itemCnabData?.bidPlaced) {
        console.log('itemCnabData?.bidPlaced is null');
        throw new Error('Lance embútido não está preenchido (nula).');
      }

      if (!itemCnabData?.bank_ag) {
        console.log('itemCnabData?.bank_ag is null');
        throw new Error('Agência do banco não está preenchida (nula).');
      }

      if (!itemCnabData?.bank_number_account) {
        console.log('itemCnabData?.bank_number_account is null');
        throw new Error('Número da conta não está preenchida (nula).');
      }

      if (!itemCnabData?.pricing) {
        console.log('itemCnabData?.pricing is null');
        throw new Error('Valor da proposta não está preenchida (nula).');
      }

      if (!itemCnabData?.codePostal) {
        console.log('itemCnabData?.codePostal is null');
        throw new Error('Código postal não está preenchida (nula).');
      }

      if (!assignee?.doc_fundo) {
        console.log('itemCnabData?.doc_fundo is null');
        throw new Error('Documento do fundo não está preenchida (nula).');
      }

      if (!itemCnabData?.document) {
        console.log('itemCnabData?.document is null');
        throw new Error('CPF/CNPJ do lead não está preenchida (nula).');
      }

      if (!itemCnabData?.contractNumber) {
        console.log('itemCnabData?.contractNumber is null');
        throw new Error('Número do contrato não está preenchida (nula).');
      }

      const credit = convertBRLToNumbers(itemCnabData.credit);
      const unitrust = convertBRLToNumbers(itemCnabData.unitrust);
      const debit = convertBRLToNumbers(itemCnabData.debit);
      const bidPlaced = convertBRLToNumbers(itemCnabData.bidPlaced);
      const differenceMonthsContemplated = itemCnabData?.tmsContemplated ? numberDiffOfMonth(
        new Date(itemCnabData?.tmsContemplated), new Date(),
      ) : 0;

      let rescueDate = new Date();

      if (itemCnabData.strategy === StrategiesCRM.Quitação) {
        rescueDate.setMonth(
          rescueDate.getMonth() + administrator.liquidityMonth + 6 - differenceMonthsContemplated,
        );
      } else {
        rescueDate = new Date(itemCnabData.tmsDieGroup);
      }

      const {
        origin,
        taxMax,
        taxMin,
      } = rate;

      let transferValue = 0;

      if (origin === 'VC') {
        transferValue = credit;
      } else if (origin === 'SD') {
        transferValue = debit;
      }

      let transferRate = (taxMax * transferValue) / 100;

      if (transferRate < taxMin) {
        transferRate = taxMin;
      }

      if (assignee.id === 2) {
        transferRate = 0;
      }

      const fine = (unitrust / credit) * 100;
      let finePercentage = 0;

      const {
        multa1,
        multa2,
        multa3,
        intervalo1,
        intervalo2,
        intervalo3,
      } = rate;

      if (fine <= intervalo1) {
        finePercentage = multa1;
      } else if (fine <= intervalo2) {
        finePercentage = multa2;
      } else if (fine <= intervalo3) {
        finePercentage = multa3;
      }

      let updatedCreditValue = 0;
      let contractValue = 0;

      let updatedDebitValue = debit;
      let debitValue = debit;

      if (TypesCRM.Cancelado !== itemCnabData.type) {
        contractValue = credit - debit - bidPlaced;
        updatedCreditValue = credit - bidPlaced;
      } else if (TypesCRM.Cancelado === itemCnabData.type) {
        contractValue = unitrust - (unitrust * (finePercentage / 100));
        updatedCreditValue = unitrust - (unitrust * (finePercentage / 100));
        updatedDebitValue = 0;
        debitValue = 0;
      }

      // eslint-disable-next-line
      let [bankAccount, bankAccountDigit] = itemCnabData.bank_number_account?.split('-');

      if (!bankAccountDigit) {
        bankAccountDigit = '0';
      }

      // eslint-disable-next-line
      let [agency, agencyDigit] = itemCnabData.bank_ag?.split('-');

      if (!agencyDigit) {
        agencyDigit = '0';
      }

      const [quota] = itemCnabData.quota?.split('-');

      const commission = commissions.find(
        (itemCommission: any) => itemCommission.strategy === itemCnabData.strategy
          && itemCommission.cessionario === itemCnabData.cessionario,
      );

      if (typeof commission !== 'number' && !commission) {
        console.log('commission is null');
        throw new Error('Comissão não encontrada, por favor, crie uma baseada em suas condições.');
      }

      let commissionValue = 0;

      if (itemCnabData.type !== TypesCRM.Cancelado) {
        commissionValue = (commission.commission / 100)
          * (credit - bidPlaced);
      }

      if (itemCnabData.type === TypesCRM.Cancelado) {
        commissionValue = unitrust - fine;
      }

      if (itemCnabData.cessionario === 2) {
        commissionValue = 0;
      }

      const updatedAcquisitionValue = convertBRLToNumbers(itemCnabData.release.description)
        + transferRate
        + commissionValue;

      let redemptionDate = '';

      if (StrategiesCRM.Cancelada === itemCnabData.strategy) {
        redemptionDate = convertTmsToAmericanCnabDate(itemCnabData.tmsDieGroup);
      } else if (StrategiesCRM.Quitação === itemCnabData.strategy) {
        const finalDate = new Date();

        if (itemCnabData.tmsContemplated) {
          finalDate.setMonth(finalDate.getMonth() + 6 + differenceMonthsContemplated);

          redemptionDate = convertTmsToAmericanCnabDate(finalDate.getTime());
        } else {
          finalDate.setMonth(finalDate.getMonth() + 6 + administrator.liquidityMonth);

          redemptionDate = convertTmsToAmericanCnabDate(finalDate.getTime());
        }
      }

      let tipo_bem = 'OUTROS';

      if (itemCnabData.item === 1) {
        tipo_bem = 'IMOVEIS';
      } else if (itemCnabData.item === 2) {
        tipo_bem = 'AUTO';
      }

      const fidcJSON = {
        agent: token,
        email: assignee?.email,
        password: assignee?.password,
        id: itemCnabData.id,
        fundo: removeAllCommas(assignee.doc_fundo),
        data: convertTmsToAmericanCnabDate(itemCnabData.release.tms),
        // data: '2021-12-27',
        codigo: 'CS61',
        tipo: 'consorcio',
        principal: {
          fundo: {
            documento: removeAllCommas(assignee.doc_fundo),
            nome: `${assignee.nome_fundo}`,
          },
          cedente: {
            documento: removeAllCommas(`${itemCnabData?.document}`),
            nome: itemCnabData.display,
            tipo: removeAllCommas(itemCnabData.document).length === 11 ? '1' : '2',
            endereco: itemCnabData.address,
            cep: `${removeAllCommas(`${itemCnabData?.codePostal}`)}`,
          },
          originador: {
            documento: itemCnabData.cessionario === 2 ? '07625159000140' : '43444675000132',
            nome: itemCnabData.cessionario === 2 ? 'BTG PACTUAL GESTORA DE INVESTIMENTOS ALTERNATIVOS LTDA' : 'CONTEMPLATO INTERMEDIAÇÃO DE CONSÓRCIOS LTDA',
          },
          registradora: {
            codigo: '01',
          },
          certificadora: {
            documento: '',
            nome: '',
          },
          pagamento: {
            banco: `${itemCnabData.bank}`,
            agencia: `${agency}`,
            agencia_digito: `${agencyDigit}`,
            conta: `${bankAccount}`,
            conta_tipo: `${itemCnabData.bank_account_type?.toLowerCase()}` || 'corrente',
            conta_digito: `${bankAccountDigit}`,
          },
        },
        linhas: [
          {
            administradora: {
              documento: removeAllCommas(administrator.cnpj),
              nome: `${administrator.social}`,
              rating: `${administrator.class}`,
            },
            recebivel: {
              valor_credito_atualizado: `${updatedCreditValue.toFixed(2)}`,
              valor_devedor: `${typeof debitValue === 'string' ? convertBRLToNumbers(debitValue) : debitValue.toFixed(2)}`,
              valor_devedor_atualizado: `${updatedDebitValue.toFixed(2)}`,
              valor_lance_embutido: `${convertBRLToNumbers(`${itemCnabData.bidPlaced}`).toFixed(2)}`,
              valor_taxa_transferencia: `${convertBRLToNumbers(`${transferRate}`).toFixed(2)}`,
              comissao_valor: `${commissionValue.toFixed(2)}`,
              indice_reajuste: itemCnabData?.indexReadjustment,
              tipo_bem,
              tipo_transacao: itemCnabData.target === 5 ? 'Digital' : 'Físico', // se veio do saas = digital, se veio do crm = físico ARRUMAR
              transfere_fundo: 'S',
              parcela_proxima_data: itemCnabData.type === 3 ? '' : convertTmsToAmericanCnabDate(itemCnabData.tmsParcela),
              parcela_proxima_valor: itemCnabData.type === 3 ? '' : `${convertBRLToNumbers(itemCnabData.portion).toFixed(2)}`,
              parcela_quantidade_restante: itemCnabData.type === 3 ? '' : `${itemCnabData.totalPortionToPay}`,
              data_emissao: convertTmsToAmericanCnabDate(itemCnabData.tmsIssueQuota),
              data_vencimento: convertTmsToAmericanCnabDate(itemCnabData.tmsDieGroup),
              data_resgate: redemptionDate,
              situacao: TypesCRM.Cancelado === itemCnabData.type ? '2' : '1',
              contemplada: TypesCRM['Contemplado(a)'] === itemCnabData.type ? '1' : '0',
              contrato_numero: `${itemCnabData.contractNumber}`,
              cota_codigo: `${itemCnabData.id}`,
              cota_numero: `${quota}`,
              grupo_numero: `${itemCnabData.group}`,
              grupo_termino: convertTmsToAmericanCnabDate(itemCnabData.tmsDieGroup),
              valor_contrato: `${contractValue.toFixed(2)}`,
              valor_compra_atualizado: `${updatedAcquisitionValue.toFixed(2)}`,
              valor_compra: `${convertBRLToNumbers(itemCnabData.release.description).toFixed(2)}`,
              valor_credito: `${updatedCreditValue.toFixed(2)}`,
            },
          },
        ],
      };

      return fidcJSON;
    });

    return FidcJSONs;
  }, [token, cnabData, requestAdministrators, requestItems, requestRates]);

  const handlePreviewTransference = useCallback(async () => {
    try {
      const fidcJSONs = await handleCreateFidcJSON();

      const fidcJSONLocal = fidcJSONs.map((itemJSON) => {
        // eslint-disable-next-line
        // @ts-ignore
        delete itemJSON.agent;
        // eslint-disable-next-line
        // @ts-ignore
        delete itemJSON.email;
        // eslint-disable-next-line
        // @ts-ignore
        delete itemJSON.password;

        return itemJSON;
      });

      navigator.clipboard.writeText(`${JSON.stringify(fidcJSONLocal, null, 2)}`);

      alert('JSON copiado.');
    } catch (err) {
      alert(err);
      console.error(err);
    }
  }, [handleCreateFidcJSON]);

  const handleOnTransference = useCallback(async () => {
    try {
      const fidcJSON = await handleCreateFidcJSON();

      fidcJSON?.forEach((json) => {
        api.post('/input/fsengine-api', json).then(({ data }) => {
          if (!data.status) {
            alert('Não foi possível transferir para o fundo, cheque os erros na aba Rede.');
          } else {
            apiMogno.post('/log', json).then((response: any) => console.log(response));
            alert('Transferido com sucesso!');
            console.log('%cTransferido com sucesso!', 'color: blue; font-size: 20px');
            console.log('%cJSON GERADO:', 'color: blue; font-size: 20px');
            console.log(fidcJSON);
          }
        });
      });
    } catch (err: any) {
      alert(err.message);
      console.error(err);
    }
  }, [token, cnabData, handleCreateFidcJSON]);

  // eslint-disable-next-line
  const handleGenerateExcel = useCallback(async () => {
    const administrators = await requestAdministrators();

    const cnabReceivable = generateCnabReceivable(cnabData, administrators);
    const cnabPayable = generateCnabPayable(cnabData, administrators, releaseOptions);

    if (cnabReceivable.length) {
      const ws = XLSX.utils.json_to_sheet(cnabReceivable);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'ws_data');
      XLSX.writeFile(wb, 'contemplato_cnab_ativo.xlsb');
    }

    if (cnabPayable.length) {
      const ws2 = XLSX.utils.json_to_sheet(cnabPayable);
      const wb2 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb2, ws2, 'ws_data');
      XLSX.writeFile(wb2, 'contemplato_cnab_passivo.xlsb');
    }
  }, [cnabData, releaseOptions]);

  useEffect(() => {
    localStorage.setItem('@Contemplato/cnab', JSON.stringify(cnabData));
  }, [cnabData]);

  useEffect(() => {
    const requestReleasesData = async (): Promise<void> => {
      try {
        const { data } = await api.post('/output/lista_lancamentos', {
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          setReleaseOptions(data.data);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente (nula).');
        console.error(err);
        handleLogout();
      }
    };

    if (cnabData.length && token) {
      requestReleasesData();
    }
  }, [cnabData, token]);

  const originOptions = {
    0: 'Contemplato',
    1: 'FIDC',
  } as any;

  return (
    <CnabModalContext.Provider
      value={{
        cnabData, handleAddCnabData, handleRemoveCnabData,
      }}
    >
      {children}
      {
        (!!cnabData.length && !!token) && (
          <div className="10r" style={Container}>
            <table cellPadding={0} cellSpacing={0} className="100w Gray2" style={ModalTable}>
              <thead>
                <tr>
                  <th style={ModalTableTh}>Código</th>
                  <th style={ModalTableTh}>Nome</th>
                  <th style={ModalTableTh}>Data</th>
                  <th style={ModalTableTh}>Valor</th>
                  <th style={ModalTableTh}>Origem</th>
                  <th style={ModalTableTh}>Ação</th>
                </tr>
              </thead>
              <tbody>
                {cnabData.map((cnab) => (
                  <tr className="darken" key={cnab.release.id}>
                    <td style={ModalTableTd}>
                      {cnab.id}
                    </td>
                    <td style={ModalTableTd}>
                      {cnab.release.display}
                    </td>
                    <td style={ModalTableTd}>
                      {tmsToDateMask(cnab.release.tms)}
                    </td>
                    <td style={ModalTableTd}>
                      R$ {cnab.release.description}
                    </td>
                    <td style={ModalTableTd}>
                      {originOptions[cnab.release.origin]}
                    </td>
                    <td style={ModalTableTd}>
                      <button className="10r 5p Tomato" type="button" onClick={() => handleRemoveCnabData(cnab.release.id)}>
                        <ImCross className="blanc" size={10} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="100w row">
              <button type="button" className="100w 10p Green blanc" onClick={handlePreviewTransference}>Preview JSON</button>
              <button type="button" className="100w 10p Blue blanc" onClick={handleOnTransference}>Transferir</button>
            </div>
          </div>
        )
      }
    </CnabModalContext.Provider>
  );
};

function useCnabModal(): CnabModalContextData {
  const context = useContext(CnabModalContext);

  if (!context) {
    throw new Error('useCnabModal must be used within an CnabModalProvider');
  }

  return context;
}

function generateCnabReceivable(
  cnabData: CnabModal[], administrators: Administrator[],
): any {
  const tmsHoje = new Date().getTime();

  const cnabReceivable = cnabData.filter((cnab) => +cnab.release.specie === 15 || cnab.release.display === 'Compra').map((cnab) => {
    const cnabAdministrator = administrators[administrators.findIndex(
      (administrator) => administrator.id === cnab.administrator,
    )];

    const contemplatedDate = cnab.tmsContemplated ? new Date(cnab.tmsContemplated) : new Date();

    if (cnab.tmsContemplated) {
      contemplatedDate.setDate(contemplatedDate.getDate() + 180);
    } else {
      contemplatedDate.setDate(contemplatedDate.getDate() + 240);
    }

    const weekDay = contemplatedDate.toLocaleDateString('en', { weekday: 'long' });

    /* os dias da data_vencimento_titulo não podem ser sabado ou domingo,
    caso sejam, deve-se transferir para segunda */
    if (weekDay === 'Sunday') {
      contemplatedDate.setDate(contemplatedDate.getDate() + 1);
    } else if (weekDay === 'Saturday') {
      contemplatedDate.setDate(contemplatedDate.getDate() + 2);
    }

    let contemplatedTMS = contemplatedDate.getTime();

    let acquisitionValue = 0;
    let amountTitle = 0;
    let amountPaid = 0;

    const contractCode = `${`${cnab.id}`.padStart(10, '0') + `${cnab.release.id}`.padEnd(14, '0')}1`;
    const credit = convertBRLToNumbers(cnab.credit);
    const debit = convertBRLToNumbers(cnab.debit);
    const purchaseAmount = convertBRLToNumbers(cnab.release.description);

    if (cnab.release.display === 'Quitação') { amountPaid = convertBRLToNumbers(cnab.release.description); }
    if (cnab.release.display === 'Lance') { amountPaid = convertBRLToNumbers(cnab.release.description); }
    if (cnab.release.display === 'Parcela') {
      acquisitionValue = convertBRLToNumbers(cnab.release.description);
      amountPaid = convertBRLToNumbers(cnab.release.description);
    }

    if (cnab.release.display === 'Compra') {
      // const credit = 32854;
      // const debit = 24935.38;
      // const purchaseAmount = 6000;
      acquisitionValue = (purchaseAmount / (credit - debit)) * credit;
    }

    // se for cancelada
    if (cnab.status === 350) {
      amountTitle = convertBRLToNumbers(cnab.unitrust);
      acquisitionValue = (purchaseAmount / (credit - debit)) * credit;

      if (cnab.tmsDieGroup) {
        contemplatedTMS = cnab.tmsDieGroup;
      }
    } else {
      amountTitle = convertBRLToNumbers(cnab.credit);
    }

    amountTitle = Math.round(
      amountTitle * 100,
    );

    acquisitionValue = Math.round(
      acquisitionValue * 100,
    );

    amountPaid = Math.round(
      amountPaid * 100,
    );

    return {
      _key: 'value',
      id_registro: '1',
      data_carencia: '0',
      tipo_juros: '0',
      taxa_juros: '0',
      coobrigacao: '02',
      caracteristica_especial: '99',
      modalidade_operacao: '0',
      natureza_operacao: '0',
      origem_recurso: '0',
      classe_risco_operacao: cnabAdministrator?.class,
      TOPICO_12: '0',
      codigo_contrato: contractCode,
      numero_banco: '000',
      quantidade_lastros: '000',
      valor_pago: removeAllCommas(`${amountPaid}`),
      papeleta_cobranca: '',
      debito_automatico: '',
      data_liquidacao: '0',
      id_operacao_banco: '',
      indicador_rateio_credito: '',
      enderecamente_aviso_debito_automatico: '',
      id_ocorrencia: `${cnab.release.occurrence}`.padStart(2, '0'),
      numero_documento: `${cnab.id}`,
      data_vencimento_titulo: tmsToCnabDate(contemplatedTMS),
      valor_titulo: removeAllCommas(`${amountTitle}`),
      banco_cobranca: '237',
      agencia_depositaria: '3381',
      especie_titulo: '15',
      id_especie_titulo: ' ',
      data_aquisicao_fundo: tmsToCnabDate(tmsHoje),
      primeira_instrucao: '0',
      segunda_instrucao: '0',
      tipo_cedente: '02',
      juros_atraso: '0',
      id_termo_cessao: `${cnab.id}`,
      valor_aquisicao: removeAllCommas(`${acquisitionValue}`),
      valor_abatimento: '0',
      id_tipo_sacado: '02',
      id_inscricao_sacado: removeAllCommas(cnabAdministrator?.cnpj),
      nome_sacado: cnabAdministrator?.social,
      endereco_completo: 'RUA HELENA 218 SAO PAULO SP',
      numero_nf: '000000000',
      numero_serie_nf: '000',
      cep: '04552050',
      cedente: 'CONTEMPLATO INTERMEDIAÇÃO CONSORCIOS LTDA',
      cnpj_cedente: '35120044000130',
      chave_nota: '0',
      taxa_juros_mes: '0',
      juros_mora: '0',
      multa: '0',
      _54: '0',
      id_sequencial_registro: '',
    };
  });

  return cnabReceivable;
}

function generateCnabPayable(
  cnabData: CnabModal[], administrators: Administrator[], releaseOptions: ReleaseOptions[],
): any {
  const tmsHoje = new Date().getTime();

  const cnabPayable = cnabData.filter((cnab) => +cnab.release.specie === 16 || cnab.release.display === 'Compra').map((cnab) => {
    const cnabAdministrator = administrators[administrators.findIndex(
      (administrator) => administrator.id === cnab.administrator,
    )];

    const contemplatedDate = cnab.tmsContemplated ? new Date(cnab.tmsContemplated) : new Date();

    if (cnab.tmsContemplated) {
      contemplatedDate.setDate(contemplatedDate.getDate() + 180);
    } else {
      contemplatedDate.setDate(contemplatedDate.getDate() + 240);
    }

    const weekDay = contemplatedDate.toLocaleDateString('en', { weekday: 'long' });

    /* os dias da data_vencimento_titulo não podem ser sabado ou domingo,
    caso sejam, deve-se transferir para segunda */
    if (weekDay === 'Sunday') {
      contemplatedDate.setDate(contemplatedDate.getDate() + 1);
    } else if (weekDay === 'Saturday') {
      contemplatedDate.setDate(contemplatedDate.getDate() + 2);
    }

    let contemplatedTMS = contemplatedDate.getTime();

    let acquisitionValue = 0;
    let amountPaid = 0;
    let amountTitle = convertBRLToNumbers(cnab.debit);
    let contractCode = `${`${cnab.id}`.padStart(10, '0') + `${cnab.release.id}`.padEnd(14, '0')}0`;

    if (cnab.release.display === 'Quitação') { amountPaid = convertBRLToNumbers(cnab.release.description); }
    if (cnab.release.display === 'Lance') { amountPaid = convertBRLToNumbers(cnab.release.description); }
    if (cnab.release.display === 'Parcela') {
      acquisitionValue = convertBRLToNumbers(cnab.release.description);
      amountPaid = convertBRLToNumbers(cnab.release.description);

      const releaseId = releaseOptions.find((option) => option.display === 'Compra')?.id;

      if (releaseId) {
        contractCode = `${`${cnab.id}`.padStart(10, '0') + `${cnab.launches.find((launcher) => +launcher.display === +releaseId && launcher.origin === 1)?.id}`.padEnd(14, '0')}0`;
      }
    }

    if (cnab.release.display === 'Compra') {
      // const credit = 32854;
      // const debit = 24935.38;
      // const purchaseAmount = 6000;
      const credit = convertBRLToNumbers(cnab.credit);
      const debit = convertBRLToNumbers(cnab.debit);
      const purchaseAmount = convertBRLToNumbers(cnab.release.description);
      acquisitionValue = (purchaseAmount / (credit - debit)) * debit;
    }

    if (cnab.status === 350) {
      amountTitle = 0;
      acquisitionValue = 0;

      if (cnab.tmsDieGroup) {
        contemplatedTMS = cnab.tmsDieGroup;
      }
    } else {
      amountTitle = convertBRLToNumbers(cnab.debit);
    }

    amountTitle = Math.round(
      amountTitle * 100,
    );

    acquisitionValue = Math.round(
      acquisitionValue * 100,
    );

    amountPaid = Math.round(
      amountPaid * 100,
    );

    return {
      _key: 'value',
      id_registro: '1',
      data_carencia: '0',
      tipo_juros: '0',
      taxa_juros: '0',
      coobrigacao: '02',
      caracteristica_especial: '99',
      modalidade_operacao: '0',
      natureza_operacao: '0',
      origem_recurso: '0',
      classe_risco_operacao: cnabAdministrator?.class,
      TOPICO_12: '0',
      codigo_contrato: contractCode,
      numero_banco: '000',
      quantidade_lastros: '000',
      valor_pago: removeAllCommas(`${amountPaid}`),
      papeleta_cobranca: '',
      debito_automatico: '',
      data_liquidacao: '0',
      id_operacao_banco: '',
      indicador_rateio_credito: '',
      enderecamente_aviso_debito_automatico: '',
      id_ocorrencia: `${cnab.release.occurrence}`.padStart(2, '0'),
      numero_documento: `${cnab.id}`,
      data_vencimento_titulo: tmsToCnabDate(contemplatedTMS), // arrumar
      valor_titulo: removeAllCommas(`${amountTitle}`),
      banco_cobranca: '237',
      agencia_depositaria: '3381',
      especie_titulo: '16',
      id_especie_titulo: ' ',
      data_aquisicao_fundo: tmsToCnabDate(tmsHoje),
      primeira_instrucao: '0',
      segunda_instrucao: '0',
      tipo_cedente: '02',
      juros_atraso: '0',
      id_termo_cessao: `${cnab.id}`,
      valor_aquisicao: removeAllCommas(`${acquisitionValue}`),
      valor_abatimento: '0',
      id_tipo_sacado: '02',
      id_inscricao_sacado: removeAllCommas(cnabAdministrator?.cnpj),
      nome_sacado: cnabAdministrator?.social,
      endereco_completo: 'RUA HELENA 218 SAO PAULO SP',
      numero_nf: '000000000',
      numero_serie_nf: '000',
      cep: '04552050',
      cedente: 'CONTEMPLATO INTERMEDIAÇÃO CONSORCIOS LTDA',
      cnpj_cedente: '35120044000130',
      chave_nota: '0',
      taxa_juros_mes: '0',
      juros_mora: '0',
      multa: '0',
      _54: '0',
      id_sequencial_registro: '',
    };
  });

  return cnabPayable;
}

export { CnabModalContext, CnabModalProvider, useCnabModal };
