const Container = {
  minHeight: '100vh',
};

const Content = {
  justifyContent: 'center',
  height: '100%',
};

const Wrapper = {
  marginTop: '16px',
  color: '#000',
};

const ContainerDocuments = {
  marginTop: '16px',
};

const Title = {
  width: '100%',
  textAlign: 'center' as const,
};

const TableContainer = {
  overflow: 'auto',
  maxHeight: '380px',
  height: '100%',
};

const ButtonCreateRelease = {
  padding: '12px 0px',
  marginTop: '8px',
};

const ButtonTransferFidcDocument = {
  padding: '12px 0px',
  marginTop: '8px',
};

export {
  TableContainer,
  Container,
  Content,
  Title,
  Wrapper,
  ContainerDocuments,
  ButtonCreateRelease,
  ButtonTransferFidcDocument,
};
