import React, { useRef, MutableRefObject, useState } from 'react';

import { dateBrToDefaultString } from '../../../../../../../../utils/converts';
import { dateMaskByElementKeyDown } from '../../../../../../../../utils/masks';

import { FilterAttributes } from './interface';

import Input from '../../../../../Input';

const Filter: React.FC<FilterAttributes> = ({ onFilter }) => {
  const [filtersName, setFiltersName] = useState('id');

  const extractRef = useRef() as MutableRefObject<HTMLInputElement>;
  const initialDateRef = useRef() as MutableRefObject<HTMLInputElement>;
  const lastDateRef = useRef() as MutableRefObject<HTMLInputElement>;
  const filterByRef = useRef() as MutableRefObject<HTMLInputElement>;
  const selectFilterByRef = useRef() as MutableRefObject<HTMLSelectElement>;
  const selectFilterByStatusRef = useRef() as MutableRefObject<HTMLSelectElement>;

  const filterOptions = {
    id: 'Código',
    display: 'Nome',
    email: 'Email',
    phone: 'Telefone',
  } as any;

  const handleOnFilter = async (e: any): Promise<void> => {
    e.preventDefault();

    let selectValue = selectFilterByRef.current.value === 'id'
      ? +filterByRef.current.value : filterByRef.current.value as any;
    const selectByStatusValue = selectFilterByStatusRef.current?.value
      ? +selectFilterByStatusRef.current?.value : undefined;

    if (selectValue === '' || !selectValue) {
      selectValue = undefined;
    }

    const obj = {
      [selectFilterByRef.current.value]: selectValue,
      extract: extractRef.current?.checked,
      status: selectByStatusValue,
      initialDate: initialDateRef.current?.value
        ? new Date(dateBrToDefaultString(initialDateRef.current?.value)) : undefined,
      lastDate: lastDateRef.current?.value
        ? new Date(dateBrToDefaultString(lastDateRef.current?.value)) : undefined,
    };

    await onFilter(obj);
  };

  return (
    <form className="100w" onSubmit={handleOnFilter}>
      <div className="row">
        <div className="row 100w">
          <Input reference={initialDateRef} placeholder="DD/MM/YYYY" labelName="Data inicial" onKeyDown={dateMaskByElementKeyDown} maxLength={10} />
          <Input reference={lastDateRef} placeholder="DD/MM/YYYY" labelName="Data final" onKeyDown={dateMaskByElementKeyDown} maxLength={10} />
        </div>
        <div className="10p 100w">
          <span>Filtrar pelo:</span>
          <select ref={selectFilterByStatusRef} className="10r 10p 100w" defaultValue={100} name="" id="">
            <option value="">Todos</option>
            <option value={100}>Início - 100</option>
            <option value={110}>Preços - 110</option>
            <option value={120}>Análise - 120</option>
            <option value={130}>Finalizados - 130</option>
          </select>
        </div>
      </div>
      <div className="row center">
        <Input
          reference={filterByRef}
          labelName={filterOptions[filtersName]}
        />
        <div className="10p">
          <span>Filtrar por:</span>
          <select ref={selectFilterByRef} onChange={(e) => setFiltersName(e.currentTarget.value)} className="10r 10p 100w" defaultValue="id" name="" id="">
            <option value="id">Código</option>
            <option value="display">Nome</option>
            <option value="email">E-mail</option>
            {/* <option value="tmsIn">Data</option> */}
            <option value="phone">Telefone</option>
          </select>
        </div>
      </div>
      <div className="10p 100w">
        <label htmlFor="has_extract" style={{ cursor: 'pointer' }}>
          <input id="has_extract" ref={extractRef} type="checkbox" style={{ color: '#000', backgroundColor: '#0f0' }} />
          <span className="5p">Extrato</span>
        </label>
      </div>
      <div className="100w 10p">
        <button className="100w 10p 10r Green blanc" type="submit">Procurar</button>
      </div>
    </form>
  );
};

export default Filter;
