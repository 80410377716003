import React, { useMemo } from 'react';

import AssigneeComponent from './components/AssigneeComponent';

import { TableAssigneeAttributes } from './interface';
import { Assignee } from '../../interface';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
} from './styles';

const TableAssignees: React.FC<TableAssigneeAttributes> = ({
  onUpdateField,
  assignees,
  onDelete,
}) => {
  const assigneeComponent = useMemo(() => assignees.map(
    (assignee: Assignee) => (
      <AssigneeComponent
        onUpdateField={onUpdateField}
        key={assignee.id}
        assignee={assignee}
        onDelete={onDelete}
      />
    ),
  ), [assignees, onUpdateField]);

  return (
    <div className="100w 10r" style={{ overflow: 'auto', height: '580px' }}>
      <table
        style={TableContent}
        className="10r 100w"
      >
        <thead className="100w" style={TableHeadContainer}>
          <tr className="100w">
            <th style={TableHeadContent}>
              Nome
            </th>
            <th style={TableHeadContent}>
              Código da registradora
            </th>
            <th style={TableHeadContent}>
              Documento certificadora
            </th>
            <th style={TableHeadContent}>
              Documento do cessionário
            </th>
            <th style={TableHeadContent}>
              Nome da certificadora
            </th>
            <th style={TableHeadContent}>
              Nome do cessionário
            </th>
            <th style={TableHeadContent}>
              Pré-sessão
            </th>
            <th style={TableHeadContent}>
              Email
            </th>
            <th style={TableHeadContent}>
              Senha
            </th>
            <th style={TableHeadContent}>
              Signatário
            </th>
            <th style={TableHeadContent}>
              Ação
            </th>
          </tr>
        </thead>
        <tbody style={TableBodyContainer}>
          {assigneeComponent}
        </tbody>
      </table>
    </div>
  );
};

export default TableAssignees;
