const ContentDocuments = {
  display: 'flex',
  flexDirection: 'row' as const,
  alignItems: 'center',
  justifyContent: 'space-evenly' as const,
};

const LinkDocument = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column' as const,
  color: '#1f1f1f',
};

const LinkDocumentLabel = {
  ...LinkDocument,
  cursor: 'pointer',
};

const InputFile = {
  display: 'none',
};

export {
  ContentDocuments,
  LinkDocument,
  LinkDocumentLabel,
  InputFile,
};
