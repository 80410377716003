import React, {
  useState, useRef, useEffect, MutableRefObject, useMemo,
} from 'react';
import { IoIosDocument } from 'react-icons/io';
import { FaAddressBook, FaCopy } from 'react-icons/fa';
import { RiChat4Fill, RiUserAddFill } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../../../../hooks/auth';

import apiSaas from '../../../../../../services/apiSaas';
import api from '../../../../../../services/api';

import Modal from '../../../../components/Modal';

import { useGoogleApi } from '../../../../../../hooks/googleApi';

import { dateMaskByElementKeyDown, phoneMaskByElement, tmsToDateMask } from '../../../../../../utils/masks';
import { dateBrToDefaultString } from '../../../../../../utils/converts';

import { LeadProps } from './interface';

import {
  ContainerLead,
  SpanStyle,
  ExtraDataContainer,
  AddressBookIcon,
  AddressBookButton,
} from './styles';

const LeadComponent: React.FC<LeadProps> = ({
  lead, operatorName, onUpdateUser, onUpdateStatus,
}) => {
  const history = useHistory();

  const { id } = useParams<any>();
  const { token } = useAuth();
  const { authenticate, createContact } = useGoogleApi();

  const nameRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const emailRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const phoneRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const tmsInRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const targetRef = useRef<HTMLSelectElement>(null) as MutableRefObject<HTMLSelectElement>;

  const [toggleTarget, setToggleTarget] = useState('');
  const [updateField, setUpdateField] = useState({ ...lead });
  const [toggleModal, setToggleModal] = useState(false);
  const [resultOcr, setResultOcr] = useState({} as any);

  function handleOnDoubleClick(field: string): void {
    setToggleTarget(field);
  }

  async function handleCreateContact(): Promise<void> {
    await authenticate();
    await createContact(lead.display, lead.phone);
  }

  async function handleConsultValidationDocuments(): Promise<void> {
    setToggleModal(true);

    const id_onboarding = Buffer.from(id).toString('base64');

    const response = await api.post('/output/ocr-match-client', {
      agent: token,
      id_onboarding,
    });

    setResultOcr(response?.data?.data);
  }

  function handleOnBlur(): void {
    const tmsIn = new Date(dateBrToDefaultString(tmsInRef.current.value)).getTime();

    const obj = {
      display: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      target: +targetRef.current.value,
      tmsIn,
    };

    updateField.dateIn = tmsToDateMask(obj.tmsIn);

    setToggleTarget('');
    setUpdateField({
      ...updateField,
      ...obj,
    });
    onUpdateUser(obj);
  }

  async function handleContactLeadByMessages(): Promise<any> {
    const getAssigneeName:any = {
      2: 'consort',
      6: 'rodobens',
    };
    try {
      const assigneeName = getAssigneeName[lead?.cessionario] || 'contemplato';
      const campaignName = `init-chat-${assigneeName}`;

      await apiSaas.post('/v1/messaging-crm/open-chat', {
        campaignName,
        operatorName,
        contact: {
          phone: `55${lead.phone.replace(/\D/g, '')}`,
          name: lead.display,
        },
      });

      alert('Enviado com sucesso');
    } catch (err) {
      alert('Falha ao enviar a requisição, erro nos logs');

      console.log(err);
    }
  }

  useEffect(() => {
    if (toggleTarget) {
      nameRef.current.focus();
      emailRef.current.focus();
      phoneRef.current.focus();
      tmsInRef.current.focus();
      targetRef.current.focus();
    }
  }, [toggleTarget]);

  const targetOptions = {
    0: 'Formulário',
    1: 'Whatsapp',
    2: 'Facebook',
    3: 'Instagram',
    4: 'Outros',
    5: 'Saas',
  } as any;

  const target = targetOptions[updateField.target];

  const extractIcon = useMemo(() => (
    <a rel="noreferrer" target="_blank" href={`/firebase/${updateField.id}`}>
      <IoIosDocument size={38} color="#fff" />
    </a>
  ), [updateField]);

  const idEncriptedBase64 = btoa(`${lead.id}`);

  const urlOnboarding = `${process.env.REACT_APP_LINK_URL_ONBOARDING}/${idEncriptedBase64}`;

  async function handleOnUpdateStatus(e: any): Promise<void> {
    const status = +e.currentTarget.value;

    await onUpdateStatus(status);

    if (status === 300) {
      alert('Movido para gestão');
      history.push('/dossie');
    }
  }

  return (
    <div className="col" style={ContainerLead}>
      <Modal isOpen={toggleModal} setIsOpen={setToggleModal}>
        {Object.keys(resultOcr).length > 0 ? (
          <div className="col center">
            <b style={{ marginBottom: 10, fontSize: 18 }}> Resultado OCR </b>
            <span style={{ marginBottom: 10 }}>
              Documento: {(resultOcr.document * 100).toFixed(2)} %
            </span>
            <span style={{ marginBottom: 10 }}>
              Documento secundário: {(resultOcr.document2 * 100).toFixed(2)} %
            </span>
            <span style={{ marginBottom: 10 }}>
              Nome: {(resultOcr.name * 100).toFixed(2)} %
            </span>
            <span style={{ marginBottom: 10 }}>
              Data de aniversário: {(resultOcr.name * 100).toFixed(2)} %
            </span>
            <b style={{ marginBottom: 10 }}>
              Média: {(resultOcr.average * 100).toFixed(2)} %
            </b>
          </div>
        ) : (
          <div>
            <span>OCR não disponível</span>
          </div>
        )}
      </Modal>
      <div className="row center" style={{ justifyContent: 'space-between' }}>
        <span className="fo16" style={SpanStyle}><b>Código: {updateField.id}</b></span>
        <select className="10r 10p" defaultValue={lead.status} onChange={handleOnUpdateStatus}>
          <option value="200">Inicio</option>
          <option value="210">Contrato</option>
          <option value="215">Contrato enviado</option>
          <option value="220">Solicitação de transferência</option>
          <option value="221">Reativação de Cota</option>
          <option value="220">Solicitação de transferência</option>
          <option value="222">Solicitar Kit Transferência</option>
          <option value="223">Aguardo Documento Interno</option>
          <option value="224">Aguardo Documento Cliente</option>
          <option value="225">Cartório</option>
          <option value="226">Motoboy</option>
          <option value="227">Distrato</option>
          <option value="230">Cadastro na administradora</option>
          <option value="240">Envio de documentos</option>
          <option value="250">Termo de cessão</option>
          <option value="260">Aguardando transferência</option>
          <option value="270">Desistente</option>
          <option value="271">Descartado</option>
          <option value="300">Finalizado</option>
        </select>
      </div>
      <div className="row center wrap">
        <div className="col 1s">
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('name')}>
            <span style={SpanStyle}>
              Nome:
              <span hidden={toggleTarget === 'name'} style={SpanStyle}> {updateField.display}</span>
              <input ref={nameRef} defaultValue={updateField.display} onBlur={handleOnBlur} hidden={toggleTarget !== 'name'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('email')}>
            <span style={SpanStyle}>
              E-mail:
              <span hidden={toggleTarget === 'email'} style={SpanStyle}> {updateField.email}</span>
              <input ref={emailRef} defaultValue={updateField.email} onBlur={handleOnBlur} hidden={toggleTarget !== 'email'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('target')}>
            <span style={SpanStyle}>
              Vindo do:
              <span hidden={toggleTarget === 'target'} style={SpanStyle}> {target}</span>
              <select ref={targetRef} defaultValue={updateField.target} onBlur={handleOnBlur} hidden={toggleTarget !== 'target'}>
                {
                  Object.values(targetOptions).map((itemTarget: any, index: number) => (
                    <option key={itemTarget} value={index}>{itemTarget}</option>
                  ))
                }
              </select>
            </span>
          </div>
          {lead.tmsPricing && (
          <span style={SpanStyle}>
            Data da proposta: {tmsToDateMask(lead.tmsPricing)}
          </span>
          )}
        </div>
        <div className="col 1s">
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('phone')}>
            <span style={SpanStyle}>
              Telefone:
              <span hidden={toggleTarget === 'phone'} style={SpanStyle}> {updateField.phone}</span>
              <input ref={phoneRef} defaultValue={updateField.phone} maxLength={15} onBlur={handleOnBlur} onChange={phoneMaskByElement} hidden={toggleTarget !== 'phone'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('tmsIn')}>
            <span style={SpanStyle}>
              Data:
              <span hidden={toggleTarget === 'tmsIn'} style={SpanStyle}> {updateField.dateIn}</span>
              <input ref={tmsInRef} defaultValue={updateField.dateIn} maxLength={10} onKeyDown={dateMaskByElementKeyDown} onBlur={handleOnBlur} hidden={toggleTarget !== 'tmsIn'} type="text" />
            </span>
          </div>
          <div className="row 1s">
            <span style={SpanStyle}>
              <a href={urlOnboarding} target="_blank" rel="noreferrer">Link onboarding</a> <FaCopy cursor="pointer" onClick={() => navigator.clipboard.writeText(urlOnboarding)} />
            </span>
          </div>
        </div>
        <div style={ExtraDataContainer}>
          <button type="button" style={AddressBookButton} onClick={handleContactLeadByMessages}>
            <RiChat4Fill size={29} color="#fff" />
          </button>
          <button type="button" style={AddressBookButton} onClick={handleCreateContact}>
            <RiUserAddFill size={29} color="#fff" />
          </button>
          <button type="button" style={AddressBookButton} onClick={handleConsultValidationDocuments}>
            <FaAddressBook size={29} color="#fff" style={AddressBookIcon} />
          </button>
          {extractIcon}
        </div>
      </div>
    </div>
  );
};

export default LeadComponent;
