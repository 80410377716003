import React, { useRef, MutableRefObject } from 'react';

import { ModalFormProps } from './interface';

import { ButtonCreateRelease } from './styles';

const ModalForm: React.FC<ModalFormProps> = ({ userLead, onSubmit, assignees }) => {
  const optionStatusRef = useRef() as MutableRefObject<HTMLSelectElement>;

  const handleOnSubmit = (e: any): void => {
    e.preventDefault();

    const option = +optionStatusRef.current.value;

    if (option === 2) {
      const status = 400;

      onSubmit({
        ...userLead,
        cessionario: option,
        status,
      });
    } else {
      const status = 200;

      onSubmit({
        ...userLead,
        cessionario: option,
        status,
      });
    }
  };

  return (
    <form className="1s" onSubmit={handleOnSubmit}>
      <div className="10p">
        <label htmlFor="">
          <div>Cessionário:</div>
        </label>
        <div className="col center">
          <select className="10p 10r 100w" ref={optionStatusRef} defaultValue={userLead?.cessionario || 1}>
            <option value={0} disabled>Cessionário</option>
            {assignees.map((assignee) => (
              <option key={assignee.id} value={assignee.id}>{assignee.display}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="10p">
        <button className="100w 10r blanc 1p" type="submit" style={ButtonCreateRelease}>Confirmar</button>
      </div>
    </form>
  );
};

export default ModalForm;
