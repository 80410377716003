import React, { useEffect } from 'react';
import * as dotenv from 'dotenv';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';

dotenv.config();

const App: React.FC = () => {
  useEffect(() => {
    try {
      gapi?.load('client:auth2', () => {
        gapi.auth2.init({
          client_id: `${process.env.REACT_APP_GOOGLE_USER_ID}`,
        });
      });
    } catch (err) {
      alert('Ocorreu uma falha ao conectar na google api');
      console.log(err);
    }
  }, []);

  return (
    <Router>
      <Routes />
    </Router>
  );
};

export default App;
