const ButtonCreateRelease = {
  backgroundColor: '#4AEE78',
  padding: '12px 0px',
  marginTop: '8px',
};

const SelectContainer = {
  backgroundColor: '#19181E',
};

export {
  ButtonCreateRelease,
  SelectContainer,
};
