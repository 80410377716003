import React, { useState, useRef } from 'react';
import { IoIosSearch } from 'react-icons/io';

import api from '../../../../../../services/api';
import { storage } from '../../../../../../services/firebase';

import { useAuth } from '../../../../../../hooks/auth';

import { FilterComponent } from './interface';

import {
  Container,
  Content,
  Wrapper,
  InputContainer,
  SelectContainer,
  ButtonContainer,
} from './styles';

const Filter: React.FC<FilterComponent> = ({ status, listUsers, setFilteredUsers }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const selectRef = useRef<HTMLSelectElement>(null);

  const { token } = useAuth();

  const [isToggle, setIsToggle] = useState(false);

  function handleOnToggle(): void {
    setIsToggle(!isToggle);
  }

  async function handleOnSubmit(e: any): Promise<void> {
    e.preventDefault();

    const inputElement = inputRef.current;
    const selectElement = selectRef.current;

    if (inputElement?.value === '') {
      setFilteredUsers(listUsers);
    }

    if (inputElement?.value && selectElement?.value) {
      const { data: requestApiData } = await api.post('/output/atendimento', {
        [selectElement.value]: selectElement.value === 'id' ? +inputElement.value : inputElement.value,
        status,
        agent: token,
      });

      if (!requestApiData.data.length) {
        alert('Sem resultados');
      } else {
        const storageRef = storage.ref(process.env.REACT_APP_FIREBASE_STORAGE_PREFIX);
        const storageList = await storageRef.listAll();
        const userIds = storageList.prefixes.map((userId) => +userId.name);

        requestApiData.data.forEach((user: any) => {
          const hasExtract = userIds.find((userId) => userId === user.id);

          if (hasExtract) {
            user.extract = 1;
          } else {
            user.extract = 0;
          }
        });

        const importantUsers = requestApiData.data.filter((user: any) => user.extract === 1);
        const restUsers = requestApiData.data.filter((user: any) => user.extract === 0);

        requestApiData.data = [...importantUsers, ...restUsers];

        setFilteredUsers(requestApiData.data);
      }
    } else {
      setFilteredUsers([]);
    }
  }

  return (
    <div style={Container}>
      {
        isToggle && (
          <form className="Gray2" style={Content} onSubmit={handleOnSubmit}>
            <div className="100w row center" style={Wrapper}>
              <input ref={inputRef} className="100w 10r 10p" type="text" placeholder="O que deseja pesquisar?" style={InputContainer} />
              <select ref={selectRef} className="10r 10p" name="" id="" style={SelectContainer}>
                <option value="id">Código</option>
                <option value="display">Nome</option>
                <option value="email">E-mail</option>
                {/* <option value="tmsIn">Data</option> */}
                <option value="phone">Telefone</option>
              </select>
            </div>
            <button className="100w 10p 10r Black blanc" type="submit">Procurar</button>
          </form>
        )
      }
      <button className="blanc darken" onClick={handleOnToggle} type="button" style={ButtonContainer}>
        <IoIosSearch size={24} />
      </button>
    </div>
  );
};

export default Filter;
