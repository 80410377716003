import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu } from 'react-icons/fi';
import { IoMdNotifications, IoMdNotificationsOutline } from 'react-icons/io';

import Menu from '../Menu';
import { numberOfDaysBetweenToday } from '../../../../utils/converts';

import { useNotifications } from '../../../../hooks/notification';

import {
  Container,
  ContainerLink,
  ContentLink,
  Content,
  NotificationContent,
  NotificationText,
  ContainerOptions,
} from './styles';

interface Map {
  [key: number]: (text: string) => any | undefined
}

const Header: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);

  const { notifications } = useNotifications();

  const url = window.location.pathname;

  const notificationContainer = useMemo(() => {
    if (hasNotifications) {
      return (
        <div className="col center">
          <button type="button" onFocus={() => setHasNotifications(!hasNotifications)} className="1p" style={{ backgroundColor: 'transparent' }}>
            <IoMdNotifications className="col center" color="#fff" size={24} />
          </button>
          <div
            className="Blanc col center 10r 20p"
            style={{
              position: 'absolute', boxShadow: '0 0 2em #9f9f9f', overflow: 'auto', marginTop: '36px', height: '400px', width: '380px',
            }}
          >
            <strong className="fo18">Notificações</strong>
            {notifications.map((notification) => {
              const previousDate = new Date();
              const remainingDays = 5;

              previousDate.setDate(previousDate.getDate() + remainingDays);

              const notificationOptions: Map = {
                0: (text: string) => (
                  <div
                    key={text + notification.id}
                    className="Gray2 100w 10p 10r darken"
                    style={NotificationContent}
                  >
                    <strong className="fo16">{notification.id} - {text}</strong>
                    <span style={NotificationText}>{text} é hoje!</span>
                  </div>
                ),
                5: (text: string) => (
                  <div
                    key={text + notification.id}
                    className="Gray2 100w 10p 10r darken"
                    style={NotificationContent}
                  >
                    <strong className="fo16">{notification.id} - {text}</strong>
                    <span style={NotificationText}>
                      {text} será daqui a <b>{remainingDays} dias</b>, no dia
                      <b> {previousDate.toLocaleDateString()} </b>
                    </span>
                  </div>
                ),
              };

              const dateAssembleia = new Date(notification.tmsAssembleia);
              const dateParcela = new Date(notification.tmsParcela);

              const differenceInDaysAssembleia = numberOfDaysBetweenToday(dateAssembleia);
              const differenceInDaysParcela = numberOfDaysBetweenToday(dateParcela);

              const notificationAssembleia = notificationOptions[differenceInDaysAssembleia];
              const notificationParcela = notificationOptions[differenceInDaysParcela];

              if ((!notificationAssembleia && !notificationParcela && differenceInDaysParcela >= 0) || +notification.debit.replaceAll('.', '').replaceAll(',', '.') === 0) {
                return <div key={notification.id} />;
              }

              return (
                <a href={`#${notification.id}`} key={notification.id} className="100w black">
                  {notificationAssembleia && notificationAssembleia('Assembleia')}
                  {notificationParcela && notificationParcela('Vencimento da parcela')}
                  {differenceInDaysParcela < 0
                  && (
                    <div
                      key={notification.id}
                      className="Gray2 100w 10p 10r darken"
                      style={NotificationContent}
                    >
                      <strong className="fo16">{notification.id} - Parcela venceu</strong>
                      <span style={NotificationText}>O prazo do pagamento da parcela venceu!</span>
                    </div>
                  )}
                </a>
              );
            })}

          </div>
        </div>
      );
    }

    return (
      <button type="button" className="1p col center" onClick={() => setHasNotifications(!hasNotifications)} style={{ backgroundColor: 'transparent' }}>
        <IoMdNotificationsOutline className="col center" color="#fff" size={24} />
      </button>
    );
  }, [hasNotifications]);

  return (
    <header className="100w center row" style={Container}>
      <Menu isOpen={isOpenMenu} setIsOpen={setIsOpenMenu} />
      <FiMenu size={24} color="#fff" style={{ marginLeft: '24px', cursor: 'pointer' }} onClick={() => setIsOpenMenu(true)} />
      <div className="100w center row" style={Content}>
        <Link className="fo16" to="/wallet" style={ContainerLink}>
          <div className="blanc" style={ContentLink(url, '/wallet')}>
            Cotas
          </div>
        </Link>
        {/* <Link className="blanc fo16" style={ContainerLink} to="/dossie/pricing">
        <div className="blanc" style={ContentLink(url, '/dossie/pricing')}>
          Preços
        </div>
      </Link> */}
        <div style={ContainerOptions} className="center">
          <div>
            {notificationContainer}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
