import React, { useState } from 'react';

import FirebaseModal from 'modules/components/FirebaseModal';
// import { Container } from './styles';

interface ModalUploadDocumentsProps {
  id: number;
  handleOnUploadDocument(file: any): void;
}

const ModalUploadDocuments: React.FC<ModalUploadDocumentsProps> = ({
  id,
  handleOnUploadDocument,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  function handleOnCheck(file: any, allSelectedFiles: any): void {
    setSelectedFiles(allSelectedFiles);
  }

  function handleOnSubmit(): void {
    selectedFiles.forEach((file: any) => {
      handleOnUploadDocument(file);
    });
  }

  return (
    <div className="col" style={{ width: '100%', padding: '32px', overflow: 'auto' }}>
      <div className="col center 100w">
        <h1 className="fo20 blanc bold">Transferir arquivos</h1>
      </div>
      <div style={{ marginTop: '16px' }}>
        <FirebaseModal
          id={id}
          selectable
          defaultSelected={selectedFiles}
          onCheckFile={handleOnCheck}
        />
      </div>
      <button onClick={handleOnSubmit} className="blanc Blue 10p 10r 100w" type="button" style={{ marginTop: '16px' }}>Trasmitir arquivos</button>
    </div>
  );
};

export default ModalUploadDocuments;
