import React, {
  useRef, useCallback, MutableRefObject, FormEvent,
} from 'react';
import { IoMdLock } from 'react-icons/io';

import Input from '../../../../components/Input';

import { SignInFormProps } from './interface';

import { FormContainer } from './styles';

const Form: React.FC<SignInFormProps> = ({ fnOnSubmit }) => {
  const emailRef = useRef() as MutableRefObject<HTMLInputElement>;
  const passwordRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleOnSubmit = useCallback((e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const formData = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    fnOnSubmit(formData);
  }, [fnOnSubmit]);

  return (
    <form style={FormContainer} onSubmit={handleOnSubmit} className="col center 680y Gray2 20p 10r" method="post">
      <div className="10p row center">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '44px',
          height: '44px',
          backgroundColor: '#EEF1FF',
          borderRadius: '100%',
        }}
        >
          <IoMdLock color="#FF9632" size={20} />
        </div>
        <span
          style={{
            marginLeft: '12px',
            fontSize: '20px',
          }}
        >Login
        </span>
      </div>
      <Input reference={emailRef} placeholder="E-mail" type="email" name="email" required />
      <Input reference={passwordRef} placeholder="Senha" type="password" name="password" required />
      <div className="100w 10p">
        <button type="submit" className="100w Blue blanc 10r 10p fo16">Entrar</button>
      </div>
    </form>
  );
};

export default Form;
