import React from 'react';

import { TableStatusAttributes } from './interface';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
  TableBodyContent,
} from './styles';

const Table: React.FC<TableStatusAttributes> = ({ userLead }) => (
  <table
    style={TableContent}
    className="10r 100w"
  >
    <thead className="100w" style={TableHeadContainer}>
      <tr className="100w">
        <th style={TableHeadContent}>
          Status
        </th>
        <th style={TableHeadContent}>
          Data
        </th>
      </tr>
    </thead>
    <tbody style={TableBodyContainer}>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data origem
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsIn ? new Date(userLead.tmsIn).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data proposta
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsPricing ? new Date(userLead.tmsPricing).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data recusado
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsDeny ? new Date(userLead.tmsDeny).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data onboarding
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsOnboarding ? new Date(userLead.tmsOnboarding).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Última atualização (geral)
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsOut ? new Date(userLead.tmsOut).toLocaleDateString() : '-'}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Table;
