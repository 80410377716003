import React, { useMemo } from 'react';
import { IoIosDocument, IoIosCloudUpload } from 'react-icons/io';

import { DocumentAttributes } from './interface';

import {
  ContentDocuments,
  LinkDocument,
  LinkDocumentLabel,
  InputFile,
} from './styles';

const Documents: React.FC<DocumentAttributes> = ({ id, documents, onStorageFile }) => {
  const documentsComponent = useMemo(() => documents.map((document) => {
    if (document.hasDocument) {
      return (
        <a key={document.name} style={LinkDocument} className="darken" rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_FIREBASE_STORAGE_CRM_PATH_URL}${id}~2F${document.name}`}>
          <IoIosDocument size={38} color="#fff" />
          <span>{document.name}</span>
        </a>
      );
    }

    return (
      <label htmlFor={`${document.name}contractUpload`} key={document.name} style={LinkDocumentLabel} className="darken">
        <IoIosCloudUpload size={38} color="#fff" />
        <span>{document.name}</span>
        <input id={`${document.name}contractUpload`} onChange={(e) => onStorageFile(e.currentTarget.files, document.name)} type="file" style={InputFile} multiple />
      </label>
    );
  }), [id, documents, onStorageFile]);

  return <div className="100w" style={ContentDocuments}>{documentsComponent}</div>;
};

export default Documents;
