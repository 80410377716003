import React, { useEffect, useRef, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';

import { removeEmptyFields } from 'utils/functionalities';
import { Assignee } from '../../../../interface';
import { AssigneeProps } from './interface';

import Modal from '../../../../../../components/Modal';
import SignatoryComponent from './components/Signatory';

import {
  TableBodyContent,
  // TableInput,
  TableHeadContent,
} from '../../styles';

interface Signatory {
  email: string;
  contract: number;
  sign_as: string;
  sign_key?: string;
}

interface SignatoryAttributes extends HTMLInputElement {
  handleGetSignatoryValues(): Signatory;
}

const AssigneeComponent: React.FC<AssigneeProps> = (
  {
    assignee,
    onUpdateField,
    onDelete,
  },
) => {
  const [toggleTarget, setToggleTarget] = useState('');
  const [assigneeData, setAssigneeData] = useState(assignee as Assignee);
  const [isOpenSignatoryModal, setIsOpenSignatoryModal] = useState(false);

  // eslint-disable-next-line
  const [signatories, setSignatories] = useState(() => {
    if (assigneeData?.signatures) {
      return JSON.parse(assigneeData?.signatures);
    }

    return [];
  });

  const codigo_registradoraRef = useRef<HTMLInputElement>(null);
  const displayRef = useRef<HTMLInputElement>(null);
  const doc_cedenteRef = useRef<HTMLInputElement>(null);
  const doc_certificadoraRef = useRef<HTMLInputElement>(null);
  const doc_fundoRef = useRef<HTMLInputElement>(null);
  const doc_originadorRef = useRef<HTMLInputElement>(null);
  const nome_cedenteRef = useRef<HTMLInputElement>(null);
  const nome_certificadoraRef = useRef<HTMLInputElement>(null);
  const nome_fundoRef = useRef<HTMLInputElement>(null);
  const nome_originadorRef = useRef<HTMLInputElement>(null);
  const statusRef = useRef<HTMLInputElement>(null);
  const tipo_cedenteRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const preSessionRef = useRef<HTMLSelectElement>(null);
  const signatoriesRef = useRef<SignatoryAttributes[]>([]);

  const handleOnFocus = (target: any): void => {
    setToggleTarget(target);
  };

  const handleOnBlur = async (e: any, target: any): Promise<void> => {
    setToggleTarget('');

    const newAssignee = {
      codigo_registradora: assigneeData.codigo_registradora,
      display: assigneeData.display,
      doc_certificadora: assigneeData.doc_certificadora,
      doc_fundo: assigneeData.doc_fundo,
      id: assigneeData?.id,
      nome_certificadora: assigneeData.nome_certificadora,
      nome_fundo: assigneeData.nome_fundo,
      email: assigneeData.email,
      password: assigneeData.password,
      enable: 1,
      pre_cessao: +assigneeData.pre_cessao,
      signatures: assigneeData.signatures,
      [target]: target === 'pre_cessao' ? +e.currentTarget.value : e.currentTarget.value,
    };

    setAssigneeData(newAssignee);
    await onUpdateField(newAssignee);
  };

  useEffect(() => {
    if (toggleTarget) {
      codigo_registradoraRef.current?.focus();
      displayRef.current?.focus();
      doc_cedenteRef.current?.focus();
      doc_certificadoraRef.current?.focus();
      doc_fundoRef.current?.focus();
      doc_originadorRef.current?.focus();
      nome_cedenteRef.current?.focus();
      nome_certificadoraRef.current?.focus();
      nome_fundoRef.current?.focus();
      nome_originadorRef.current?.focus();
      statusRef.current?.focus();
      tipo_cedenteRef.current?.focus();
      preSessionRef.current?.focus();
      emailRef.current?.focus();
      passwordRef.current?.focus();
    }
  }, [toggleTarget]);

  const handleOnDelete = (): void => {
    if (assigneeData?.id) {
      onDelete(assigneeData);
    } else {
      alert('Erro: id não encontrado');
    }
  };

  const handleOnOpenSignatoryModal = (): any => {
    setIsOpenSignatoryModal((isOpen) => !isOpen);
  };

  const handleOnUpdateSignatory = async (): Promise<any> => {
    try {
      const signatoriesUpdated = signatoriesRef.current?.reduce(
        (curr, prev): any => [...curr, prev.handleGetSignatoryValues()], [],
      );

      setIsOpenSignatoryModal(false);

      const assigneeWithoutNullableFields = removeEmptyFields({
        ...assigneeData,
      });

      await onUpdateField({
        ...assigneeWithoutNullableFields,
        signatures: JSON.stringify(signatoriesUpdated) as any,
      });

      setSignatories(signatoriesUpdated);
      // alert('Atualziado com sucesso!');
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateNewSignatory = (): void => {
    const newSignatories = [...signatories, { email: '', contract: 0, sign_as: '' }];

    setSignatories([...signatories, newSignatories]);
  };

  return (
    <>
      <Modal isOpen={isOpenSignatoryModal} setIsOpen={setIsOpenSignatoryModal}>
        <div className="100w">
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span className="fo16" style={{ fontWeight: 'bold' }}>Signatários</span>
          </div>
          {signatories?.map((signatory: any, index: number) => (
            <SignatoryComponent
              key={signatory.email}
              ref={(el: SignatoryAttributes) => signatoriesRef.current[index] = el}
              signatory={signatory}
            />
          ))}
          <div className="row center">
            <button className="100w blanc Green 10p 10r" type="button" onClick={handleOnUpdateSignatory} style={{ marginTop: '16px' }}>Salvar</button>
            <button className="100w blanc Blue 10p 10r" type="button" onClick={handleCreateNewSignatory} style={{ marginTop: '16px', marginLeft: '8px' }}>Criar novo signatário</button>
          </div>
        </div>
      </Modal>
      <tr
        className="darken 100w"
        style={TableBodyContent}
        key={assigneeData.display}
      >
        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('display')}
        >
          <input
            ref={displayRef}
            hidden={!(toggleTarget === 'display')}
            type="text"
            defaultValue={assigneeData.display}
            onBlur={(e) => handleOnBlur(e, 'display')}
          />
          <span hidden={toggleTarget === 'display'}>{assigneeData.display}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('codigo_registradora')}
        >
          <input
            ref={codigo_registradoraRef}
            hidden={!(toggleTarget === 'codigo_registradora')}
            type="text"
            defaultValue={assigneeData.codigo_registradora}
            onBlur={(e) => handleOnBlur(e, 'codigo_registradora')}
          />
          <span hidden={toggleTarget === 'codigo_registradora'}>{assigneeData.codigo_registradora}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('doc_certificadora')}
        >
          <input
            ref={doc_certificadoraRef}
            hidden={!(toggleTarget === 'doc_certificadora')}
            type="text"
            defaultValue={assigneeData.doc_certificadora}
            onBlur={(e) => handleOnBlur(e, 'doc_certificadora')}
          />
          <span hidden={toggleTarget === 'doc_certificadora'}>{assigneeData.doc_certificadora}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('doc_fundo')}
        >
          <input
            ref={doc_fundoRef}
            hidden={!(toggleTarget === 'doc_fundo')}
            type="text"
            defaultValue={assigneeData.doc_fundo}
            onBlur={(e) => handleOnBlur(e, 'doc_fundo')}
          />
          <span hidden={toggleTarget === 'doc_fundo'}>{assigneeData.doc_fundo}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('nome_certificadora')}
        >
          <input
            ref={nome_certificadoraRef}
            hidden={!(toggleTarget === 'nome_certificadora')}
            type="text"
            defaultValue={assigneeData.nome_certificadora}
            onBlur={(e) => handleOnBlur(e, 'nome_certificadora')}
          />
          <span hidden={toggleTarget === 'nome_certificadora'}>{assigneeData.nome_certificadora}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('nome_fundo')}
        >
          <input
            ref={nome_fundoRef}
            hidden={!(toggleTarget === 'nome_fundo')}
            type="text"
            defaultValue={assigneeData.nome_fundo}
            onBlur={(e) => handleOnBlur(e, 'nome_fundo')}
          />
          <span hidden={toggleTarget === 'nome_fundo'}>{assigneeData.nome_fundo}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('pre_cessao')}
        >
          <select
            ref={preSessionRef}
            hidden={!(toggleTarget === 'pre_cessao')}
            onBlur={(e) => handleOnBlur(e, 'pre_cessao')}
            defaultValue={assigneeData.pre_cessao}
          >
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
          <span hidden={toggleTarget === 'pre_cessao'}>{assigneeData.pre_cessao === 1 ? 'Sim' : 'Não'}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('email')}
        >
          <input
            ref={emailRef}
            hidden={!(toggleTarget === 'email')}
            type="text"
            defaultValue={assigneeData.email}
            onBlur={(e) => handleOnBlur(e, 'email')}
          />
          <span hidden={toggleTarget === 'email'}>{assigneeData.email}</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={() => handleOnFocus('password')}
        >
          <input
            ref={passwordRef}
            hidden={!(toggleTarget === 'password')}
            type="password"
            onBlur={(e) => handleOnBlur(e, 'password')}
          />
          <span hidden={toggleTarget === 'password'}>*******</span>
        </td>

        <td
          style={TableHeadContent}
          onDoubleClick={handleOnOpenSignatoryModal}
        >
          {signatories?.map((signature: any) => (
            <p>{signature.email}</p>
          ))}
        </td>

        <td
          style={TableHeadContent}
        >
          <button className="5p Tomato 10r center col" type="button" onClick={handleOnDelete}>
            <AiFillDelete size={20} color="white" />
          </button>
        </td>
      </tr>
    </>
  );
};

export default AssigneeComponent;
