const Container = {
  minHeight: '100vh',
  height: '100%',
  backgroundColor: '#E7E9ED',
  padding: '20px 24px',
};

const Content = {
  padding: '40px 60px',
  backgroundColor: '#FFF',
};

const ContentTitle = {
};

const ContainerButtons = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between' as const,
  flexWrap: 'wrap' as const,
};

const ContainerLinks = {
  padding: '8px',
  maxWidth: '360px',
  width: '100%',
  margin: '8px',
};

export {
  Container,
  Content,
  ContentTitle,
  ContainerButtons,
  ContainerLinks,
};
