import React, { useState } from 'react';
import { IoMdMail } from 'react-icons/io';

import { useAuth } from '../../../../../../hooks/auth';

import { storage } from '../../../../../../services/firebase';
import apiFunctions from '../../../../../../services/apiFunctions';
import api from '../../../../../../services/api';

import { FilterData, Message, UserLead } from './interface';

import TableFilteredLeads from './components/TableFilteredLeads';
import Filter from './components/Filter';
import Form from './components/Form';
import Modal from '../../../Modal';

import {
  Container,
  ButtonContainer,
} from './styles';

const MultiNotification: React.FC = () => {
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleCreateMessage, setToggleCreateMessage] = useState(false);
  const [checkedAddLeads, setCheckedAddLeads] = useState([] as any);
  const [checkedRemoveLeads, setCheckedRemoveLeads] = useState([] as any);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [preparedLeads, setPreparedLeads] = useState([]);

  const { token } = useAuth();

  async function handleOnFilter(filterData: FilterData): Promise<void> {
    const {
      extract, initialDate, lastDate, ...filter
    } = filterData;

    const { data } = await api.post('/output/atendimento', {
      agent: token,
      ...filter,
    });

    const storageRef = storage.ref(process.env.REACT_APP_FIREBASE_STORAGE_PREFIX);
    const storageList = await storageRef.listAll();
    const userIds = storageList.prefixes.map((userId) => +userId.name);

    data.data.forEach((user: any) => {
      const hasExtract = userIds.find((userId) => userId === user.id);

      if (hasExtract) {
        user.extract = 1;
      } else {
        user.extract = 0;
      }
    });

    let filteredUserLeads = data.data.filter((item: any) => !!item.extract === extract);

    if (initialDate && lastDate) {
      filteredUserLeads = filteredUserLeads.filter((item: any) => (
        item.tmsIn >= initialDate
          && item.tmsIn <= lastDate
      ));
    }

    let newFilteredLeads = [...filteredUserLeads] as any;

    preparedLeads.forEach((preparedLead: any) => {
      newFilteredLeads = newFilteredLeads.filter((lead: any) => lead.id !== preparedLead.id);
    });

    setFilteredLeads(newFilteredLeads);
  }

  async function handleOnSendMessage(message: Message): Promise<void> {
    const leads = [] as any;

    setToggleCreateMessage(false);

    preparedLeads.forEach((lead: any) => {
      leads.push({
        name: lead.display,
        phone: lead.phone,
        email: lead.email,
        administrator: lead.administrator,
        pushEmail: lead.pushEmail,
        pushWhatsapp: lead.pushWhatsapp,
      });
    });

    await apiFunctions.post('/messenger/env/send', {
      type: message.option,
      message: message.message,
      subject: message.subject,
      to: leads,
    });
  }

  function handleOnToggle(): void {
    setToggleModal(!toggleModal);
  }

  function handleOnAddLeadCheck(lead: UserLead, e: any): void {
    if (e.checked) {
      const leadChecked = checkedAddLeads.find((checkedLead: any) => checkedLead.id === lead.id);

      if (!leadChecked) {
        setCheckedAddLeads((oldLeads: any) => [...oldLeads, lead]);
      }
    } else {
      setCheckedAddLeads((oldLeads: any) => oldLeads?.filter(
        (checkedLead: any) => checkedLead.id !== lead.id,
      ));
    }
  }

  function handleOnRemoveLeadCheck(lead: UserLead, e: any): void {
    if (e.checked) {
      const leadChecked = checkedRemoveLeads.find((checkedLead: any) => checkedLead.id === lead.id);

      if (!leadChecked) {
        setCheckedRemoveLeads((oldLeads: any) => [...oldLeads, lead]);
      }
    } else {
      setCheckedRemoveLeads((oldLeads: any) => oldLeads?.filter(
        (checkedLead: any) => checkedLead.id !== lead.id,
      ));
    }
  }

  function handleOnAddLead(): void {
    let newFilteredLeads = [...filteredLeads] as any;

    checkedAddLeads.forEach((checkedLead: any) => {
      newFilteredLeads = newFilteredLeads.filter((lead: any) => lead.id !== checkedLead.id);
    });

    setFilteredLeads(newFilteredLeads);
    setPreparedLeads((oldLeads) => [...oldLeads, ...checkedAddLeads] as any);
    setCheckedAddLeads([]);
  }

  function handleOnRemoveLead(): void {
    let newPreparedLeads = [...preparedLeads] as any;

    checkedRemoveLeads.forEach((checkedLead: any) => {
      newPreparedLeads = newPreparedLeads.filter((lead: any) => lead.id !== checkedLead.id);
    });

    setFilteredLeads((oldLeads) => [...oldLeads, ...checkedRemoveLeads] as any);
    setPreparedLeads(newPreparedLeads);
    setCheckedRemoveLeads([]);
  }

  function handleOnChangeScreen(): void {
    setToggleCreateMessage(true);
    setToggleModal(false);
  }

  return (
    <div style={Container}>
      <Modal isOpen={toggleModal} setIsOpen={setToggleModal} fullSize>
        <div className="100w" style={{ overflow: 'auto', maxHeight: '500px' }}>
          <div className="20p">
            <div className="100w col center">
              <h2>Filtro dos destinatários</h2>
            </div>
            <Filter onFilter={handleOnFilter} />
            {
              !!filteredLeads?.length && (
                <>
                  <div className="100w 10p">
                    <b>Filtros:</b>
                  </div>
                  <div className="100w" style={{ overflow: 'auto', maxHeight: '240px', backgroundColor: 'transparent' }}>
                    <TableFilteredLeads
                      userLeads={filteredLeads}
                      checkedLeads={checkedAddLeads}
                      handleOnCheck={handleOnAddLeadCheck}
                    />
                  </div>
                  <div className="100w 10p">
                    <button className="100w 10p 10r Green blanc" type="button" onClick={handleOnAddLead}>Adicionar destinatários</button>
                  </div>
                </>
              )
            }

            {
              !!preparedLeads?.length && (
                <>
                  <div className="100w 10p">
                    <b>Destinatários:</b>
                  </div>
                  <div className="100w" style={{ overflow: 'auto', maxHeight: '240px', backgroundColor: 'transparent' }}>
                    <TableFilteredLeads
                      userLeads={preparedLeads}
                      checkedLeads={checkedRemoveLeads}
                      handleOnCheck={handleOnRemoveLeadCheck}
                    />
                  </div>
                  <div className="100w 10p">
                    <button className="100w 10p 10r Tomato blanc" type="submit" onClick={handleOnRemoveLead}>Remover destinatários</button>
                  </div>
                </>
              )
            }
            {
              !!preparedLeads.length && (
                <div className="100w 10p">
                  <button onClick={handleOnChangeScreen} className="100w 10p 10r Blue blanc" type="submit">Criar mensagem</button>
                </div>
              )
            }
          </div>
        </div>
      </Modal>
      <Modal isOpen={toggleCreateMessage} setIsOpen={setToggleCreateMessage} fullSize>
        <Form onSubmit={handleOnSendMessage} />
      </Modal>
      <button className="Blue2 blanc darken" onClick={handleOnToggle} type="button" style={ButtonContainer}>
        <IoMdMail size={24} />
      </button>
    </div>
  );
};

export default MultiNotification;
