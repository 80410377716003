export function dateBrToDefaultString(dateBrl: string): string {
  const [day, month, year] = dateBrl.split('/');

  if (!day || !month || !year) {
    return dateBrl;
  }

  const newDate = `${month.padStart(2, '0')}/${day.padStart(2, '0')}/${year}`;

  return newDate;
}

export function numberOfDaysBetweenToday(date: Date): number {
  const today = new Date();

  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);

  const differenceInTime = date.getTime() - today.getTime();
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
}

export function numberOfDaysBetweenDates(firstDate: Date, date: Date): number {
  const differenceInTime = date.getTime() - firstDate.getTime();
  const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
}

export function numberDiffOfMonth(d1: Date, d2: Date): number {
  let months;
  months = (d1.getFullYear() - d2.getFullYear()) * 12;
  months += d1.getMonth();
  months -= d2.getMonth();

  return months;
}

export function convertBRLToNumbers(brl: string): number {
  if (!brl) {
    return 0;
  }

  const n1 = brl.replaceAll('.', '');
  const n2 = n1.replaceAll(',', '.');

  return +n2;
}

export function removeAllCommas(number: string): string {
  if (!number) {
    return '0';
  }

  return number?.replaceAll(/[^\d]/g, '');
}

export function tmsToCnabDate(tms: number): string {
  const localeDate = new Date(tms).toLocaleDateString();
  const [day, month, year] = localeDate.split('/');

  if (!day || !month || !year) {
    return 'TMS inválido';
  }

  return `${day.padStart(2, '0')}${month.padStart(2, '0')}${year.slice(-2)}`;
}
