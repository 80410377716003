import React, { useMemo } from 'react';

import CommissionComponent from './components/CommissionComponent';

import { TableCommissionAttributes } from './interface';
import { Commission } from '../../interface';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
} from './styles';

const TableCommissions: React.FC<TableCommissionAttributes> = ({
  onUpdateField,
  commissions,
  assignees,
  onDelete,
}) => {
  const commissionComponent = useMemo(() => commissions.map(
    (commission: Commission) => (
      <CommissionComponent
        onUpdateField={onUpdateField}
        key={commission.id}
        commission={commission}
        assignees={assignees}
        onDelete={onDelete}
      />
    ),
  ), [commissions, assignees, onUpdateField, onDelete]);

  return (
    <div className="100w 10r" style={{ overflow: 'auto', height: '580px' }}>
      <table
        style={TableContent}
        className="10r 100w"
      >
        <thead className="100w" style={TableHeadContainer}>
          <tr className="100w">
            <th style={TableHeadContent}>
              Nome
            </th>
            <th style={TableHeadContent}>
              Comissão
            </th>
            <th style={TableHeadContent}>
              Cessionário
            </th>
            <th style={TableHeadContent}>
              Estratégia
            </th>
            <th style={TableHeadContent}>
              Ação
            </th>
          </tr>
        </thead>
        <tbody style={TableBodyContainer}>
          {commissionComponent}
        </tbody>
      </table>
    </div>
  );
};

export default TableCommissions;
