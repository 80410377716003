import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';

import { IoIosEye } from 'react-icons/io';
import { phoneMaskByValue } from '../../../../../../utils/masks';

import Filter from '../../../../components/Filter';

import { ListUsersAttributes } from './interface';

import { XCross } from './styles';

const ListUsers: React.FC<ListUsersAttributes> = ({
  listUsers,
  initialLeads,
  handleOnFilter,
  handleOnDelete,
  handleOnUpdate,
}) => {
  const tableColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
    },
    {
      field: 'display',
      headerName: 'Nome',
      width: 200,
      editable: true,
    },
    {
      field: 'phone',
      headerName: 'Telefone',
      width: 150,
      editable: true,
      valueFormatter: ({ value }) => phoneMaskByValue(value),
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 220,
      editable: true,
    },
    {
      field: 'extract',
      headerName: 'Extrato',
      width: 135,
      renderCell: (cell) => <span>{cell.value ? 'Sim' : 'Não'}</span>,
    },
    {
      field: 'action',
      headerName: 'Ação',
      width: 150,
      renderCell: (cell) => {
        const lead: any = cell.row;

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a style={{ display: 'flex' }} href={`/crm/extract/${lead.id}`} target="_blank" rel="noreferrer">
              <button className="1p 5r" style={{ ...XCross, backgroundColor: 'green', padding: '4px' }} type="button">
                <IoIosEye className="col center" size={16} />
              </button>
            </a>
            <button className="1p 5r" style={{ ...XCross, marginLeft: '8px' }} onClick={() => handleOnDelete(lead)} type="button">
              <FaTimes className="col center" size={12} />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="100w" style={{ maxWidth: '960px', marginTop: '16px' }}>
      <Filter onFilter={handleOnFilter} initialLeads={initialLeads} />
      <div style={{ marginTop: '16px' }}>
        <DataGridPro
          checkboxSelection={false}
          rows={listUsers}
          columns={tableColumns}
          rowHeight={38}
          autoPageSize
          pagination
          autoHeight
          pageSize={20}
          isCellEditable={() => true}
          onCellEditCommit={handleOnUpdate}
          disableSelectionOnClick
        />
      </div>
    </div>
  );
};
export default ListUsers;
