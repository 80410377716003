const Container = {
  height: '80px',
  justifyContent: 'space-evenly',
  backgroundColor: '#50A8FF',
  zIndex: 999,
};

const ContainerLink = {
  display: 'flex',
  height: '100%',
  width: '120px',
};

const ContentLink = (url: string, path: string): any => {
  let styles = {};

  if (url === path) {
    styles = {
      borderBottom: '3px solid #fff',
      fontWeight: 'bold' as const,
    };
  }

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    ...styles,
  };
};

const Content = {
  height: '100%',
  justifyContent: 'space-evenly',
};

const NotificationContent = {
  cursor: 'pointer',
  display: 'flex',
  marginTop: '12px',
  flexDirection: 'column' as const,
  justifyContent: 'center',
};

const NotificationTitle = {
  cursor: 'pointer',
  display: 'flex',
  marginTop: '12px',
  flexDirection: 'column' as const,
  justifyContent: 'center',
};

const NotificationText = {
  marginTop: '8px',
  color: '#3b3b3b',
};

const ContainerOptions = {
  display: 'flex',
  flexDirection: 'row' as const,
};

export {
  Container,
  ContainerLink,
  ContentLink,
  Content,
  NotificationContent,
  NotificationTitle,
  NotificationText,
  ContainerOptions,
};
