const BackgroundContainer = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#00000080',
};

const Container = {
  top: 0,
  left: 0,
  position: 'fixed' as const,
  display: 'flex',
  alignItems: 'center' as const,
  justifyContent: 'center' as const,
  height: '100%',
  width: '100%',
  zIndex: 9999,
};

const Content = (fullSize: boolean): any => {
  let maxWidth = '400px';

  if (fullSize) {
    maxWidth = '1200px';
  }

  return ({
    display: 'flex',
    alignItems: 'center' as const,
    justifyContent: 'center' as const,
    flexDirection: 'column' as const,
    position: 'fixed' as const,
    width: '100%',
    minHeight: '200px',
    backgroundColor: '#f0f0f0',
    borderRadius: '16px',
    padding: '32px',
    maxWidth,
  });
};

export {
  BackgroundContainer,
  Container,
  Content,
};
