import React, { useMemo } from 'react';

import Lead from './components/Lead';

import { ListUsersAttributes } from './interface';

const ListUsers: React.FC<ListUsersAttributes> = ({
  listUsers,
  handleOnAccept,
  handleOnRefused,
  handleMoveSelect,
  handleOnDelete,
  assignees,
}) => {
  const listUsersComponent = useMemo(() => {
    if (!listUsers.length) {
      return <div className="20p">Tudo em ordem!</div>;
    }

    return (
      listUsers.map((userLead) => (
        <Lead
          userLead={userLead}
          key={userLead.id}
          handleOnAccept={handleOnAccept}
          handleOnRefused={handleOnRefused}
          handleMoveSelect={handleMoveSelect}
          handleOnDelete={handleOnDelete}
          assignees={assignees}
        />
      ))
    );
  }, [listUsers, handleOnAccept]);

  return <div className="100w col center">{listUsersComponent}</div>;
};

export default ListUsers;
