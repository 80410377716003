import React from 'react';

import { InputAttributes } from './interface';

import { InputStyle } from './styles';

const Input: React.FC<InputAttributes> = ({
  reference, labelName, ...rest
}) => (
  <div className="10p 100w">
    {labelName && <label htmlFor={rest.name}>{labelName}:</label>}
    <input className="100w 10p 10r" style={InputStyle} ref={reference} {...rest} />
  </div>
);

export default Input;
