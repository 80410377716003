import React, {
  createContext, useContext, useState, useEffect,
} from 'react';

import { SendNotificationParams, NotificationContextData, NotificationData } from './interface';

import { numberOfDaysBetweenToday } from '../../utils/converts';

import api from '../../services/api';

import { useAuth } from '../auth';

const NotificationContext = createContext<NotificationContextData>({} as NotificationContextData);

const NotificationProvider:React.FC = ({ children }) => {
  const { token } = useAuth();
  const [notifications, setNotifications] = useState(() => {
    const notificationStorage = localStorage.getItem('@Contemplato/notifications');

    if (notificationStorage) {
      return JSON.parse(notificationStorage) as NotificationData[];
    }

    return [] as NotificationData[];
  });

  useEffect(() => {
    const requestNotifications = async (): Promise<void> => {
      const { data } = await api.post('/output/gestao', {
        agent: token,
      });

      if (data.status && token) {
        const today = new Date();
        const lastNotificationDateStorage = localStorage.getItem('@Contemplato/lastNotificationDate');

        const filteredNotifications = data?.data?.filter(
          (lead: any) => lead?.status !== 340 && lead?.status !== 320 && lead?.status !== 350 && +lead?.debit?.replaceAll('.', '')?.replaceAll(',', '.') > 0,
        );

        setNotifications(filteredNotifications);
        localStorage.setItem('@Contemplato/notifications', JSON.stringify(filteredNotifications));

        if (!lastNotificationDateStorage) {
          verifyAndSendNotifications(filteredNotifications);
          localStorage.setItem('@Contemplato/lastNotificationDate', JSON.stringify(today));
        } else {
          const lastNotificationDate = new Date(JSON.parse(lastNotificationDateStorage));

          if (today.toLocaleDateString() !== lastNotificationDate.toLocaleDateString()) {
            verifyAndSendNotifications(filteredNotifications);
            localStorage.setItem('@Contemplato/lastNotificationDate', JSON.stringify(today));
          }
        }
      }
    };

    if (!token) {
      localStorage.removeItem('@Contemplato/notifications');
    }

    const verifyAndSendNotifications = (newNotifications: NotificationData[]): void => {
      const {
        notificationsAssembleiaLength,
        notificationsParcelaLength,
      } = newNotifications.reduce((previous: any, curr: NotificationData) => {
        const dateParcela = new Date(curr.tmsParcela);
        const dateAssembleia = new Date(curr.tmsAssembleia);

        const differenceInDaysParcela = numberOfDaysBetweenToday(dateParcela);
        const differenceInDaysAssembleia = numberOfDaysBetweenToday(dateAssembleia);

        if (differenceInDaysAssembleia === 5 || differenceInDaysAssembleia === 0) {
          previous.notificationsAssembleiaLength++;
          return previous;
        }

        if (differenceInDaysParcela === 5 || differenceInDaysParcela <= 0) {
          previous.notificationsParcelaLength++;
          return previous;
        }

        return previous;
      }, {
        notificationsAssembleiaLength: 0,
        notificationsParcelaLength: 0,
      });

      if (notificationsAssembleiaLength) {
        sendNotification({
          title: `Você tem ${notificationsAssembleiaLength} notificações sobre a Assembleia!`,
          body: 'Vá no gerenciamento de carteira para ver todas as notificações.',
        });
      }

      if (notificationsParcelaLength) {
        sendNotification({
          title: `Você tem ${notificationsParcelaLength} notificações sobre os Vencimentos das parcelas!`,
          body: 'Vá no gerenciamento de carteira para ver todas as notificações.',
        });
      }
    };

    if (token) {
      requestNotifications();
    }
  }, [token]);

  return (
    <NotificationContext.Provider value={{ notifications }}>
      {children}
    </NotificationContext.Provider>
  );
};

function useNotifications(): NotificationContextData {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error('useNotification must be used within an NotificationProvider');
  }

  return context;
}

function sendNotification({ title, ...rest }: SendNotificationParams): void {
  if (!window.Notification) {
    alert('Desculpe, o seu navegador não suporta as notificações.');
  }

  if (Notification.permission === 'default') {
    alert('Por favor habilite as notificações do navegador antes de usar o serviço.');
  } else {
    // eslint-disable-next-line
    const notify = new Notification(title, {
      ...rest,
    });
  }
}

export { useNotifications, sendNotification, NotificationProvider };
