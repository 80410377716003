import React from 'react';

import { TableStatusAttributes } from './interface';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
  TableBodyContent,
} from './styles';

const Table: React.FC<TableStatusAttributes> = ({ userLead }) => (
  <table
    style={TableContent}
    className="10r 100w"
  >
    <thead className="100w" style={TableHeadContainer}>
      <tr className="100w">
        <th style={TableHeadContent}>
          Status
        </th>
        <th style={TableHeadContent}>
          Data
        </th>
      </tr>
    </thead>
    <tbody style={TableBodyContainer}>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data origem
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsIn ? new Date(userLead.tmsIn).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data proposta
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsPricing ? new Date(userLead.tmsPricing).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data recusado
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsDeny ? new Date(userLead.tmsDeny).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Data onboarding
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsOnboarding ? new Date(userLead.tmsOnboarding).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Última atualização (geral)
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsOut ? new Date(userLead.tmsOut).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Contrato
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsContract ? new Date(userLead.tmsContract).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Solicitação de transferência
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsTransfer ? new Date(userLead.tmsTransfer).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Cadastro na administradora
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsAdmin ? new Date(userLead.tmsAdmin).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Envio de documentos
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsSendDoc ? new Date(userLead.tmsSendDoc).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Termo de cessão
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsTerm ? new Date(userLead.tmsTerm).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Aguardando transferência
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsWaitTransfer ? new Date(userLead.tmsWaitTransfer).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Desistente
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsDieTransfer ? new Date(userLead.tmsDieTransfer).toLocaleDateString() : '-'}
        </td>
      </tr>
      <tr
        className="darken 100w"
        style={TableBodyContent}
      >
        <td style={TableHeadContent}>
          Finalizado
        </td>
        <td style={TableHeadContent}>
          {userLead.tmsFinished ? new Date(userLead.tmsFinished).toLocaleDateString() : '-'}
        </td>
      </tr>
    </tbody>
  </table>
);

export default Table;
