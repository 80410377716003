const BackgroundContainer = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#00000080',
  zIndex: 99998,
};

const Container = {
  top: 0,
  left: 0,
  position: 'fixed' as const,
  display: 'flex',
  alignItems: 'center' as const,
  justifyContent: 'center' as const,
  height: '100%',
  width: '100%',
  zIndex: 99999,
};

const Content = {
  display: 'flex',
  alignItems: 'center' as const,
  flexDirection: 'column' as const,
  position: 'fixed' as const,
  maxWidth: '800px',
  width: '100%',
  minHeight: '200px',
  maxHeight: '500px',
  overflow: 'auto',
  backgroundColor: '#f0f0f0',
  borderRadius: '16px',
  padding: '32px',
  zIndex: 99999,
};

const ButtonModalContainer = {
  height: '36px',
};

export {
  BackgroundContainer,
  Container,
  Content,
  ButtonModalContainer,
};
