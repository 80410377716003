import React, { useRef } from 'react';

import { CNPJMaskByElement } from '../../../../../../utils/masks';

import Input from '../../../../components/Input';

import { ModalAdministratorAttributes } from './interface';

import {
  BackgroundContainer,
  Container,
  Content,
  ButtonModalContainer,
} from './styles';

const Modal: React.FC<ModalAdministratorAttributes> = ({ isOpen, setIsOpen, onSubmit }) => {
  const partnershipRef = useRef<HTMLSelectElement>(null);
  const displayRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const socialRef = useRef<HTMLInputElement>(null);
  const classRef = useRef<HTMLInputElement>(null);
  const CNPJRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);

  const handleOnSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    const displayElement = displayRef.current;
    const addressElement = addressRef.current;
    const socialElement = socialRef.current;
    const classElement = classRef.current;
    const CNPJElement = CNPJRef.current;
    const urlElement = urlRef.current;
    const partnershipElement = partnershipRef.current;

    if (!displayElement?.value
      || !addressElement?.value
      || !classElement?.value
      || !CNPJElement?.value
      || !socialElement?.value
      || !partnershipElement?.value
      || !urlElement?.value) {
      throw alert('Campos vazios, por favor, preencha todos os campos corretamente');
    }

    await onSubmit({
      display: displayElement?.value,
      address: addressElement?.value,
      class: classElement?.value,
      cnpj: CNPJElement?.value,
      url: urlElement?.value,
      social: socialElement?.value,
      launch: 50,
      liquidityMonth: 2,
      remin: 20,
      remax: 25,
      partnership: +partnershipElement?.value,
    });

    setIsOpen(false);
  };

  return (
    isOpen ? (
      <div style={Container}>
        <div
          style={BackgroundContainer}
          onClick={() => setIsOpen(!isOpen)}
        />
        <div style={Content}>
          <h4>Cadastro da administradora</h4>
          <form onSubmit={handleOnSubmit} className="100w col">
            <div className="row 100w">
              <Input reference={displayRef} name="display" labelName="Nome" />
              <Input reference={socialRef} name="social" labelName="Social" />
            </div>
            <div className="row 100w">
              <Input reference={CNPJRef} name="cnpj" labelName="CNPJ" onKeyDown={CNPJMaskByElement} maxLength={18} />
              <Input reference={addressRef} name="address" labelName="Endereço" />
            </div>
            <div className="row 100w">
              <Input reference={classRef} name="class" labelName="Classe" maxLength={2} />
              <Input reference={urlRef} name="url" labelName="URL" />
            </div>
            <div className="col 10p">
              <span>Conveniado:</span>
              <select className="10p 10r" defaultValue={0} ref={partnershipRef}>
                <option value={1}>Sim</option>
                <option value={0}>Não</option>
              </select>
            </div>
            <div className="10p">
              <button className="1p 10r 100w Green blanc" type="submit" style={ButtonModalContainer}>Cadastrar</button>
            </div>
          </form>
        </div>
      </div>
    ) : <div />
  );
};
export default Modal;
