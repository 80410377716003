const Content = {
  marginTop: '16px',
};

const ContainerLead = {
  padding: '18px 0 18px 36px',
  borderRadius: '16px 0 0 16px',
  justifyContent: 'space-between',
};

const SpanStyle = {
  margin: '8px 0',
};

const ExtractStatusContainer = {
  position: 'relative' as const,
  width: '60px',
  flex: '0, 1',
  zIndex: 10,
  borderRadius: '0 16px 16px 0',
};

const SpanHeader = {
  paddingRight: '32px',
  alignItems: 'center',
  justifyContent: 'space-between' as const,
};

const XCross = {
  position: 'absolute' as const,
  top: -10,
  right: -10,
  color: '#fff',
  backgroundColor: '#630c0a',
  padding: '6px',
};

const ContainerSelectStatus = {
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'flex-end',
  justifyContent: 'flex-end',
  flex: 1,
};

const SpanHeaderStatus = (status: number): any => {
  let styleStatus = '';

  switch (true) {
    case status === 131:
      styleStatus = '#000';
      break;
    case status === 134:
      styleStatus = '#ef5350';
      break;
    default:
      styleStatus = '#000';
  }

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '8px',
    maxWidth: '180px',
    height: '36px',
    fontWeight: 'bold' as const,
    backgroundColor: styleStatus,
    color: '#fff',
  };
};

export {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
  SpanHeaderStatus,
  XCross,
  SpanHeader,
  ContainerSelectStatus,
};
