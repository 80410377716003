import React from 'react';
import { IoLogoWhatsapp, IoMailOutline } from 'react-icons/io5';

import { LeadProps } from './interface';

import { TableBodyContent, TableHeadContent } from '../../styles';

const Lead: React.FC<LeadProps> = ({
  lead, index, checkboxRef, handleOnCheck, checkedLeads,
}) => {
  const isChecked = checkedLeads.find((checkedLead) => checkedLead.id === lead.id);

  return (
    <tr
      key={lead.id}
      className="darken 100w"
      style={TableBodyContent}
    >
      <td style={TableHeadContent}>
        <input defaultChecked={!!isChecked} ref={(el) => checkboxRef.current[index] = el} onChange={() => handleOnCheck(lead, checkboxRef.current[index])} type="checkbox" name="foo" />
      </td>
      <td style={TableHeadContent}>
        {lead.id}
      </td>
      <td style={TableHeadContent}>
        {lead.display}
      </td>
      <td style={TableHeadContent}>
        {lead.email}
      </td>
      <td style={TableHeadContent}>
        {lead.phone}
      </td>
      <td
        style={{
          display: 'flex', ...TableHeadContent,
        }}
      >
        {lead.pushEmail && <IoMailOutline size={20} />}
        {lead.pushWhatsapp && <IoLogoWhatsapp size={20} style={{ marginLeft: '12px' }} />}
      </td>
    </tr>
  );
};

export default Lead;
