import React from 'react';
import { BiTransfer } from 'react-icons/bi';
import { SiGoogleanalytics } from 'react-icons/si';
import { FaWallet, FaCompressAlt } from 'react-icons/fa';
import { HiOfficeBuilding } from 'react-icons/hi';
import { RiWifiOffFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import { IoMdSettings } from 'react-icons/io';
import {
  Container,
  ContainerButtons,
  ContainerLinks,
  Content,
  ContentTitle,
} from './styles';

const Selection: React.FC = () => (
  <div style={Container} className="row center 100w">
    <div className="col 20r" style={Content}>
      <h2 style={ContentTitle}>Escolha onde deseja ir:</h2>
      <div className="100w" style={ContainerButtons}>
        <Link to="/crm" style={ContainerLinks}>
          <div
            className="100w 10r hover-selection"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '180px',
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '100%',
              height: '40px',
              width: '40px',
            }}
            >
              <HiOfficeBuilding size={20} />
            </div>
            <strong style={{ marginTop: '8px' }}>Originação passiva</strong>
            <span style={{ marginTop: '12px' }}>Gestão dos leads com base no procedimento da esteira</span>
          </div>
        </Link>
        <Link to="/presession" style={ContainerLinks}>
          <div
            className="100w 10r hover-selection"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '180px',
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '100%',
              height: '40px',
              width: '40px',
            }}
            >
              <FaCompressAlt size={20} />
            </div>
            <strong style={{ marginTop: '8px' }}>Pré-sessão</strong>
            <span style={{ marginTop: '12px' }}>Gestão dos leads que estão em análise de cessionárias</span>
          </div>
        </Link>
        <Link to="/dossie" style={ContainerLinks}>
          <div
            className="100w 10r hover-selection"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '180px',
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '100%',
              height: '40px',
              width: '40px',
            }}
            >
              <BiTransfer size={20} />
            </div>
            <strong style={{ marginTop: '8px' }}>Transferência</strong>
            <span style={{ marginTop: '12px' }}>Gestão de todo o processo de transferência das cotas</span>
          </div>
        </Link>
        <Link to="/wallet" style={ContainerLinks}>
          <div
            className="100w 10r hover-selection"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '180px',
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '100%',
              height: '40px',
              width: '40px',
            }}
            >
              <FaWallet size={16} />
            </div>
            <strong style={{ marginTop: '8px' }}>Gerenciamento da carteira</strong>
            <span style={{ marginTop: '12px' }}>Gestão das cotas adquiridas</span>
          </div>
        </Link>
        <Link to="/analytics/data-studio" style={ContainerLinks}>
          <div
            className="100w 10r hover-selection"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '180px',
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '100%',
              height: '40px',
              width: '40px',
            }}
            >
              <SiGoogleanalytics size={18} />
            </div>
            <strong style={{ marginTop: '8px' }}>Dados análiticos</strong>
            <span style={{ marginTop: '12px' }}>Exibição de dados relevantes relacionadas aos serviços prestados</span>
          </div>
        </Link>
        <Link to="/managment" style={ContainerLinks}>
          <div
            className="100w 10r hover-selection"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '180px',
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '100%',
              height: '40px',
              width: '40px',
            }}
            >
              <IoMdSettings size={20} />
            </div>
            <strong style={{ marginTop: '8px' }}>Configurações</strong>
            <span style={{ marginTop: '12px' }}>Configuração da parte interna</span>
          </div>
        </Link>
        <Link to="/" style={ContainerLinks}>
          <div
            className="10r 100w hover-selection-off"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '181px',
            }}
          >
            <div
              className="Gray"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100%',
                color: '#fff',
                height: '40px',
                width: '40px',
              }}
            >
              <RiWifiOffFill size={20} />
            </div>
            <strong className="gray" style={{ marginTop: '8px' }}>Originação ativa</strong>
            <span className="gray" style={{ marginTop: '12px' }}>Em desenvolvimento...</span>
          </div>
        </Link>
        <Link to="/" style={{ ...ContainerLinks, cursor: 'default' }}>
          <div
            className="10r 100w"
            style={{
              display: 'flex', flexDirection: 'column', padding: '32px 16px', minHeight: '181px',
            }}
          />
        </Link>
      </div>
    </div>
  </div>
);

export default Selection;
