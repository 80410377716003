const ButtonContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '24px',
  width: '40px',
};

export {
  ButtonContainer,
};
