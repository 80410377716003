import axios from 'axios';

const api = axios.create({
  baseURL: String(process.env.REACT_APP_API_URL),
  headers: {
    // Authorization: 'APP',
    Authorization: process.env.REACT_APP_DEV_TEST === 'true' ? 'nodejs-webapi-crm-staging' : 'No.Auth.Local',
  },
});

export default api;
