const BackgroundContainer = {
  position: 'fixed' as const,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#00000080',
};

const Container = {
  display: 'flex',
  alignItems: 'center' as const,
  justifyContent: 'center' as const,
  height: '100%',
  width: '100%',
  zIndex: 9999,
};

const Content = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 10000,
  width: '100%',
};

const Wrapper = {
  backgroundColor: '#fff',
  width: '100%',
  maxWidth: '950px',
  border: '1px solid #D6D7D9',
  boxShadow: '0px 1px 4px #BCBEC0',
  borderRadius: '6px',
  maxHeight: '600px',
  overflow: 'auto',
};

const FolderHeaderLink = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '16px',
  borderBottom: '1px solid #E0E0E0',
  backgroundColor: '#FAFAFA',
  color: '#737373',
};

const FolderHeaderLinkEditable = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderBottom: '1px solid #E0E0E0',
  backgroundColor: '#FAFAFA',
  color: '#737373',
};

const IconsStyle = {
  position: 'relative' as const,
  top: '1px',
  margin: '0 6px',
};

const FolderLink = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '12px 16px',
  borderBottom: '1px solid #E0E0E0',
};

const CopyDocsButtonStyle = {
  display: 'flex',
  padding: '5px 14px',
  margin: '5px 5px',
  alignSelf: 'right',
  backgroundColor: '#00DE5D',
  fontWeight: 'bold',
  border: '1px solid #000',
};

const ModalConfirmStyle = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  p: 4,
};

export {
  Container,
  Content,
  BackgroundContainer,
  Wrapper,
  FolderLink,
  FolderHeaderLink,
  FolderHeaderLinkEditable,
  IconsStyle,
  CopyDocsButtonStyle,
  ModalConfirmStyle,
};
