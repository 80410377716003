export function currencyMaskByElement(e: any): string {
  const { value } = e.currentTarget;

  let v = value.replace(/\D/g, '');
  v = `${(v / 100).toFixed(2)}`;
  v = v.replace('.', ',')
    .replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
    .replace(/(\d)(\d{3}),/g, '$1.$2,');

  e.currentTarget.value = v;

  return e;
}

export function currencyMaskByValue(value: string): string {
  let v = value.replace(/\D/g, '');
  v = `${(+v / 100).toFixed(2)}`;
  v = v.replace('.', ',')
    .replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
    .replace(/(\d)(\d{3}),/g, '$1.$2,');

  return v;
}

export function phoneMaskByElement(e: any): void {
  let v = e.currentTarget.value;

  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d\d)(\d)/g, '($1) $2');

  if (e.currentTarget.value.length < 15) {
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    v = v.replace(/(\d{5})(\d)/, '$1-$2');
  }

  e.currentTarget.value = v;
}

let oldValuePhoneMask = '';

export function phoneMaskByValue(value: any): any {
  if (!value) {
    return '';
  }

  if (value.length > 15) {
    return oldValuePhoneMask;
  }

  let v = value;

  v = v.replace(/\D/g, '');
  v = v.replace(/^(\d\d)(\d)/g, '($1) $2');

  if (v.length < 14) {
    v = v.replace(/(\d{4})(\d)/, '$1-$2');
  } else {
    v = v.replace(/(\d{5})(\d)/, '$1-$2');
  }

  oldValuePhoneMask = v;

  return v;
}

export function MaskNumberToBRL(value: number): string {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export function CNPJMaskByElement(e: any): void {
  let v = e.currentTarget.value;

  v = v.replace(/\D/g, '');

  v = v.replace(/^(\d{2})(\d)/, '$1.$2');
  v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  v = v.replace(/\.(\d{3})(\d)/, '.$1/$2');
  v = v.replace(/(\d{4})(\d)/, '$1-$2');

  e.currentTarget.value = v;
}

export function CNPJMaskByValue(cnpjValue: string): string {
  let cnpj = cnpjValue;

  cnpj = cnpj.replace(/\D/g, '');

  cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
  cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');

  return cnpj;
}

export function CPFMaskByElement(e: any): void {
  let cpf = e.currentTarget.value;

  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  e.currentTarget.value = cpf;
}

export function CPFMaskByValue(cpfValue: string): string {
  let cpf = cpfValue;

  cpf = cpf.replace(/\D/g, '');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  return cpf;
}

export function tmsToDateMask(tms: number): string {
  const date = new Date(tms).toLocaleDateString();

  return date;
}

export function dateMaskByElementKeyDown(e: any): void {
  const { value } = e.currentTarget;

  let v = value.replace(/[^\d/]/g, '');

  if (e.key !== 'Backspace') {
    if (v.match(/^\d{2}$/) !== null) {
      v = `${v}/`;
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      v = `${v}/`;
    }
  }

  e.currentTarget.value = v;
}

let oldValueDateMask = '';

export function dateMaskByValue(value: string): any {
  if (value.length > 10) {
    return oldValueDateMask;
  }

  let v = value.replace(/[^\d/]/g, '');

  if (v.match(/^\d{2}$/) !== null) {
    v = `${v}/`;
  } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
    v = `${v}/`;
  }

  oldValueDateMask = v;

  return v;
}
