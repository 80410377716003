import React, { useMemo } from 'react';

import { tmsToDateMask } from '../../../../../../utils/masks';

import Releases from './components/Releases';

import { TableReleasesAttributes } from './interface';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
} from './styles';

const TableReleases: React.FC<TableReleasesAttributes> = ({
  releases,
  releaseOptions,
  onUpdateField,
  onAddModalReleases,
  onDeleteRelease,
}) => {
  const listReleases = releases?.sort((a, b) => {
    if (a?.tms > b?.tms) {
      return -1;
    }
    if (a?.tms < b?.tms) {
      return 1;
    }

    return 0;
  });

  const releasesComponent = useMemo(() => listReleases?.map((release) => {
    if (!release.id || !releaseOptions.length) {
      return <tr key={-1} />;
    }

    const newRelease = {
      ...release,
      dateLancamento: tmsToDateMask(release.tms),
    };

    return (
      <Releases
        onUpdateField={onUpdateField}
        onDeleteRelease={onDeleteRelease}
        releaseOptions={releaseOptions}
        onAddModalReleases={onAddModalReleases}
        key={release.id}
        release={newRelease}
      />
    );
  }), [releaseOptions, onUpdateField, onAddModalReleases, onDeleteRelease, listReleases]);

  return (
    <table
      style={TableContent}
      className="10r 100w"
    >
      <thead className="100w" style={TableHeadContainer}>
        <tr className="100w">
          <th style={TableHeadContent}>
            Lançamentos
          </th>
          <th style={TableHeadContent}>
            Data
          </th>
          <th style={TableHeadContent}>
            Valor
          </th>
          <th style={TableHeadContent}>
            Origem
          </th>
          <th style={TableHeadContent}>
            Ação
          </th>
        </tr>
      </thead>
      <tbody style={TableBodyContainer}>
        {!!(releases?.length && releaseOptions?.length) && releasesComponent}
      </tbody>
    </table>
  );
};

export default TableReleases;
