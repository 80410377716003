import React, {
  forwardRef, useCallback, useImperativeHandle, useRef,
} from 'react';

interface Signatory {
  email: string;
  contract: number;
  sign_as: string;
}

interface SignatoryAttributes {
  handleGetSignatoryValues(): Signatory | unknown;
}

interface SignatoryProps {
  signatory: Signatory;
}

const SignatoryComponent: React.ForwardRefRenderFunction<
SignatoryAttributes | null, SignatoryProps> = (
  { signatory }, ref,
) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const signAsRef = useRef<HTMLSelectElement>(null);
  const contractRef = useRef<HTMLSelectElement>(null);

  const handleGetSignatoryValues = useCallback((): Signatory | unknown => {
    try {
      const contract = +`${contractRef.current?.value}`;
      const sign_as = signAsRef.current?.value;
      const email = emailRef.current?.value;

      if (!email) {
        throw new Error('Email não está preenchido');
      }

      if (!sign_as) {
        throw new Error('Selecione um assinante.');
      }

      if (!contract) {
        throw new Error('Selecione um contrato.');
      }

      return {
        ...signatory,
        email,
        sign_as,
        contract,
      };
    } catch (err) {
      alert(err);

      throw err;
    }
  }, []);

  useImperativeHandle(ref, () => ({
    handleGetSignatoryValues,
  }));

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
      <input ref={emailRef} className="100w 10p 10r" defaultValue={signatory?.email} type="text" />
      <select ref={signAsRef} className="100w 10r 10p" style={{ width: '140px', margin: '0 8px' }} defaultValue={signatory?.sign_as}>
        <option value="transferee">Cessionário</option>
        <option value="transferor">Cedente</option>
        <option value="witness">Testemunhas</option>
        <option value="intervening">Interveniente</option>
      </select>
      <select ref={contractRef} className="100w 10r 10p" style={{ width: '200px' }} defaultValue={signatory?.contract || 0}>
        <option disabled value={0}>Selecione o tipo</option>
        <option value={1}>Termo de transferência</option>
        <option value={2}>Termo de cessão</option>
        <option value={3}>Termo de transferência e cessão</option>
      </select>
    </div>
  );
};

export default forwardRef(SignatoryComponent);
