import React, { useEffect, useRef, useState } from 'react';
import { BiLinkExternal } from 'react-icons/bi';

import { Administrator } from '../../../../interface';
import { AdministratorProps } from './interface';

import {
  TableBodyContent,
  // TableInput,
  TableHeadContent,
} from '../../styles';

const AdministratorComponent: React.FC<AdministratorProps> = (
  {
    administrator,
    onUpdateField,
  },
) => {
  const [toggleTarget, setToggleTarget] = useState('');
  const [administratorData, setAdministratorData] = useState(administrator as Administrator);

  const liquidityMonthRef = useRef<HTMLInputElement>(null);
  const partnershipRef = useRef<HTMLSelectElement>(null);
  const displayRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const launchRef = useRef<HTMLInputElement>(null);
  const socialRef = useRef<HTMLInputElement>(null);
  const classRef = useRef<HTMLInputElement>(null);
  const reminRef = useRef<HTMLInputElement>(null);
  const remaxRef = useRef<HTMLInputElement>(null);
  const CNPJRef = useRef<HTMLInputElement>(null);
  const urlRef = useRef<HTMLInputElement>(null);

  const handleOnFocus = (target: 'display' | 'address' | 'class' | 'cnpj' | 'url' | 'social' | 'launch' | 'liquidityMonth' | 'remin' | 'remax' | 'partnership'): void => {
    setToggleTarget(target);
  };

  const handleOnBlur = async (e: any, target: 'display' | 'address' | 'class' | 'cnpj' | 'url' | 'social' | 'launch' | 'liquidityMonth' | 'remin' | 'remax' | 'partnership'): Promise<void> => {
    setToggleTarget('');

    const newAdministrator = {
      liquidityMonth: administratorData?.liquidityMonth,
      partnership: administratorData.partnership,
      display: administratorData.display || '',
      address: administratorData.address || '',
      launch: administratorData.launch,
      social: administratorData.social || '',
      remin: administratorData.remin,
      remax: administratorData.remax,
      class: administratorData.class || '',
      cnpj: administratorData.cnpj || '',
      url: administratorData.url || '',
      id: administratorData.id,
      [target]: isNaN(+e.currentTarget.value) ? e.currentTarget.value : +e.currentTarget.value,
    };

    setAdministratorData(newAdministrator);
    await onUpdateField(newAdministrator);
  };

  useEffect(() => {
    if (toggleTarget) {
      liquidityMonthRef.current?.focus();
      partnershipRef.current?.focus();
      addressRef.current?.focus();
      displayRef.current?.focus();
      launchRef.current?.focus();
      socialRef.current?.focus();
      classRef.current?.focus();
      reminRef.current?.focus();
      CNPJRef.current?.focus();
      urlRef.current?.focus();
    }
  }, [toggleTarget]);

  return (
    <tr
      className="darken 100w"
      style={TableBodyContent}
      key={administratorData.id}
    >
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('display')}
      >
        <input
          ref={displayRef}
          hidden={!(toggleTarget === 'display')}
          type="text"
          defaultValue={administratorData.display}
          onBlur={(e) => handleOnBlur(e, 'display')}
        />
        <span hidden={toggleTarget === 'display'}>{administratorData.display}</span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('cnpj')}
      >
        <input
          ref={CNPJRef}
          hidden={!(toggleTarget === 'cnpj')}
          type="text"
          defaultValue={administratorData.cnpj}
          onBlur={(e) => handleOnBlur(e, 'cnpj')}
        />
        <span hidden={toggleTarget === 'cnpj'}>
          {administratorData.cnpj}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('address')}
      >
        <input
          ref={addressRef}
          hidden={!(toggleTarget === 'address')}
          type="text"
          defaultValue={administratorData.address}
          onBlur={(e) => handleOnBlur(e, 'address')}
        />
        <span hidden={toggleTarget === 'address'}>
          {administratorData.address}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('class')}
      >
        <input
          ref={classRef}
          hidden={!(toggleTarget === 'class')}
          type="text"
          defaultValue={administratorData.class}
          onBlur={(e) => handleOnBlur(e, 'class')}
        />
        <span hidden={toggleTarget === 'class'}>
          {administratorData.class}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('url')}
      >
        <input
          ref={urlRef}
          hidden={!(toggleTarget === 'url')}
          type="text"
          defaultValue={administratorData.url}
          onBlur={(e) => handleOnBlur(e, 'url')}
        />
        <span hidden={toggleTarget === 'url'}>
          {administratorData.url}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('social')}
      >
        <input
          ref={socialRef}
          hidden={!(toggleTarget === 'social')}
          type="text"
          defaultValue={administratorData.social}
          onBlur={(e) => handleOnBlur(e, 'social')}
        />
        <span hidden={toggleTarget === 'social'}>
          {administratorData.social}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('launch')}
      >
        <input
          ref={launchRef}
          hidden={!(toggleTarget === 'launch')}
          type="text"
          defaultValue={administratorData.launch}
          onBlur={(e) => handleOnBlur(e, 'launch')}
        />
        <span hidden={toggleTarget === 'launch'}>
          {administratorData.launch}%
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('liquidityMonth')}
      >
        <input
          ref={liquidityMonthRef}
          hidden={!(toggleTarget === 'liquidityMonth')}
          type="text"
          defaultValue={administratorData.liquidityMonth}
          onBlur={(e) => handleOnBlur(e, 'liquidityMonth')}
        />
        <span hidden={toggleTarget === 'liquidityMonth'}>
          {administratorData.liquidityMonth}
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('remin')}
      >
        <input
          ref={reminRef}
          hidden={!(toggleTarget === 'remin')}
          type="text"
          defaultValue={administratorData.remin}
          onBlur={(e) => handleOnBlur(e, 'remin')}
        />
        <span hidden={toggleTarget === 'remin'}>
          {administratorData.remin}%
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('remax')}
      >
        <input
          ref={remaxRef}
          hidden={!(toggleTarget === 'remax')}
          type="text"
          defaultValue={administratorData.remax}
          onBlur={(e) => handleOnBlur(e, 'remax')}
        />
        <span hidden={toggleTarget === 'remax'}>
          {administratorData.remax}%
        </span>
      </td>
      <td
        style={TableHeadContent}
        onDoubleClick={() => handleOnFocus('partnership')}
      >
        <select
          ref={partnershipRef}
          hidden={!(toggleTarget === 'partnership')}
          onBlur={(e) => handleOnBlur(e, 'partnership')}
          defaultValue={administratorData.partnership}
        >
          <option value={1}>Sim</option>
          <option value={0}>Não</option>
        </select>
        <span hidden={toggleTarget === 'partnership'}>{administratorData.partnership === 1 ? 'Sim' : 'Não'}</span>
      </td>
      <td style={TableHeadContent}>
        <a href={`/managment/administrator/${administratorData.id}`} target="_blank" rel="noreferrer">
          <button type="button" className="10r 5p Green blanc">
            <BiLinkExternal size={16} className="row" />
          </button>
        </a>
      </td>
    </tr>
  );
};

export default AdministratorComponent;
