const Container = {
  position: 'relative' as const,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  flexDirection: 'column' as const,
  marginBottom: '8px',
};

const Content = {
  position: 'relative' as const,
  border: '1px solid #00000015',
  borderRadius: '16px',
  padding: '8px',
  marginBottom: '8px',
};

const Wrapper = {
  marginBottom: '8px',
};

const ButtonContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '54px',
  width: '54px',
  borderRadius: '100%',
  border: 0,
  backgroundColor: '#0f0',
};

const InputContainer = {
  marginRight: '8px',
};

const SelectContainer = {
};

export {
  Container,
  Content,
  Wrapper,
  InputContainer,
  SelectContainer,
  ButtonContainer,
};
