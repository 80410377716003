import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import XLSX from 'xlsx';

import { tmsToDateMask } from '../../../../utils/masks';

import { useAuth } from '../../../../hooks/auth';
import { useCache } from '../../../../hooks/cacheServices';

import api from '../../../../services/api';
import apiSaas from '../../../../services/apiSaas';
import { storage } from '../../../../services/firebase';

import ModalOptions from '../../components/ModalOptions';
import Modal from '../../components/Modal';
import Header from '../../components/Header';
import ListUsers from './components/ListUsers';
import FormModal from './components/FormModal';
import Filter from './components/Filter';

import {
  UserLeadQuota, SubmitButtonParams, SubmitDataDTO,
} from './interface';

import {
  Container,
} from './styles';

let requestedLeads: any[] = [];

const ListQuotas: React.FC = () => {
  const { token, handleLogout } = useAuth();

  const history = useHistory();

  const [filters, setFilters] = useState(() => {
    const currentFilters = localStorage.getItem('@Contemplato/filters-wallet');

    if (currentFilters) {
      const storagedFilters = JSON.parse(currentFilters);

      return storagedFilters;
    }

    return {};
  });

  const [listUsers, setListUsers] = useState([] as UserLeadQuota[]);
  const [releaseOptions, setReleaseOptions] = useState([] as any[]);
  const [isOpenReleases, setIsOpenReleases] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [assignees, setAssignees] = useState([] as any[]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    requestAdministrators,
    requestReleases,
    requestBacen,
    requestItems,
    requestTypes,
    administrators,
    types,
    items,
    bacen,
    releases,
  } = useCache();

  useEffect(() => {
    const requestAssigneeList = async (): Promise<void> => {
      try {
        const { data: assigneeData } = await api.post('/output/cessionario', {
          agent: token,
        });

        setAssignees(assigneeData.data);
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    const requestReleasesData = async (): Promise<void> => {
      try {
        const { data } = await api.post('/output/lista_lancamentos', {
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          setReleaseOptions(data.data);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    const requestUsersList = async (): Promise<void> => {
      try {
        const storageRef = storage.ref(process.env.REACT_APP_FIREBASE_STORAGE_PREFIX);

        const storageList = await storageRef.listAll();

        const userIds = storageList.prefixes.map((userId) => +userId.name);

        const { data } = await api.post('/output/gestao', {
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          const usersResponse = Object.values(data.data) as UserLeadQuota[];

          const promisesLeads: any = await usersResponse.map(
            async (lead: any): Promise<any> => {
              const hasExtract = userIds.find((userId) => userId === lead.id);

              if (hasExtract) {
                lead.extract = 1;
              } else {
                lead.extract = 0;
              }

              if (+lead?.group) {
                const bacenLead = bacen.find(
                  (itemBacen: any) => itemBacen.administrator === +lead.administrator
                    && itemBacen.group === +lead.group,
                );

                if (bacenLead?.dateBase && bacenLead?.totalAssembly) {
                  const month = bacenLead.dateBase?.substring(4);
                  const year = bacenLead.dateBase?.substring(4, -2);

                  if (month && year) {
                    const readjustmentDate = new Date(`${month}/01/${year}`);

                    readjustmentDate.setMonth(
                      readjustmentDate.getMonth() - bacenLead.totalAssembly,
                    );

                    lead.mesReajuste = readjustmentDate.getMonth();
                  }
                }
              }

              return lead;
            },
          );

          const leads = await Promise.all(promisesLeads) as any;

          requestedLeads = leads;
          setListUsers(leads);
          handleOnFilter(filters);
          setIsLoading(false);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    };

    requestAssigneeList();
    requestItems();
    requestTypes();
    requestReleasesData();
    requestAdministrators();
    requestReleases();
    requestBacen();
    requestItems();
    requestUsersList();
    requestTypes();
  }, [bacen]);

  const handleOnSubmit = async (params: SubmitButtonParams): Promise<void> => {
    const index = listUsers.findIndex((lead) => lead.id === params.idRelacional);

    listUsers[index].tmsParcela = params.tmsParcela;

    setListUsers([...listUsers]);
    await api.put('/input/gestao', {
      id: params.idRelacional,
      tmsParcela: params.tmsParcela,
      status: listUsers[index].status,
      agent: token,
    });
  };

  const handleCalculateAllTirs = async (): Promise<void> => {
    const { data } = await api.put('/input/calculate-tir', {
      agent: token,
    });

    console.log(data);
  };

  const handleOnSubmitModal = async (submitData: SubmitDataDTO): Promise<void> => {
    const { data } = await api.post('/input/atendimento', {
      ...submitData,
      extract: 0,
    });

    await api.put('/input/atendimento', {
      id: data.data.insertId,
      administrator: 1,
      type: 1,
      item: 1,
      status: 300,
      agent: token,
      cessionario: 1,
    });

    setIsOpen(false);

    history.push(`/wallet/extract/${data.data.insertId}`);
  };

  const handleOnDelete = useCallback(async (id: number): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        const usersFiltered = listUsers.filter((user) => user.id !== +id);
        setListUsers(usersFiltered);

        const { data: updateData } = await api.put('/input/gestao', {
          id,
          status: 3,
          agent: token,
        });

        if (!updateData.status) {
          alert(`Erro: ${updateData.message}`);
          handleLogout();
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  }, [token, listUsers]);

  const handleUpdateField = useCallback(async (userLead: any): Promise<void> => {
    try {
      if (typeof userLead.strategy === 'string') {
        delete userLead.strategy;
      }

      const { data } = await api.put('/input/gestao', {
        agent: token,
        status: userLead.status,
        ...userLead,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  }, [token, handleLogout]);

  const handleOnInsertModalRelease = useCallback(async (modalData: any): Promise<void> => {
    await api.post('/input/lancamentos', {
      idRelational: +modalData.id,
      agent: token,
      description: modalData.description,
      display: +`${modalData.display}`,
      visible: 1,
      tms: modalData.tms,
      into: 'gestao',
    });
  }, [token]);

  const handleMoveSelect = useCallback(async (userLead: any): Promise<void> => {
    try {
      const { data } = await api.put('/input/gestao', {
        id: userLead.id,
        status: userLead.status,
        agent: token,
        sendNotification: true,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  }, [listUsers, token, handleLogout]);

  const handleOnUpdateStrategy = async (lead: any): Promise<void> => {
    const { data } = await api.put<any>('/input/gestao', {
      ...lead,
      agent: token,
    });

    if (!data?.status) {
      alert(`Erro: ${data.error}`);
    }
  };

  const handleConvertDataToCSV = async (): Promise<void> => {
    const storageRef = storage.ref(process.env.REACT_APP_FIREBASE_STORAGE_PREFIX);
    const storageList = await storageRef.listAll();
    const userIds = storageList.prefixes.map((userId) => +userId.name);
    const { data } = await api.post('/output/gestao', {
      agent: token,
    });

    const { data: releasesOptions } = await api.post('/output/lancamentos', {
      agent: token,
    });

    const allLeads = Object.values(data.data) as UserLeadQuota[];
    const leadsUser = await Promise.all(allLeads.map(async (user: UserLeadQuota) => {
      const hasExtract = userIds.find((userId) => userId === user.id);

      const { data: userReleases } = await api.post('/output/lancamentos', {
        agent: token,
        relational: user.id,
      });

      user.launches = userReleases.data;

      if (hasExtract) {
        user.extract = 1;
      } else {
        user.extract = 0;
      }

      return user;
    }));

    const displayOptions = {} as any;

    releasesOptions.data.forEach((option: any) => {
      displayOptions[option.id] = option.display;
    });

    const newUsers = [] as any;
    const newLaunches = [] as any;
    leadsUser.forEach((user: UserLeadQuota) => {
      const userObj = {
        id: user.id || '#######',
        Data: tmsToDateMask(user.tmsIn) || '#######',
        'Data proposta': tmsToDateMask(user.tmsPricing) || '#######',
        'Data finalizado': tmsToDateMask(user.tmsFinished) || '#######',
        'Data assembleia': tmsToDateMask(user.tmsAssembleia) || '#######',
        'Data parcela': tmsToDateMask(user.tmsParcela) || '#######',
        'id Extrato': user.idExtract || '#######',
        'id Data': user.idData || '#######',
        'id Tms': user.idTms || '#######',
        extract: user.extract || '#######',
        status: user.status || '#######',
        Nome: user.display || '#######',
        'E-mail': user.email || '#######',
        Telefone: user.phone || '#######',
        Administrador: administrators.find(
          (administrator: any) => administrator.id === user.administrator,
        )?.display || '#######',
        Item: items?.find(
          (item: any) => item.id === user.item,
        )?.display || '#######',
        Tipo: types?.find(
          (type: any) => type.id === user.type,
        )?.display || '#######',
        Crédito: user.credit || '#######',
        'Total pago': user.paid || '#######',
        'Divida restante': user.debit || '#######',
        'Fundo comum': user.unitrust || '#######',
        'Débito total': user.totalDebit || '#######',
        'Valor da parcela': user.portion || '#######',
        'Total de parcelas': user.totalPortion || '#######',
        'Parcelas restantes': user.totalPortionToPay || '#######',
        'Meses em aberto': user.totalPortionToDie || '#######',
        'Preço da proposta': user.pricing || '#######',
      };

      if (user.launches.length) {
        user?.launches?.forEach((launch: any) => {
          const launchObj = {
            'id Lancamento': launch.id || '#######',
            'Status Lancamento': launch.status || '#######',
            'Nome Lancamento': displayOptions[launch.display] || '#######',
            'Data Lancamento': tmsToDateMask(launch.tms) || '#######',
            'Descrição Lancamento': launch.description || '#######',
          };

          newLaunches.push(launchObj);
        });
      }

      newUsers.push(userObj);
    });

    const ws = XLSX.utils.json_to_sheet(newUsers);
    const wsLaunches = XLSX.utils.json_to_sheet(newLaunches);
    const wb = XLSX.utils.book_new();
    const wbLaunches = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ws_data');
    XLSX.utils.book_append_sheet(wbLaunches, wsLaunches, 'ws_data');
    XLSX.writeFile(wb, 'contemplato_wallet_leads.xlsb');
    XLSX.writeFile(wbLaunches, 'contemplato_wallet_launches.xlsb');
  };

  const handleOnFilter = useCallback(async (filter: any): Promise<void> => {
    const hasAnyFilter = !!Object.values(filter).length;

    if (hasAnyFilter) {
      const filteredLeads = requestedLeads.filter((lead) => {
        if (
          filter?.display && !lead.display.toLowerCase().includes(filter?.display.toLowerCase())
        ) {
          return false;
        }

        if (
          filter?.email && !lead.email.toLowerCase().includes(filter?.email.toLowerCase())
        ) {
          return false;
        }

        if (
          filter?.phone && !lead.phone.replaceAll(/\D/g, '').toLowerCase().includes(filter?.phone.replaceAll(/\D/g, '').toLowerCase())
        ) {
          return false;
        }

        if (
          filter?.status && lead.status !== +filter?.status
        ) {
          return false;
        }

        if (
          filter?.cessionario && lead.cessionario !== +filter?.cessionario
        ) {
          return false;
        }

        if (
          filter?.id && filter?.id !== lead.id
        ) {
          return false;
        }

        return true;
      });

      setListUsers(filteredLeads);
    } else {
      setListUsers(requestedLeads);
    }
  }, []);

  const handleSetFilters = (newFilters: any): void => {
    setFilters(newFilters);
  };

  const handleUploadCSV = async (file: File): Promise<void> => {
    try {
      const formData = new FormData();

      formData.append('quotas', file);

      await apiSaas.post('/v1/leads-crm/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      alert('CSV enviado com sucesso.');

      window.location.reload();
    } catch (err) {
      alert(`Ocorreu um erro: ${err?.response?.data || err.message}`);
    }
  };

  return (
    <div className="col center wallet-table" style={Container}>
      <Header />
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      >
        <h3>Cadastrar novo cliente</h3>
        <FormModal
          fnOnSubmit={handleOnSubmitModal}
        />
      </Modal>
      <ModalOptions
        handleOnClick={handleConvertDataToCSV}
        handleUploadCSV={handleUploadCSV}
      />
      {
        isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Filter
              assignees={assignees}
              filters={filters}
              onSetFilters={handleSetFilters}
              isLoading={isLoading}
              onFilter={handleOnFilter}
            />
            <ListUsers
              items={items}
              types={types}
              releases={releases}
              administrators={administrators}
              listUsers={listUsers}
              onSubmit={handleOnSubmit}
              handleOnDelete={handleOnDelete}
              onUpdateField={handleUpdateField}
              setListUsers={setListUsers}
              setIsOpen={setIsOpen}
              onMoveSelect={handleMoveSelect}
              setIsOpenReleases={setIsOpenReleases}
              releaseOptions={releaseOptions}
              isOpenReleases={isOpenReleases}
              onUpdateStrategy={handleOnUpdateStrategy}
              onInsertModalRelease={handleOnInsertModalRelease}
              calculateAllTirs={handleCalculateAllTirs}
            />
          </>
        )
      }
    </div>
  );
};

export default ListQuotas;
