import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { mutate as mutateGlobal } from 'swr';

import { numberDiffOfMonth } from '../../../../utils/converts';
import { removeEmptyFields } from '../../../../utils/functionalities';
import { currencyMaskByValue } from '../../../../utils/masks';

import ModalOptions from '../../components/ModalOptions';
import Header from '../../components/Header';
import ListUsers from './components/ListUsers';
import Filter from '../../components/Filter';

import api from '../../../../services/api';

import { useAuth } from '../../../../hooks/auth';
import { useFetch } from '../../../../hooks/fetch';
import { useCache } from '../../../../hooks/cacheServices';
import { useInfiniteScroll } from '../../../../hooks/infiniteScroll';

import { UserLeadsAll } from '../../../_interfaces';
import { OnSubmitParams } from './interface';

let initialLeads: any[] = [];

const Pricing: React.FC = () => {
  const { token, handleLogout } = useAuth();
  const { pagination, infiniteScrollPagination } = useInfiniteScroll();

  const [listUsers, setListUsers] = useState([] as UserLeadsAll[]);
  const [filteredUsers, setFilteredUsers] = useState([] as UserLeadsAll[]);
  const [assignees, setAssignees] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(true);

  const { apiSwr } = useFetch();
  const {
    requestBacen, administrators: administratorsData, requestAdministrators, requestRates,
  } = useCache();

  const { dataFetch, mutate } = apiSwr('list-110', '/output/atendimento', token, {
    status: 110,
  });

  function quitterQuota(
    netPayment: number,
    debit: number,
    launch: number,
    liquidMonth: number,
    transferRate: number,
    minimumExpectedReturn: number,
    maximumExpectedReturn: number,
    tmsContemplated: number,
    type: number,
    commissionTableValue: number,
    portion: number,
  ): any {
    let minimumFactor;
    let maximumFactor;

    let otherCosts = 0;

    if (type === 3) {
      otherCosts = 0.4 * portion;
    }

    if (!tmsContemplated || type !== 1) {
      minimumFactor = 1 / (1 + maximumExpectedReturn) ** ((6 + liquidMonth) / 12);
      maximumFactor = 1 / (1 + minimumExpectedReturn) ** ((6 + liquidMonth) / 12);

      // console.log(`maximumFactor = 1 / (1 + ${minimumExpectedReturn})
      // ** ((6 + ${liquidMonth}) / 12) = ${maximumFactor}`);
      // console.log(`minimumFactor = 1 / (1 + ${maximumExpectedReturn})
      // ** ((6 + ${liquidMonth}) / 12) = ${minimumFactor}`);
    } else {
      let differenceMonthsContemplated = numberDiffOfMonth(
        new Date(tmsContemplated), new Date(),
      );

      if (differenceMonthsContemplated < -5) {
        differenceMonthsContemplated = -5;
      }

      minimumFactor = 1 / (1 + maximumExpectedReturn) ** (
        (6 + differenceMonthsContemplated) / 12
      );

      maximumFactor = 1 / (1 + minimumExpectedReturn) ** (
        (6 + differenceMonthsContemplated) / 12
      );

      // console.log(`maximumFactor = 1 / (1 + ${minimumExpectedReturn}) ** (
      //   (6 + ${differenceMonthsContemplated}) / 12
      // );`);

      // console.log(`minimumFactor = 1 / (1 + ${maximumExpectedReturn}) ** (
      //   (6 + ${differenceMonthsContemplated}) / 12
      // )`);
    }

    let launchValue = (launch / 100) * netPayment;

    if (launchValue > debit) {
      launchValue = debit;
    }

    if (type === 1 && tmsContemplated) {
      launchValue = 0;
    }

    const minimumFactorLaunchValue = 1 / (1 + maximumExpectedReturn) ** (
      liquidMonth / 12
    );

    const maximumFactorLaunchValue = 1 / (1 + minimumExpectedReturn) ** (
      liquidMonth / 12
    );

    // console.log(`minimumFactorLaunchValue = 1 / (1 + ${maximumExpectedReturn}) ** (
    //   ${liquidMonth} / 12
    // );`);

    // console.log(`maximumFactorLaunchValue = 1 / (1 + ${minimumExpectedReturn}) ** (
    //   ${liquidMonth} / 12
    // );`);

    // console.log(`launchValue = (${launch} / 100) * ${netPayment} = ${launchValue}`);

    const expirationValue = netPayment - debit + launchValue;

    // eslint-disable-next-line
    // console.log(`expirationValue = ${netPayment} - ${debit} + ${launchValue} = ${expirationValue}`);

    const commission = netPayment * (commissionTableValue / 100);

    // console.log(`commission = ${netPayment} * (${commissionTableValue} / 100)`);

    // eslint-disable-next-line
    let minimumValue = expirationValue * minimumFactor - launchValue * minimumFactorLaunchValue - transferRate - commission - otherCosts - 180;

    // eslint-disable-next-line
    let maximumValue = expirationValue * maximumFactor - launchValue * maximumFactorLaunchValue - transferRate - commission - otherCosts - 180;

    // console.log(`maximumValue = (
    // ${expirationValue} * ${maximumFactor} - ${launchValue} * ${maximumFactorLaunchValue}
    // - ${transferRate} - ${commission} - ${otherCosts}
    // ) - 180 = ${maximumValue}`);

    // console.log(`minimumValue = (
    // ${expirationValue} * ${minimumFactor} - ${launchValue} * ${minimumFactorLaunchValue}
    // - ${transferRate} - ${commission} - ${otherCosts}
    // ) - 180 = ${minimumValue}`);

    if (minimumValue < 0) {
      minimumValue = 0;
    }

    if (maximumValue < 0) {
      maximumValue = 0;
    }

    return {
      minimumValue,
      maximumValue,
    };
  }

  function canceledQuota(
    rate: any,
    netPayment: number,
    portion: number,
    unitrust: number,
    tmsDieGroup: number,
    transferRate: number,
    minimumExpectedReturn: number,
    maximumExpectedReturn: number,
    commissionTableValue: number,
    type: number,
  ): any {
    if (!tmsDieGroup) {
      return {
        maximumValue: 0,
        minimumValue: 0,
      };
    }

    const fine = (unitrust / netPayment) * 100;
    let finePercentage = 0;

    const {
      multa1,
      multa2,
      multa3,
      intervalo1,
      intervalo2,
      intervalo3,
    } = rate;

    if (fine <= intervalo1) {
      finePercentage = multa1;
    } else if (fine <= intervalo2) {
      finePercentage = multa2;
    } else if (fine <= intervalo3) {
      finePercentage = multa3;
    }

    const discountedUnitrust = unitrust * (1 - ((1 * finePercentage) / 100));

    const dateDieGroup = new Date(tmsDieGroup);
    const today = new Date();

    const differenceInDays = getWorkingDays(today, dateDieGroup) + 1;

    const minimumFactor = 1 / (1 + maximumExpectedReturn) ** (differenceInDays / 252);
    const maximumFactor = 1 / (1 + minimumExpectedReturn) ** (differenceInDays / 252);

    let otherCosts = 0;

    if (type === 3) {
      otherCosts = 0.4 * portion;
    }

    const commission = (
      unitrust - (unitrust * (finePercentage / 100))) * (commissionTableValue / 100
    );

    let minimumValue = (
      discountedUnitrust * minimumFactor - transferRate - commission - otherCosts
    ) - 180;
    let maximumValue = (
      discountedUnitrust * maximumFactor - transferRate - commission - otherCosts
    ) - 180;

    if (minimumValue < 0) {
      minimumValue = 0;
    }

    if (maximumValue < 0) {
      maximumValue = 0;
    }

    return {
      minimumValue,
      maximumValue,
    };
  }

  function getWorkingDays(startDate: Date, endDate: Date): number {
    let result = 0;

    const currentDate = startDate;
    while (currentDate <= endDate) {
      const weekDay = currentDate.getDay();
      if (weekDay !== 0 && weekDay !== 6) { result++; }

      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  }

  async function calculateLeadsPricing(leads: any[]): Promise<any> {
    const { data: commissionData } = await api.post('/output/commission', {
      agent: token,
    });

    const { data: assigneeData } = await api.post('/output/cessionario', {
      agent: token,
    });

    setAssignees(assigneeData?.data);

    const newLeads = await Promise.all(leads.map(async (lead) => {
      const MIN_VALUE = 10000;

      const credit = +lead.credit.replace(/[^0-9,]/g, '').replace(',', '.');
      const bidPlaced = +lead.bidPlaced?.replace(/[^0-9,]/g, '').replace(',', '.') || 0;
      const netPayment = credit - bidPlaced;

      if (netPayment < MIN_VALUE) {
        return lead;
      }

      if (lead.type > 2) {
        lead.type = 2;
      }

      if (lead.item > 2) {
        lead.item = 2;
      }

      const administrators = await requestAdministrators();
      const rates = await requestRates();

      const rate = rates.find(
        (itemRate: any) => itemRate.administrator === lead.administrator
        && itemRate.type === lead.type
        && itemRate.item === lead.item,
      );

      const commissionQuitter = commissionData.data?.find(
        (itemCommission: any) => itemCommission.cessionario === lead.cessionario
        && itemCommission.strategy === 1,
      );

      const commissionCancel = commissionData.data?.find(
        (itemCommission: any) => itemCommission.cessionario === lead.cessionario
        && itemCommission.strategy === 2,
      );

      const administrator = administrators.find(
        (itemAdministrator: any) => itemAdministrator.id === lead.administrator,
      );

      const commissionTableValueCancel = commissionCancel?.commission || 0;
      const commissionTableValueQuitter = commissionQuitter?.commission || 0;

      if (rate && administrator && rate?.origin) {
        const {
          remin,
          remax,
          launch,
          liquidityMonth,
        } = administrator;

        const {
          origin,
          taxMax,
          taxMin,
        } = rate;

        let transferValue = 0;

        const unitrust = +lead.unitrust.replace(/[^0-9,]/g, '').replace(',', '.');
        const portion = +lead.portion.replace(/[^0-9,]/g, '').replace(',', '.');

        const debit = +lead.debit.replace(/[^0-9,]/g, '').replace(',', '.');

        if (origin === 'VC') {
          transferValue = credit;
        } else if (origin === 'SD') {
          transferValue = debit;
        }

        let transferRate = (taxMax * transferValue) / 100;

        if (transferRate < taxMin) {
          transferRate = taxMin;
        }

        const minimumExpectedReturn = remin / 100;
        const maximumExpectedReturn = remax / 100;

        const quitter = quitterQuota(
          netPayment,
          debit,
          launch,
          liquidityMonth,
          transferRate,
          minimumExpectedReturn,
          maximumExpectedReturn,
          lead?.tmsContemplated,
          lead.type,
          commissionTableValueQuitter,
          portion,
        );

        const canceled = canceledQuota(
          rate,
          netPayment,
          portion,
          unitrust,
          lead.tmsDieGroup,
          transferRate,
          minimumExpectedReturn,
          maximumExpectedReturn,
          commissionTableValueCancel,
          lead.type,
        );

        lead.canceledMaximumValue = canceled.maximumValue;
        lead.canceledMinimumValue = canceled.minimumValue;
        lead.quitterMaximumValue = quitter.maximumValue;
        lead.quitterMinimumValue = quitter.minimumValue;
        lead.taxTransfer = currencyMaskByValue(`${transferRate.toFixed(2)}`);
      }

      return lead;
    }));

    return newLeads;
  }

  useEffect(() => {
    async function fetchRequests(): Promise<void> {
      const bacen = await requestBacen();

      if (filteredUsers.length) {
        setListUsers(
          await calculateLeadsPricing(filteredUsers.slice(0, pagination * 30)),
        );
      } else if (bacen) {
        const leadsPricing = await calculateLeadsPricing(dataFetch.data);

        const promisesLeads: any = await leadsPricing.map(
          async (lead: any): Promise<any> => {
            if (+lead?.group) {
              const bacenLead = bacen.find(
                (itemBacen: any) => itemBacen.administrator === +lead.administrator
                  && itemBacen.group === +lead.group,
              );

              if (bacenLead) {
                if (bacenLead?.dateBase && bacenLead?.totalAssembly) {
                  const month = bacenLead.dateBase?.substring(4);
                  const year = bacenLead.dateBase?.substring(4, -2);

                  if (month && year) {
                    const readjustmentDate = new Date(`${month}/01/${year}`);

                    readjustmentDate.setMonth(
                      readjustmentDate.getMonth() - bacenLead.totalAssembly,
                    );

                    lead.mesReajuste = readjustmentDate.getMonth();
                  }
                }

                if (bacenLead?.quotaMonth) {
                  lead.quotaMonth = bacenLead.quotaMonth;
                }
              }
            }

            return lead;
          },
        );

        const leads = await Promise.all(promisesLeads) as any;
        initialLeads = leads;
        setListUsers(leads.slice(0, pagination * 30));
        setIsLoading(false);
      }
    }

    if (dataFetch?.data) {
      fetchRequests();

      if (!listUsers.length) {
        infiniteScrollPagination();
      }
    }
  }, [dataFetch, pagination, filteredUsers]);

  const handleOnSubmit = async (formData: OnSubmitParams): Promise<void> => {
    try {
      const usersFiltered = listUsers.filter((user) => user.id !== formData.id);

      setListUsers(usersFiltered);
      mutate(usersFiltered, false);
      mutateGlobal(`list-${formData.status}`, {}, true);

      if (!formData?.strategy) {
        formData.strategy = 1;
      }

      const leadsWithPricing = await calculateLeadsPricing(listUsers);

      const { taxTransfer } = leadsWithPricing.find((lead: any) => lead.id === formData.id);

      const { data } = await api.put('/input/atendimento', removeEmptyFields({
        ...formData,
        agent: token,
        taxTransfer,
        sendNotification: true,
      }));

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleMoveSelect = async (e: any, userLead: UserLeadsAll): Promise<void> => {
    try {
      const { value } = e.currentTarget;

      const usersFiltered = listUsers.filter((user) => user.id !== userLead.id);
      setListUsers(usersFiltered);

      const { data } = await api.put('/input/atendimento', {
        id: userLead.id,
        status: +value,
        agent: token,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      } else {
        mutate(usersFiltered, false);
        mutateGlobal(`list-${value}`, {}, true);
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleUpdateStrategy = async (e: any, userLead: UserLeadsAll): Promise<void> => {
    try {
      const { value } = e.currentTarget;

      const { data } = await api.put('/input/atendimento', {
        id: userLead.id,
        strategy: +value,
        status: userLead.status,
        agent: token,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleOnDelete = useCallback(async (userLead: UserLeadsAll): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        if (filteredUsers.length) {
          const usersFiltered = filteredUsers.filter((user) => user.id !== userLead.id);
          setFilteredUsers(usersFiltered);
        }

        const usersFiltered = listUsers.filter((user) => user.id !== userLead.id);
        setListUsers(usersFiltered);

        const { data: updateData } = await api.put('/input/atendimento', {
          id: userLead.id,
          status: 1,
          agent: token,
        });

        if (!updateData.status) {
          alert(`Erro: ${updateData.message}`);
          handleLogout();
        } else {
          mutate(usersFiltered, false);
          mutateGlobal('list-110', {}, true);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  }, [token, listUsers]);

  const handleOnChangeAssignee = async (
    userLead: UserLeadsAll, assigneeId: number,
  ): Promise<void> => {
    const assigneeItem = assignees.find((itemAssignee) => itemAssignee.id === assigneeId);

    if (assigneeItem) {
      const updatedListUsers = listUsers.map((lead) => {
        if (lead.cessionario === assigneeItem.id) {
          return {
            ...lead,
            cessionario: +assigneeItem.id,
          };
        }

        return lead;
      });

      setListUsers(updatedListUsers);

      try {
        const { data } = await api.put('/input/atendimento', {
          id: userLead.id,
          cessionario: +assigneeItem.id,
          status: userLead.status,
          agent: token,
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    } else {
      alert('Erro: Cessionário não encontrado, reinicie a página, caso o erro persista, contate os devs.');
    }
  };

  const handleOnFilterLeads = async (filteredLeads: any): Promise<void> => {
    setListUsers(filteredLeads);
  };

  return (
    <div className="col center">
      <Header />
      <ModalOptions
        status={110}
        listUsers={listUsers}
        setFilteredUsers={setFilteredUsers}
      />
      {
        isLoading ? (
          <div style={{ marginTop: '16px' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="100w" style={{ maxWidth: '960px', marginTop: '16px' }}>
              <Filter initialLeads={initialLeads} onFilter={handleOnFilterLeads} />
            </div>
            <ListUsers
              assignees={assignees}
              administrators={administratorsData}
              listUsers={listUsers}
              handleOnSubmit={handleOnSubmit}
              handleMoveSelect={handleMoveSelect}
              handleOnDelete={handleOnDelete}
              handleUpdateStrategy={handleUpdateStrategy}
              handleOnChangeAssignee={handleOnChangeAssignee}
            />
          </>
        )
      }

    </div>
  );
};

export default Pricing;
