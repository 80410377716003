const Container = {
  height: '80px',
  borderBottom: '1px solid #80808050',
};

const Content = {
  height: '100%',
  justifyContent: 'space-evenly',
};

const ContainerLink = {
  display: 'flex',
  height: '100%',
  width: '120px',
};

const ContentLink = (url: string, path: string): any => {
  let styles = {};

  if (url === path) {
    styles = {
      borderBottom: '3px solid #000',
      fontWeight: 'bold' as const,
    };
  }

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    ...styles,
  };
};

export {
  Container,
  Content,
  ContainerLink,
  ContentLink,
};
