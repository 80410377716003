import React, { useState } from 'react';
import { FiMenu } from 'react-icons/fi';

import Menu from '../../components/Menu';

const DataStudio: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const dataStudioEmbedURL = 'https://datastudio.google.com/embed/reporting/b35d65d3-c938-49f0-b66c-5c5a00045899/page/p_4f6w76hvvc';

  return (
    <div>
      <header style={{ borderBottom: '1px solid black', padding: '20px 24px' }}>
        <FiMenu style={{ cursor: 'pointer' }} size={20} onClick={() => setIsOpen((currIsOpen) => !currIsOpen)} />
        <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
      </header>
      <div className="flex-auto px-4 lg:px-10 py-10">
        <iframe title="data-studio" width="100%" height="700" src={dataStudioEmbedURL} frameBorder="0" style={{ border: 0 }} allowFullScreen />
      </div>
    </div>
  );
};

export default DataStudio;
