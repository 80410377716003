import React, {
  useState, useRef, useEffect, MutableRefObject, useMemo,
} from 'react';
import { IoIosDocument } from 'react-icons/io';
import { FaAddressBook } from 'react-icons/fa';
import { RiChat4Fill, RiUserAddFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import apiSaas from '../../../../../../services/apiSaas';

import { useGoogleApi } from '../../../../../../hooks/googleApi';

import { dateMaskByElementKeyDown, phoneMaskByElement, tmsToDateMask } from '../../../../../../utils/masks';
import { dateBrToDefaultString } from '../../../../../../utils/converts';

import Modal from '../Modal';

import { LeadProps } from './interface';

import {
  ContainerLead,
  SpanStyle,
  ExtraDataContainer,
  AddressBookIcon,
  AddressBookButton,
} from './styles';

const LeadComponent: React.FC<LeadProps> = ({
  lead, operatorName, onUpdateUser, onUpdateStatus, fnConsultDocuments,
}) => {
  const { authenticate, createContact } = useGoogleApi();

  const history = useHistory();

  const nameRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const emailRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const phoneRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const tmsInRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const targetRef = useRef<HTMLSelectElement>(null) as MutableRefObject<HTMLSelectElement>;

  const [toggleTarget, setToggleTarget] = useState('');
  const [updateField, setUpdateField] = useState({ ...lead });
  const [toggleModal, setToggleModal] = useState(false);
  const [bgCheckedData, setBgCheckedData] = useState({} as any);

  function handleOnDoubleClick(field: string): void {
    setToggleTarget(field);
  }

  async function handleCreateContact(): Promise<void> {
    await authenticate();
    await createContact(lead.display, lead.phone);
  }

  async function handleConsultValidationDocuments(): Promise<void> {
    setToggleModal(true);

    const data = await fnConsultDocuments();

    setBgCheckedData(data.result?.data);
  }

  function handleOnBlur(): void {
    const tmsIn = new Date(dateBrToDefaultString(tmsInRef.current.value)).getTime();

    const obj = {
      display: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      tmsIn,
      target: +targetRef.current.value,
    };

    updateField.dateIn = tmsToDateMask(obj.tmsIn);

    setToggleTarget('');
    setUpdateField({
      ...updateField,
      ...obj,
    });
    onUpdateUser(obj);
  }

  async function handleContactLeadByMessages(): Promise<any> {
    const getAssigneeName:any = {
      2: 'consort',
      6: 'rodobens',
    };
    try {
      const assigneeName = getAssigneeName[lead?.cessionario] || 'contemplato';
      const campaignName = `init-chat-${assigneeName}`;

      await apiSaas.post('/v1/messaging-crm/open-chat', {
        campaignName,
        operatorName,
        contact: {
          phone: `55${lead.phone.replace(/\D/g, '')}`,
          name: lead.display,
        },
      });

      alert('Enviado com sucesso');
    } catch (err) {
      alert('Falha ao enviar a requisição, erro nos logs');

      console.log(err);
    }
  }

  useEffect(() => {
    if (toggleTarget) {
      nameRef.current.focus();
      emailRef.current.focus();
      phoneRef.current.focus();
      tmsInRef.current.focus();
      targetRef.current.focus();
    }
  }, [toggleTarget]);

  const targetOptions = {
    0: 'Formulário',
    1: 'Whatsapp',
    2: 'Facebook',
    3: 'Instagram',
    4: 'Outros',
    5: 'Saas',
  } as any;

  const target = targetOptions[updateField.target];

  const extractIcon = useMemo(() => (
    <a rel="noreferrer" target="_blank" href={`/firebase/${updateField.id}`}>
      <IoIosDocument size={38} color="#000" />
    </a>
  ), [updateField]);

  async function handleOnUpdateStatus(e: any): Promise<void> {
    const status = +e.currentTarget.value;

    await onUpdateStatus(status);

    if (status === 300) {
      alert('Movido para gestão');
      history.push('/dossie');
    }
  }

  return (
    <div className="col" style={ContainerLead}>
      <Modal isOpen={toggleModal} setIsOpen={setToggleModal}>
        {typeof bgCheckedData === 'object' && Object.values(bgCheckedData).length > 0 ? (
          <div className="col center">
            <span>Código de controle: <b>{bgCheckedData.code_control}</b></span>
            <span>Nome: <b>{bgCheckedData.name}</b></span>
            {bgCheckedData.social_name && (
              <span>Nome social: <b>{bgCheckedData.social_name}</b></span>
            )}
            <span>Data de nascimento: <b>{bgCheckedData.birth_date}</b></span>
            <span>CPF/CNPJ: <b>{lead.document}</b></span>
            <span>Situação cadastral: <b>{bgCheckedData.cadastral_situation}</b></span>
            <span>Tipo: <b>{bgCheckedData.name_type}</b></span>
            <span>Digito verificador: <b>{bgCheckedData.verifying_digit}</b></span>
            <span>Data de inscrição: <b>{bgCheckedData.subscription_date}</b></span>
            <span>Data de emissão: <b>{bgCheckedData.emission_date}</b></span>
            <span>Hora da emissão: <b>{bgCheckedData.emission_hour}</b></span>
            {bgCheckedData.additional_info && (
              <span>Informações adicionais: <b>{bgCheckedData.additional_info}</b></span>
            )}
            <a className="orange 10p" rel="noreferrer" target="_blank" href={`${process.env.REACT_APP_FIREBASE_STORAGE_CRM_PATH_URL}${updateField.id}~2FFotos`}>
              Acessar Fotos
            </a>
          </div>
        ) : (
          <>
            {typeof bgCheckedData === 'object' ? (
              <span>Carregando...</span>
            ) : (
              <>
                {lead.bgChecked ? (
                  <span>Dados inválidos</span>
                ) : (
                  <span>Usuário não preencheu os dados (Token não encontrado)</span>
                )}
              </>
            )}
          </>
        )}
      </Modal>
      <div className="row center" style={{ justifyContent: 'space-between' }}>
        <span className="fo16" style={SpanStyle}><b>Código: {updateField.id}</b></span>
        <select className="10r 10p" defaultValue={lead.status} onChange={handleOnUpdateStatus}>
          <option value="300">Inicio</option>
          <option value="301">Excluido</option>
          <option value="310">Cadastro</option>
          <option value="320">Não contemplada</option>
          <option value="330">Contemplada</option>
          <option value="340">Finalizada</option>
          <option value="350">Cancelada</option>
        </select>
      </div>
      <div className="row center wrap">
        <div className="col 1s">
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('name')}>
            <span style={SpanStyle}>
              Nome:
              <span hidden={toggleTarget === 'name'} style={SpanStyle}> {updateField.display}</span>
              <input ref={nameRef} defaultValue={updateField.display} onBlur={handleOnBlur} hidden={toggleTarget !== 'name'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('email')}>
            <span style={SpanStyle}>
              E-mail:
              <span hidden={toggleTarget === 'email'} style={SpanStyle}> {updateField.email}</span>
              <input ref={emailRef} defaultValue={updateField.email} onBlur={handleOnBlur} hidden={toggleTarget !== 'email'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('target')}>
            <span style={SpanStyle}>
              Vindo do:
              <span hidden={toggleTarget === 'target'} style={SpanStyle}> {target}</span>
              <select ref={targetRef} defaultValue={updateField.target} onBlur={handleOnBlur} hidden={toggleTarget !== 'target'}>
                {
                  Object.values(targetOptions).map((itemTarget: any, index: number) => (
                    <option key={itemTarget} value={index}>{itemTarget}</option>
                  ))
                }
              </select>
            </span>
          </div>
        </div>
        <div className="col 1s">
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('phone')}>
            <span style={SpanStyle}>
              Telefone:
              <span hidden={toggleTarget === 'phone'} style={SpanStyle}> {updateField.phone}</span>
              <input ref={phoneRef} defaultValue={updateField.phone} maxLength={15} onBlur={handleOnBlur} onChange={phoneMaskByElement} hidden={toggleTarget !== 'phone'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('tmsIn')}>
            <span style={SpanStyle}>
              Data:
              <span hidden={toggleTarget === 'tmsIn'} style={SpanStyle}> {updateField.dateIn}</span>
              <input ref={tmsInRef} defaultValue={updateField.dateIn} maxLength={10} onKeyDown={dateMaskByElementKeyDown} onBlur={handleOnBlur} hidden={toggleTarget !== 'tmsIn'} type="text" />
            </span>
          </div>
        </div>
        <div style={ExtraDataContainer}>
          <button type="button" style={AddressBookButton} onClick={handleContactLeadByMessages}>
            <RiChat4Fill size={29} color="#000" />
          </button>
          <button type="button" style={AddressBookButton} onClick={handleCreateContact}>
            <RiUserAddFill size={29} color="#000" />
          </button>
          <button type="button" style={AddressBookButton} onClick={handleConsultValidationDocuments}>
            <FaAddressBook size={29} color="#000" style={AddressBookIcon} />
          </button>
          {extractIcon}
        </div>
      </div>
    </div>
  );
};

export default LeadComponent;
