import React from 'react';

import { ModalAttributes } from './interface';

import { BackgroundContainer, Container } from './styles';

const Modal: React.FC<ModalAttributes> = ({
  isOpen, setIsOpen, onClose, maxWidth = 400, height = '', children,
}) => {
  const handleToggleModal = (): void => {
    if (isOpen && onClose) {
      onClose();
    }

    setIsOpen(!isOpen);
  };

  return (
    <div style={{ visibility: `${isOpen ? 'visible' : 'hidden'}` as const, zIndex: 9999 }}>
      <div
        style={BackgroundContainer}
        onClick={handleToggleModal}
      />
      <div style={{ ...Container, maxWidth, height }}>{children}</div>
    </div>
  );
};

export default Modal;
