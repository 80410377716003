import React, { useMemo } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { MaskNumberToBRL, tmsToDateMask } from '../../../../../../utils/masks';

import Form from '../Form';

import { ListUsersAttributes } from './interface';

import {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
  SpanHeader,
  XCross,
} from './styles';

const ListUsers: React.FC<ListUsersAttributes> = ({
  assignees,
  listUsers,
  administrators,
  handleOnSubmit,
  handleMoveSelect,
  handleOnDelete,
  handleUpdateStrategy,
  handleOnChangeAssignee,
}) => {
  const listUsersComponent = useMemo(() => {
    if (!listUsers.length) {
      return <div className="20p">Tudo em ordem!</div>;
    }

    const brMonths = {
      0: 'Janeiro',
      1: 'Fevereiro',
      2: 'Março',
      3: 'Abril',
      4: 'Maio',
      5: 'Junho',
      6: 'Julho',
      7: 'Agosto',
      8: 'Setembro',
      9: 'Outubro',
      10: 'Novembro',
      11: 'Dezembro',
    } as any;

    return listUsers.map((userLead) => (
      <div className="960y row 20r" style={Content} key={userLead.id}>
        <div className="100w Gray2" style={ContainerLead}>
          <div style={SpanHeader} className="row center wrap">
            <span style={SpanStyle} className="fo16"><b>Código: {userLead.id}</b></span>
            <div>
              <select className="Blanc 10p 10r" defaultValue="" onChange={(e) => handleMoveSelect(e, userLead)}>
                <option value="" disabled>Mover para</option>
              </select>
            </div>
          </div>
          <Link className="row center black 1s" to={`/crm/extract/${userLead.id}`}>
            <div className="col">
              <div className="row center wrap">
                <div className="col 1s">
                  <span style={SpanStyle}>Nome: {userLead.display}</span>
                  <span style={SpanStyle}>E-mail: {userLead.email}</span>
                  {userLead.tmsPricing && (
                    <span style={SpanStyle}>
                      Data da proposta: {tmsToDateMask(userLead.tmsPricing)}
                    </span>
                  )}
                  {userLead.tmsDeny && (
                    <span style={SpanStyle}>
                      Data da proposta <b>NEGADA</b>: {tmsToDateMask(userLead.tmsDeny)}
                    </span>
                  )}
                  <span style={SpanStyle}>Administradora: {
                    administrators.find(
                      (administrator) => administrator.id === +userLead.administrator,
                    )?.display
                  }
                  </span>
                </div>
                <div className="col 1s">
                  <span style={SpanStyle}>Data: {tmsToDateMask(userLead.tmsIn)}</span>
                  <span style={SpanStyle}>Telefone: {userLead.phone}</span>
                </div>
              </div>
            </div>
          </Link>
          <Form
            handleUpdateStrategy={handleUpdateStrategy}
            userLead={userLead}
            assignees={assignees}
            fnOnSubmit={handleOnSubmit}
            fnOnChangeAssignee={handleOnChangeAssignee}
          />
          <div className="col">
            {
              userLead.mesReajuste ? (
                <i className="gray">
                  Mês reajuste: {brMonths[userLead.mesReajuste]}
                </i>

              ) : (
                <i className="gray">
                  Mês reajuste: N/A
                </i>
              )
            }
            {
              userLead.canceledMinimumValue
                && userLead.canceledMaximumValue ? (
                  <i className="gray">
                    Sugestão (Cancelada): {`${MaskNumberToBRL(userLead.canceledMinimumValue)} ~ ${MaskNumberToBRL(userLead.canceledMaximumValue)}`}
                  </i>
                ) : (
                  <i className="gray">
                    Sugestão (Cancelada): N/A
                  </i>
                )
            }
            {
              userLead.quitterMinimumValue
                && userLead.quitterMaximumValue ? (
                  <i className="gray">
                    Sugestão (Quitação): {`${MaskNumberToBRL(userLead.quitterMinimumValue)} ~ ${MaskNumberToBRL(userLead.quitterMaximumValue)}`}
                  </i>
                ) : (
                  <i className="gray">
                    Sugestão (Quitação): N/A
                  </i>
                )
            }
            {
              (userLead.quotaMonth !== undefined && userLead.quotaMonth !== null)
                ? (
                  <i className="gray">
                    Quantidade contemplação: {userLead.quotaMonth}
                  </i>
                ) : (
                  <i className="gray">
                    Quantidade contemplação: N/A
                  </i>
                )
            }
          </div>
        </div>
        <div style={{ ...ExtractStatusContainer, backgroundColor: userLead.extract ? '#1e8cf9' : '#ef5350' }}>
          <button className="1p 5r" style={XCross} onClick={() => handleOnDelete(userLead)} type="button">
            <FaTimes className="col center" size={16} />
          </button>
        </div>
      </div>
    ));
  }, [listUsers, administrators]);

  return <div className="100w col center">{listUsersComponent}</div>;
};

export default ListUsers;
