const Container = {
  position: 'fixed' as const,
  bottom: '24px',
  right: '24px',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  flexDirection: 'column' as const,
  zIndex: 200,
};

export {
  Container,
};
