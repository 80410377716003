import React, { useRef } from 'react';
import { RiFileExcelLine, RiUploadCloud2Line } from 'react-icons/ri';

import { ModalOptionsComponent } from './interface';

import {
  Container,
} from './styles';

const ModalOptions: React.FC<ModalOptionsComponent> = ({
  handleOnClick,
  handleUploadCSV,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  function handleOpenUploadFile(): void {
    inputRef.current?.click();
  }

  return (
    <div style={Container}>
      <input id="file-upload" ref={inputRef} onChange={(e) => handleUploadCSV(e.currentTarget.files[0])} style={{ visibility: 'hidden' }} type="file" />
      <button
        className="blanc darken Blue"
        type="button"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '54px',
          width: '54px',
          borderRadius: '100%',
          border: 0,
        }}
        onClick={handleOnClick}
      >
        <RiFileExcelLine size={24} />
      </button>
      <button
        className="blanc darken Green"
        type="button"
        onClick={handleOpenUploadFile}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '54px',
          width: '54px',
          borderRadius: '100%',
          marginTop: '8px',
          border: 0,
        }}
      >
        <RiUploadCloud2Line size={24} />
      </button>
    </div>
  );
};

export default ModalOptions;
