import React from 'react';

import { InputAttributes } from './interface';

import { InputStyle } from './styles';

const Input: React.FC<InputAttributes> = ({
  reference, labelName, defaultValue, value, ...rest
}) => {
  if (defaultValue) {
    if (value) {
      return (
        <div className="10p 100w">
          {labelName && <label className="blanc" htmlFor={rest.name}>{labelName}:</label>}
          <input className="blanc 100w 10p 10r" value={value} defaultValue={defaultValue} style={InputStyle} ref={reference} {...rest} />
        </div>
      );
    }

    return (
      <div className="10p 100w">
        {labelName && <label className="blanc" htmlFor={rest.name}>{labelName}:</label>}
        <input className="blanc 100w 10p 10r" defaultValue={defaultValue} style={InputStyle} ref={reference} {...rest} />
      </div>
    );
  }

  if (value) {
    return (
      <div className="10p 100w">
        {labelName && <label className="blanc" htmlFor={rest.name}>{labelName}:</label>}
        <input className="blanc 100w 10p 10r" value={value} style={InputStyle} ref={reference} {...rest} />
      </div>
    );
  }

  return (
    <div className="10p 100w">
      {labelName && <label className="blanc" htmlFor={rest.name}>{labelName}:</label>}
      <input className="blanc 100w 10p 10r" style={InputStyle} ref={reference} {...rest} />
    </div>
  );
};

export default Input;
