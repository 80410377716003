import React, {
  useRef, useCallback, MutableRefObject,
} from 'react';

import { currencyMaskByElement } from '../../../../../../utils/masks';

import { PricingFormProps } from './interface';

import {
  SubmitButton,
  DenyButton,
  ContainerButtons,
} from './styles';

const Form: React.FC<PricingFormProps> = ({
  userLead, fnOnSubmit, handleUpdateStrategy, assignees, fnOnChangeAssignee,
}) => {
  const inputRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const optionStatusRef = useRef() as MutableRefObject<HTMLSelectElement>;

  const handleOnSubmit = useCallback((e: any): void => {
    const objSubmit = {
      ...userLead,
      id: userLead.id,
      cessionario: +optionStatusRef.current.value,
      pricing: inputRef.current.value,
      status: e.nativeEvent.submitter.id === 'deny' ? 131 : 120,
      sendNotification: true,
    };

    const todayTMS = new Date().getTime();

    if (e.nativeEvent.submitter.id === 'deny') {
      fnOnSubmit({
        ...objSubmit,
        tmsDeny: todayTMS,
      });
    } else {
      fnOnSubmit({
        ...objSubmit,
        tmsPricing: todayTMS,
      });
    }
  }, [fnOnSubmit, userLead]);

  const assigneeContemplato = assignees?.find((assignee) => assignee.display === 'Contemplato');

  return (
    <form className="col 1s 100w" onSubmit={(a) => handleOnSubmit(a)}>
      <div className="row center">
        <input ref={inputRef} name="price" onChange={(e) => currencyMaskByElement(e)} placeholder="Preço" maxLength={14} className="Blanc 10p 100w 10r" required />
        <select className="Blanc 10p 10r 100w" defaultValue={userLead.strategy} style={{ marginLeft: '16px' }} onChange={(e) => handleUpdateStrategy(e, userLead)}>
          <option disabled value={0}>Estratégia</option>
          <option value={1}>Quitação</option>
          <option value={2}>Cancelada</option>
          <option value={3}>Intermediação</option>
        </select>
        <div className="col center" style={{ marginLeft: '16px' }}>
          <select className="10p 10r 100w" ref={optionStatusRef} defaultValue={userLead?.cessionario || assigneeContemplato?.id} onChange={(e) => fnOnChangeAssignee(userLead, +e.currentTarget.value)}>
            <option value={0} disabled>Cessionário</option>
            {assignees.map((assignee) => (
              <option key={assignee.id} value={assignee.id}>{assignee.display}</option>
            ))}
          </select>
        </div>
      </div>
      <div style={ContainerButtons} className="row wrap">
        <button className="100w 10r 10p Blue2" style={SubmitButton} type="submit">Enviar Proposta</button>
        <button id="deny" className="100w 10r 10p Tomato" style={DenyButton} type="submit">Recusar</button>
      </div>
    </form>
  );
};
export default Form;
