import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';

import { DropZoneAttributes } from './interface';

import { Container } from './styles';

const DropZone:React.FC<DropZoneAttributes> = ({ onDropAccepted, isMultiple = true }) => {
  async function handleOnDropAccepted(acceptedFiles: any): Promise<void> {
    const files = await Promise.all(acceptedFiles.map(async (file: any) => {
      const reader = new FileReader();

      const base64 = new Promise(
        (resolve) => reader.onload = (event) => resolve(event.target?.result),
      );

      reader.readAsDataURL(file);

      return {
        id: uuidv4(),
        ...file,
        base64: await base64,
      };
    }));

    onDropAccepted(files);
  }

  const {
    getRootProps, getInputProps, isDragAccept, isDragReject, draggedFiles,
  } = useDropzone({ onDropAccepted: handleOnDropAccepted, accept: 'application/pdf', multiple: isMultiple });

  const dropZoneClassName = useMemo(() => {
    if (isDragAccept) {
      return 'Blanc black dossie-dropzone 100w gray dropzone-accept';
    } if (isDragReject) {
      return 'Blanc black dossie-dropzone 100w gray dropzone-reject';
    }
    return 'Blanc black dossie-dropzone 100w gray dropzone';
  }, [isDragAccept, isDragReject]);

  return (
    <div className={dropZoneClassName} style={Container} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragAccept && <span>Solte o seu arquivo!</span>}
      {(draggedFiles.length > 1 && !isMultiple) ? <span>Apenas um arquivo!</span> : (
        isDragReject && <span>Arquivo não suportado, apenas PDF&apos;s</span>
      )}
      {(!isDragReject && !isDragAccept) && (
        <span>Arraste seu arquivo aqui</span>
      )}
    </div>
  );
};

export default DropZone;
