import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { mutate as mutateGlobal } from 'swr';

import ModalOptions from '../../components/ModalOptions';
import Header from '../../components/Header';
import ListUsers from './components/ListUsers';
import Filter from '../../components/Filter';

import api from '../../../../services/api';
import apiMogno from '../../../../services/apiMogno';

import { useAuth } from '../../../../hooks/auth';
import { useFetch } from '../../../../hooks/fetch';
import { useInfiniteScroll } from '../../../../hooks/infiniteScroll';

import { UserLeadsAll } from '../../../_interfaces';

let initialLeads: any[] = [];

const Pricing: React.FC = () => {
  const { token, handleLogout } = useAuth();
  const { pagination, infiniteScrollPagination } = useInfiniteScroll();

  const [listUsers, setListUsers] = useState([] as UserLeadsAll[]);
  const [assignees, setAssignees] = useState([] as any[]);
  const [filteredUsers, setFilteredUsers] = useState([] as UserLeadsAll[]);
  const [isLoading, setIsLoading] = useState(true);

  const { apiSwr } = useFetch();

  const { dataFetch, mutate } = apiSwr('list-120', '/output/atendimento', token, {
    status: 120,
  });

  useEffect(() => {
    function handleOnRequest(): void {
      setListUsers(dataFetch.data);
      setIsLoading(false);

      initialLeads = dataFetch.data;
    }

    if (dataFetch?.data) {
      handleOnRequest();
    }
  }, [dataFetch]);

  useEffect(() => {
    async function requestAssignees(): Promise<void> {
      const { data } = await api.post('/output/cessionario', {
        agent: token,
      });

      setAssignees(data.data);
    }

    requestAssignees();
  }, []);

  useEffect(() => {
    function fetchRequests(): void {
      if (filteredUsers.length) {
        setListUsers(filteredUsers.slice(0, pagination * 30));
      } else if (listUsers.length) {
        setListUsers(listUsers.slice(0, pagination * 30));
      }
    }

    if (!listUsers.length) {
      fetchRequests();
      infiniteScrollPagination();
    }
  }, [pagination, filteredUsers]);

  const handleOnAccept = async (userLead: UserLeadsAll): Promise<void> => {
    const usersFiltered = listUsers.filter((user: any) => user.id !== userLead.id);

    try {
      setListUsers(usersFiltered);
      setFilteredUsers(usersFiltered);

      const assignee = assignees.find((itemAssignee) => itemAssignee.id === userLead.cessionario);

      // cessionário id 1 = Fundo Contemplato
      if (assignee.id === 1) {
        await apiMogno.post('/quota', userLead);
      }

      const { data } = await api.put('/input/atendimento', {
        id: userLead.id,
        cessionario: userLead.cessionario,
        status: 200,
        agent: token,
        sendNotification: true,
        tmsAccept: new Date().getTime(),
        tmsOut: new Date().getTime(),
        isSaasV2: userLead.isSaasV2,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      } else {
        mutate(usersFiltered, false);
        mutateGlobal('list-200', {}, true);
      }
    } catch (err: any) {
      if (err?.isAxiosError) {
        const { data } = await api.put('/input/atendimento', {
          id: userLead.id,
          cessionario: userLead.cessionario,
          status: 400,
          agent: token,
          sendNotification: true,
          tmsAccept: new Date().getTime(),
          tmsOut: new Date().getTime(),
        });

        if (!data.status) {
          alert(`Erro: ${data.message}`);
          handleLogout();
        } else {
          mutate(usersFiltered, false);
          mutateGlobal('list-200', {}, true);
        }
      } else {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  };

  const handleOnRefused = async (userLead: UserLeadsAll): Promise<void> => {
    try {
      const usersFiltered = listUsers.filter((user: any) => user.id !== userLead.id);

      setListUsers(usersFiltered);
      setFilteredUsers(usersFiltered);

      const { data } = await api.put('/input/atendimento', {
        id: userLead.id,
        status: 134,
        agent: token,
        sendNotification: true,
        tmsDeny: new Date().getTime(),
        isSaasV2: userLead.isSaasV2,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      } else {
        mutate(usersFiltered, false);
        mutateGlobal('list-134', {}, true);
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleMoveSelect = async (e: any, userLead: UserLeadsAll): Promise<void> => {
    try {
      const { value } = e.currentTarget;

      const usersFiltered = listUsers.filter((user: any) => user.id !== userLead.id);

      setListUsers(usersFiltered);
      setFilteredUsers(usersFiltered);

      const { data } = await api.put('/input/atendimento', {
        id: userLead.id,
        status: +value,
        agent: token,
        isSaasV2: userLead.isSaasV2,
      });

      if (!data.status) {
        alert(`Erro: ${data.message}`);
        handleLogout();
      } else {
        mutate(usersFiltered, false);
        mutateGlobal(`list-${value}`, {}, true);
      }
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  const handleOnDelete = useCallback(async (userLead: UserLeadsAll): Promise<void> => {
    const r = confirm('Você quer mesmo deletar?');

    if (r) {
      try {
        if (filteredUsers.length) {
          const usersFiltered = filteredUsers.filter((user) => user.id !== userLead.id);
          setFilteredUsers(usersFiltered);
        }

        const usersFiltered = listUsers.filter((user) => user.id !== userLead.id);
        setListUsers(usersFiltered);

        const { data: updateData } = await api.put('/input/atendimento', {
          id: userLead.id,
          status: 1,
          agent: token,
          isSaasV2: userLead.isSaasV2,
        });

        if (!updateData.status) {
          alert(`Erro: ${updateData.message}`);
          handleLogout();
        } else {
          mutate(usersFiltered, false);
          mutateGlobal('list-120', {}, true);
        }
      } catch (err) {
        alert('Falha na requisição, tente novamente.');
        console.error(err);
      }
    }
  }, [token, listUsers]);

  const handleOnFilterLeads = async (filteredLeads: any): Promise<void> => {
    setListUsers(filteredLeads);
  };

  return (
    <div className="col center">
      <Header />
      <ModalOptions
        status={120}
        listUsers={listUsers}
        setFilteredUsers={setFilteredUsers}
      />
      {
        isLoading ? (
          <div style={{ marginTop: '16px' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="100w" style={{ maxWidth: '960px', marginTop: '16px' }}>
              <Filter initialLeads={initialLeads} onFilter={handleOnFilterLeads} />
            </div>
            <ListUsers
              listUsers={filteredUsers.length ? filteredUsers : listUsers}
              handleOnAccept={handleOnAccept}
              handleOnRefused={handleOnRefused}
              handleMoveSelect={handleMoveSelect}
              handleOnDelete={handleOnDelete}
              assignees={assignees}
            />
          </>
        )
      }
    </div>
  );
};

export default Pricing;
