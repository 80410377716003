import React, { useRef } from 'react';

import Input from '../../../../components/Input';
import { removeEmptyFields } from '../../../../../../utils/functionalities';

import { ModalCommissionAttributes } from './interface';

import {
  BackgroundContainer,
  Container,
  Content,
  ButtonModalContainer,
} from './styles';

const Modal: React.FC<ModalCommissionAttributes> = ({
  isOpen,
  setIsOpen,
  onSubmit,
  assignees,
}) => {
  const displayRef = useRef<HTMLInputElement>(null);
  const commissionRef = useRef<HTMLInputElement>(null);
  const assigneeRef = useRef<HTMLSelectElement>(null);
  const strategyRef = useRef<HTMLSelectElement>(null);

  const handleOnSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    const displayElement = displayRef.current;
    const commissionElement = commissionRef.current;
    const assigneeElement = assigneeRef.current;
    const strategyElement = strategyRef.current;

    if (
      !displayElement?.value
      || !commissionElement?.value
      || !assigneeElement?.value
      || !strategyElement?.value
    ) {
      throw alert('Campos vazios, por favor, preencha os campos corretamente');
    }

    if (isNaN(+commissionElement?.value)) {
      throw alert('O campo comissão deve ser um número');
    }

    await onSubmit(removeEmptyFields({
      display: displayElement?.value,
      commission: commissionElement?.value,
      cessionario: +assigneeElement?.value,
      strategy: +strategyElement?.value,
    }));

    setIsOpen(false);
  };

  return (
    isOpen ? (
      <div style={Container}>
        <div
          style={BackgroundContainer}
          onClick={() => setIsOpen(!isOpen)}
        />
        <div style={Content}>
          <h4>Cadastro da comissão</h4>
          <form onSubmit={handleOnSubmit} className="100w col">
            <div className="row">
              <Input reference={displayRef} name="display" labelName="Nome" />
              <Input reference={commissionRef} name="commission" labelName="Comissão" />
            </div>
            <div className="row">
              <label className="100w 10p">
                <span>Cessionário:</span>
                <select ref={assigneeRef} className="100w 10p 10r" style={{ height: '40px' }} defaultValue={1} name="pre_session" id="pre_session">
                  {
                    assignees.map(
                      (itemAssignee) => (
                        <option value={itemAssignee.id}>
                          {itemAssignee.display}
                        </option>
                      ),
                    )
                  }
                </select>
              </label>
              <label className="100w 10p">
                <span>Estratégia:</span>
                <select ref={strategyRef} className="100w 10p 10r" style={{ height: '40px' }} defaultValue={1} name="pre_session" id="pre_session">
                  <option disabled value={0}>Estratégia</option>
                  <option value={1}>Quitação</option>
                  <option value={2}>Cancelada</option>
                  <option value={3}>Intermediação</option>
                </select>
              </label>
            </div>
            <div className="10p">
              <button className="1p 10r 100w Green blanc" type="submit" style={ButtonModalContainer}>Cadastrar</button>
            </div>
          </form>
        </div>
      </div>
    ) : <div />
  );
};
export default Modal;
