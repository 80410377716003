import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import api from '../../../../services/api';

import { useCache } from '../../../../hooks/cacheServices';
import { useAuth } from '../../../../hooks/auth';

import Header from '../../components/Header';
import TableRate from './components/TableRate';

import { RouteParams } from '../../../_interfaces';
import {
  Rate, SubmitRate,
} from './interface';

import {
  Container,
  Content,
  Title,
  ButtonContainer,
} from './styles';
import ModalRate from './components/ModalRate';

const AdministratorRate: React.FC = () => {
  const [rates, setRates] = useState([] as Rate[]);
  const [isOpen, setIsOpen] = useState(false);

  const { token } = useAuth();
  const {
    items, types, requestItems, requestTypes,
  } = useCache();

  const { id } = useParams<RouteParams>();

  useEffect(() => {
    async function requestRate(): Promise<void> {
      const { data } = await api.post('/output/rate', {
        agent: token,
        administrator: +id,
      });

      setRates(data.data);
    }

    if (id && token) {
      requestRate();
      requestItems();
      requestTypes();
    }
  }, [id, token]);

  const handleOnUpdateRate = async (params: Rate): Promise<void> => {
    await api.put('/input/rate', {
      ...params,
      agent: token,
    });
  };

  const handleOnSubmit = async (rate: SubmitRate): Promise<void> => {
    const submitObj = {
      agent: token,
      administrator: +id,
      ...rate,
    };

    const { data } = await api.post('/input/rate', submitObj);

    if (data.status) {
      alert('Multa criada com sucesso!');

      const typeValue = types.find(
        (type) => type.id === submitObj.type,
      );

      const itemValue = items.find(
        (item) => item.id === submitObj.item,
      );

      setRates((allRates) => [...allRates, {
        ...submitObj,
        id: +data.data.insertId,
        displayItem: itemValue?.display,
        displayType: typeValue?.display,
      }]);
    } else {
      alert('Erro ao criar a multa, tente novamente.');
    }
  };

  return (
    <div>
      <Header />
      <ModalRate
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onSubmit={handleOnSubmit}
        items={items}
        types={types}
      />
      <div className="10p col" style={Container}>
        <div style={Content} className="col center Gray2 20r 20p">
          <h1 style={Title} className="fo20 10p">Administradora {rates[0]?.displayAdministrator}</h1>
          <TableRate
            rates={rates}
            onUpdateField={handleOnUpdateRate}
          />
          <button type="button" className="10r 100w 10p Green blanc" style={ButtonContainer} onClick={() => setIsOpen(!isOpen)}>Cadastrar nova multa</button>
        </div>
      </div>
    </div>
  );
};

export default AdministratorRate;
