import React, {
  useState, useRef, useEffect, MutableRefObject, useMemo,
} from 'react';
import { IoIosCloudUpload, IoIosDocument } from 'react-icons/io';
import { RiChat4Fill, RiUserAddFill } from 'react-icons/ri';

import apiSaas from '../../../../../../services/apiSaas';

import Modal from '../../../../components/Modal';

import { useGoogleApi } from '../../../../../../hooks/googleApi';

import { dateMaskByElementKeyDown, tmsToDateMask, phoneMaskByElement } from '../../../../../../utils/masks';
import { dateBrToDefaultString } from '../../../../../../utils/converts';

import { LeadProps } from './interface';

import {
  InputFile,
  ContainerLead,
  SpanStyle,
  ExtraDataContainer,
  AddContactButton,
  LinkDocument,
  LinkDocumentLabel,
} from './styles';

const LeadComponent: React.FC<LeadProps> = ({ lead, onUpdateUser, onStorageFile }) => {
  const { authenticate, createContact } = useGoogleApi();

  const operatorRef = useRef<HTMLSelectElement>(null) as MutableRefObject<HTMLSelectElement>;
  const nameRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const emailRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const phoneRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const tmsInRef = useRef<HTMLInputElement>(null) as MutableRefObject<HTMLInputElement>;
  const targetRef = useRef<HTMLSelectElement>(null) as MutableRefObject<HTMLSelectElement>;

  const [isOpen, setIsOpen] = useState(false);
  const [toggleTarget, setToggleTarget] = useState('');
  const [updateField, setUpdateField] = useState({ ...lead });

  function handleOnDoubleClick(field: string): void {
    setToggleTarget(field);
  }

  async function handleCreateContact(): Promise<void> {
    await authenticate();
    await createContact(lead.display, lead.phone);
  }

  function handleOnBlur(): void {
    const tmsIn = new Date(dateBrToDefaultString(tmsInRef.current.value)).getTime();

    const obj = {
      display: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      target: +targetRef.current.value,
      tmsIn,
    };

    updateField.dateIn = tmsToDateMask(obj.tmsIn);

    setToggleTarget('');
    setUpdateField({
      ...updateField,
      ...obj,
    });
    onUpdateUser(obj);
  }

  const extractIcon = useMemo(() => {
    if (lead.extract) {
      return (
        <a style={LinkDocument} className="darken" rel="noreferrer" target="_blank" href={`/firebase/${lead.id}`}>
          <IoIosDocument size={38} color="#000" />
        </a>
      );
    }

    return (
      <label htmlFor="extract_input" style={LinkDocumentLabel} className="darken">
        <IoIosCloudUpload size={38} color="#000" />
        <input
          id="extract_input"
          onChange={(e) => onStorageFile(e.currentTarget.files)}
          type="file"
          style={InputFile}
          multiple
        />
      </label>
    );
  }, [lead]);

  async function handleContactLeadByMessages(): Promise<any> {
    try {
      setIsOpen(false);

      const getAssigneeName:any = {
        2: 'consort',
        6: 'rodobens',
      };

      const operatorName = operatorRef.current.value;
      const assigneeName = getAssigneeName[lead?.cessionario] || 'contemplato';
      const campaignName = `init-chat-${assigneeName}`;

      await apiSaas.post('/v1/messaging-crm/open-chat', {
        campaignName,
        operatorName,
        contact: {
          phone: `55${lead.phone.replace(/\D/g, '')}`,
          name: lead.display,
        },
      });

      alert('Enviado com sucesso');
    } catch (err) {
      alert('Falha ao enviar a requisição, erro nos logs');

      console.log(err);
    }
  }

  function handleOnOpenModal(): any {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (toggleTarget) {
      nameRef.current.focus();
      emailRef.current.focus();
      phoneRef.current.focus();
      tmsInRef.current.focus();
      targetRef.current.focus();
    }
  }, [toggleTarget]);

  const targetOptions = {
    0: 'Formulário',
    1: 'Whatsapp',
    2: 'Facebook',
    3: 'Instagram',
    4: 'Outros',
    5: 'Saas',
  } as any;

  const target = targetOptions[updateField.target];

  return (
    <div className="col" style={ContainerLead}>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="100w 10p">
          <span>Selecione o operador</span>
          <select className="100w 10p 10r" ref={operatorRef}>
            <option value="adriele">adriele</option>
            <option value="debora">debora</option>
            <option value="fernando">fernando</option>
            <option value="luise">luise</option>
          </select>
          <button className="Green blanc 10p 10r 100w" type="button" onClick={handleContactLeadByMessages} style={{ marginTop: '8px' }}>Abrir Chat</button>
        </div>
      </Modal>
      <span className="fo16" style={SpanStyle}><b>Código: {updateField.id}</b></span>
      <div className="row center wrap">
        <div className="col 1s">
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('name')}>
            <span style={SpanStyle}>
              Nome:
              <span hidden={toggleTarget === 'name'} style={SpanStyle}> {updateField.display}</span>
              <input ref={nameRef} defaultValue={updateField.display} onBlur={handleOnBlur} hidden={toggleTarget !== 'name'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('email')}>
            <span style={SpanStyle}>
              E-mail:
              <span hidden={toggleTarget === 'email'} style={SpanStyle}> {updateField.email}</span>
              <input ref={emailRef} defaultValue={updateField.email} onBlur={handleOnBlur} hidden={toggleTarget !== 'email'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('target')}>
            <span style={SpanStyle}>
              Vindo do:
              <span hidden={toggleTarget === 'target'} style={SpanStyle}> {target}</span>
              <select ref={targetRef} defaultValue={updateField.target} onBlur={handleOnBlur} hidden={toggleTarget !== 'target'}>
                {
                  Object.values(targetOptions).map((itemTarget: any, index: number) => (
                    <option key={itemTarget} value={index}>{itemTarget}</option>
                  ))
                }
              </select>
            </span>
          </div>
          {updateField.tmsPricing && (
            <span style={SpanStyle}>
              Data da proposta: {tmsToDateMask(updateField.tmsPricing)}
            </span>
          )}
          {updateField.tmsDeny && (
            <span style={SpanStyle}>
              Data da proposta <b>NEGADA</b>: {tmsToDateMask(updateField.tmsDeny)}
            </span>
          )}
        </div>
        <div className="col 1s">
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('phone')}>
            <span style={SpanStyle}>
              Telefone:
              <span hidden={toggleTarget === 'phone'} style={SpanStyle}> {updateField.phone}</span>
              <input ref={phoneRef} defaultValue={updateField.phone} maxLength={15} onBlur={handleOnBlur} onChange={phoneMaskByElement} hidden={toggleTarget !== 'phone'} type="text" />
            </span>
          </div>
          <div className="row 1s" onDoubleClick={() => handleOnDoubleClick('tmsIn')}>
            <span style={SpanStyle}>
              Data:
              <span hidden={toggleTarget === 'tmsIn'} style={SpanStyle}> {updateField.dateIn}</span>
              <input ref={tmsInRef} defaultValue={updateField.dateIn} maxLength={10} onKeyDown={dateMaskByElementKeyDown} onBlur={handleOnBlur} hidden={toggleTarget !== 'tmsIn'} type="text" />
            </span>
          </div>
        </div>

        <div style={ExtraDataContainer}>
          <button type="button" style={AddContactButton} onClick={handleOnOpenModal}>
            <RiChat4Fill size={29} color="#000" />
          </button>
          <button type="button" style={AddContactButton} onClick={handleCreateContact}>
            <RiUserAddFill size={29} color="#000" />
          </button>
          {extractIcon}
        </div>
      </div>
    </div>
  );
};

export default LeadComponent;
