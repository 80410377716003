import React, {
  useEffect, useState, useCallback, useMemo, useRef,
} from 'react';
import {
  MdFolderOpen, MdLink, MdKeyboardArrowRight, MdOutlineCloudUpload, MdOutlineFileUpload, MdClose,
} from 'react-icons/md';
import {
  BsFileEarmarkExcelFill,
  BsFileEarmarkFill, BsFileEarmarkImageFill, BsFillFileEarmarkFontFill, BsFillFileEarmarkPdfFill,
} from 'react-icons/bs';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import {
  Modal, Box, Typography, Snackbar,
} from '@mui/material';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import api from 'services/api';
import { useAuth } from 'hooks/auth';
import { storage } from '../../../services/firebase';
import { uploadDocsApi } from '../../../services/uploadDocsApi';

import { FirebaseModalAttributes } from './interface';

import {
  Container,
  BackgroundContainer,
  Content,
  Wrapper,
  FolderLink,
  FolderHeaderLink,
  FolderHeaderLinkEditable,
  IconsStyle,
  CopyDocsButtonStyle,
  ModalConfirmStyle,
} from './styles';

const FirebaseModal: React.FC<FirebaseModalAttributes> = ({
  onCloseModal, id, selectable, hideHeader, onCheckFile, defaultSelected,
}) => {
  const [archives, setArchives] = useState([] as any[]);
  const [actualPath, setActualPath] = useState(`/${id}`);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const { token } = useAuth();

  useEffect(() => {
    if (defaultSelected) {
      setSelectedFiles(defaultSelected);
    }
  }, [defaultSelected]);

  const inputSearchRef = useRef<HTMLInputElement>(null);

  const storageRef = useMemo(() => storage.ref(`${process.env.REACT_APP_FIREBASE_STORAGE_PREFIX}${actualPath}`), [actualPath]);

  const currentPathArray = actualPath.split('/').filter((path) => path !== '');
  const currentPathId = +currentPathArray[0];

  const requestLeadOnFirebase = useCallback(async (): Promise<void> => {
    setIsLoading(true);

    const storageItemsResponse = await storageRef.list();

    const storageItemsPromise = storageItemsResponse.items.map(async (item: any) => {
      const itemUrl = await item.getDownloadURL();

      return ({
        name: item.name,
        type: 'file',
        fullPath: item.fullPath,
        url: itemUrl,
      });
    });

    const storageItems = await Promise.all(storageItemsPromise);

    const storageFolders = storageItemsResponse.prefixes.map((folder: any) => ({
      name: folder.name,
      fullPath: folder.fullPath,
      type: 'folder',
    }));

    const firebaseArchives = [
      ...storageItems,
      ...storageFolders,
    ];

    setArchives(firebaseArchives);
    setIsLoading(false);
  }, [storageRef]);

  const handleOnStorageFile = useCallback(async (
    files: FileList | null, path: string,
  ): Promise<void> => {
    setIsLoading(true);

    if (!files) {
      setIsLoading(false);
      throw alert('Arquivos reconhecidos como nulos, tente novamente.');
    }

    const arrayFiles = Object.values(files);

    await Promise.all(
      arrayFiles.map((file: File): any => {
        const refChild = storageRef.child(`${path}/${file.name}`);

        return refChild.put(file);
      }),
    );

    setActualPath(`/${currentPathId}/${path}`);
  }, [currentPathId, storageRef]);

  useEffect(() => {
    requestLeadOnFirebase();
  }, [requestLeadOnFirebase, storageRef, handleOnStorageFile]);

  useEffect(() => {
    inputSearchRef.current?.focus();
  }, [isEditable]);

  const pathsFirebase = useMemo(() => {
    if (currentPathArray.length > 1) {
      return <div />;
    }

    const possiblePaths = ['Fotos', 'Contrato', 'Comprovantes', 'ContratoAssinado', 'Fundo Comum', 'Reserva ou Sobra Resgatar', 'Lance e Quitação', 'Solicitação Fundo Comum', 'Solicitação de Crédito em Espécie', 'Solicitação de Transferência', 'Termo de cessão', 'Ficha cadastral', 'Boletos', 'Pagamentos', 'Recebimentos', 'Sedex (Recebimento e Envios)', 'Documentos públicos', 'Extratos'];

    return [...possiblePaths].map((path) => {
      const hasArchive = archives.find((archive) => archive.name === path);

      if (hasArchive) {
        return (
          <div
            key={path}
            className="firebase-hover-link"
            style={FolderLink}
            onClick={
              () => setActualPath(`/${currentPathId}/${path}`)
            }
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdFolderOpen size={24} color="#757575" />
              <span style={{ marginLeft: '8px' }}>{path}</span>
            </div>
          </div>
        );
      }

      return (
        <label key={path} htmlFor={`firebase-upload-file${path}`}>
          <div
            className="firebase-hover-link"
            style={FolderLink}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <MdOutlineCloudUpload size={24} color="#BEBEBE" />
              <span style={{ marginLeft: '8px', color: '#BEBEBE' }}>{path}</span>
            </div>
          </div>
          <input
            style={{ display: 'none' }}
            type="file"
            name={`firebase-upload-file${path}`}
            id={`firebase-upload-file${path}`}
            onChange={(e) => handleOnStorageFile(e.currentTarget.files, path)}
            multiple
          />
        </label>
      );
    });
  }, [archives]);

  const handleRemoveFile = useCallback(async (path: string) => {
    const confirmDelete = confirm('Você tem certeza que gostaria de remover o arquivo?');

    if (confirmDelete) {
      const deleteRef = storage.ref(path);

      await deleteRef.delete();

      setArchives(archives.filter((archive) => archive?.fullPath !== path));
    }
  }, [archives]);

  const filesFirebase = useMemo(() => archives
    .filter((archive) => archive.type === 'file')
    .map((archive) => {
      const nameFileSplited = archive.name.split('.');
      const extensionFile = nameFileSplited[nameFileSplited.length - 1];

      const generateImageFileByExtension = (extension: string): any => {
        switch (extension) {
          case 'pdf':
            return <BsFillFileEarmarkPdfFill key={extension} size={24} color="#757575" />;
          case 'png':
          case 'jpg':
          case 'jpeg':
            return <BsFileEarmarkImageFill key={extension} size={24} color="#757575" />;
          case 'csv':
            return <BsFileEarmarkExcelFill key={extension} size={24} color="#757575" />;
          case 'docx':
          case 'doc':
          case 'txt':
            return <BsFillFileEarmarkFontFill key={extension} size={24} color="#757575" />;
          default:
            return <BsFileEarmarkFill key={extension} size={24} color="#757575" />;
        }
      };

      function handleOnCheckFile(file: any): void {
        if (onCheckFile) {
          setSelectedFiles((currSelectedFiles) => {
            const hasFile = currSelectedFiles.find(
              (itemFile) => itemFile.fullPath === file.fullPath,
            );

            if (hasFile) {
              const filteredFiles = currSelectedFiles.filter(
                (itemFile) => itemFile.fullPath !== file.fullPath,
              );

              onCheckFile(file, filteredFiles);

              return filteredFiles;
            }

            onCheckFile(file, [...currSelectedFiles, file]);

            return [...currSelectedFiles, file];
          });
        }
      }

      const fileExtension = generateImageFileByExtension(extensionFile);

      const isChecked = !!selectedFiles.find(
        (selectedFile) => selectedFile?.fullPath === archive?.fullPath,
      );

      if (selectable) {
        return (
          <a
            className="firebase-hover-link"
            key={archive.name}
            href={archive.url}
            style={{
              ...FolderLink, display: 'flex', alignItems: 'center', padding: '4px',
            }}
            target="_blank"
            rel="noreferrer"
          >
            <Checkbox style={{ marginLeft: '4px' }} onClick={() => handleOnCheckFile(archive)} checked={isChecked} />
            <div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {fileExtension}
                <span style={{ marginLeft: '8px' }}>{archive.name}</span>
              </div>
            </div>
          </a>
        );
      }

      return (
        <div className="row firebase-hover-link">
          <a key={archive.name} href={archive.url} target="_blank" rel="noreferrer" style={{ width: '100%' }}>
            <div style={FolderLink}>
              <div className="firebase-hover-link" style={{ display: 'flex', alignItems: 'center' }}>
                {fileExtension}
                <span style={{ marginLeft: '8px' }}>{archive.name}</span>
              </div>
            </div>
          </a>
          <button type="button" onClick={() => handleRemoveFile(archive?.fullPath)} className="center" style={{ border: 0, backgroundColor: 'transparent', paddingRight: '16px' }}>
            <MdClose size={20} color="#555" />
          </button>
        </div>
      );
    }), [archives, selectable, onCheckFile, selectedFiles, handleRemoveFile]);

  const [modalOpen, setModalOpen] = useState({
    open: false,
    loading: false,
  });

  const [snackBar, setSnackBar] = useState({
    open: false,
    severity: '',
    message: '',
  });

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>((
    props,
    ref,
  ) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

  const handleCopyDocumentsToAnotherUser = async (): Promise<void> => {
    setModalOpen({ ...modalOpen, loading: true });
    const proposalId = inputUserIdRef.current?.value;
    const baseFolder = `${process.env.REACT_APP_FIREBASE_STORAGE_PREFIX}`;

    if (proposalId === '' || proposalId === undefined) {
      alert('Por favor colocar o id de um usuário no campo');
    } else {
      try {
        const { data: fromId } = await api.post('/output/dossie', {
          agent: token,
          id: +id,
        });

        const { data: toId } = await api.post('/output/dossie', {
          agent: token,
          id: +proposalId,
        });

        if (fromId.status && toId.status) {
          console.log(fromId.data);
          console.log(toId.data);

          if (fromId.data.length === 0 || toId.data.length === 0) {
            throw Error('ID inválido. Favor colocar um id válido.');
          }

          const fromIdData = fromId.data[0].idData;
          const toIdData = toId.data[0].idData;

          if (fromIdData !== toIdData) {
            throw Error('A cópia realizada não é para o mesmo usuário.');
          }
        } else {
          throw Error('Erro ao consultar a API. Favor tentar novamente.');
        }

        await Promise.all([
          uploadDocsApi.post('/documents/copy-documents/v2', {
            baseFolder,
            fromUserId: +id,
            toUserId: [+proposalId],
            postFolder: 'Comprovantes',
          }),
          uploadDocsApi.post('/documents/copy-documents/v2', {
            baseFolder,
            fromUserId: +id,
            toUserId: [+proposalId],
            postFolder: 'Fotos',
          }),
        ]);

        setSnackBar({
          open: true,
          severity: 'success',
          message: 'Dados copiados com sucesso!',
        });
      } catch (error: any) {
        setSnackBar({
          open: true,
          severity: 'error',
          message: error.message ?? 'Dados não copiados. Tente novamente.',
        });
      }
    }

    setModalOpen({ open: false, loading: false });
  };

  const inputUserIdRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Modal
        style={{ zIndex: 10000 }}
        open={modalOpen.open}
        onClose={() => setModalOpen({ ...modalOpen, open: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalConfirmStyle}>
          {
            modalOpen.loading
              ? (
                <CircularProgress color="secondary" />
              )
              : (
                <>
                  <Typography id="modal-modal-title" variant="h6" fontWeight="bold" component="h2">
                    Deseja copiar os documentos para outro usuário ?
                  </Typography>
                  <label htmlFor="user_id"> Id da Proposta
                    <input id="user_id" name="user_id" type="number" style={{ marginTop: '1rem', marginLeft: '1rem' }} ref={inputUserIdRef} />
                  </label>
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                    <button type="button" style={{ ...CopyDocsButtonStyle, width: '100%' }} onClick={handleCopyDocumentsToAnotherUser}>Sim</button>
                    <button
                      type="button"
                      style={{ ...CopyDocsButtonStyle, width: '100%', backgroundColor: '#EE4266' }}
                      onClick={() => setModalOpen({ ...modalOpen, open: false })}
                    >Não
                    </button>
                  </div>
                </>
              )

          }
        </Box>
      </Modal>
      <Snackbar
        open={snackBar.open}
        style={{ zIndex: 11000 }}
        autoHideDuration={6000}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
      >
        {
          snackBar.severity === 'success'
            ? (
              <Alert onClose={() => setSnackBar({ ...snackBar, open: false })} severity="success" sx={{ width: '100%' }}>
                {snackBar.message}
              </Alert>
            )
            : (
              <Alert onClose={() => setSnackBar({ ...snackBar, open: false })} severity="error" sx={{ width: '100%' }}>
                {snackBar.message}
              </Alert>
            )
        }
      </Snackbar>
      <div style={Container}>
        {onCloseModal && (
        <div
          style={BackgroundContainer}
          onClick={onCloseModal}
        />
        )}
        <div style={Content}>
          <div style={Wrapper}>
            <button type="button" style={CopyDocsButtonStyle} onClick={() => setModalOpen({ ...modalOpen, open: true })}>Copiar documentos</button>

            <div
              style={{
                ...FolderHeaderLinkEditable,
                display: isEditable ? 'flex' : 'none',
              }}
            >
              <input
                type="text"
                key={actualPath}
                defaultValue={actualPath}
                style={{
                  width: '100%', height: '100%', padding: '16px', fontSize: '18px', border: 0,
                }}
                onKeyDown={(e) => e.key === 'Enter' && inputSearchRef.current?.blur()}
                ref={inputSearchRef}
                onBlur={(e) => {
                  setIsEditable(false);
                  setActualPath(e.currentTarget.value);
                }}
              />
            </div>

            <div
              style={{
                ...FolderHeaderLink,
                display: isEditable || hideHeader ? 'none' : 'flex',
              }}
              onDoubleClick={() => {
                setIsEditable(!isEditable);
              }}
            >
              <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <MdLink size={24} color="#757575" style={{ marginRight: '2px' }} />
                <span style={{ marginLeft: '8px' }}>gs://{storageRef.bucket}</span>
                <MdKeyboardArrowRight size={20} color="#757575" style={IconsStyle} />
                <span>Documentos</span>
                <MdKeyboardArrowRight size={20} color="#757575" style={IconsStyle} />
                <span>CRM</span>
                <MdKeyboardArrowRight size={20} color="#757575" style={IconsStyle} />
                <span>Avaliacao</span>
                {
              currentPathArray.map((path) => (
                <div
                  key={path}
                  style={{
                    height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                  }}
                >
                  <MdKeyboardArrowRight size={20} color="#757575" style={IconsStyle} />
                  <span
                    className="firebase-hover-header-link"
                    style={{ color: '#000', cursor: 'pointer' }}
                    onClick={() => setActualPath((currentPath) => {
                      const pathIndex = currentPathArray.findIndex((oldPath) => path === oldPath);

                      if (pathIndex === currentPathArray.length - 1) {
                        return currentPath;
                      }

                      if (pathIndex > -1) {
                        const actualPaths = [...currentPathArray].splice(pathIndex, 1)?.join('');
                        return `/${actualPaths}`;
                      }

                      return `${actualPath}/${path}`;
                    })}
                  >{path}
                  </span>
                </div>
              ))
              }
              </div>
              <label style={{ cursor: 'pointer' }} htmlFor="firebase-upload-file">
                <MdOutlineFileUpload size={24} color="#757575" style={IconsStyle} />
                <input
                  style={{ display: 'none' }}
                  type="file"
                  name="firebase-upload-file"
                  id="firebase-upload-file"
                  onChange={(e) => handleOnStorageFile(e.currentTarget.files, '')}
                  multiple
                />
              </label>
            </div>

            {isLoading ? (
              <div
                className="40p"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <CircularProgress
                  size={40}
                />
              </div>
            ) : (
              <>
                {pathsFirebase}
                {filesFirebase}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FirebaseModal;
