const ContainerLead = {
  padding: '0 16px',
};

const SpanStyle = {
  margin: '8px 0',
};

const ExtraDataContainer = {
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'center',
};

const AddressBookIcon = {
};

const AddressBookButton = {
  backgroundColor: 'transparent',
  border: 0,
  marginBottom: '10px',
};

const LinkDocument = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column' as const,
  color: '#fff',
};

const LinkDocumentLabel = {
  ...LinkDocument,
  cursor: 'pointer',
};

const InputFile = {
  display: 'none',
};

export {
  LinkDocumentLabel,
  InputFile,
  ContainerLead,
  SpanStyle,
  ExtraDataContainer,
  AddressBookIcon,
  AddressBookButton,
};
