import React, { useRef, MutableRefObject } from 'react';

import { FormAttributes } from './interface';

import Input from '../../../../../Input';
import TextArea from '../../../../../TextArea';

const Form: React.FC<FormAttributes> = ({ onSubmit }) => {
  const optionsRef = useRef<HTMLInputElement>([] as any) as any;
  const subjectRef = useRef() as MutableRefObject<HTMLInputElement>;
  const messageRef = useRef() as MutableRefObject<HTMLTextAreaElement>;

  const handleOnSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    let option = '';

    const options = optionsRef.current;
    for (let i = 0, n = options.length; i < n; i++) {
      if (options[i]?.checked) {
        option = options[i].value;
      }
    }

    const obj = {
      subject: subjectRef.current?.value,
      message: messageRef.current?.value,
      option,
    };

    await onSubmit(obj);
  };

  return (
    <form className="100w" onSubmit={handleOnSubmit}>
      <div className="100w col center">
        <h2>Envio da Mensagem</h2>
      </div>
      <Input reference={subjectRef} labelName="Assunto" />
      <TextArea reference={messageRef} labelName="Mensagem" style={{ minHeight: '200px' }} />
      <div className="col 10p">
        <b className="5p">Forma de envio</b>
        <label htmlFor="radio-whatsapp">
          <input id="radio-whatsapp" ref={(el) => optionsRef.current[0] = el} name="option-send" type="radio" value="WhatsApp" defaultChecked />
          <span>Whatsapp</span>
        </label>
        <label htmlFor="radio-email">
          <input id="radio-email" ref={(el) => optionsRef.current[1] = el} name="option-send" type="radio" value="Mail" />
          <span>Email</span>
        </label>
        <label htmlFor="radio-both">
          <input id="radio-both" ref={(el) => optionsRef.current[2] = el} name="option-send" type="radio" value="Notification" />
          <span>Email e Whatsapp</span>
        </label>
      </div>
      <div className="100w 10p">
        <button className="100w 10p 10r Blue blanc" type="submit">Enviar mensagem</button>
      </div>
    </form>
  );
};

export default Form;
