import React, { useState, useEffect } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { DataGridPro } from '@mui/x-data-grid-pro';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import { LeadAttributes } from './interfaces';

const TableDataGrid: React.FC<LeadAttributes> = ({
  rows,
  columns,
  handleOnUpdate,
  handleOnInputChange,
  setIsOpen,
  isTableEditable,
  setIsTableEditable,
}) => {
  const [tableColumns, setTableColumns] = useState(columns);
  const [visibleColumns, setVisibleColumns] = useState(() => {
    const storagedData = localStorage.getItem('@Contemplato/columns');

    if (storagedData?.length) {
      return JSON.parse(storagedData);
    }

    return [];
  });

  useEffect(() => {
    if (visibleColumns?.length) {
      const newColumns = columns.map((column: any) => {
        const storagedColumn = visibleColumns.find(
          (visibleColumn: any) => visibleColumn.field === column.field,
        );

        if (storagedColumn) {
          column.hide = storagedColumn.hide;
        }

        return column;
      });

      setTableColumns(newColumns);
    } else {
      setTableColumns([...columns]);
    }
  }, [columns, visibleColumns, isTableEditable]);

  function handleOnColumnVisibilityChange(column: any): void {
    const newStoragedColumn = {
      field: column.field,
      hide: !column.isVisible,
    };

    setVisibleColumns((allVisibleColumns: any) => {
      const hasColumn = allVisibleColumns.find(
        (visibleColumn: any) => visibleColumn.field === newStoragedColumn.field,
      );

      const allColumns = allVisibleColumns.map((visibleColumn: any) => {
        if (visibleColumn.field === newStoragedColumn.field) {
          visibleColumn.hide = newStoragedColumn.hide;
        }

        return visibleColumn;
      });

      if (!hasColumn) {
        allColumns.push(newStoragedColumn);
      }

      localStorage.setItem('@Contemplato/columns', JSON.stringify(allColumns));

      return allColumns;
    });
  }

  return (
    <>
      <div className="row center 100w" style={{ marginBottom: '8px', padding: '0 16px', justifyContent: 'space-between' }}>
        <FormGroup>
          <FormControlLabel control={<Switch value={isTableEditable} onChange={() => setIsTableEditable((actualEdit: boolean) => !actualEdit)} />} label="Habilitar edição" />
        </FormGroup>
        <button
          type="button"
          className="blanc bold"
          style={{
            borderRadius: '4px', padding: '8px 10px', border: 0, backgroundColor: '#0A65FE',
          }}
          onClick={() => setIsOpen(true)}
        >
          <div className="100w row center">
            <AiOutlinePlusCircle size={24} style={{ marginRight: '6px' }} />
            Cadastrar lead
          </div>
        </button>
      </div>
      <DataGridPro
        checkboxSelection={false}
        rows={rows}
        columns={tableColumns}
        rowHeight={38}
        autoPageSize
        onColumnVisibilityChange={handleOnColumnVisibilityChange}
        pagination
        autoHeight
        classes={{
          columnHeader: 'datagrid-dossie-cell',
          root: 'datagrid-dossie-cell',
          row: 'datagrid-dossie-row',
        }}
        pageSize={20}
        isCellEditable={() => isTableEditable}
        onCellEditCommit={handleOnUpdate}
        disableSelectionOnClick
        onEditCellPropsChange={handleOnInputChange}
      />
    </>
  );
};
export default TableDataGrid;
