import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

import api from '../services/api';

interface RouteProps extends ReactDOMProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { token, handleLogout } = useAuth();

  api.interceptors.response.use((response) => {
    if (response.data.error === 'not-auth') {
      handleLogout();
    }

    return response;
  });

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => (isPrivate === !!token ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: isPrivate ? '/signin' : '/',
            state: { from: location },
          }}
        />
      ))}
    />
  );
};

export default Route;
