import React from 'react';

import Form from './components/Form';

import { SubmitParams } from './interface';

import { useAuth } from '../../../../hooks/auth';

import Logo from '../../../../assets/logo_contemplato_azul.png';

import {
  Container,
} from './styles';

const SignIn: React.FC = () => {
  const { handleLogin } = useAuth();

  const handleSignIn = async (formData: SubmitParams): Promise<void> => {
    try {
      await handleLogin(formData);
    } catch (err) {
      alert('Falha na requisição, tente novamente.');
      console.error(err);
    }
  };

  return (
    <div style={Container} className="row center 100w">
      <img
        src={Logo}
        alt="contemplato_logo"
        style={{
          position: 'absolute', top: '20px', left: '30px', width: 'auto', height: 'auto', maxHeight: '40px',
        }}
      />
      <Form
        fnOnSubmit={handleSignIn}
      />
    </div>
  );
};

export default SignIn;
