import React, {
  createContext, useCallback, useState, useContext,
} from 'react';

import api from '../../services/api';

import { AuthContextData, AuthData, AuthSessionData } from './interface';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [authData, setAuthData] = useState<AuthData>(() => {
    const token = localStorage.getItem('@Contemplato/token');
    const operator = localStorage.getItem('@Contemplato/operator');

    if (token && operator) {
      return {
        token,
        operator: JSON.parse(operator),
      };
    }

    return {} as AuthData;
  });

  const handleLogout = useCallback(() => {
    localStorage.removeItem('@Contemplato/token');

    setAuthData({} as AuthData);
  }, []);

  // useEffect(() => {
  //   const verifyToken = async (): Promise<void> => {
  //     const { token } = authData;

  //     if (token) {
  //       try {
  //         const { data } = await api.post('/crm/verify_token', {
  //           token,
  //         });

  //         if (!data.status) {
  //           handleLogout();
  //         }
  //       } catch (err) {
  //         handleLogout();
  //       }
  //     } else {
  //       handleLogout();
  //     }

  //     setIsLoading(false);
  //   };

  //   verifyToken();
  // }, [handleLogout]);

  const handleLogin = useCallback(
    async (formData: AuthSessionData): Promise<void> => {
      setIsLoading(true);

      try {
        const { data } = await api.post('/input/login', {
          ...formData,
        });

        if (data.status) {
          const operators: any = {
            'adriele@contemplato.com': 'adriele',
            'debora@contemplato.com': 'debora',
            'fernando@contemplato.com': 'fernando',
            'luise@contemplato.com': 'luise',
            'dev@contemplato.com': 'dev',
          };

          const operatorName: string = operators[formData.email] || '';

          const signedOperator = {
            name: operatorName,
            email: formData.email,
          };

          localStorage.setItem('@Contemplato/operator', JSON.stringify(signedOperator));
          localStorage.setItem('@Contemplato/token', data.data.token);

          setAuthData({
            operator: signedOperator,
            token: data.data.token,
          });
        } else {
          console.log(data.error);
          alert(`Erro: ${data.message}`);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        throw new Error(err as any);
      }
    }, [],
  );

  if (isLoading) {
    return (
      <div>
        Carregando...
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        token: authData.token,
        operator: authData.operator,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
