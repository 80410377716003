import React from 'react';

import { ModalAttributes } from './interface';

import { BackgroundContainer, Container, Content } from './styles';

const Modal: React.FC<ModalAttributes> = ({
  isOpen, setIsOpen, fullSize, children,
}) => (
  isOpen ? (
    <div style={Container}>
      <div
        style={BackgroundContainer}
        onClick={() => setIsOpen(!isOpen)}
      />
      <div style={Content(!!fullSize)}>{children}</div>
    </div>
  ) : <div />
);

export default Modal;
