import React, { useMemo } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { tmsToDateMask } from '../../../../../../utils/masks';

import { ListUsersAttributes } from './interface';

import {
  Content,
  ContainerLead,
  SpanStyle,
  ExtractStatusContainer,
  XCross,
} from './styles';

const ListUsers: React.FC<ListUsersAttributes> = ({
  listUsers,
  handleOnDelete,
}) => {
  const listUsersComponent = useMemo(() => {
    if (!listUsers.length) {
      return <div className="20p">Tudo em ordem!</div>;
    }

    return listUsers.map((lead) => (
      <Link className="960y row black 20r" style={Content} key={lead.id} to={`/crm/extract/${lead.id}`}>
        <div style={ContainerLead} className="row center Gray2 1s">
          <div className="col">
            <span style={SpanStyle} className="fo16"><b>Código: {lead.id}</b></span>
            <div className="row center wrap">
              <div className="col 1s">
                <span style={SpanStyle}>Nome: {lead.display}</span>
                <span style={SpanStyle}>E-mail: {lead.email}</span>
                {lead.tmsPricing && (
                <span style={SpanStyle}>
                  Data da proposta: {tmsToDateMask(lead.tmsPricing)}
                </span>
                )}
                {lead.tmsDeny && (
                <span style={SpanStyle}>
                  Data da proposta <b>NEGADA</b>: {tmsToDateMask(lead.tmsDeny)}
                </span>
                )}
              </div>
              <div className="col 1s">
                <span style={SpanStyle}>Data: {tmsToDateMask(lead.tmsIn)}</span>
                <span style={SpanStyle}>Telefone: {lead.phone}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ ...ExtractStatusContainer, backgroundColor: lead.extract ? '#1e8cf9' : '#ef5350' }}>
          <button className="1p 5r" style={XCross} onClick={() => handleOnDelete(lead)} type="button">
            <FaTimes className="col center" size={16} />
          </button>
        </div>
      </Link>
    ));
  }, [listUsers]);

  return <div className="100w col center">{listUsersComponent}</div>;
};

export default ListUsers;
