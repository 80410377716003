import React, { useMemo } from 'react';

import RateComponent from './components/RateComponent';

import { TableRateAttributes } from './interface';
import { Rate } from '../../interface';

import {
  TableContent,
  TableHeadContainer,
  TableHeadContent,
  TableBodyContainer,
} from './styles';

const TableRate: React.FC<TableRateAttributes> = ({
  onUpdateField,
  rates,
}) => {
  const administratorComponent = useMemo(() => rates.map(
    (rate: Rate) => (
      <RateComponent
        onUpdateField={onUpdateField}
        key={rate.id}
        rate={rate}
      />
    ),
  ), [rates, onUpdateField]);

  return (
    <table
      style={TableContent}
      className="10r 100w"
    >
      <thead className="100w" style={TableHeadContainer}>
        <tr className="100w">
          <th style={TableHeadContent}>
            Tipo
          </th>
          <th style={TableHeadContent}>
            Item
          </th>
          <th style={TableHeadContent}>
            Origin
          </th>
          <th style={TableHeadContent}>
            Taxa Máxima
          </th>
          <th style={TableHeadContent}>
            Taxa Mínima
          </th>
          <th style={TableHeadContent}>
            Multa 1
          </th>
          <th style={TableHeadContent}>
            Intervalo 1
          </th>
          <th style={TableHeadContent}>
            Multa 2
          </th>
          <th style={TableHeadContent}>
            Intervalo 2
          </th>
          <th style={TableHeadContent}>
            Multa 3
          </th>
          <th style={TableHeadContent}>
            Intervalo 3
          </th>
        </tr>
      </thead>
      <tbody style={TableBodyContainer}>
        {administratorComponent}
      </tbody>
    </table>
  );
};

export default TableRate;
