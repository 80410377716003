import React from 'react';

import { AuthProvider } from './auth';
import { FetchProvider } from './fetch';
import { NotificationProvider } from './notification';
import { CnabModalProvider } from './cnabModal';
import { CacheProvider } from './cacheServices';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <CacheProvider>
      <CnabModalProvider>
        <NotificationProvider>
          <FetchProvider>
            {children}
          </FetchProvider>
        </NotificationProvider>
      </CnabModalProvider>
    </CacheProvider>
  </AuthProvider>
);

export default AppProvider;
