const Container = {
  position: 'fixed' as const,
  bottom: '16px',
  left: '16px',
  border: '1px solid #00000020',
  overflow: 'hidden',
};

const ModalTable = {
  textAlign: 'left' as const,
};

const ModalTableTd = {
  backgroundColor: '#E8E8E8',
  borderTop: '1px solid #CFCFCF',
  padding: '8px 12px',
  margin: '8px',
};

const ModalTableTh = {
  padding: '12px',
};

export {
  Container,
  ModalTable,
  ModalTableTd,
  ModalTableTh,
};
